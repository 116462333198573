import {createSelector} from '@ngrx/store';
import {dataStateSelector} from '../data/selector';
import {GameGroupStateEnum, GameStateEnum} from '../../graphql/types.graphql-gen';

export const getGameVouchersSelector = createSelector(
    dataStateSelector,
    (data) => data.gameVoucher.gameVouchers
);

export const getActiveGameVoucherSelector = createSelector(
    dataStateSelector,
    (data) => data.gameVoucher.activeGameVoucher
);

export const getGameGroupOptionsSelector = createSelector(
    dataStateSelector,
    (data) => data.helperData.gameGroups?.filter((go) => go.games.length).sort((a, b) => {
        if(a.state !== 'hidden' && b.state !== 'hidden') {
            if(a.games.find((g) => g.state === GameStateEnum.Hidden)) {
                return 1;
            }
            if(b.games.find((g) => g.state === GameStateEnum.Hidden)) {
                return -1;
            }
            return 0;
        }
        if(a.state === GameGroupStateEnum.Hidden) {
            return 1;
        }
        return -1;
    })
);
