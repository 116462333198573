import {createSelector} from '@ngrx/store';
import {dataStateSelector} from '../data/selector';
import {IDataState} from '../state';
import {AttributeTypeEnum} from '../../graphql/types.graphql-gen';


export const getNavCollapsedSelector = createSelector(
    dataStateSelector,
    (data: IDataState) => data.helperData.navCollapsed
);


export const getProjectDataSelector = createSelector(
    dataStateSelector,
    (data: IDataState) => data.helperData.projectData
);

export const getAvailablePagesSelector = createSelector(
    getProjectDataSelector,
    (data) => data?.availablePages ?? []
)

export const getHelpersDataSelector = createSelector(
    dataStateSelector,
    (data: IDataState): IDataState['helperData'] => data.helperData
);

export const getVouchersFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['gameVouchers'] => helpersData.filters.gameVouchers
);

export const getPlayersFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['players'] => helpersData.filters.players
);

export const getPushPlayersFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['pushPlayers'] => helpersData.filters.pushPlayers
);

export const getPurchasesFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['purchases'] => helpersData.filters.purchases
);

export const getGameGroupsFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['gameGroups'] => helpersData.filters.gameGroups
);

export const getReviewsFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['reviews'] => helpersData.filters.reviews
);


export const getTranslationsFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['translations'] => helpersData.filters.translations
);

export const getProgressesFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['progresses'] => helpersData.filters.progresses
);

export const getLicensesFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['progresses'] => helpersData.filters.licenses
);

export const getPermissionGroupsFilterSelector = createSelector(
    getHelpersDataSelector,
    (helpersData): IDataState['helperData']['filters']['permissionGroups'] => helpersData.filters.permissionGroups
);

export const getAllPermissionsSelector = createSelector(
    getHelpersDataSelector,
    (data) => {
        return data.allPermissions
    }
);

export const getAvailableEditorsSelector = createSelector(
    getHelpersDataSelector,
    (data) => data.availableEditors
);

export const getAvailablePlayersSelector = createSelector(
    getHelpersDataSelector,
    (data) => data.availablePlayers
);

export const getTranslationsHelperDataSelector = createSelector(
    getHelpersDataSelector,
    (data) => data.translations
)


export const getAvailableAttributeOptionsSelector = createSelector(
    getProjectDataSelector,
    (data) => (data?.availableAttributes ?? [])
);

export const getAvailableMapOptionsSelector = createSelector(
    getProjectDataSelector,
    (data) => data?.availableMaps ?? []
);

export const getAvailableAttachmentOptionsSelector = createSelector(
    getProjectDataSelector,
    (data) => data?.availableAttachments ?? []
);

export const getAvailableLicensesSelector = createSelector(
    getProjectDataSelector,
    (data) => data?.availableLicenses ?? []
);
