import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetGameGroupsDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGameGroupsDataQuery = (
  { __typename?: 'Query' }
  & { getGameGroups: (
    { __typename?: 'GameGroupsResult' }
    & { availableGroupOptionsMeta: Array<(
      { __typename?: 'GameGroup' }
      & Pick<Types.GameGroup, 'id' | 'photoSource' | 'name' | 'state'>
      & { lang?: Types.Maybe<(
        { __typename?: 'Lang' }
        & Pick<Types.Lang, 'id' | 'flagPath'>
      )>, city?: Types.Maybe<(
        { __typename?: 'City' }
        & Pick<Types.City, 'id' | 'name'>
      )>, license?: Types.Maybe<(
        { __typename?: 'License' }
        & Pick<Types.License, 'id'>
      )>, games: Array<(
        { __typename?: 'Game' }
        & Pick<Types.Game, 'id' | 'name' | 'state' | 'difficulty' | 'price'>
        & { productPartner?: Types.Maybe<(
          { __typename?: 'ProductPartner' }
          & Pick<Types.ProductPartner, 'id'>
        )>, gameSaleSources: Array<(
          { __typename?: 'GameSaleSource' }
          & Pick<Types.GameSaleSource, 'price'>
          & { saleSource: (
            { __typename?: 'SaleSource' }
            & Pick<Types.SaleSource, 'id'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export const GetGameGroupsDataDocument = gql`
    query getGameGroupsData {
  getGameGroups {
    availableGroupOptionsMeta {
      id
      photoSource
      name
      state
      lang {
        id
        flagPath
      }
      city {
        id
        name
      }
      license {
        id
      }
      games(filter: {deletedAt: null, filter: {limit: 100}}) {
        id
        name
        state
        difficulty
        price
        productPartner {
          id
        }
        gameSaleSources {
          saleSource {
            id
          }
          price
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGameGroupsDataGQL extends Apollo.Query<GetGameGroupsDataQuery, GetGameGroupsDataQueryVariables> {
    document = GetGameGroupsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }