import {defaultDataState, IDataState} from '../state';
import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {
    fetchAvailableEditorsSuccessAction,
    fetchPlayersSuccessAction,
    fetchPlayerSuccessAction,
    updatePlayerSuccessAction
} from './actions';

export const playerReducer = createImmerReducer(
    defaultDataState,
    on(fetchPlayersSuccessAction, (state, action) => {
        if (action.result) {
            state.player.players = action.result;
        }
        return state;
    }),
    on(fetchPlayerSuccessAction, (state, action) => {
        state.player.activePlayer = action.player;
        return state;
    }),
    on(updatePlayerSuccessAction, (state: IDataState, action) => {
        const updatedPlayer = action.result.player;
        if(!updatedPlayer) {
            return state;
        }
        if(state.player.activePlayer) {
            state.player.activePlayer = action.result.player;
        } else {
            const index = state.player.players?.result.findIndex((p) => p.id === updatedPlayer.id) ?? -1;
            if(index !== -1 && state.player.players) {
                state.player.players.result[index] = updatedPlayer;
            }
        }
        return state;
    }),
    on(fetchAvailableEditorsSuccessAction, (state: IDataState, action) => {
        state.helperData.availableEditors = action.editors;
        return state;
    })
);
