import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchReviewsSuccessAction, fetchReviewSuccessAction, updateReviewSuccessAction} from './actions';

export const reviewReducer = createImmerReducer(
    defaultDataState,
    on(fetchReviewsSuccessAction, (state: IDataState, action: ReturnType<typeof fetchReviewsSuccessAction>) => {
        state.review.reviews = action.result;
        return state;
    }),
    on(fetchReviewSuccessAction, (state: IDataState, action: ReturnType<typeof fetchReviewSuccessAction>) => {
        state.review.activeReview = action.review;
        return state;
    }),
    on(updateReviewSuccessAction, (state: IDataState, action: ReturnType<typeof updateReviewSuccessAction>) => {
        if (action.result.review) {
            if (state.review.activeReview?.id === action.result.review.id) {
                state.review.activeReview = action.result.review;
            }
            if (state.review.reviews) {
                const index = state.review.reviews.result.findIndex((r) => r.id === action.result.review?.id)
                if (index !== -1) {
                    state.review.reviews.result[index] = action.result.review;
                }
            }
        }
        if (action.result.reviewRemoved) {
            if(state.review.reviews) {
                state.review.reviews.result = state.review.reviews.result.filter((r) => r.id !== action.result.reviewRemoved);
            }
            if(state.review.activeReview?.id === action.result.reviewRemoved) {
                state.review.activeReview = null;
            }
        }
        return state;
    })
);

