import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {Galleria} from 'primeng/galleria';

@Directive({
    selector: '[appExtendedGalleria]',
    exportAs: 'extended'
})
export class ExtendedGalleriaDirective {

    clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
    });
    constructor(
        private el: ElementRef,
        private galleria: Galleria
    ) {
    }

    @HostListener('click', ['$event']) onClick(e: any) {
        if (e.target?.nodeName?.toLowerCase() === 'div') {
            this.galleria.onMaskHide();
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if(event.key === 'ArrowLeft') {
            this.left();
        }
        if(event.key === 'ArrowRight') {
            this.right();
        }
    }

    left() {
        const element =  this.el.nativeElement.getElementsByClassName('p-galleria-item-prev-icon')[0];
        if(!element) {
            return;
        }
        element.dispatchEvent(this.clickEvent)
    }

    right() {
        const element =  this.el.nativeElement.getElementsByClassName('p-galleria-item-prev-icon')[0];
        if(!element) {
            return;
        }
        element.dispatchEvent(this.clickEvent)
    }


}
