import * as Types from '../../types.graphql-gen';

import { GameVoucherFragment } from '../../queries/getGameVouchers/getGameVouchers.graphql-gen';
import { gql } from 'apollo-angular';
import { GameVoucherFragmentDoc } from '../../queries/getGameVouchers/getGameVouchers.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateGameVoucherMutationVariables = Types.Exact<{
  input: Types.GameVoucherInput;
}>;


export type UpdateGameVoucherMutation = (
  { __typename?: 'Mutation' }
  & { updateGameVoucher: (
    { __typename?: 'UpdateGameVoucherResult' }
    & Pick<Types.UpdateGameVoucherResult, 'gameVoucherDeleted' | 'downloadLink'>
    & { gameVoucher?: Types.Maybe<(
      { __typename?: 'GameVoucher' }
      & GameVoucherFragment
    )>, gameVouchers?: Types.Maybe<Array<(
      { __typename?: 'GameVoucher' }
      & GameVoucherFragment
    )>>, commissionData?: Types.Maybe<(
      { __typename?: 'VoucherCommissionData' }
      & VoucherCommissionDataFragment
    )> }
  ) }
);

export type VoucherCommissionDataFragment = (
  { __typename?: 'VoucherCommissionData' }
  & Pick<Types.VoucherCommissionData, 'commissionProductPartnerPercent' | 'commissionSaleSourcePercent' | 'voucherPrice' | 'voucherRealPrice' | 'partnerDiscountType' | 'partnerDiscount' | 'partnerDiscountDirectSaleType' | 'partnerDiscountDirectSale' | 'partnerCommissionType' | 'gatewayCharge' | 'gatewayChargePercent'>
);

export const VoucherCommissionDataFragmentDoc = gql`
    fragment voucherCommissionData on VoucherCommissionData {
  commissionProductPartnerPercent
  commissionSaleSourcePercent
  voucherPrice
  voucherRealPrice
  partnerDiscountType
  partnerDiscount
  partnerDiscountDirectSaleType
  partnerDiscountDirectSale
  partnerCommissionType
  gatewayCharge
  gatewayChargePercent
}
    `;
export const UpdateGameVoucherDocument = gql`
    mutation updateGameVoucher($input: GameVoucherInput!) {
  updateGameVoucher(input: $input) {
    gameVoucher {
      ...gameVoucher
    }
    gameVouchers {
      ...gameVoucher
    }
    gameVoucherDeleted
    commissionData {
      ...voucherCommissionData
    }
    downloadLink
  }
}
    ${GameVoucherFragmentDoc}
${VoucherCommissionDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGameVoucherGQL extends Apollo.Mutation<UpdateGameVoucherMutation, UpdateGameVoucherMutationVariables> {
    document = UpdateGameVoucherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }