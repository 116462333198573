import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {firstUpper} from '../../../helpers';

@Component({
    selector: 'app-permission-tooltip',
    templateUrl: './permission-tooltip.component.html',
    styleUrls: ['./permission-tooltip.component.scss']
})
export class PermissionTooltipComponent implements OnInit {


    @Input() permissionTranslationCode: string;
    @Input() showDescription = true;

    tooltip: string = '';
    tooltipImage: string = '';

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        let tooltipCode = null;
        let tooltipImageCode = null;
        if(!this.showDescription) {
            tooltipCode = this.permissionTranslationCode + '.tooltip';
            tooltipImageCode = this.permissionTranslationCode + '.tooltip.image';
        }
        else {
            tooltipCode = this.permissionTranslationCode + '.tooltip';
            tooltipImageCode = this.permissionTranslationCode + '.tooltip.image';
        }

        if(!tooltipCode || !tooltipImageCode) {
            return;
        }

        if(this.translateService.instant(tooltipCode) !== tooltipCode) {
            this.tooltip = firstUpper(this.translateService.instant(tooltipCode));
        }
        if(this.translateService.instant(tooltipImageCode) !== tooltipImageCode) {
            this.tooltipImage = this.translateService.instant(tooltipImageCode);
            if(!this.tooltip) {
                this.tooltip = '';
            }
        }

    }

}
