import {Component, OnInit, ViewChild} from '@angular/core';
import {StepBasicSettingsFormType} from '../../../forms/types';
import {getGameStepStateOptions} from '../../../forms/helpers';
import {TranslateService} from '@ngx-translate/core';
import {AppActions} from '../../../../store/app-actions.service';
import {ScrollToInvalidControlDirective} from '../../../directives/scroll-to-invalid-control.directive';
import {
    updateGameStepAction,
    updateGameStepFailAction,
    updateGameStepSuccessAction
} from '../../../../store/games/actions';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MessageService} from 'primeng/api';
import {UpdateGameStepInput} from '../../../../graphql/types.graphql-gen';
import {GameHelper} from '../../../forms/game-helpers';

@Component({
    selector: 'app-new-game-step',
    templateUrl: './new-game-step.component.html',
    styleUrls: ['./new-game-step.component.scss']
})
export class NewGameStepComponent implements OnInit {

    @ViewChild(ScrollToInvalidControlDirective) scrollDirective: ScrollToInvalidControlDirective;
    form: StepBasicSettingsFormType;
    gameStepStateOptions = getGameStepStateOptions(this.translateService);
    loading = false;
    data: { gameId: number, groupId: number, gameStepId?: number};

    constructor(
        private translateService: TranslateService,
        private appActions: AppActions,
        public dynamicDialogConfig: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.data = this.dynamicDialogConfig.data;
        this.form = GameHelper.getStepBasicSettingsForm();
    }

    save() {
        if(this.loading) {
            return;
        }
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.scrollDirective.scrollToFirstInvalidControl();
            return;
        }
        const value: UpdateGameStepInput = {
            ...this.form.getRawValue(),
            inactiveFrom: this.form.value.inactiveFromTo ? this.form.value.inactiveFromTo[0] : null,
            inactiveTo: this.form.value.inactiveFromTo && this.form.value.inactiveFromTo.length === 2 ? this.form.value.inactiveFromTo[1] : null,
        };
        // @ts-ignore
        delete value.inactiveFromTo;
        this.loading = true;
        this.appActions.dispatch(updateGameStepAction({
            gameId: this.data.gameId,
            groupId: this.data.groupId,
            input: {updateGameStep: {...value, parentId: this.data.gameStepId}, gameId: this.data.gameId}
        }), [updateGameStepSuccessAction, updateGameStepFailAction]).subscribe((action) => {
            if (action.type === updateGameStepFailAction.type) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: action.message});
            } else {
                this.ref.close({success: true});
            }
            this.loading = false;
        });
    }

}
