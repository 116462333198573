import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPlayerProgressesQueryVariables = Types.Exact<{
  filter: Types.ManagerPlayerProgressFilter;
}>;


export type GetPlayerProgressesQuery = (
  { __typename?: 'Query' }
  & { getManagerPlayerProgresses: (
    { __typename?: 'ManagerPlayerProgressesResult' }
    & { result: Array<(
      { __typename?: 'PlayerProgress' }
      & PlayerProgressFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetPlayerProgressQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetPlayerProgressQuery = (
  { __typename?: 'Query' }
  & { getManagerPlayerProgresses: (
    { __typename?: 'ManagerPlayerProgressesResult' }
    & { result: Array<(
      { __typename?: 'PlayerProgress' }
      & PlayerProgressFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type PlayerProgressFragment = (
  { __typename?: 'PlayerProgress' }
  & Pick<Types.PlayerProgress, 'id' | 'actualIndex' | 'completed' | 'timePassed' | 'resultType' | 'progress' | 'time' | 'points' | 'emailsSent' | 'multiplayerGame' | 'inviteHash' | 'startedAt' | 'createdAt' | 'updatedAt' | 'syncKey' | 'isLobbyFinished'>
  & { playerProgressPlayers?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'PlayerProgressPlayer' }
    & Pick<Types.PlayerProgressPlayer, 'nickname'>
    & { player: (
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id' | 'fullname' | 'email'>
    ) }
  )>>>, user?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'fullname' | 'email'>
  )>, game?: Types.Maybe<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name'>
  )>, playerPhotos: Array<(
    { __typename?: 'PlayerPhoto' }
    & PlayerPhotoFragment
  )> }
);

export type PlayerPhotoFragment = (
  { __typename?: 'PlayerPhoto' }
  & Pick<Types.PlayerPhoto, 'id' | 'filename' | 'createdAt'>
);

export type FullPlayerProgressFragment = (
  { __typename?: 'PlayerProgress' }
  & Pick<Types.PlayerProgress, 'id' | 'actualIndex' | 'progress' | 'completed' | 'timePassed' | 'resultType' | 'time' | 'points' | 'emailsSent' | 'multiplayerGame' | 'inviteHash' | 'startedAt' | 'createdAt' | 'updatedAt' | 'syncKey' | 'gameSnapshot' | 'isLobbyFinished'>
  & { playerProgressPlayers?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'PlayerProgressPlayer' }
    & { player: (
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id' | 'fullname' | 'email'>
    ) }
  )>>>, user?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'fullname' | 'email'>
  )>, game?: Types.Maybe<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name'>
  )> }
);

export const PlayerPhotoFragmentDoc = gql`
    fragment playerPhoto on PlayerPhoto {
  id
  filename
  createdAt
}
    `;
export const PlayerProgressFragmentDoc = gql`
    fragment playerProgress on PlayerProgress {
  id
  actualIndex
  completed
  timePassed
  resultType
  progress
  time
  points
  emailsSent
  multiplayerGame
  inviteHash
  startedAt
  createdAt
  updatedAt
  syncKey
  playerProgressPlayers {
    nickname
    player {
      id
      fullname
      email
    }
  }
  user {
    id
    fullname
    email
  }
  game {
    id
    name
  }
  playerPhotos {
    ...playerPhoto
  }
  isLobbyFinished
}
    ${PlayerPhotoFragmentDoc}`;
export const FullPlayerProgressFragmentDoc = gql`
    fragment fullPlayerProgress on PlayerProgress {
  id
  actualIndex
  progress
  completed
  timePassed
  resultType
  time
  points
  emailsSent
  multiplayerGame
  inviteHash
  startedAt
  createdAt
  updatedAt
  syncKey
  gameSnapshot
  playerProgressPlayers {
    player {
      id
      fullname
      email
    }
  }
  user {
    id
    fullname
    email
  }
  game {
    id
    name
  }
  isLobbyFinished
}
    `;
export const GetPlayerProgressesDocument = gql`
    query getPlayerProgresses($filter: ManagerPlayerProgressFilter!) {
  getManagerPlayerProgresses(filter: $filter) {
    result {
      ...playerProgress
    }
    meta {
      ...metaFragment
    }
  }
}
    ${PlayerProgressFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayerProgressesGQL extends Apollo.Query<GetPlayerProgressesQuery, GetPlayerProgressesQueryVariables> {
    document = GetPlayerProgressesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPlayerProgressDocument = gql`
    query getPlayerProgress($id: String!) {
  getManagerPlayerProgresses(filter: {id: $id}) {
    result {
      ...playerProgress
    }
    meta {
      ...metaFragment
    }
  }
}
    ${PlayerProgressFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayerProgressGQL extends Apollo.Query<GetPlayerProgressQuery, GetPlayerProgressQueryVariables> {
    document = GetPlayerProgressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }