import {AttributeTypeEnum, GameGroupStateEnum, GameStateEnum} from '../app/graphql/types.graphql-gen';
import {MenuModules} from '../app/modules/backend/components/navigation/navigation.component';

export const environment = {
    projectName: 'Get Out Fun (beta)',
    production: false,
    SHOW_DEBUGGER: false,
    memeModeActive: true,
    webOrigin: 'https://api.gofcon.com',
    graphQLOrigin: 'https://api.gofcon.com/graphql',
    resourcesOrigin: 'https://api.gofcon.com/uploads/poeg',
    fileUploadOrigin: 'https://api.gofcon.com/file-upload',
    buyOrigin: 'https://www.getoutfun.com/buy',
    test: null,
    syncKey: 'asdlklskjdklagjlaskjdjklaasjdkaadsssdflsjsdsdsksdsdsdlaasdasass',
    version: '1.0.48',
    facebookAppId: '309442706170241',
    googleClientId: null,
    options: {
        allowedGameStates: Object.values(GameStateEnum),
        allowedGameGroupStates: Object.values(GameGroupStateEnum),
        disabledModules: [] as MenuModules[],
        allowedExternalVouchers: true,
        elements: {
            allowHashedVersion: false
        },
        allowedAttributeTypes: [
            AttributeTypeEnum.Category,
            AttributeTypeEnum.GameType,
            AttributeTypeEnum.TargetGroup,
        ],
        allowedAttributeTreeTypes: [
        ],
    },
    pages: {
    },
    permissionTemplates: [
    ] as {name: string, permissions: [string, string][]}[]
};
