import {createAction, props} from '@ngrx/store';
import {LicenseFilter, LicenseInput, LicenseKeyInput, UpdateLicenseResult} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {FullLicenseFragment, GetLicensesQuery} from '../../graphql/queries/getLicenses/getLicenses.graphql-gen';

export const fetchLicensesAction = createAction('[License] FETCH_LICENSES', props<FetchLicensesActionProps>());
export const fetchLicensesSuccessAction = createAction('[License] FETCH_LICENSES_SUCCESS', props<FetchLicensesSuccessActionProps>());
export const fetchLicensesFailAction = createAction('[License] FETCH_LICENSES_FAIL', props<FetchLicensesFailActionProps>());

export const fetchLicenseAction = createAction('[License] FETCH_LICENSE', props<FetchLicenseActionProps>());
export const fetchLicenseSuccessAction = createAction('[License] FETCH_LICENSE_SUCCESS', props<FetchLicenseSuccessActionProps>());
export const fetchLicenseFailAction = createAction('[License] FETCH_LICENSE_FAIL', props<FetchLicenseFailActionProps>());

export const updateLicenseAction = createAction('[License] UPDATE_LICENSE', props<UpdateLicenseActionProps>());
export const updateLicenseSuccessAction = createAction('[License] UPDATE_LICENSE_SUCCESS', props<UpdateLicenseSuccessActionProps>());
export const updateLicenseFailAction = createAction('[License] UPDATE_LICENSE_FAIL', props<UpdateLicenseFailActionProps>());

export type FetchLicensesActionProps = { filter: LicenseFilter };
export type FetchLicensesFailActionProps = DefaultFailMessage;
export type FetchLicensesSuccessActionProps = { result: GetLicensesQuery['getLicenses']}

export type FetchLicenseActionProps = { id: number };
export type FetchLicenseFailActionProps = DefaultFailMessage;
export type FetchLicenseSuccessActionProps = { license: FullLicenseFragment | null }

export type UpdateLicenseActionProps = {input: {licenseInput?: LicenseInput, licenseKeyInput?: LicenseKeyInput}, context?: any};
export type UpdateLicenseFailActionProps = DefaultFailMessage;
export type UpdateLicenseSuccessActionProps = {result: UpdateLicenseResult};
