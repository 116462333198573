import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PartnerCommissionTypeEnum, PartnerDiscountTypeEnum} from '../../../../graphql/types.graphql-gen';
import {PartnerFragment} from '../../../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {getPartnerCommissionTypeOptions, getPartnerDiscountTypeOptions} from '../../../forms/helpers';
import {TranslateService} from '@ngx-translate/core';
import {ScrollToInvalidControlDirective} from '../../../directives/scroll-to-invalid-control.directive';
import {ProductPartnerFragment} from '../../../../graphql/queries/getProductPartners/getProductPartners.graphql-gen';
import {combineLatest, lastValueFrom} from 'rxjs';
import {getProductPartnersSelector} from '../../../../store/productPartner/selectors';
import {debounceTime, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state';
import {AppActions} from '../../../../store/app-actions.service';
import {
    updatePartnerAction,
    updatePartnerFailAction,
    updatePartnerSuccessAction
} from '../../../../store/partner/actions';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-update-partner',
    templateUrl: './update-partner.component.html',
    styleUrls: ['./update-partner.component.scss']
})
export class UpdatePartnerComponent extends BaseComponent implements OnInit {

    partner?: PartnerFragment;
    @ViewChildren(ScrollToInvalidControlDirective) scrollDirective: QueryList<ScrollToInvalidControlDirective>;

    form: FormGroup<EditPartnerForm>;
    partnerDiscountTypeOptions = getPartnerDiscountTypeOptions(this.translateService);
    partnerCommissionTypeOptions = getPartnerCommissionTypeOptions(this.translateService);
    productPartners: ProductPartnerFragment[];
    loaded = false;
    loading = false;
    initialValue: string;
    formChanged = false;

    constructor(
        public dynamicDialogConfig: DynamicDialogConfig,
        private translateService: TranslateService,
        private store: Store<IAppState>,
        private appActions: AppActions,
        private messageService: MessageService,
        private ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.partner = this.dynamicDialogConfig.data.partner;
        this.subSink.sink = combineLatest([
            this.store.select(getProductPartnersSelector).pipe(take(1))
        ]).subscribe(([productPartners]) => {
            if (!productPartners) {
                throw new Error('Missing Product Partners');
            }
            this.productPartners = productPartners;
            this.initForm();
            if (this.partner) {
                this.form.patchValue({
                    id: this.partner.id,
                    scalarData: {
                        ...this.partner,
                        discountDirectSale: this.partner.discountDirectSale ?? 0,
                        activeFrom: this.partner.activeFrom ? new Date(this.partner.activeFrom) : null,
                        activeTo: this.partner.activeTo ? new Date(this.partner.activeTo) : null,
                    },
                    gameIds: (this.partner.gameIds as number[]),
                    productPartnerIds: this.partner.productPartners.map(((pp) => pp.id))
                });
            }
            this.form.markAllAsTouched();
            this.initialValue = JSON.stringify(this.form.getRawValue());
            this.subSink.sink = this.form.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
                this.formChanged = this.initialValue !== JSON.stringify(this.form.getRawValue());
            });
            this.loaded = true;
        });
    }

    initForm() {
        const max99Validator = Validators.max(99);
        this.form = new FormGroup<EditPartnerForm>({
            id: new FormControl(),
            gameIds: new FormControl([], {nonNullable: true}),
            productPartnerIds: new FormControl<number[]>([], {nonNullable: true}),
            scalarData: new FormGroup({
                name: new FormControl('', {
                    nonNullable: true,
                    validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]
                }),
                discount: new FormControl(1, {nonNullable: true, validators: [Validators.required, Validators.min(1)]}),
                discountType: new FormControl<PartnerDiscountTypeEnum>(PartnerDiscountTypeEnum.Percent, {
                    nonNullable: true,
                    validators: [Validators.required, CustomValidatorsService.customValidator((control) => {
                        if (!this.form) {
                            return;
                        }
                        if (control.value === PartnerDiscountTypeEnum.Percent) {
                            this.form.controls.scalarData.controls.discount.addValidators(max99Validator);
                        } else {
                            this.form.controls.scalarData.controls.discount.removeValidators(max99Validator);
                        }
                        this.form.controls.scalarData.controls.discount.updateValueAndValidity({onlySelf: true});
                    })]
                }),
                discountDirectSale: new FormControl(0.0, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
                discountDirectSaleType: new FormControl<PartnerDiscountTypeEnum>(PartnerDiscountTypeEnum.Percent, {nonNullable: true, validators: [
                    Validators.required,
                        CustomValidatorsService.customValidator(control => {
                            if (!this.form) {
                                return;
                            }
                            if (control.value === PartnerDiscountTypeEnum.Percent) {
                                this.form.controls.scalarData.controls.discountDirectSale.addValidators(max99Validator);
                            } else {
                                this.form.controls.scalarData.controls.discountDirectSale.removeValidators(max99Validator);
                            }
                            this.form.controls.scalarData.controls.discountDirectSale.updateValueAndValidity({onlySelf: true});
                        })
                    ]}),
                code: new FormControl<string>('', {
                    nonNullable: true,
                    validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]
                }),
                activeFrom: new FormControl<Date | null>(null),
                activeTo: new FormControl<Date | null>(null),
                commissionType: new FormControl<PartnerCommissionTypeEnum>(PartnerCommissionTypeEnum.Normal, {
                    nonNullable: true,
                    validators: Validators.required
                })
            })
        });
        if (this.partner) {
            this.form.controls.scalarData.controls.discount.disable({onlySelf: true})
            this.form.controls.scalarData.controls.discountType.disable({onlySelf: true})
            this.form.controls.scalarData.controls.commissionType.disable({onlySelf: true})
            this.form.controls.scalarData.controls.code.disable({onlySelf: true})
        }
    }

    async updatePartner() {
        this.form.markAllAsTouched();
        if(!this.formChanged) {
            return;
        }
        if (!this.form.valid) {
            this.scrollDirective.first.scrollToFirstInvalidControl();
            return;
        }
        this.loading = true;
        const res = await lastValueFrom(this.appActions.dispatch(updatePartnerAction({input: {updatePartner: this.form.getRawValue()}}),
            [updatePartnerSuccessAction, updatePartnerFailAction]));
        if (res.type === updatePartnerSuccessAction.type) {
            this.messageService.add({
                severity: 'success', summary: this.translateService.instant('general.dataSaved'),
                detail: this.translateService.instant('partner.messages.partnerSaved')
            });
            this.ref.close();
        } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: res.message, life: 4000});
        }
        this.loading = false;
    }

    protected readonly parseInt = parseInt;
}


interface EditPartnerForm {
    id?: FormControl<number>
    gameIds: FormControl<number[]>,
    productPartnerIds: FormControl<number[]>
    scalarData: FormGroup<{
        name: FormControl<string>,
        discount: FormControl<number>,
        discountType: FormControl<PartnerDiscountTypeEnum>,
        discountDirectSale: FormControl<number>,
        discountDirectSaleType: FormControl<PartnerDiscountTypeEnum>,
        code: FormControl<string>,
        activeFrom: FormControl<Date | null>
        activeTo: FormControl<Date | null>
        commissionType: FormControl<PartnerCommissionTypeEnum>
    }>
}
