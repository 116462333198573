import {createSelector} from '@ngrx/store';
import {dataStateSelector} from '../data/selector';
import * as _ from 'lodash';
import {AttributeOptionFragment} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {AttributeTypeEnum} from '../../graphql/types.graphql-gen';

export const getAttributesSelector = createSelector(
    dataStateSelector,
    (data) => data.attribute.attributes
);

export const getActiveAttributeSelector = createSelector(
    dataStateSelector,
    (data) => data.attribute.activeAttribute
);

export const getAvailableAttributesSelector = createSelector(
    dataStateSelector,
    (data) => data.helperData.projectData?.availableAttributes ?? []
);

export const getAttributesTreeSelector = createSelector(
    getAvailableAttributesSelector,
    (data) => buildTreeFromAttributes(data)
);


export const buildTreeFromAttributes = (attributes: AttributeOptionFragment[]): {[key in AttributeTypeEnum]: AttributeTreeOptionType[]} => {
    // @ts-ignore
    let tree: {[key in AttributeTypeEnum]: AttributeTreeOptionType[]} = Object.values(AttributeTypeEnum).reduce((acc, curr) => {
        // @ts-ignore
        acc[curr] = []
        return acc;
    }, {});
    for (const [type, data] of Object.entries(_.groupBy(attributes, 'type'))) {
        // @ts-ignore
        tree[type] = buildTree(data, null);
    }
    return tree;
}


function buildTree(items: AttributeOptionFragment[], parentId: null|number) {
    const node: AttributeTreeOptionType[] = [];

    // Filter items with matching parentId
    const children = items.filter(item => item.parentId === parentId);

    // Recursively build child nodes
    children.forEach(child => {
        node.push({
            ...child,
            key: child.id.toString(),
            label: child.name,
            children: buildTree(items, child.id)
        })
    });

    return node.sort((a, b) => a.ordering - b.ordering);
}


export type AttributeTreeOptionType = AttributeOptionFragment & {children: AttributeTreeOptionType[], label: string, key: string};
