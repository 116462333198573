import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetGameVouchersQueryVariables = Types.Exact<{
  filter: Types.GameVoucherFilter;
}>;


export type GetGameVouchersQuery = (
  { __typename?: 'Query' }
  & { getGameVouchers: (
    { __typename?: 'GameVouchersResult' }
    & { meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
    & GameVouchersResultFragmentFragment
  ) }
);

export type GameVouchersResultFragmentFragment = (
  { __typename?: 'GameVouchersResult' }
  & { result: Array<(
    { __typename?: 'GameVoucher' }
    & GameVoucherFragment
  )> }
);

export type GameVoucherFragment = (
  { __typename?: 'GameVoucher' }
  & Pick<Types.GameVoucher, 'id' | 'description' | 'saleSourceCode' | 'code' | 'validTo' | 'maxUsedCount' | 'bought' | 'price' | 'target' | 'photoSource' | 'createdAt' | 'updatedAt' | 'gender' | 'paidToProductPartner' | 'commissionProductPartner' | 'commissionSaleSource' | 'voucherPrice' | 'canceledAt'>
  & { used?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'email' | 'fullname'>
  )>, game: (
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name' | 'difficulty' | 'sendQrCodes'>
    & { gameGroup: (
      { __typename?: 'GameGroup' }
      & { city?: Types.Maybe<(
        { __typename?: 'City' }
        & Pick<Types.City, 'id' | 'name'>
      )> }
    ) }
  ), productPartner?: Types.Maybe<(
    { __typename?: 'ProductPartner' }
    & Pick<Types.ProductPartner, 'id'>
  )>, saleSource?: Types.Maybe<(
    { __typename?: 'SaleSource' }
    & Pick<Types.SaleSource, 'id'>
  )>, partner?: Types.Maybe<(
    { __typename?: 'Partner' }
    & Pick<Types.Partner, 'id'>
  )>, boughtGames: Array<(
    { __typename?: 'BoughtGames' }
    & BoughtGameFragmentFragment
  )>, user?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'email' | 'fullname'>
  )>, purchase?: Types.Maybe<(
    { __typename?: 'Purchase' }
    & Pick<Types.Purchase, 'id' | 'orderId' | 'orderNumber' | 'price' | 'cardType'>
  )>, billingData?: Types.Maybe<(
    { __typename?: 'GameVoucherBillingData' }
    & GameVoucherBillingDataFragment
  )> }
);

export type GameVoucherBillingDataFragment = (
  { __typename?: 'GameVoucherBillingData' }
  & Pick<Types.GameVoucherBillingData, 'voucherPrice' | 'voucherRealPrice' | 'commissionSaleSource' | 'commissionProductPartner' | 'partnerCommissionType' | 'partnerDiscountType' | 'partnerDiscountDirectSaleType' | 'partnerDiscount' | 'partnerDiscountDirectSale' | 'gatewayCharge' | 'gatewayChargePercent' | 'paidToProductPartner'>
);

export type BoughtGameFragmentFragment = (
  { __typename?: 'BoughtGames' }
  & Pick<Types.BoughtGames, 'id' | 'voucherCode' | 'active' | 'createdAt' | 'updatedAt' | 'fromSlevomat'>
  & { player?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'email'>
  )>, playerProgress?: Types.Maybe<(
    { __typename?: 'PlayerProgress' }
    & Pick<Types.PlayerProgress, 'id'>
  )> }
);

export const BoughtGameFragmentFragmentDoc = gql`
    fragment boughtGameFragment on BoughtGames {
  id
  voucherCode
  active
  createdAt
  updatedAt
  fromSlevomat
  player {
    id
    email
  }
  playerProgress {
    id
  }
}
    `;
export const GameVoucherBillingDataFragmentDoc = gql`
    fragment gameVoucherBillingData on GameVoucherBillingData {
  voucherPrice
  voucherRealPrice
  commissionSaleSource
  commissionProductPartner
  partnerCommissionType
  partnerDiscountType
  partnerDiscountDirectSaleType
  partnerDiscount
  partnerDiscountDirectSale
  gatewayCharge
  gatewayChargePercent
  paidToProductPartner
}
    `;
export const GameVoucherFragmentDoc = gql`
    fragment gameVoucher on GameVoucher {
  id
  description
  saleSourceCode
  code
  validTo
  maxUsedCount
  bought
  price
  target
  photoSource
  createdAt
  updatedAt
  gender
  paidToProductPartner
  commissionProductPartner
  commissionSaleSource
  voucherPrice
  canceledAt
  used {
    id
    email
    fullname
  }
  game {
    id
    name
    difficulty
    sendQrCodes
    gameGroup {
      city {
        id
        name
      }
    }
  }
  productPartner {
    id
  }
  saleSource {
    id
  }
  partner {
    id
  }
  boughtGames {
    ...boughtGameFragment
  }
  user {
    id
    email
    fullname
  }
  purchase {
    id
    orderId
    orderNumber
    price
    cardType
  }
  billingData {
    ...gameVoucherBillingData
  }
}
    ${BoughtGameFragmentFragmentDoc}
${GameVoucherBillingDataFragmentDoc}`;
export const GameVouchersResultFragmentFragmentDoc = gql`
    fragment gameVouchersResultFragment on GameVouchersResult {
  result {
    ...gameVoucher
  }
}
    ${GameVoucherFragmentDoc}`;
export const GetGameVouchersDocument = gql`
    query getGameVouchers($filter: GameVoucherFilter!) {
  getGameVouchers(filter: $filter) {
    ...gameVouchersResultFragment
    meta {
      ...metaFragment
    }
  }
}
    ${GameVouchersResultFragmentFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGameVouchersGQL extends Apollo.Query<GetGameVouchersQuery, GetGameVouchersQueryVariables> {
    document = GetGameVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }