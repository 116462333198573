import {ElementBaseComponent} from './element-base.component';
import {AbstractControl} from '@angular/forms';
import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {GameStepClueInput} from '../../../graphql/types.graphql-gen';
import {createGameStepClueFormGroup} from '../../forms/helpers';
import {$} from 'protractor';

@Component({
    template: ''
})
export abstract class ElementBaseWithCluesComponent<SettingsControls extends {
    [key: string]: AbstractControl;
}, ElementPreferences extends any = any> extends ElementBaseComponent<SettingsControls, ElementPreferences> implements OnChanges {
    gameStepClues: GameStepClueInput[];
    addClue(clue: GameStepClueInput) {
        this.stepElementForm.markAsTouched();
        this.stepElementForm.controls.gameStepClues.push(createGameStepClueFormGroup(clue));
        this.gameStepClues = this.stepElementForm.controls.gameStepClues.getRawValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.gameStepClues = this.stepElementForm.controls.gameStepClues.getRawValue();
    }



    removeClue($event: { index: number }) {
        this.stepElementForm.markAsTouched();
        this.stepElementForm.controls.gameStepClues.removeAt($event.index);
        this.gameStepClues = this.stepElementForm.controls.gameStepClues.getRawValue();
    }

    updateClue($event: { index: number; clue: GameStepClueInput }) {
        this.stepElementForm.markAsTouched();
        this.stepElementForm.controls.gameStepClues.controls[$event.index].patchValue({...$event.clue, title: $event.clue.title ?? ''});
        this.gameStepClues = this.stepElementForm.controls.gameStepClues.getRawValue();
    }
}
