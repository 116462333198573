import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchProductPartnersAction,
    fetchProductPartnersFailAction,
    fetchProductPartnersSuccessAction,
    updateProductPartnerAction, updateProductPartnerFailAction, updateProductPartnerSuccessAction
} from './actions';
import {map, switchMap} from 'rxjs/operators';
import {GetProductPartnersGQL} from '../../graphql/queries/getProductPartners/getProductPartners.graphql-gen';
import {UpdateProductPartnerGQL} from '../../graphql/mutations/updateProductPartner/updateProductPartner.graphql-gen';
import {ProductPartnersResult, UpdateProductPartnerResult} from '../../graphql/types.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class ProductPartnerEffect implements OnDestroy {


    subSink = new SubSink();

    fetchProductPartners$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchProductPartnersAction
        ),
        switchMap((action) => {
            return this.getProductPartnersGQL.fetch();
        }),
        map((res) => {
            if (res.errors) {
                return fetchProductPartnersFailAction({header: '', message: res.errors[0].message});
            }
            return fetchProductPartnersSuccessAction({result: res.data.getProductPartners as ProductPartnersResult});
        })
    ));

    updateProductPartner$ = createEffect(() => this.actions$.pipe(
        ofType(updateProductPartnerAction),
        switchMap((action) => {
            return fixMutation(this.updateProductPartnersGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if (res.errors) {
                return updateProductPartnerFailAction({header: '', message: res.errors[0].message});
            }
            return updateProductPartnerSuccessAction({result: res.data.updateProductPartner as UpdateProductPartnerResult});
        })
    ))

    constructor(
        private actions$: Actions,
        private getProductPartnersGQL: GetProductPartnersGQL,
        private updateProductPartnersGQL: UpdateProductPartnerGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
