import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {RefreshTokenGQL} from '../graphql/mutations/refreshToken/refreshToken.graphql-gen';
import {Store} from '@ngrx/store';
import {IAppState} from '../store/state';
import {logoutAction, updateUserPermissionsAction} from '../store/user/actions';
import {MyStorageService} from '../services/my-storage.service';
import {environment} from '../../environments/environment';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    private refreshTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private refreshTokenInProgress = false;

    first = true;

    constructor(
        private refreshTokenGQL: RefreshTokenGQL,
        private storage: MyStorageService,
        private store: Store<IAppState>
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ([environment.graphQLOrigin, environment.fileUploadOrigin].includes(request.url)) {
            return next.handle(request).pipe(
                catchError((e) => {
                    if (e instanceof HttpErrorResponse) {
                        if (e?.error?.errors) {
                            if (request.headers.get('SecondAttempt') === null
                                && e.status === 401
                                && !['Login', 'RefreshToken', 'Register'].includes(request.body?.operationName)
                            ) {
                                if (this.refreshTokenInProgress) {
                                    return this.refreshTokenSubject$.pipe(
                                        filter(result => result != null),
                                        take(1),
                                        switchMap((accessToken) => {
                                            if (accessToken === 'INVALID') {
                                                return this.modifyErrorResponse(e);
                                            }
                                            return this.repeatRequest(request, next, accessToken);
                                        })
                                    );
                                } else {
                                    this.refreshTokenInProgress = true;
                                    this.refreshTokenSubject$.next(null);
                                    return this.refreshTokenGQL.mutate({onlyAccessToken: true}).pipe(
                                        tap((r) => {
                                            const permissions = r.data?.refreshToken?.implicitPermissions;
                                            const managedGameIds = r.data?.refreshToken?.managedGameIds;
                                            if(permissions && managedGameIds) {
                                                this.store.dispatch(updateUserPermissionsAction({implicitPermissions: permissions, managedGameIds}))
                                            }
                                        }),
                                        switchMap((r) => {
                                            this.refreshTokenSubject$
                                                .next(r?.data?.refreshToken?.accessToken ? r?.data?.refreshToken?.accessToken : 'INVALID');
                                            if (r?.data?.refreshToken?.accessToken) {
                                                if(r?.data?.refreshToken?.refreshToken) {
                                                    this.storage.set('refreshTokenGQL', r.data.refreshToken.refreshToken).then();
                                                }
                                                return from(this.storage.set('accessTokenGQL', r.data.refreshToken.accessToken)).pipe(
                                                    map(() => {
                                                        // @ts-ignore
                                                        return r.data.refreshToken.accessToken as string;
                                                    })
                                                );
                                            }
                                            return of('INVALID');
                                        }),
                                        switchMap((accessToken: string) => {
                                            if (accessToken === 'INVALID') {
                                                return this.modifyErrorResponse(e);
                                            }
                                            return this.repeatRequest(request, next, accessToken);
                                        }),
                                        finalize(() => {
                                            this.refreshTokenInProgress = false;
                                        })
                                    );
                                }
                            }
                            if (e.status === 401 && !['Login', 'Register'].includes(request.body?.operationName)) {
                                this.store.dispatch(logoutAction());
                            }
                            return this.modifyErrorResponse(e);
                        } else {
                            return this.modifyErrorResponse(e);
                        }
                    }
                    throw e;
                }),
                tap((r) => {
                    if (r.type === HttpEventType.Response) {
                        if (r?.body?.debugBar) {
                            let statusCode = 200;
                            if (r?.body?.errorCode) {
                                statusCode = r?.body?.errorCode;
                            }
                            this.showDebugger(r?.body?.debugBar, statusCode);
                        }
                    }
                })
            );
        } else {
            return next.handle(request);
        }
    }

    repeatRequest(request: HttpRequest<any>, next: HttpHandler, accessToken: string) {
        const modifiedRequest = request.clone(
            {
                headers: request.headers.append('SecondAttempt', 'true').set('Authorization', accessToken)
            }
        );
        return next.handle(modifiedRequest).pipe(
            catchError((er) => {
                if (er instanceof HttpErrorResponse) {
                    return this.modifyErrorResponse(er);
                }
                throw er;
            })
        );
    }

    modifyErrorResponse(e: HttpErrorResponse) {
        const error = e?.error?.errors ? e.error : {errors: ['unknown_error']};
        const url = e.url ?? undefined;
        const modifiedResponse = new HttpResponse(
            {
                body: {...error, errorCode: e.status},
                headers: e.headers,
                status: 200,
                statusText: 'OK',
                url
            });
        return of(modifiedResponse);
    }

    showDebugger(s: any, statusCode: number) {
        if (!this.first) {
            setTimeout(() => {
                // @ts-ignore
                Tracy.Debug.loadAjax(s.content);
            }, 300)
            return;
        }
        this.first = false;
        const data = s.split('<!-- Tracy Debug Bar -->');
        if (data.length > 1) {
            document.getElementById('tracy-debug')?.remove();
            const scripts = data[1].split('</script>');

            const script1 = document.createRange()
                .createContextualFragment(scripts[0].replace('<script src="/', '<script data-tracy src="' + environment.webOrigin + '/'));
            // @ts-ignore
            environment.test = script1.querySelector('[data-id]').getAttribute('data-id');
            document.body.appendChild(script1);
            setTimeout(() => {
                const script2 = document.createRange().createContextualFragment(scripts[1]);
                document.body.appendChild(script2);
            }, 300);
            return;
        }
    }
}
