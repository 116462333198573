import {Component, OnInit, ViewChild} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {AudioSettings} from '../element-types';
import { MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {SafeUrl} from '@angular/platform-browser';
import {readFileUrl} from '../../../helpers';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    removeFileFromBufferAction
} from '../../../../store/general/actions';
import {FileUploadComponent} from '../../custom/file-upload/file-upload.component';
import {DefaultFailMessage} from '../../../../store/user/actions';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss']
})
export class AudioComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    @ViewChild('audioC') audioComponent: FileUploadComponent;

    audio: { loading: boolean, url: string | SafeUrl | null } = {loading: false, url: null};
    file: File|null;
    lastBufferedFilename: string|null = null;

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            src: new FormControl('', {nonNullable: true, validators: Validators.required}),
            autoplay: new FormControl(false, {nonNullable: true, validators: Validators.required})
        });
    }


    async ngOnInit() {
        super.ngOnInit();
        this.audio = {
            url: this.stepElementForm.getRawValue().settings.src ? await readFileUrl(this.stepElementForm.getRawValue().settings.src, this.appActions) : null,
            loading: false
        };
    }

    onFileUpload: (file: File | null) => Promise<true | DefaultFailMessage> = (file: File | null) => {
        return new Promise(async (resolve) => {
            let filename = null;
            if (file) {
                filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
                this.stepElementForm.controls.settings.controls.src.setValue(filename);
                this.stepElementForm.controls.settings.controls.src.markAsTouched();
            }
            if(this.lastBufferedFilename) {
                await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: this.lastBufferedFilename})));
            }
            this.file = file;
            this.lastBufferedFilename = filename;
            this.audio.url = filename ? await readFileUrl(filename, this.appActions) : null;
            if(this.audio.url) {
                this.audioComponent.reloadFile();
            }
            resolve(true);
        });
    };
}


type SettingsControl = {
    src: FormControl<string>,
    autoplay: FormControl<boolean>
}
