import {createSelector} from '@ngrx/store';
import {dataStateSelector} from '../data/selector';
import {IDataState} from '../state';
import {Game} from '../../graphql/types.graphql-gen';

export const getGameGroupsSelector = createSelector(
    dataStateSelector,
    (data: IDataState) => data.gameGroup.gameGroups
);

export const getActiveGameGroupSelector = createSelector(
    dataStateSelector,
    (data: IDataState) => data.gameGroup.activeGameGroup
);

// Here are only game groups that player has access to read/create the game
export const getGameGroupOptionsSelector = createSelector(
    dataStateSelector,
    // (data) => data?.result.map((gg) => ({id: gg.id, name: gg.name})) ?? []
    (data) => data.helperData.projectData?.availableGameGroups ?? []
);

export const getGameSelector = createSelector(
    dataStateSelector,
    (data) => data.game
);
