import {createAction, props} from '@ngrx/store';
import {GetPurchasesQuery} from '../../graphql/queries/getPurchases/getPurchases.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {PurchaseFilter, PurchaseInput} from '../../graphql/types.graphql-gen';
import {FullPurchaseFragment} from '../../graphql/queries/getPurchase/getPurchase.graphql-gen';
import {UpdatePurchaseMutation} from '../../graphql/mutations/updatePurchase/updatePurchase.graphql-gen';

export const fetchPurchasesAction = createAction('[Purchases] LOAD_PURCHASES', props<FetchPurchasesActionProps>());
export const fetchPurchasesSuccessAction = createAction('[Purchases] LOAD_PURCHASES_SUCCESS', props<FetchPurchasesSuccessActionProps>());
export const fetchPurchasesFailAction = createAction('[Purchases] LOAD_PURCHASES_FAIL', props<FetchPurchasesFailActionProps>());

export const fetchPurchaseAction = createAction('[Purchases] FETCH_PURCHASE', props<FetchPurchaseActionProps>());
export const fetchPurchaseSuccessAction = createAction('[Purchases] FETCH_PURCHASE_SUCCESS', props<FetchPurchaseSuccessActionProps>())
export const fetchPurchaseFailAction = createAction('[Purchase] FETCH_PURCHASE_FAIL', props<FetchPurchaseFailActionProps>())

export const updatePurchaseAction = createAction('[Purchases] UPDATE_PURCHASE', props<UpdatePurchaseActionProps>());
export const updatePurchaseFailAction = createAction('[Purchases] UPDATE_PURCHASE_FAIL', props<UpdatePurchaseFailActionProps>());
export const updatePurchaseSuccessAction = createAction('[Purchases] UPDATE_PURCHASE_SUCCESS', props<UpdatePurchaseSuccessActionProps>());


export type UpdatePurchaseActionProps = {input: PurchaseInput};
export type UpdatePurchaseFailActionProps = DefaultFailMessage;
export type UpdatePurchaseSuccessActionProps = {result: UpdatePurchaseMutation['updatePurchase']};

export type FetchPurchaseActionProps = { id: number }
export type FetchPurchaseFailActionProps = DefaultFailMessage;
export type FetchPurchaseSuccessActionProps = {purchase: FullPurchaseFragment | null}

export type FetchPurchasesActionProps = {filter: PurchaseFilter};
export type FetchPurchasesSuccessActionProps = { purchases: GetPurchasesQuery };
export type FetchPurchasesFailActionProps = DefaultFailMessage;
