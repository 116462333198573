import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormRecord, ValidatorFn} from '@angular/forms';
import {BaseComponent} from '../../../base/base.component';
import {combineLatest} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../../store/state';
import {availableLanguagesSelector} from '../../../../../store/data/selector';
import {take} from 'rxjs/operators';
import {LangFragment} from '../../../../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {EntityTranslation} from '../../../../../graphql/types.graphql-gen';

@Component({
    selector: 'app-entity-translation-form',
    templateUrl: './entity-translation-form.component.html',
    styleUrls: ['./entity-translation-form.component.scss']
})
export class EntityTranslationFormComponent extends BaseComponent implements OnInit {

    @Input() form: FormRecord<FormArray<FormGroup<{ langCode: FormControl<string>, value: FormControl<string> }>>>;
    @Input() translationPrefix: string;

    languages: LangFragment[];
    loaded = false;

    translations: { [langCode: string]: ({ [paramName: string]: string }) } = {};
    translationProperties: string[];



    constructor(
        private store: Store<IAppState>
    ) {
        super();
    }

    ngOnInit(): void {

        this.subSink.sink = combineLatest([
            this.store.select(availableLanguagesSelector)
        ]).pipe(take(1)).subscribe(([languages]) => {
            this.languages = languages;
            this.initTranslations();
            this.loaded = true;
        });
    }

    initTranslations() {
        this.translationProperties = Object.keys(this.form.controls);
    }
}


export const createTranslationFormGroup = <Properties extends {
    [key: string]: {
        formArrayValidators?: ValidatorFn | ValidatorFn[] | null | undefined,
        valueValidators?: ValidatorFn | ValidatorFn[] | null | undefined
    } | null | undefined
}>(languages: LangFragment[], properties: Properties): FormGroup<{ [K in keyof Properties]: FormArray<FormGroup<{ langCode: FormControl<string>, value: FormControl<string> }>> }> => {
    const formGroup = new FormGroup({});
    for (const [property, value] of Object.entries(properties)) {
        formGroup.addControl(property, new FormArray(languages.map((l) => {
            return new FormGroup<{ langCode: FormControl<string>; value: FormControl<string> }>({
                value: new FormControl('', {nonNullable: true, validators: value?.valueValidators}),
                langCode: new FormControl(l.code, {nonNullable: true})
            });
        }), {validators: value?.formArrayValidators}))
    }
    return formGroup as any;
}

export const setValuesForTranslationFormGroup = (formGroup: FormRecord<FormArray<FormGroup<{ langCode: FormControl<string>, value: FormControl<string> }>>>, translations: { [key: string | '__typename']: EntityTranslation[] | string | undefined }) => {
    for (const [key, value] of Object.entries(formGroup.controls)) {
        const t = translations[key];
        if (!Array.isArray(t)) {
            continue;
        }
        t.forEach((et) => {
            const control = value.controls.find((c) => c.controls.langCode.value === et.langCode);
            if (!control) {
                return;
            }
            control.controls.value.setValue(et.value);
        });
    }
}
