import * as Types from '../../types.graphql-gen';

import { PageGameGroupFragment } from '../../queries/getPagesData/getPagesData.graphql-gen';
import { gql } from 'apollo-angular';
import { PageGameGroupFragmentDoc } from '../../queries/getPagesData/getPagesData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateGameGroupMutationVariables = Types.Exact<{
  input: Types.GameGroupInput;
}>;


export type UpdateGameGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGameGroup: (
    { __typename?: 'UpdateGameGroupResult' }
    & Pick<Types.UpdateGameGroupResult, 'groupDeleted'>
    & { gameGroup?: Types.Maybe<(
      { __typename?: 'GameGroup' }
      & GameGroupFragment
    )> }
  ) }
);

export type GameGroupFragment = (
  { __typename?: 'GameGroup' }
  & Pick<Types.GameGroup, 'id' | 'name' | 'slug' | 'description' | 'descriptionWeb' | 'isForTeambuilding' | 'state' | 'order' | 'language' | 'restrictions' | 'distance' | 'startingPosition' | 'photoSource' | 'photoSourceAlt' | 'videoSource' | 'rating' | 'createdAt' | 'updatedAt' | 'hidden' | 'notificationMails' | 'innerMostMapId'>
  & { pages: Array<(
    { __typename?: 'PageGameGroup' }
    & PageGameGroupFragment
  )>, city?: Types.Maybe<(
    { __typename?: 'City' }
    & Pick<Types.City, 'id' | 'name' | 'photoSource'>
  )>, license?: Types.Maybe<(
    { __typename?: 'License' }
    & Pick<Types.License, 'id' | 'name'>
  )>, voucherImage?: Types.Maybe<(
    { __typename?: 'VoucherImage' }
    & VoucherImageFragment
  )>, attributes: Array<(
    { __typename?: 'Attribute' }
    & Pick<Types.Attribute, 'id' | 'type'>
  )> }
  & GamesFragmentFragment
  & GroupLangFragmentFragment
  & GamePhotosFragmentFragment
);

export type VoucherImageFragment = (
  { __typename?: 'VoucherImage' }
  & Pick<Types.VoucherImage, 'id' | 'name' | 'xAxis' | 'yAxis'>
  & { extraSettings?: Types.Maybe<(
    { __typename?: 'VoucherImageExtraSettings' }
    & VoucherImageExtraSettingsFragment
  )> }
);

export type VoucherImageExtraSettingsFragment = (
  { __typename?: 'VoucherImageExtraSettings' }
  & { codeBox: (
    { __typename?: 'ImageTextBoxData' }
    & ImageTextBoxDataFragment
  ), gameNameBox?: Types.Maybe<(
    { __typename?: 'ImageTextBoxData' }
    & ImageTextBoxDataFragment
  )>, groupNameBox?: Types.Maybe<(
    { __typename?: 'ImageTextBoxData' }
    & ImageTextBoxDataFragment
  )>, qrBox?: Types.Maybe<(
    { __typename?: 'ImageTextBoxData' }
    & ImageTextBoxDataFragment
  )>, validToBox?: Types.Maybe<(
    { __typename?: 'ImageTextBoxData' }
    & ImageTextBoxDataFragment
  )> }
);

export type ImageTextBoxDataFragment = (
  { __typename?: 'ImageTextBoxData' }
  & Pick<Types.ImageTextBoxData, 'x1' | 'y1' | 'x2' | 'y2' | 'fontSize' | 'color' | 'testText' | 'font' | 'active'>
);

export type GamesFragmentFragment = (
  { __typename?: 'GameGroup' }
  & { games: Array<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'synchKey' | 'authorId' | 'state' | 'order' | 'public' | 'name' | 'photoSource' | 'difficulty' | 'difficultyNote' | 'duration' | 'isForFree' | 'price' | 'appPurchaseId' | 'type' | 'distance' | 'resultType' | 'maxTime' | 'minTime' | 'phoneNumber' | 'predefinedNotes' | 'language' | 'startingPosition' | 'restrictions' | 'purchaseNote' | 'teamInfo' | 'textRating' | 'textEmail' | 'teamNamePopupActive' | 'teamNamePopupText' | 'size' | 'averageRatingYear' | 'averageRating' | 'averageRatingThreeMonths' | 'congratsContent' | 'labelDifficulty' | 'labelContinue' | 'labelSubmit' | 'labelPrevious' | 'labelNext' | 'labelSkip' | 'labelSkipquestion' | 'labelPlay' | 'labelDownload' | 'labelCancel' | 'labelOk' | 'labelHelper' | 'labelHelperquestion' | 'labelNotes' | 'labelBuy' | 'labelSkipphoto' | 'labelTakephoto' | 'labelShareonfacebook' | 'labelComplete' | 'labelNoclues' | 'labelClosehelper' | 'labelActualize' | 'labelSize' | 'labelBought' | 'labelStartgame' | 'labelStartgamequestion' | 'labelRightansweris' | 'labelTelephonesupport' | 'labelTelephonesupportquestion' | 'deletedAt' | 'createdAt' | 'updatedAt' | 'players' | 'randomCode' | 'multiplayerGame' | 'mixStats' | 'extraSettings'>
    & { gameGroup: (
      { __typename?: 'GameGroup' }
      & Pick<Types.GameGroup, 'id'>
    ), playerGames?: Types.Maybe<Array<(
      { __typename?: 'PlayerGame' }
      & { player: (
        { __typename?: 'Player' }
        & Pick<Types.Player, 'id' | 'fullname' | 'email'>
      ) }
    )>> }
  )> }
);

export type GroupLangFragmentFragment = (
  { __typename?: 'GameGroup' }
  & { lang?: Types.Maybe<(
    { __typename?: 'Lang' }
    & Pick<Types.Lang, 'id' | 'code' | 'urlCode' | 'name' | 'active' | 'flagPath'>
  )> }
);

export type GamePhotosFragmentFragment = (
  { __typename?: 'GameGroup' }
  & { gamePhotos?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'GamePhoto' }
    & Pick<Types.GamePhoto, 'id' | 'filename'>
  )>>> }
);

export const GamesFragmentFragmentDoc = gql`
    fragment gamesFragment on GameGroup {
  games {
    id
    synchKey
    authorId
    state
    order
    public
    name
    photoSource
    difficulty
    difficultyNote
    duration
    isForFree
    price
    appPurchaseId
    type
    distance
    resultType
    maxTime
    minTime
    phoneNumber
    predefinedNotes
    language
    startingPosition
    restrictions
    purchaseNote
    teamInfo
    textRating
    textEmail
    teamNamePopupActive
    teamNamePopupText
    size
    averageRatingYear
    averageRating
    averageRatingThreeMonths
    congratsContent
    labelDifficulty
    labelContinue
    labelSubmit
    labelPrevious
    labelNext
    labelSkip
    labelSkipquestion
    labelPlay
    labelDownload
    labelCancel
    labelOk
    labelHelper
    labelHelperquestion
    labelNotes
    labelBuy
    labelSkipphoto
    labelTakephoto
    labelShareonfacebook
    labelComplete
    labelNoclues
    labelClosehelper
    labelActualize
    labelSize
    labelBought
    labelStartgame
    labelStartgamequestion
    labelRightansweris
    labelTelephonesupport
    labelTelephonesupportquestion
    deletedAt
    createdAt
    updatedAt
    players
    randomCode
    multiplayerGame
    mixStats
    extraSettings
    gameGroup {
      id
    }
    playerGames {
      player {
        id
        fullname
        email
      }
    }
  }
}
    `;
export const GroupLangFragmentFragmentDoc = gql`
    fragment groupLangFragment on GameGroup {
  lang {
    id
    code
    urlCode
    name
    active
    flagPath
  }
}
    `;
export const GamePhotosFragmentFragmentDoc = gql`
    fragment gamePhotosFragment on GameGroup {
  gamePhotos {
    id
    filename
  }
}
    `;
export const ImageTextBoxDataFragmentDoc = gql`
    fragment imageTextBoxData on ImageTextBoxData {
  x1
  y1
  x2
  y2
  fontSize
  color
  testText
  font
  active
}
    `;
export const VoucherImageExtraSettingsFragmentDoc = gql`
    fragment voucherImageExtraSettings on VoucherImageExtraSettings {
  codeBox {
    ...imageTextBoxData
  }
  gameNameBox {
    ...imageTextBoxData
  }
  groupNameBox {
    ...imageTextBoxData
  }
  qrBox {
    ...imageTextBoxData
  }
  validToBox {
    ...imageTextBoxData
  }
}
    ${ImageTextBoxDataFragmentDoc}`;
export const VoucherImageFragmentDoc = gql`
    fragment voucherImage on VoucherImage {
  id
  name
  xAxis
  yAxis
  extraSettings {
    ...voucherImageExtraSettings
  }
}
    ${VoucherImageExtraSettingsFragmentDoc}`;
export const GameGroupFragmentDoc = gql`
    fragment gameGroup on GameGroup {
  id
  name
  slug
  description
  descriptionWeb
  isForTeambuilding
  state
  order
  language
  restrictions
  distance
  startingPosition
  photoSource
  photoSourceAlt
  videoSource
  rating
  createdAt
  updatedAt
  hidden
  notificationMails
  ...gamesFragment
  ...groupLangFragment
  ...gamePhotosFragment
  pages {
    ...pageGameGroup
  }
  city {
    id
    name
    photoSource
  }
  license {
    id
    name
  }
  voucherImage {
    ...voucherImage
  }
  attributes {
    id
    type
  }
  innerMostMapId
}
    ${GamesFragmentFragmentDoc}
${GroupLangFragmentFragmentDoc}
${GamePhotosFragmentFragmentDoc}
${PageGameGroupFragmentDoc}
${VoucherImageFragmentDoc}`;
export const UpdateGameGroupDocument = gql`
    mutation updateGameGroup($input: GameGroupInput!) {
  updateGameGroup(input: $input) {
    gameGroup {
      ...gameGroup
    }
    groupDeleted
  }
}
    ${GameGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGameGroupGQL extends Apollo.Mutation<UpdateGameGroupMutation, UpdateGameGroupMutationVariables> {
    document = UpdateGameGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }