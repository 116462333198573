import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PipesModule} from './pipes/pipes.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ElementsModule} from './components/elements/elements.module';
import {CustomModule} from './components/custom/custom.module';
import {CustomValidatorsService} from './forms/custom-validators.service';
import {PrimeModule} from './prime.module';
import {IsAllowedDirective} from './directives/is-allowed.directive';
import {environment} from '../../environments/environment';
import {ChipModule} from 'primeng/chip';
import {CdrDirective} from './directives/cdr.directive';
import {ScrollableHeaderCardDirective} from './directives/scrollable-header-card.directive';
import {DirectivesModule} from './directives/directives.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RatingModule} from 'primeng/rating';
import {StepsModule} from 'primeng/steps';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {MonacoEditorModule} from 'ngx-monaco-editor-v2';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
}


@NgModule({
    declarations: [
        IsAllowedDirective,
        CdrDirective,
        ScrollableHeaderCardDirective
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        SweetAlert2Module.forRoot(),
        MonacoEditorModule.forRoot(),
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        ElementsModule,
        CustomModule,
        PrimeModule,
    ],
    exports: [
        PipesModule,
        SweetAlert2Module,
        MonacoEditorModule,
        TranslateModule,
        FormsModule,
        InputTextareaModule,
        ReactiveFormsModule,
        DragDropModule,
        ElementsModule,
        CustomModule,
        PrimeModule,
        IsAllowedDirective,
        CdrDirective,
        ChipModule,
        ScrollableHeaderCardDirective,
        DirectivesModule,
        StepsModule,
        MatStepperModule,
        AutoCompleteModule,
        RatingModule,
        MatInputModule,
        MatIconModule,
        MatTableModule,
        ClipboardModule
    ],
    providers: [CustomValidatorsService, DatePipe]
})
export class SharedModule {
}
