import {Pipe, PipeTransform} from '@angular/core';
import {AppActions} from '../../store/app-actions.service';
import {readFileUrl} from '../helpers';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {getPlayerPhotoPath} from '../forms/helpers';

@Pipe({
    name: 'resourcePath'
})
export class ResourcePathPipe implements PipeTransform {

    constructor(private appActions: AppActions) {
    }

    transform(value: string, type: 'file' | 'voucher' = 'file'): Promise<string | SafeUrl> {
        return readFileUrl(value, this.appActions, type);
    }

}


@Pipe({
    name: 'playerPhotoPath'
})
export class PlayerPhotoPipe implements PipeTransform {

    constructor(private appActions: AppActions) {
    }

    transform(filename: string): string {
        return getPlayerPhotoPath(filename)
    }

}
