import * as Types from '../../types.graphql-gen';

import { PermissionGroupFragment, FullPermissionGroupFragment } from '../getLicenses/getLicenses.graphql-gen';
import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { PermissionGroupFragmentDoc, FullPermissionGroupFragmentDoc } from '../getLicenses/getLicenses.graphql-gen';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPermissionGroupsQueryVariables = Types.Exact<{
  filter: Types.PermissionGroupFilter;
}>;


export type GetPermissionGroupsQuery = (
  { __typename?: 'Query' }
  & { getPermissionGroups: (
    { __typename?: 'PermissionGroupsResult' }
    & { result: Array<(
      { __typename?: 'PermissionGroup' }
      & PermissionGroupFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetPermissionGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetPermissionGroupQuery = (
  { __typename?: 'Query' }
  & { getPermissionGroups: (
    { __typename?: 'PermissionGroupsResult' }
    & { result: Array<(
      { __typename?: 'PermissionGroup' }
      & FullPermissionGroupFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export const GetPermissionGroupsDocument = gql`
    query getPermissionGroups($filter: PermissionGroupFilter!) {
  getPermissionGroups(filter: $filter) {
    result {
      ...permissionGroup
    }
    meta {
      ...metaFragment
    }
  }
}
    ${PermissionGroupFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPermissionGroupsGQL extends Apollo.Query<GetPermissionGroupsQuery, GetPermissionGroupsQueryVariables> {
    document = GetPermissionGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPermissionGroupDocument = gql`
    query getPermissionGroup($id: String!) {
  getPermissionGroups(filter: {id: $id}) {
    result {
      ...fullPermissionGroup
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullPermissionGroupFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPermissionGroupGQL extends Apollo.Query<GetPermissionGroupQuery, GetPermissionGroupQueryVariables> {
    document = GetPermissionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }