<form *ngIf="loaded" [formGroup]="form">
    <div class="p-fluid pb-4">
        <div>
            <label for="gameName" class="pb-2">{{'games.form.name'|translate|firstUpper}}</label>
            <input
                id="gameName"
                class="{{form.controls.name.touched && form.controls.name.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="name" type="text" pInputText>
        </div>
        <div class="pt-4">
            <label for="gameDifficulty" class="pb-2">{{'games.form.difficulty'|translate|firstUpper}}</label>
            <p-dropdown [options]="difficultyOptions" id="gameDifficulty" appendTo="body" formControlName="difficulty" [filter]="true" optionValue="value" optionLabel="label">
            </p-dropdown>
        </div>
        <div class="pt-4" *ngIf="activeFormScheme.showType">
            <label for="gameType" class="pb-2">{{'games.form.type'|translate|firstUpper}}</label>
            <p-dropdown [options]="gameTypeOptions" id="gameType" appendTo="body" formControlName="type" [filter]="true" optionValue="value" optionLabel="label">
            </p-dropdown>
        </div>
        <div class="pt-4">
            <label for="gameGroup" class="pb-2">{{'games.form.gameGroup'|translate|firstUpper}}</label>
            <p-dropdown [options]="groupOptions" id="gameGroup" appendTo="body" formControlName="groupId" [filter]="true" optionValue="id" optionLabel="name">
            </p-dropdown>
        </div>
        <div class="pt-4" *ngIf="activeFormScheme.showPrice">
            <label for="gamePrice" class="pb-2">{{'games.form.price'|translate|firstUpper}}</label>
            <input
                id="gamePrice"
                class="{{form.controls.name.touched && form.controls.price.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="price" type="number" pInputText>
        </div>
    </div>

</form>
<app-dialog-footer>
    <div>
        <button [disabled]="loading.form" [loading]="loading.form" icon="pi pi-plus" class="p-button-success p-button-sm" pButton pRipple type="button" label="{{'general.create'|translate|firstUpper}}" (click)="createGame()"></button>
    </div>
</app-dialog-footer>
