import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-form-error-message',
    templateUrl: './form-error-message.component.html',
    styleUrls: ['./form-error-message.component.scss']
})
export class FormErrorMessageComponent extends BaseComponent implements OnChanges {
    @Input() c: FormControl|FormArray;

    errorMessage = '';

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.subSink.unsubscribe();
        this.checkErrors();
    }



    checkErrors(): void {
        this.subSink.sink = this.c.valueChanges.pipe(debounceTime(50)).subscribe(() => {
            this.validateError();
        })
        this.validateError();
    }

    validateError() {
        if(this.c.errors?.maxlength) {
            this.errorMessage = this.translateService.instant('errors.maxLength', this.c.errors.maxlength);
            return;
        }
        if(this.c.errors?.minlength) {
            this.errorMessage = this.translateService.instant('errors.minLength', this.c.errors.minlength);
            return;
        }
        if(this.c.errors?.required && this.c.touched) {
            this.errorMessage = this.translateService.instant('errors.required');
            return;
        }
        if(this.c.errors?.oneTranslationRequired) {
            this.errorMessage = this.translateService.instant(this.c.errors?.oneTranslationRequired);
            return;
        }
        this.errorMessage = '';
    }
}
