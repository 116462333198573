import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePlayerProgressMutationVariables = Types.Exact<{
  input: Types.PlayerProgressInputType;
}>;


export type UpdatePlayerProgressMutation = (
  { __typename?: 'Mutation' }
  & { updateManagerPlayerProgress: (
    { __typename?: 'UpdatePlayerProgressResult' }
    & Pick<Types.UpdatePlayerProgressResult, 'fileUrl' | 'renderedProgress' | 'removedPlayerProgressId'>
  ) }
);

export const UpdatePlayerProgressDocument = gql`
    mutation updatePlayerProgress($input: PlayerProgressInputType!) {
  updateManagerPlayerProgress(input: $input) {
    fileUrl
    renderedProgress
    removedPlayerProgressId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePlayerProgressGQL extends Apollo.Mutation<UpdatePlayerProgressMutation, UpdatePlayerProgressMutationVariables> {
    document = UpdatePlayerProgressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }