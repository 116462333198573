import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';
import {getAttachmentPath} from '../forms/helpers';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  transform(value: string): string {
    return environment.webOrigin + value;
  }

}


@Pipe({
    name: 'attachmentUrl'
})
export class AttachmentUrlPipe implements PipeTransform {

    transform(value: string): string {
        return getAttachmentPath(value);
    }

}
