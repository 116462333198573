<div class="p-fluid grid">
    <div *ngIf="!stepElementForm.controls.id.value && allowHashedVersion" [formGroup]="stepElementForm.controls.settings">
        <label for="hashedVersion-{{uniqueId}}" class="pb-2 col-12 pt-4">
            {{'elements.qrCode.hashedVersion'|translate|firstUpper}}
            <app-tooltip
                value="{{'elements.tooltip.qrCode.hashedVersion'|translate|firstUpper}}"></app-tooltip>
        </label>
        <div class="col-4">
            <p-selectButton (onChange)="hashedVersionChanged()" id="hashedVersion-{{uniqueId}}" [options]="boolOptions"
                            formControlName="hashedVersion" optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
        <div class="col-12" *ngIf="stepElementForm.controls.settings.controls.hashedVersion.value">
            <small class="p-error">POZOR, po uložení již nebude možné QR kód stáhnout, uložte si ho</small>
        </div>
    </div>
    <div class="col-12 mt-2">
        <label for="label-{{uniqueId}}" class="pb-2">{{'elements.qrCode.code'|translate|firstUpper}}*</label>
        <input id="label-{{uniqueId}}"
               [readOnly]="!!stepElementForm.value.id"
               [(ngModel)]="code"
               (ngModelChange)="codeChanged()"
               type="text" pInputText
               class="{{stepElementForm.controls.settings.controls.code.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-12">
        <img class="mt-2" *ngIf="currentCode" src="https://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data={{currentCode}}&qzone=1&margin=0&size=800x800&ecc=L&format=jpeg&download=1" style="width: 200px; max-width: 100%">
    </div>
    <div class="col-12 mt-0 ml-2 pt-0">
        <div style="width: 100px" class="d-flex">
            <a
                *ngIf="currentCode" href="https://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data={{currentCode}}&qzone=1&margin=0&size=800x800&ecc=L&format=jpeg&download=1"
                pButton
                pRipple
                icon="pi pi-download"
                type="button"
                label="{{'gameVoucher.download'|translate|firstUpper}}"
                class="p-button-success p-button-text inline-block"
            ></a>
        </div>
    </div>
</div>
