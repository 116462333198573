import * as Types from '../../types.graphql-gen';

import { LangFragment } from '../../queries/getInitialData/getinitialData.graphql-gen';
import { gql } from 'apollo-angular';
import { LangFragmentDoc } from '../../queries/getInitialData/getinitialData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateLangMutationVariables = Types.Exact<{
  input: Types.LangInput;
}>;


export type UpdateLangMutation = (
  { __typename?: 'Mutation' }
  & { updateLang: (
    { __typename?: 'UpdateLangResult' }
    & { lang?: Types.Maybe<(
      { __typename?: 'Lang' }
      & LangFragment
    )> }
  ) }
);

export const UpdateLangDocument = gql`
    mutation updateLang($input: LangInput!) {
  updateLang(input: $input) {
    lang {
      ...lang
    }
  }
}
    ${LangFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLangGQL extends Apollo.Mutation<UpdateLangMutation, UpdateLangMutationVariables> {
    document = UpdateLangDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }