import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {SentenceForm} from '../../pair-sentence.component';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {lastValueFrom} from 'rxjs';
import {readFileUrlAction, readFileUrlSuccessAction} from '../../../../../../store/general/actions';
import {AppActions} from '../../../../../../store/app-actions.service';

@Component({
  selector: 'app-sentence-form',
  templateUrl: './sentence-form.component.html',
  styleUrls: ['./sentence-form.component.scss']
})
export class SentenceFormComponent extends BaseComponent implements OnInit {
    @Input() form: SentenceForm;
    image: { loading: boolean, url: string | SafeUrl | null } = {loading: false, url: null};
    file: File | null;

    constructor(
        private domSanitizer: DomSanitizer,
        private appActions: AppActions
    ) {
        super();
    }

    async ngOnInit() {
        if (this.form.value.src) {
            this.image.url = (await lastValueFrom(this.appActions.dispatch(readFileUrlAction({filename: this.form.value.src}), [readFileUrlSuccessAction]))).url;
        }
    }



    onFileUpload(): (file: File | null) => Promise<true> {
        return (file: File | null) => {
            return new Promise(async (resolve) => {
                if (file) {
                    try {
                        this.image.url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
                        this.image.loading = false;
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.image.url = null;
                }
                this.image.loading = false;
                this.file = file;
                this.form.controls.src.setValue(this.file?.name ?? null);
                resolve(true);
            });
        };
    }
}
