<p-table
    #pushNotificationsTable
    [stateStorage]="'local'"
    [stateKey]="'pushNotificationFilter-' + activeType"
    [value]="pushNotifications"
    [rows]="10"
    [paginator]="true"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="[10,20,50,100]"
    [lazy]="true"
    [loading]="loading.dataGrid"
    class="{{pushNotifications && pushNotifications.length === 0 ? 'hidden' : ''}}"
    [autoLayout]="false"
    sortMode="multiple"
    (onLazyLoad)="tableFilterChanged($event)"
    responsiveLayout="scroll" styleClass="p-datatable-sm p-datatable-gridlines">
    <ng-template pTemplate="caption">
        <div class="flex">
            <button pButton label="{{'general.reset'|translate|firstUpper}}" class="p-button-outlined"
                    icon="pi pi-filter-slash" (click)="filterReset(null)"></button>
        </div>
    </ng-template>
    <ng-container *pTemplate="'header'; let columns=columns">
        <tr>
            <th>{{'pushNotification.name'|translate|firstUpper}}</th>
            <th>{{'pushNotification.title'|translate|firstUpper}}</th>
            <th>{{'pushNotification.message'|translate|firstUpper}}</th>
            <th *ngIf="activeType === 'upcoming' || activeType === 'finished'">
                {{'pushNotification.stats'|translate|firstUpper}}
            </th>
            <th *ngIf="activeType === 'upcoming'"
                pSortableColumn="sendAt">{{'pushNotification.table.sendAt'|translate|firstUpper}}
                <p-sortIcon field="sendAt"></p-sortIcon>
            </th>
            <th *ngIf="activeType === 'concept'"
                pSortableColumn="createdAt">{{'player.table.createdAt'|translate|firstUpper}}
                <p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th *ngIf="activeType === 'finished'"
                pSortableColumn="dateFinished">{{'pushNotification.table.dateFinished'|translate|firstUpper}}
                <p-sortIcon field="dateFinished"></p-sortIcon>
            </th>
            <th style="width: 2rem">{{'general.actions'|translate|firstUpper}}</th>
        </tr>
    </ng-container>
    <ng-container *pTemplate="'body'; let pushNotification; let columns=columns">
        <tr class="{{activeType === 'upcoming' && (!pushNotification.sendAt || ((pushNotification.sendAt)|toDate) <= now) ? 'success' : ''}}"
            [activeType]="activeType" appPushNotificationRow [pushNotification]="pushNotification"
            [actions]="getPushNotificationActions(pushNotification)"></tr>
    </ng-container>
</p-table>
<h5 *ngIf="pushNotifications && pushNotifications.length === 0">
    {{'general.noDataFound'|translate|firstUpper}}
</h5>
