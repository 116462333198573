import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {PhoneNumberSettings} from '../element-types';
import { MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            title: new FormControl('', {validators: Validators.required, nonNullable: true}),
            text: new FormControl('', {validators: Validators.required, nonNullable: true}),
            placeholder: new FormControl('', {nonNullable: true})
        });
    }


}

type SettingsControl = {
    placeholder: FormControl<string>,
    title: FormControl<string>,
    text: FormControl<string>
}
