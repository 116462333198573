import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent {
    displayModal: boolean;
    @Input() title: string;
    @Input() buttonLabel: string;
    showModalDialog() {
        this.displayModal = true;
    }
}
