import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchGameVoucherAction,
    fetchGameVoucherFailAction,
    fetchGameVouchersAction,
    fetchGameVouchersFailAction,
    fetchGameVouchersSuccessAction,
    fetchGameVoucherSuccessAction,
    updateGameVoucherAction,
    updateGameVoucherFailAction,
    updateGameVoucherSuccessAction
} from './actions';
import {map, switchMap} from 'rxjs/operators';
import {GameVoucher, GameVouchersResult, UpdateGameVoucherResult} from '../../graphql/types.graphql-gen';
import {GetGameVouchersGQL} from '../../graphql/queries/getGameVouchers/getGameVouchers.graphql-gen';
import {UpdateGameVoucherGQL} from '../../graphql/mutations/updateGameVoucher/updateGameVoucher.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class GameVoucherEffect implements OnDestroy {


    subSink = new SubSink();

    fetchGameVouchers$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchGameVouchersAction
        ),
        switchMap((a) => {
            return this.getGameVouchersGQL.fetch({filter: a.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchGameVouchersFailAction({header: '', message: res.errors[0].message});
            }
            return fetchGameVouchersSuccessAction({result: res.data.getGameVouchers as GameVouchersResult});
        })
    ));

    fetchGameVoucher$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchGameVoucherAction
        ),
        switchMap((a) => {
            return this.getGameVouchersGQL.fetch({filter: {id: a.id}});
        }),
        map((res) => {
            if (res.errors || res.data?.getGameVouchers?.result?.length !== 1) {
                return fetchGameVoucherFailAction({
                    header: '',
                    message: res.errors ? res.errors[0].message : 'voucher_not_found'
                });
            }
            return fetchGameVoucherSuccessAction({gameVoucher: res.data.getGameVouchers.result[0] as GameVoucher});
        })
    ));

    updateGameVoucher$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateGameVoucherAction
        ),
        switchMap((action) => {
            return fixMutation(this.updateGameVoucherGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if (res.errors) {
                return updateGameVoucherFailAction({header: '', message: res.errors[0].message});
            }
            return updateGameVoucherSuccessAction({result: res.data.updateGameVoucher as UpdateGameVoucherResult});
        })
    ));

    constructor(
        private actions$: Actions,
        private getGameVouchersGQL: GetGameVouchersGQL,
        private updateGameVoucherGQL: UpdateGameVoucherGQL,
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
