import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchAttributesSuccessAction, fetchAttributeSuccessAction, updateAttributeSuccessAction} from './actions';
import {sortArrayByKey} from '../saleSource/reducer';
import {AttributeOptionFragment} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';

export const attributeReducer = createImmerReducer(
    defaultDataState,
    on(fetchAttributesSuccessAction, (state: IDataState, action: ReturnType<typeof fetchAttributesSuccessAction>) => {
        state.attribute.attributes = action.res;
        return state;
    }),
    on(updateAttributeSuccessAction, (state: IDataState, action: ReturnType<typeof updateAttributeSuccessAction>) => {
        if (action.res.attribute) {
            state.attribute.activeAttribute = action.res.attribute;

            const updatedAttribute: AttributeOptionFragment = {
                __typename: 'AttributeOption',
                id: action.res.attribute.id,
                active: action.res.attribute.active,
                ordering: action.res.attribute.ordering,
                level: action.res.attribute.level,
                name: action.res.attribute.name,
                type: action.res.attribute.type,
                parentId: action.res.attribute.parentId
            };
            const index = state.helperData.projectData?.availableAttributes.findIndex((a) => a.id === action.res.attribute?.id);
            if(state.helperData.projectData?.availableAttributes) {
                if (index === -1 || index === undefined) {
                    state.helperData.projectData.availableAttributes.push(updatedAttribute);
                } else {
                    state.helperData.projectData.availableAttributes[index] = updatedAttribute
                }
            }
        }
        if (action.res.removeAttributeId) {
            if (state.helperData.projectData?.availableAttributes) {
                state.helperData.projectData.availableAttributes =
                    state.helperData.projectData?.availableAttributes.filter((aa) => aa.id !== action.res.removeAttributeId);
            }
            if (state.attribute.attributes?.result) {
                state.attribute.attributes.result = state.attribute.attributes.result.filter((a) => a.id !== action.res.removeAttributeId);
            }
        }
        if (action.res.changeOrder && state.attribute.attributes?.result) {
            state.attribute.attributes.result.forEach((a) => {
                a.ordering = (action.res.changeOrder?.find((o) => o.entityId === a.id)?.sortId ?? a.ordering);
            });
            state.attribute.attributes.result = state.attribute.attributes.result.sort(sortArrayByKey('ordering'))
        }
        return state;
    }),
    on(fetchAttributeSuccessAction, (state: IDataState, action: ReturnType<typeof fetchAttributeSuccessAction>) => {
        state.attribute.activeAttribute = action.attribute;
        return state;
    })
);

