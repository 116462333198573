import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPurchasesQueryVariables = Types.Exact<{
  filter: Types.PurchaseFilter;
}>;


export type GetPurchasesQuery = (
  { __typename?: 'Query' }
  & { getPurchases: (
    { __typename?: 'PurchasesResult' }
    & { result: Array<(
      { __typename?: 'Purchase' }
      & PurchaseFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type PurchaseFragment = (
  { __typename?: 'Purchase' }
  & Pick<Types.Purchase, 'id' | 'orderId' | 'orderNumber' | 'price' | 'currency' | 'state' | 'status' | 'sourceInfo' | 'source' | 'email' | 'paymentMethod' | 'orderInfo' | 'hash' | 'cardType' | 'createdAt' | 'updatedAt'>
  & { game?: Types.Maybe<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name'>
  )>, gameVoucher?: Types.Maybe<(
    { __typename?: 'GameVoucher' }
    & Pick<Types.GameVoucher, 'id' | 'code'>
    & { used?: Types.Maybe<(
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id'>
    )> }
  )>, partner?: Types.Maybe<(
    { __typename?: 'Partner' }
    & Pick<Types.Partner, 'id' | 'name' | 'discount' | 'discountType'>
  )>, user?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'email' | 'fullname'>
  )> }
);

export type MetaFragmentFragment = (
  { __typename?: 'PageInfo' }
  & Pick<Types.PageInfo, 'totalItems' | 'hasNextPage'>
);

export const PurchaseFragmentDoc = gql`
    fragment purchase on Purchase {
  id
  orderId
  orderNumber
  price
  currency
  state
  status
  sourceInfo
  source
  email
  paymentMethod
  orderInfo
  hash
  cardType
  createdAt
  updatedAt
  game {
    id
    name
  }
  gameVoucher {
    id
    code
    used {
      id
    }
  }
  partner {
    id
    name
    discount
    discountType
  }
  user {
    id
    email
    fullname
  }
}
    `;
export const MetaFragmentFragmentDoc = gql`
    fragment metaFragment on PageInfo {
  totalItems
  hasNextPage
}
    `;
export const GetPurchasesDocument = gql`
    query getPurchases($filter: PurchaseFilter!) {
  getPurchases(filter: $filter) {
    result {
      ...purchase
    }
    meta {
      ...metaFragment
    }
  }
}
    ${PurchaseFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPurchasesGQL extends Apollo.Query<GetPurchasesQuery, GetPurchasesQueryVariables> {
    document = GetPurchasesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }