import {Component} from '@angular/core';
import {BaseComponent} from './base.component';
import {Filter, Maybe, OrderBy, OrderByEnum} from '../../../graphql/types.graphql-gen';
import {Table} from 'primeng/table';

@Component({
    selector: 'app-base-filter-component',
    template: '',
    styles: [],
})
// @ts-ignore
export abstract class BaseFilterComponent<T extends {filter?: Maybe<Filter>}|'reset'> extends BaseComponent {

    filter: T;
    extraData: any;

    abstract filterReset(any: any): any
    abstract filterChanged(filter: T | null, setToFirstPage?: boolean, extra?: any): any;

    tableFilterChanged($event: any) {
        const filter = this.filter as {filter: Filter }|'reset';
        // When user visits datagrid for the first time
        if ($event.multiSortMeta === undefined) {
            this.filterReset(null);
            return;
        }
        setTimeout(() => {
            if (!filter || filter === 'reset') {
                return;
            }
            const newFilter: {filter: Filter } = {
                ...filter,
                filter: {
                    ...filter.filter,
                    offset: $event.first,
                    limit: $event.rows
                }
            };
            if ($event.multiSortMeta && newFilter.filter) {
                newFilter.filter.orderBy = $event.multiSortMeta.map((v: { field: string, order: number }): OrderBy => ({
                    property: v.field,
                    value: v.order === -1 ? OrderByEnum.Desc : OrderByEnum.Asc
                }));
            }
            this.filterChanged(newFilter as T, undefined, this.extraData);
        });
    }

    tableReset(table: Table) {
        table.reset();
        table._multiSortMeta = [{field: 'createdAt', order: -1}];
        table._sortOrder = 1;
        table._sortField = 'id';
        table._rows = 10;
        table.sortSingle();
        table.saveState();
    }


}
