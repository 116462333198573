import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {GameDifficultyEnum, ProgressType} from '../../store/types';
import {
    AttributeTypeEnum, ExternalVoucherTypeEnum,
    GameResultType,
    GameStateEnum,
    GameStepClueInput,
    GameStepStateEnum,
    GameTypeEnum,
    GenderEnum,
    LicenseTypeEnum,
    PartnerCommissionTypeEnum,
    PartnerDiscountTypeEnum,
    PaymentMethodEnum, PurchaseAddressTypeEnum, ReviewSourceEnum,
    StepElement,
    StepElementInput, VariantAlgorithmEnum
} from '../../graphql/types.graphql-gen';
import {v4 as uuid} from 'uuid';
import * as _ from 'lodash';
import {MyFormGroupV2} from './forms';
import {AttributeOptionFragment} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {getAttributeTypeSettings} from '../../global-config';
import {lastValueFrom, Subject} from 'rxjs';
import {readFileBufferAction, readFileBufferSuccessAction, showAlertProgressAction} from '../../store/general/actions';
import {GameHelper} from './game-helpers';
import {firstUpper, sleep} from '../helpers';
import {AppActions} from '../../store/app-actions.service';
import {ProgressService} from '../../services/progress.service';
import {AttributeTreeOptionType} from '../../store/attributes/selectors';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function Emails(controlName: string) {
    return (control: AbstractControl) => {
        console.log(control);
        return null;
    };
}

export const getThumbnailPath = (filename: string) => {
    return environment.resourcesOrigin + '/thumbnail/' + filename;
};


export const getAudioPath = (filename: string) => {
    return environment.resourcesOrigin + '/app/' + filename;
};

export const getVideoPath = getAudioPath;

export const getFullPath = (filename: string) => {
    return environment.resourcesOrigin + '/app/' + filename;
};

export const getVoucherPath = (filename: string) => {
    return environment.resourcesOrigin + '/vouchers/' + filename;
};

export const getImageVoucherPath = (filename: string) => {
    return environment.webOrigin + '/voucher-templates/' + filename;
}

export const getPlayerPhotoPath = (filename: string) => {
    return environment.resourcesOrigin + '/user-photos/' + filename;
}

export const getAttachmentPath = (filename: string) => {
    return environment.resourcesOrigin + '/attachment/' + filename;
};

export const getYesNoOptions = (translateService: TranslateService, includeEmpty = false) => {
    const res = [
        {
            label: translateService.instant('settings.form.no'),
            value: false
        },
        {
            label: translateService.instant('settings.form.yes'),
            value: true
        },
    ];
    if (includeEmpty) {
        return [{
            label: '- - -',
            value: null
        }, ...res];
    }
    return res;
};

export const getYesNoNullOptions = (translateService: TranslateService) => {
    return [
        {
            label: '- - -',
            value: null
        },
        {
            label: translateService.instant('settings.form.no'),
            value: '%NULL%'
        },
        {
            label: translateService.instant('settings.form.yes'),
            value: '%NOT_NULL%'
        },
    ];
};

export const getYesNoOptionsAlt = (translateService: TranslateService) => {
    return [
        {
            label: translateService.instant('settings.form.no'),
            value: '0'
        },
        {
            label: translateService.instant('settings.form.yes'),
            value: '1'
        },
    ];
};

export const getDifficultyOptions = (translateService: TranslateService) => {
    return Object.values(GameDifficultyEnum).map((v) => ({
        label: translateService.instant('games.difficulty.' + v),
        value: v
    }));
};

export const getPaymentMethodOptions = (translateService: TranslateService, filterOut: PaymentMethodEnum[] = []) => {
    const getPaymentFlag = (method: PaymentMethodEnum) => {
        switch (method) {
            case PaymentMethodEnum.Gopay:
                return 'gopay.webp';
            case PaymentMethodEnum.BenefitPlus:
                return 'benefit-plus.webp'
            case PaymentMethodEnum.Benefity:
                return 'benefity.webp';
            case PaymentMethodEnum.Cod:
                return 'cod.png';
            case PaymentMethodEnum.Sodexo:
                return 'sodexo.webp';
            case PaymentMethodEnum.Sphere:
                return 'sphere.webp';
            case PaymentMethodEnum.Up:
                return 'up.webp'
            default:
                return '';
        }
    }
    return Object.values(PaymentMethodEnum).filter((pm) => filterOut.find((fo) => fo !== pm)).map((method) => ({
        label: translateService.instant('paymentMethod.' + method),
        value: method,
        flag: getPaymentFlag(method)
    }));
}

export const getGameTypeOptions = (translateService: TranslateService) => {
    return Object.values(GameTypeEnum).map((v) => ({
        label: translateService.instant('games.type.' + v),
        value: v
    }));
};

export const getVariantAlgorithmOptions = (translateService: TranslateService) => {
    return Object.values(VariantAlgorithmEnum).map((v) => {
        return {
            label: firstUpper(translateService.instant('games.variantAlgorithm.' + v)),
            value: v
        }
    });
}


export type AttributeOptionFormOptions = { [key in AttributeTypeEnum]: { options: AttributeOptionFragment[], settings: ReturnType<typeof getAttributeTypeSettings> } };
export const getAttributeOptionFormOptions = (availableOptions: AttributeOptionFragment[]): AttributeOptionFormOptions => {
    const res: AttributeOptionFormOptions = {} as AttributeOptionFormOptions;
    Object.values(AttributeTypeEnum).forEach((attributeType) => {
        res[attributeType] = {
            options: availableOptions.filter((o) => o.type === attributeType),
            settings: getAttributeTypeSettings(attributeType)
        };
    });
    return res;
};

export const getAttributeTypeOptions = (translateService: TranslateService) => {
    return Object.values(AttributeTypeEnum).map((v) => ({
        label: translateService.instant('attributes.type.' + v),
        value: v
    }));
};


export const getPartnerDiscountTypeOptions = (translateService: TranslateService, includeNullOption = false) => {
    const res = Object.values(PartnerDiscountTypeEnum).map((v) => {
        return {
            label: translateService.instant('partner.discountType.' + v),
            value: v
        }
    })
    if (includeNullOption) {
        return [
            {
                label: '- - -',
                value: null
            },
            ...res
        ]
    }
    return res;
}

export const getExternalVoucherTypeOptions = (translateService: TranslateService, includeNullOption = false) => {
    const res = Object.values(ExternalVoucherTypeEnum).map((v) => {
        return {
            label: translateService.instant('externalVoucher.type.' + v),
            value: v
        }
    })
    if(!includeNullOption) {
        return res;
    }
    return [
        {
            label: '- - -',
            value: null
        },
        ...res
    ]
}

export const getReviewSourceTypeOptions = (translateService: TranslateService, includeNullOption = false) => {
    const res = Object.values(ReviewSourceEnum).map((v) => {
        return {
            label: translateService.instant('review.sourceType.' + v),
            value: v
        }
    })
    if(!includeNullOption) {
        return res;
    }
    return [
        {
            label: '- - -',
            value: null
        },
        ...res
    ]
}

export const getPartnerCommissionTypeOptions = (translateService: TranslateService, includeNullOption = false) => {
    const res = Object.values(PartnerCommissionTypeEnum).map((v) => {
        return {
            label: translateService.instant('partner.commissionType.' + v),
            value: v
        }
    })
    if (includeNullOption) {
        return [
            {
                label: '- - -',
                value: null
            },
            ...res
        ]
    }

    return res;
}

export const getLicenseTypeOptions = (translateService: TranslateService) => {
    return Object.values(LicenseTypeEnum).map((v) => ({
        label: translateService.instant('license.type.' + v),
        value: v
    }));
};

export const getPurchaseAddressTypeOptions = (translateService: TranslateService) => {
    return Object.values(PurchaseAddressTypeEnum).map((v) => ({
        label: translateService.instant('purchases.addressType.' + v),
        value: v
    }))
};

export const getGenderOptions = (translateService: TranslateService) => {
    return Object.values(GenderEnum).map((v) => ({
        label: translateService.instant('gameVoucher.gender.' + v),
        value: v
    }));
};

export const getGameStepStateOptions = (translateService: TranslateService) => {
    return Object.values(GameStepStateEnum).map((s) => ({
        label: translateService.instant('games.steps.state.' + s),
        value: s
    }));
};

export const getGameStates = (translateService: TranslateService) => {
    return Object.values(GameStateEnum).map((v) => ({
        label: translateService.instant('games.states.' + v),
        value: v
    }));
};

export const getGameResultOptions = (translateService: TranslateService) => {
    return [
        {
            label: translateService.instant('games.results.' + GameResultType.Points),
            value: GameResultType.Points
        },
        {
            label: translateService.instant('games.results.' + GameResultType.Time),
            value: GameResultType.Time
        }
    ];
};

export const createGameStepClueFormGroup = (gameStepClue: GameStepClueInput) => {
    const g = new MyFormGroupV2({
            id: new UntypedFormControl(null),
            filename: new UntypedFormControl(null),
            title: new UntypedFormControl(''),
            description: new UntypedFormControl(''),
            penalizationTime: new UntypedFormControl(null, Validators.required),
            penalizationPoints: new UntypedFormControl(null, Validators.required),
        }
    );
    g.patchValue(gameStepClue);
    return g;
};


export const generateUUID = () => uuid();


export const mapStepElementsToInputType = (stepElements: StepElement[]): StepElementInput[] => {
    return stepElements.map((v) => ({
        ...v,
        tempId: undefined,
        gameStepClues: v.gameStepClues.map((gsc) => ({
            ...gsc,
            filename: gsc.filename ? {name: gsc.filename} : undefined
        }))
    }));
};


export const flattenTreeArray = (treeArray: AttributeTreeOptionType[]) => {
    const flatArray: AttributeTreeOptionType[] = [];

    function flatten(node: AttributeTreeOptionType) {
        flatArray.push(node);

        if (node && node.children.length > 0) {
            for (const child of node.children) {
                flatten(child);
            }
        }
    }

    for (const node of treeArray) {
        flatten(node);
    }

    return flatArray;
}
