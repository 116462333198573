<p-galleria
    *ngIf="photos"

    [value]="photos"
    [(visible)]="displayCustom"
    [(activeIndex)]="activeIndex"
    [responsiveOptions]="responsiveOptions"
    [containerStyle]="{'max-width': '850px'}"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showItemNavigators]="true"
    [showThumbnails]="false"
    [baseZIndex]="100000"
>
    <ng-template pTemplate="item" let-item>
        {{setActivePhoto(item)}}
        <img [src]="activePhoto.fullPath" style="width: 100%; display: block;"/>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        {{setActivePhoto(item)}}
        <div class="p-grid p-nogutter p-justify-center">
            <img [src]="activePhoto.path" style="display: block;"/>
        </div>
    </ng-template>
</p-galleria>
<div *ngIf="photos" class="row mt-3 justify-content-start">
    <div *ngFor="let image of photos; let index = index;" class="col-12 col-md-4 col-sm-6 col-lg-3">
        <div style="width: 220px">
            <img [src]="image.path" alt="" style="cursor: pointer; width: 200px; height: 200px; object-fit: cover;" (click)="imageClick(index)"/>
            <button (click)="download(image.fullPath)"
                    label="{{'gameVoucher.download'|translate|firstUpper}}"
                    pButton pRipple class="p-button-success p-button-text inline-block" style="width: auto">

            </button>
            <button *ngIf="!readonly && removePhotoCallback" [disabled]="image.loading ?? false" [loading]="image.loading ?? false" pButton pRipple type="button" label="Remove" class="p-button-danger p-button-text" style="width: auto" (click)="removePhoto(image)"></button>
        </div>
    </div>
</div>
