<div class="p-fluid row align-content-center full-height m-0 p-0">
    <div class="col-6 mr-0 pr-2">
        <h3>{{'elements.pairSentence.left'|translate}}</h3>
        <div class="mb-2">
            <app-error-list [isTouched]="form.controls.left.touched" [errors]="form.controls.left.errors"></app-error-list>
        </div>
        <app-sentence-form #a [form]="form.controls.left"></app-sentence-form>
    </div>
    <div class="col-6 m-0 pl-2">
        <h3>{{'elements.pairSentence.right'|translate}}</h3>
        <div class="mb-2">
            <app-error-list [isTouched]="form.controls.right.touched" [errors]="form.controls.right.errors"></app-error-list>
        </div>
        <app-sentence-form #b [form]="form.controls.right"></app-sentence-form>
    </div>
</div>
<app-dialog-footer>
    <div class="pt-3">
        <button
            icon="pi pi-plus"
            class="p-button-success p-button-sm"
            pButton
            pRipple
            type="button"
            label="{{'general.save'|translate|firstUpper}}"
            (click)="save()">
        </button>
    </div>
</app-dialog-footer>
