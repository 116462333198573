import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GameErrors} from '../../../forms/game-validator';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-control-error',
    templateUrl: './control-error.component.html',
    styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnChanges {

    @Input() errors: GameErrors | null = null;

    errorMessages: { message: string, severity: string }[] = [];

    constructor(
        private translateService: TranslateService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refreshErrors();
    }



    refreshErrors() {
        this.errorMessages = [];
        if (this.errors) {
            for (const [key, value] of Object.entries(this.errors)) {
                switch (key) {
                    case 'min':
                        this.errorMessages.push({severity: 'error',
                            message: this.translateService.instant('form.validationErrors.min', {
                                min: value.min,
                                actual: value.actual
                            })
                        })
                        break;
                    case 'minlength':
                        this.errorMessages.push({severity: 'error', message: this.translateService.instant('form.validationErrors.minLength', value)});
                        break;
                    case 'maxlength':
                        this.errorMessages.push({severity: 'error', message: this.translateService.instant('form.validationErrors.maxLength', value)});
                        break;
                    case 'max':
                        this.errorMessages.push({severity: 'error',
                            message: this.translateService.instant('form.validationErrors.max', {
                                max: value.max,
                                actual: value.actual
                            })
                        });
                        break;
                    case 'required':
                        this.errorMessages.push({
                            severity: 'error',
                            message: this.translateService.instant('form.validationErrors.required')
                        });
                        break;
                    default:
                        this.errorMessages.push({
                            severity: 'error',
                            message: value.toString()
                        });
                }
            }
        }
    }

}
