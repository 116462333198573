import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {BaseComponent} from '../../../base/base.component';
import {ArrElement} from '../../../../forms/types';
import {SelectOptionSettingsControl} from '../select-option.component';
import {getYesNoOptions} from '../../../../forms/helpers';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';
import {readFileUrlAction, readFileUrlSuccessAction} from '../../../../../store/general/actions';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppActions} from '../../../../../store/app-actions.service';

@Component({
    selector: 'app-update-option',
    templateUrl: './update-option.component.html',
    styleUrls: ['./update-option.component.scss']
})
export class UpdateOptionComponent extends BaseComponent implements OnInit {

    boolOptions = getYesNoOptions(this.translateService);
    form: ArrElement<SelectOptionSettingsControl['options']['controls']>;
    readonly: boolean;
    image: { loading: boolean, url: string | SafeUrl | null } = {loading: false, url: null};
    file: File | null = null;

    constructor(
        public dynamicDialogConfig: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private translateService: TranslateService,
        private appActions: AppActions,
        private domSanitizer: DomSanitizer,
    ) {
        super();
    }

    async ngOnInit() {
        this.form = this.dynamicDialogConfig.data.sentence;
        this.readonly = this.dynamicDialogConfig.data.readonly;
        if (this.form.value.src) {
            this.image.url = (await lastValueFrom(this.appActions.dispatch(readFileUrlAction({filename: this.form.value.src}), [readFileUrlSuccessAction]))).url;
        }
    }


    save() {
        if(!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.ref.close({form: this.form, file: this.file});
    }

    onFileUpload(): (file: File | null) => Promise<true> {
        return (file: File | null) => {
            return new Promise(async (resolve) => {
                if (file) {
                    try {
                        this.image.url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
                        this.image.loading = false;
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.image.url = null;
                }
                this.image.loading = false;
                this.file = file;
                this.form.controls.src.setValue(this.file?.name ?? null);
                resolve(true);
            });
        };
    }
}
