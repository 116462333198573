import {createAction, props} from '@ngrx/store';
import {DefaultFailMessage} from '../user/actions';
import {GetPermissionGroupsMetaDataQuery} from '../../graphql/queries/metaData/metaData.graphql-gen';
import {GetPermissionGroupsQuery} from '../../graphql/queries/getPermissionGroups/getPermissionGroups.graphql-gen';
import {
    PermissionGroup,
    PermissionGroupFilter,
    PermissionGroupInput,
    UpdatePermissionGroupResult
} from '../../graphql/types.graphql-gen';


export const fetchPermissionGroupsMetaDataAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS_META_DATA');
export const fetchPermissionGroupsMetaDataSuccessAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS_META_DATA_SUCCESS', props<FetchPermissionGroupsMetaDataSuccessActionProps>());
export const fetchPermissionGroupsMetaDataFailAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS_META_DATA_FAIL', props<FetchPermissionGroupsMetaDataFailActionProps>());

export const fetchPermissionGroupsAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS', props<FetchPermissionGroupsActionProps>());
export const fetchPermissionGroupsSuccessAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS_SUCCESS', props<FetchPermissionGroupsSuccessActionProps>());
export const fetchPermissionGroupsFailAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUPS_FAIL', props<FetchPermissionGroupsFailActionProps>());

export const fetchPermissionGroupAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUP', props<FetchPermissionGroupActionProps>());
export const fetchPermissionGroupSuccessAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUP_SUCCESS', props<FetchPermissionGroupSuccessActionProps>());
export const fetchPermissionGroupFailAction = createAction('[PermissionGroup] FETCH_PERMISSION_GROUP_FAIL', props<FetchPermissionGroupFailActionProps>());


export const updatePermissionGroupAction = createAction('[PermissionGroup] UPDATE_PERMISSION_GROUP', props<UpdatePermissionGroupActionProps>());
export const updatePermissionGroupSuccessAction = createAction('[PermissionGroup] UPDATE_PERMISSION_GROUP_SUCCESS', props<UpdatePermissionGroupSuccessActionProps>());
export const updatePermissionGroupFailAction = createAction('[PermissionGroup] UPDATE_PERMISSION_GROUP_FAIL', props<UpdatePermissionGroupFailActionProps>());


export type UpdatePermissionGroupActionProps = {input: PermissionGroupInput};
export type UpdatePermissionGroupFailActionProps = DefaultFailMessage;
export type UpdatePermissionGroupSuccessActionProps = {result: UpdatePermissionGroupResult};

export type FetchPermissionGroupsMetaDataSuccessActionProps = { result: GetPermissionGroupsMetaDataQuery['getPermissionGroups']['result'], allPermissions: string[][] };
export type FetchPermissionGroupsMetaDataFailActionProps = DefaultFailMessage;

export type FetchPermissionGroupsActionProps = { updateStore: boolean, filter: PermissionGroupFilter };
export type FetchPermissionGroupsFailActionProps = DefaultFailMessage;
export type FetchPermissionGroupsSuccessActionProps = { result: GetPermissionGroupsQuery['getPermissionGroups'], updateStore: boolean }

export type FetchPermissionGroupActionProps = { id: number };
export type FetchPermissionGroupFailActionProps = DefaultFailMessage;
export type FetchPermissionGroupSuccessActionProps = { permissionGroup: PermissionGroup | null }
