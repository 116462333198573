<form *ngIf="loaded" [formGroup]="form">
    <div class="p-fluid pb-4">
        <div>
            <label for="name" class="pb-2">{{'productPartner.form.name'|translate|firstUpper}}</label>
            <input
                id="name"
                class="{{form.controls.name.touched && form.controls.name.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="name" type="text" pInputText>
        </div>
    </div>

</form>

<app-dialog-footer>
    <div>
        <button [disabled]="loading.form || !form.valid" [loading]="loading.form" icon="pi pi-plus" class="p-button-success p-button-sm" pButton pRipple type="button" label="{{'general.create'|translate}}" (click)="createProductPartner()"></button>
    </div>
</app-dialog-footer>
