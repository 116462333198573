import * as Types from '../../types.graphql-gen';

import { EmailSettingsFragment, GatewaySettingsFragment, GeneralSettingsFragment, AppSettingsFragment } from '../../queries/getSettings/getSettings.graphql-gen';
import { gql } from 'apollo-angular';
import { EmailSettingsFragmentDoc, GatewaySettingsFragmentDoc, GeneralSettingsFragmentDoc, AppSettingsFragmentDoc } from '../../queries/getSettings/getSettings.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateSettingsMutationVariables = Types.Exact<{
  settings: Types.SettingsInput;
}>;


export type UpdateSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateSettings: (
    { __typename?: 'Settings' }
    & { appSettings: (
      { __typename?: 'AppSettings' }
      & AppSettingsFragment
    ) }
    & EmailSettingsFragment
    & GatewaySettingsFragment
    & GeneralSettingsFragment
  ) }
);

export const UpdateSettingsDocument = gql`
    mutation updateSettings($settings: SettingsInput!) {
  updateSettings(settings: $settings) {
    ...emailSettings
    ...gatewaySettings
    ...generalSettings
    appSettings {
      ...appSettings
    }
  }
}
    ${EmailSettingsFragmentDoc}
${GatewaySettingsFragmentDoc}
${GeneralSettingsFragmentDoc}
${AppSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSettingsGQL extends Apollo.Mutation<UpdateSettingsMutation, UpdateSettingsMutationVariables> {
    document = UpdateSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }