import * as Types from '../../types.graphql-gen';

import { FullAttributeFragment } from '../../queries/getAttributes/getAttributes.graphql-gen';
import { gql } from 'apollo-angular';
import { FullAttributeFragmentDoc } from '../../queries/getAttributes/getAttributes.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateAttributeMutationVariables = Types.Exact<{
  input: Types.AttributeInput;
}>;


export type UpdateAttributeMutation = (
  { __typename?: 'Mutation' }
  & { updateAttribute: (
    { __typename?: 'UpdateAttributeResult' }
    & Pick<Types.UpdateAttributeResult, 'removeAttributeId'>
    & { attribute?: Types.Maybe<(
      { __typename?: 'Attribute' }
      & FullAttributeFragment
    )>, changeOrder?: Types.Maybe<Array<(
      { __typename?: 'ChangeOrderResult' }
      & Pick<Types.ChangeOrderResult, 'entityId' | 'sortId'>
    )>> }
  ) }
);

export const UpdateAttributeDocument = gql`
    mutation updateAttribute($input: AttributeInput!) {
  updateAttribute(input: $input) {
    attribute {
      ...fullAttribute
    }
    changeOrder {
      entityId
      sortId
    }
    removeAttributeId
  }
}
    ${FullAttributeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttributeGQL extends Apollo.Mutation<UpdateAttributeMutation, UpdateAttributeMutationVariables> {
    document = UpdateAttributeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }