import {Pipe, PipeTransform} from '@angular/core';
import {firstUpper} from '../helpers';

@Pipe({name: 'firstUpper'})
export class FirstUpperPipe implements PipeTransform {
    transform(s: string): any {
        return firstUpper(s);
    }
}


