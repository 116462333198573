<div class="full-page-wrapper">
    <div class="d-flex align-items-center w-100">
        <div class="row w-100">
            <div class="col-lg-4 mx-auto auth-form">
                <h1 class="text-center">
                    {{'auth.forgottenPassword'|translate|firstUpper}}
                </h1>
                <form class="user-detail__general" #forgottenPasswordForm="ngForm"
                      (ngSubmit)="onSubmit(forgottenPasswordForm)">
                    <div class="form-group">
                        <label class="mb-2">E-mail</label>
                        <input ngModel name="email" type="text" class="form-control"/>
                    </div>
                    <div class="form-group mt-3">
                        <button
                            class="btn btn-primary btn-block">{{'forgottenPassword.form.submit'|translate|firstUpper}}</button>
                    </div>
                </form>
                <p class="mt-4"><a routerLink="/login">{{'general.back'|translate|firstUpper}}</a></p>
            </div>

        </div>
    </div>

    <swal #alert text="" icon="error" title="">
    </swal>

</div>
