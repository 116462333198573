import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {defaultDataState, IDataState} from '../state';
import {
    fetchGameGroupsSuccessAction, fetchGameGroupSuccessAction,
    fetchGameSuccessAction,
    updateGameGroupSuccessAction,
    updateGameStepSuccessAction,
    updateGameSuccessAction
} from './actions';
import {GameGroup, GameStep} from '../../graphql/types.graphql-gen';
import {GameStepFragment} from '../../graphql/mutations/updateGame/updateGame.graphql-gen';

export const gamesReducer = createImmerReducer(
    defaultDataState,
    on(updateGameSuccessAction, (state: IDataState, action) => {
        const updatedGame = action.game;
        const gameGroup = state.gameGroup.gameGroups?.result.find((gg) => gg.id === action.gameGroupId);
        if (!gameGroup) {
            return state;
        } else {
            if (updatedGame) {
                if (updatedGame.gameGroup.id !== gameGroup.id) {
                    const newGameGroup = state.gameGroup.gameGroups?.result.find((gg) => gg.id === updatedGame.gameGroup.id) as GameGroup;
                    newGameGroup.games.push(updatedGame);
                    gameGroup.games = gameGroup.games.filter((g) => g.id !== updatedGame.id);
                } else {
                    const replaceIndex = gameGroup.games.findIndex((g) => g.id === updatedGame.id);
                    if (replaceIndex !== -1) {
                        gameGroup.games[replaceIndex] = updatedGame;
                    } else {
                        gameGroup.games.push(updatedGame);
                    }
                }
                state.game = updatedGame;
                state.helperData.gameGroups = null;
            } else if (action.gameDeleted) {
                gameGroup.games = gameGroup.games.filter((g) => g.id !== action.gameDeleted);
            }
        }
        return state;
    }),
    on(fetchGameGroupsSuccessAction, (state: IDataState, action) => {
        state.gameGroup.gameGroups = action.gameGroups.getGameGroups;
        return state;
    }),
    on(fetchGameGroupSuccessAction, (state: IDataState, action: ReturnType<typeof fetchGameGroupSuccessAction>) => {
        state.gameGroup.activeGameGroup = action.gameGroup;
        return state;
    }),
    on(updateGameGroupSuccessAction, (state: IDataState, action) => {
        const updatedGameGroup = action.gameGroup;
        if (state.gameGroup.activeGameGroup?.id === action.gameGroup?.id && action.gameGroup) {
            state.gameGroup.activeGameGroup = action.gameGroup;
        }
        if (updatedGameGroup && state.gameGroup.gameGroups?.result) {
            const ggArr = state.gameGroup.gameGroups.result.filter((gg) => gg.id !== updatedGameGroup.id);
            ggArr.push(updatedGameGroup);
            state.gameGroup.gameGroups.result = ggArr;
        } else if (action.groupDeleted && state.gameGroup.gameGroups?.result) {
            state.gameGroup.gameGroups.result = state.gameGroup.gameGroups.result.filter((gg) => gg.id !== action.groupDeleted);
        }
        return state;
    }),

    on(fetchGameSuccessAction, (state: IDataState, action) => {
        state.game = action.game;
        return state;
    }),

    on(updateGameStepSuccessAction, (state: IDataState, action: ReturnType<typeof updateGameStepSuccessAction>) => {
        if (action.result.gameStep && state.game) {
            state.game.gameSteps = ([...(state.game.gameSteps.filter((gs) => gs.id !== action.result.gameStep?.id) ?? []), action.result.gameStep] as GameStep[])
                .sort(sortGameSteps);
        }
        if (action.result.gameStepDeleted && state.game) {
            state.game.gameSteps = state.game.gameSteps.filter((gs) => gs.id !== action.result.gameStepDeleted);
        }
        if (action.result.changeOrder && state.game) {
            state.game.gameSteps.forEach((gs: GameStepFragment) => {
                gs.sortId = (action.result.changeOrder?.find((o) => o.stepId === gs.id))?.sortId ?? gs.sortId;
            });
            state.game.gameSteps = (state.game.gameSteps).sort(sortGameSteps);
        }
        if (action.result.gameStepDeleted && state.game) {
            state.game.gameSteps = state.game?.gameSteps?.filter((gs) => gs.id !== action.result.gameStepDeleted);
        }
        if (action.result.gameSteps && state.game) {
            state.game.gameSteps = action.result.gameSteps;
        }
        return state;
    })
);


const sortGameSteps = (a: GameStepFragment, b: GameStepFragment) => (a.sortId ?? 0) > (b.sortId ?? 0) ? 1 : (((a.sortId ?? 0) < (b.sortId ?? 0)) ? -1 : 0);
