import * as Types from '../../types.graphql-gen';

import { ProductPartnerFragment } from '../../queries/getProductPartners/getProductPartners.graphql-gen';
import { gql } from 'apollo-angular';
import { ProductPartnerFragmentDoc } from '../../queries/getProductPartners/getProductPartners.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateProductPartnerMutationVariables = Types.Exact<{
  input: Types.ProductPartnerInput;
}>;


export type UpdateProductPartnerMutation = (
  { __typename?: 'Mutation' }
  & { updateProductPartner: (
    { __typename?: 'UpdateProductPartnerResult' }
    & Pick<Types.UpdateProductPartnerResult, 'productPartnerDeleted'>
    & { productPartner?: Types.Maybe<(
      { __typename?: 'ProductPartner' }
      & ProductPartnerFragment
    )>, billProductPartnerResult?: Types.Maybe<(
      { __typename?: 'BillProductPartnerResult' }
      & Pick<Types.BillProductPartnerResult, 'downloadLink'>
      & { invalidVouchers?: Types.Maybe<Array<(
        { __typename?: 'GameVoucher' }
        & InvalidVouchersFragment
      )>> }
    )> }
  ) }
);

export type InvalidVouchersFragment = (
  { __typename?: 'GameVoucher' }
  & Pick<Types.GameVoucher, 'id' | 'description' | 'createdAt' | 'canceledAt' | 'code'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name' | 'difficulty'>
  ) }
);

export const InvalidVouchersFragmentDoc = gql`
    fragment invalidVouchers on GameVoucher {
  id
  description
  createdAt
  canceledAt
  code
  game {
    id
    name
    difficulty
  }
}
    `;
export const UpdateProductPartnerDocument = gql`
    mutation updateProductPartner($input: ProductPartnerInput!) {
  updateProductPartner(input: $input) {
    productPartner {
      ...productPartner
    }
    productPartnerDeleted
    billProductPartnerResult {
      downloadLink
      invalidVouchers {
        ...invalidVouchers
      }
    }
  }
}
    ${ProductPartnerFragmentDoc}
${InvalidVouchersFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductPartnerGQL extends Apollo.Mutation<UpdateProductPartnerMutation, UpdateProductPartnerMutationVariables> {
    document = UpdateProductPartnerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }