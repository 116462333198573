<div class="row">
    <div class="col-12">
        <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input type="text" [(ngModel)]="search" (ngModelChange)="filter()" pInputText placeholder="{{'general.search'|translate|firstUpper}}"/>
</span>
    </div>
    <div class="col-auto mx-1 my-4" *ngFor="let flag of filteredFlags">
        <img (click)="selectFlag(flag)" class="cursor-pointer" style="height: 4rem" *ngIf="flag"
             src="{{'/admin/assets/img/flags/svg/'+(flag)|url}}" alt="">
    </div>
</div>
