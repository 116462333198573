import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {saveFilterAction, toggleNavCollapsedAction} from './actions';

export const helpersDataReducer = createImmerReducer(
    defaultDataState,
    on(saveFilterAction, (state: IDataState, action: ReturnType<typeof saveFilterAction>) => {
        if(action.gameVouchers !== undefined) {
            state.helperData.filters.gameVouchers = action.gameVouchers;
        }
        if(action.players !== undefined) {
            state.helperData.filters.players = action.players;
        }
        if(action.permissionGroups !== undefined) {
            state.helperData.filters.permissionGroups = action.permissionGroups;
        }
        if(action.progresses !== undefined) {
            state.helperData.filters.progresses = action.progresses;
        }
        if(action.licenses !== undefined) {
            state.helperData.filters.licenses = action.licenses;
        }
        if(action.purchases !== undefined) {
            state.helperData.filters.purchases = action.purchases;
        }
        if(action.gameGroups !== undefined) {
            state.helperData.filters.gameGroups = action.gameGroups;
        }
        if(action.reviews !== undefined) {
            state.helperData.filters.reviews = action.reviews;
        }
        if(action.pushPlayers !== undefined) {
            state.helperData.filters.pushPlayers = action.pushPlayers;
        }
        if(action.translations !== undefined) {
            state.helperData.filters.translations = action.translations;
        }
        return state;
    }),
    on(toggleNavCollapsedAction, (state: IDataState, action: ReturnType<typeof toggleNavCollapsedAction>) => {
        state.helperData.navCollapsed = !state.helperData.navCollapsed;
        return state;
    })
);
