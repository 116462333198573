import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {
    fetchPushNotificationsSuccessAction,
    fetchPushNotificationSuccessAction,
    updatePushNotificationSuccessAction
} from './actions';

export const pushNotificationReducer = createImmerReducer(
    defaultDataState,
    on(fetchPushNotificationsSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPushNotificationsSuccessAction>) => {
        state.pushNotification.pushNotifications[action.activeType] = action.res;
        return state;
    }),
    on(fetchPushNotificationSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPushNotificationSuccessAction>) => {
        state.pushNotification.activePushNotification = action.pushNotification;
        return state;
    }),
    on(updatePushNotificationSuccessAction,(state: IDataState, action: ReturnType<typeof updatePushNotificationSuccessAction>) => {
        if(action.res.pushNotification && action.res.pushNotification.id === state.pushNotification.activePushNotification?.id) {
            state.pushNotification.activePushNotification = action.res.pushNotification;
        }
        if(action.res.removePushNotificationId) {
            if(state.pushNotification.activePushNotification?.id === action.res.removePushNotificationId) {
                state.pushNotification.activePushNotification = null;
            }
            if(state.pushNotification.pushNotifications.finished) {
                state.pushNotification.pushNotifications.finished.result = state.pushNotification.pushNotifications.finished.result.filter((p) => p.id !== action.res.removePushNotificationId)
            }
            if(state.pushNotification.pushNotifications.concept) {
                state.pushNotification.pushNotifications.concept.result = state.pushNotification.pushNotifications.concept.result.filter((p) => p.id !== action.res.removePushNotificationId)
            }
            if(state.pushNotification.pushNotifications.upcoming) {
                state.pushNotification.pushNotifications.upcoming.result = state.pushNotification.pushNotifications.upcoming.result.filter((p) => p.id !== action.res.removePushNotificationId)
            }
        }
        return state;
    }),
);

