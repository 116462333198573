<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="pt-4 col-12">
        <div class="text-left">
            <label class="pb-2">
                {{'elements.audio.src'|translate|firstUpper}}
                <app-tooltip image="element-audio-src.png"></app-tooltip>
            </label>
            <app-file-upload #audioC [file]="audio" [uploadHandler]="onFileUpload" [accept]="'.mp3'" type="audio"
                             [multiple]="false"></app-file-upload>
        </div>
    </div>
    <div class="col-4">
        <label for="autoplay-{{uniqueId}}" class="pb-2">{{'elements.audio.autoplay'|translate|firstUpper}}</label>
        <p-selectButton id="autoplay-{{uniqueId}}" [options]="boolOptions"
                        formControlName="autoplay" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>

</div>
