import * as Types from '../../types.graphql-gen';

import { FullPermissionGroupFragment } from '../../queries/getLicenses/getLicenses.graphql-gen';
import { gql } from 'apollo-angular';
import { FullPermissionGroupFragmentDoc } from '../../queries/getLicenses/getLicenses.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePermissionGroupMutationVariables = Types.Exact<{
  input: Types.PermissionGroupInput;
}>;


export type UpdatePermissionGroupMutation = (
  { __typename?: 'Mutation' }
  & { updatePermissionGroup: (
    { __typename?: 'UpdatePermissionGroupResult' }
    & { permissionGroup?: Types.Maybe<(
      { __typename?: 'PermissionGroup' }
      & FullPermissionGroupFragment
    )> }
  ) }
);

export const UpdatePermissionGroupDocument = gql`
    mutation updatePermissionGroup($input: PermissionGroupInput!) {
  updatePermissionGroup(input: $input) {
    permissionGroup {
      ...fullPermissionGroup
    }
  }
}
    ${FullPermissionGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePermissionGroupGQL extends Apollo.Mutation<UpdatePermissionGroupMutation, UpdatePermissionGroupMutationVariables> {
    document = UpdatePermissionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }