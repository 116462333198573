import * as Types from '../../types.graphql-gen';

import { FullReviewFragment } from '../../queries/getReviews/getReviews.graphql-gen';
import { gql } from 'apollo-angular';
import { FullReviewFragmentDoc } from '../../queries/getReviews/getReviews.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateReviewMutationVariables = Types.Exact<{
  input: Types.ReviewInput;
}>;


export type UpdateReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateReview: (
    { __typename?: 'UpdateReviewResult' }
    & Pick<Types.UpdateReviewResult, 'reviewRemoved'>
    & { review?: Types.Maybe<(
      { __typename?: 'Review' }
      & FullReviewFragment
    )> }
  ) }
);

export const UpdateReviewDocument = gql`
    mutation updateReview($input: ReviewInput!) {
  updateReview(input: $input) {
    review {
      ...fullReview
    }
    reviewRemoved
  }
}
    ${FullReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReviewGQL extends Apollo.Mutation<UpdateReviewMutation, UpdateReviewMutationVariables> {
    document = UpdateReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }