<form *ngIf="loaded" [formGroup]="form" appScrollToInvalidControl>
    <div class="p-fluid pb-4">
        <ng-container formGroupName="scalarData">
            <div>
                <label for="name" class="pb-2">{{'partner.form.name'|translate|firstUpper}}<app-tooltip [value]="'partner.tooltip.name'|translate|firstUpper"></app-tooltip></label>
                <input
                    id="name"
                    class="{{form.controls.scalarData.controls.name.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    formControlName="name" type="text" pInputText>
                <app-control-error *ngIf="form.controls.scalarData.controls.name.errors" [errors]="form.controls.scalarData.controls.name.errors"></app-control-error>
            </div>
            <div class="pt-4">
                <label for="code" class="pb-2">{{'partner.form.code'|translate|firstUpper}}<app-tooltip [value]="'partner.tooltip.code'|translate|firstUpper"></app-tooltip></label>
                <input
                    id="code"
                    class="{{form.controls.scalarData.controls.code.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    formControlName="code" type="code" pInputText>
                <app-control-error *ngIf="form.controls.scalarData.controls.code.errors" [errors]="form.controls.scalarData.controls.code.errors"></app-control-error>
            </div>
        </ng-container>

        <div class="pt-4">
            <label for="gameIds" class="pb-2">{{'partner.form.gameIds'|translate|firstUpper}}</label>
            <app-game-options [placeholder]="'partner.form.placeholder.gameIds'|translate|firstUpper"  id="gameIds" [withoutLicenses]="true" [control]="form.controls.gameIds" [multiSelect]="true"></app-game-options>
        </div>

        <div class="pt-4">
            <label for="productPartnerIds" class="pb-2">{{'partner.form.productPartnerIds'|translate|firstUpper}}<app-tooltip [value]="'partner.tooltip.productPartnerIds'|translate|firstUpper"></app-tooltip></label>
            <p-multiSelect [placeholder]="'partner.form.placeholder.productPartnerIds'|translate|firstUpper" [options]="productPartners" optionValue="id" optionLabel="name" formControlName="productPartnerIds" id="productPartnerIds"></p-multiSelect>
        </div>


        <ng-container formGroupName="scalarData">
            <div class="pt-4">
                <label for="commissionType" class="pb-2">{{'partner.form.commissionType'|translate|firstUpper}}<app-tooltip [value]="'partner.tooltip.commissionType'|translate|firstUpper"></app-tooltip></label>
                <p-dropdown [options]="partnerCommissionTypeOptions" id="commissionType" appendTo="body" formControlName="commissionType"
                            [filter]="true" optionValue="value" optionLabel="label">
                </p-dropdown>
            </div>
            <div class="pt-4">
                <label for="discountType" class="pb-2">{{'partner.form.discountType'|translate|firstUpper}}</label>
                <p-dropdown [options]="partnerDiscountTypeOptions" id="discountType" appendTo="body" formControlName="discountType"
                            [filter]="true" optionValue="value" optionLabel="label">
                </p-dropdown>
            </div>
            <div class="pt-4">
                <label for="discount" class="pb-2">{{'partner.form.discount'|translate|firstUpper}}</label>
                <p-inputNumber
                    id="discount"
                    locale="cs-CS"
                    (onInput)="form.controls.scalarData.controls.discount.setValue(parseInt($event.value),{emitEvent: true})"
                    class="{{form.controls.scalarData.controls.discount.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    formControlName="discount"></p-inputNumber>
                <app-control-error *ngIf="form.controls.scalarData.controls.discount.errors" [errors]="form.controls.scalarData.controls.discount.errors"></app-control-error>
            </div>

            <div class="pt-4">
                <label for="discountDirectSaleType" class="pb-2">{{'partner.form.discountDirectSaleType'|translate|firstUpper}}<app-tooltip value="{{'partner.form.discountDirectSaleType.tooltip'|translate|firstUpper}}"></app-tooltip></label>
                <p-dropdown [options]="partnerDiscountTypeOptions" id="discountDirectSaleType" appendTo="body" formControlName="discountDirectSaleType"
                            [filter]="true" optionValue="value" optionLabel="label">
                </p-dropdown>
            </div>
            <div class="pt-4">
                <label for="discountDirectSale" class="pb-2">{{'partner.form.discountDirectSale'|translate|firstUpper}}</label>
                <p-inputNumber
                    id="discountDirectSale"
                    locale="cs-CS"
                    (onInput)="form.controls.scalarData.controls.discountDirectSale.setValue(parseInt($event.value),{emitEvent: true})"
                    class="{{form.controls.scalarData.controls.discountDirectSale.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    formControlName="discountDirectSale"></p-inputNumber>
                <app-control-error *ngIf="form.controls.scalarData.controls.discountDirectSale.errors" [errors]="form.controls.scalarData.controls.discountDirectSale.errors"></app-control-error>
            </div>


            <div class="pt-4">
                <label for="activeFrom" class="pb-2">{{'partner.form.activeFrom'|translate|firstUpper}}</label>
                <p-calendar
                    id="activeFrom"
                    class="{{form.controls.scalarData.controls.activeFrom.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    dateFormat="dd.mm.yy" hourFormat="24" [monthNavigator]="true" [yearNavigator]="true"
                    [showTime]="true"
                    yearRange="2015:2021"
                    appendTo="body"
                    [showButtonBar]="true"
                    [touchUI]="true"
                    formControlName="activeFrom"></p-calendar>
            </div>
            <div class="pt-4">
                <label for="activeTo" class="pb-2">{{'partner.form.activeTo'|translate|firstUpper}}</label>
                <p-calendar
                    id="activeTo"
                    class="{{form.controls.scalarData.controls.activeFrom.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    dateFormat="dd.mm.yy" hourFormat="24" [monthNavigator]="true" [yearNavigator]="true"
                    [showTime]="true"
                    yearRange="2015:2021"
                    appendTo="body"
                    [showButtonBar]="true"
                    [touchUI]="true"
                    formControlName="activeTo"></p-calendar>
            </div>
        </ng-container>
    </div>

</form>
<app-dialog-footer>
    <div class="pt-2">
        <button [disabled]="loading || !formChanged"
                [loading]="loading" icon="pi pi-save"
                class="p-button-success p-button-sm"
                pButton pRipple type="button"
                label="{{(partner ? 'general.save' :'general.create')|translate}}"
                (click)="updatePartner()"></button>
    </div>
</app-dialog-footer>
