<div class="container p-0">
    <div class="row">
        <div class="col">
            <label for="" class="pb-2">
                {{'elements.clueList.clues'|translate|firstUpper}}
                <app-tooltip image="element-clueList-clues.png" value="{{'elements.tooltip.clueList.clues'|translate|firstUpper}}"></app-tooltip>
            </label>
        </div>
    </div>
    <div class="row align-items-center mb-3" *ngFor="let clue of gameStepClues">
        <div class="col-8 pt-0 pb-0">
            <img *ngIf="clue.filename" [src]="clue.filename.name|resourcePath|async" style="width: 50px; height: 50px" alt="">
            <strong class="ps-4">{{clue.title}}</strong>
        </div>
        <div class="col-4 pt-0 pb-0 text-right">
            <button
                (click)="editClue(clue)"
                class="p-button-sm p-button-outlined me-2 p-button-success"
                pButton
                type="button"
                icon="pi pi-cog"
                pTooltip="{{'elements.clueList.editClue'|translate|firstUpper}}"
                tooltipPosition="bottom">
            </button>
            <button
                *ngIf="!readonly"
                (click)="removeClue(clue)"
                class="p-button-sm p-button-outlined me-2 p-button-danger"
                pButton
                type="button"
                icon="pi pi-times"
                pTooltip="{{'elements.clueList.removeClue'|translate|firstUpper}}"
                tooltipPosition="bottom">
            </button>
        </div>
    </div>
</div>
<button
    *ngIf="!readonly && !deprecated"
    pButton
    label="{{'elements.clueList.addClue'|translate|firstUpper}}"
    class="p-button-sm p-button-success auto-width mt-2"
    icon="pi pi-plus"
    (click)="editClue()"
></button>
<small *ngIf="deprecated" class="p-error">
    {{'general.deprecated.clueList'|translate}}
</small>
