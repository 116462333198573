import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateGameMutationVariables = Types.Exact<{
  input: Types.GameInput;
}>;


export type UpdateGameMutation = (
  { __typename?: 'Mutation' }
  & { updateGame: (
    { __typename?: 'UpdateGameResult' }
    & Pick<Types.UpdateGameResult, 'gameDeleted' | 'checkAppId' | 'gameDownloadLink'>
    & { game?: Types.Maybe<(
      { __typename?: 'Game' }
      & GameFragment
    )> }
  ) }
);

export type GameFragment = (
  { __typename?: 'Game' }
  & Pick<Types.Game, 'id' | 'type' | 'hash' | 'hasAlternativeSteps' | 'variantAlgorithm' | 'note' | 'averageRating' | 'averageRatingThreeMonths' | 'averageRatingYear' | 'activeDownloadFrom' | 'activeFrom' | 'canPurchaseTo' | 'synchKey' | 'authorId' | 'state' | 'order' | 'public' | 'sendQrCodes' | 'name' | 'photoSource' | 'difficulty' | 'difficultyNote' | 'duration' | 'isForFree' | 'price' | 'appPurchaseId' | 'distance' | 'resultType' | 'maxTime' | 'minTime' | 'phoneNumber' | 'predefinedNotes' | 'language' | 'startingPosition' | 'restrictions' | 'purchaseNote' | 'teamInfo' | 'textRating' | 'textEmail' | 'teamNamePopupActive' | 'teamNamePopupText' | 'size' | 'congratsContent' | 'labelDifficulty' | 'labelContinue' | 'labelSubmit' | 'labelPrevious' | 'labelNext' | 'labelSkip' | 'labelSkipquestion' | 'labelPlay' | 'labelDownload' | 'labelCancel' | 'labelOk' | 'labelHelper' | 'labelHelperquestion' | 'labelNotes' | 'labelBuy' | 'labelSkipphoto' | 'labelTakephoto' | 'labelShareonfacebook' | 'labelComplete' | 'labelNoclues' | 'labelClosehelper' | 'labelActualize' | 'labelSize' | 'labelBought' | 'labelStartgame' | 'labelStartgamequestion' | 'labelRightansweris' | 'labelTelephonesupport' | 'labelTelephonesupportquestion' | 'deletedAt' | 'createdAt' | 'updatedAt' | 'players' | 'randomCode' | 'multiplayerGame' | 'mixStats' | 'extraSettings' | 'maxPoints' | 'stats'>
  & { gameGroup: (
    { __typename?: 'GameGroup' }
    & Pick<Types.GameGroup, 'id'>
  ), productPartner?: Types.Maybe<(
    { __typename?: 'ProductPartner' }
    & Pick<Types.ProductPartner, 'id'>
  )>, gpsEntry?: Types.Maybe<(
    { __typename?: 'GpsEntry' }
    & GpsEntryFragment
  )>, playerGames?: Types.Maybe<Array<(
    { __typename?: 'PlayerGame' }
    & { player: (
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id' | 'fullname' | 'email'>
    ) }
  )>>, attributes: Array<(
    { __typename?: 'Attribute' }
    & Pick<Types.Attribute, 'id' | 'type'>
  )>, entityAttachments: Array<(
    { __typename?: 'EntityAttachment' }
    & Pick<Types.EntityAttachment, 'id' | 'sendType'>
    & { attachment: (
      { __typename?: 'Attachment' }
      & Pick<Types.Attachment, 'id'>
    ) }
  )>, externalVouchers: Array<(
    { __typename?: 'ExternalVoucher' }
    & ExternalVoucherFragment
  )>, gameSaleSources: Array<(
    { __typename?: 'GameSaleSource' }
    & GameSaleSourcesFragment
  )> }
  & GameStepsFragment
  & InventoryItemsFragment
);

export type ExternalVoucherFragment = (
  { __typename?: 'ExternalVoucher' }
  & Pick<Types.ExternalVoucher, 'id' | 'createdAt' | 'type' | 'voucherId'>
);

export type GpsEntryFragment = (
  { __typename?: 'GpsEntry' }
  & Pick<Types.GpsEntry, 'id' | 'createdAt' | 'data' | 'lat' | 'lon' | 'radius'>
);

export type GameSaleSourcesFragment = (
  { __typename?: 'GameSaleSource' }
  & Pick<Types.GameSaleSource, 'id' | 'price'>
  & { saleSource: (
    { __typename?: 'SaleSource' }
    & Pick<Types.SaleSource, 'id'>
  ) }
);

export type GameStepFragment = (
  { __typename?: 'GameStep' }
  & Pick<Types.GameStep, 'id' | 'isParentVariant' | 'isVariant' | 'sortId' | 'selectedForTest' | 'type' | 'state' | 'inactiveFrom' | 'inactiveTo' | 'contentType' | 'title' | 'question' | 'answer' | 'answerLabel' | 'info' | 'infoTitle' | 'internalNote' | 'puzzleRatio' | 'puzzlePhotoFromCamera' | 'nophoto' | 'isSharing' | 'isPhoto' | 'isStarting' | 'isEnding' | 'isRating' | 'showSummary' | 'hasTimeRestrictions' | 'minTime' | 'maxTime' | 'summaryInstructions' | 'photoSource' | 'videoSource' | 'mapPhotoSource' | 'mapText' | 'skipPhotoSource' | 'skipInfo' | 'deletedAt' | 'textInventoryItems' | 'createdAt' | 'updatedAt'>
  & { parent?: Types.Maybe<(
    { __typename?: 'GameStep' }
    & Pick<Types.GameStep, 'id'>
  )> }
  & ContentElementsFragment
  & FooterElementsFragment
  & BeforeStepLeftElementsFragment
  & AfterStepLoadedElementsFragment
);

export type GameStepsFragment = (
  { __typename?: 'Game' }
  & { gameSteps: Array<(
    { __typename?: 'GameStep' }
    & GameStepFragment
  )> }
);

export type InventoryItemsFragment = (
  { __typename?: 'Game' }
  & { inventoryItems: Array<(
    { __typename?: 'InventoryItem' }
    & Pick<Types.InventoryItem, 'id' | 'source'>
  )> }
);

export type ContentElementsFragment = (
  { __typename?: 'GameStep' }
  & { contentElements: Array<(
    { __typename?: 'StepElement' }
    & Pick<Types.StepElement, 'id' | 'orderIndex' | 'active' | 'location' | 'type' | 'settings'>
    & GameStepCluesFragment
  )> }
);

export type FooterElementsFragment = (
  { __typename?: 'GameStep' }
  & { footerElements: Array<(
    { __typename?: 'StepElement' }
    & Pick<Types.StepElement, 'id' | 'orderIndex' | 'active' | 'location' | 'type' | 'settings'>
    & GameStepCluesFragment
  )> }
);

export type BeforeStepLeftElementsFragment = (
  { __typename?: 'GameStep' }
  & { beforeStepLeftElements: Array<(
    { __typename?: 'StepElement' }
    & Pick<Types.StepElement, 'id' | 'orderIndex' | 'active' | 'location' | 'type' | 'settings'>
    & GameStepCluesFragment
  )> }
);

export type AfterStepLoadedElementsFragment = (
  { __typename?: 'GameStep' }
  & { afterStepLoadedElements: Array<(
    { __typename?: 'StepElement' }
    & Pick<Types.StepElement, 'id' | 'orderIndex' | 'active' | 'location' | 'type' | 'settings'>
    & GameStepCluesFragment
  )> }
);

export type GameStepCluesFragment = (
  { __typename?: 'StepElement' }
  & { gameStepClues: Array<(
    { __typename?: 'GameStepClue' }
    & Pick<Types.GameStepClue, 'id' | 'filename' | 'title' | 'description' | 'penalizationTime' | 'penalizationPoints' | 'createdAt' | 'updatedAt'>
  )> }
);

export const GpsEntryFragmentDoc = gql`
    fragment gpsEntry on GpsEntry {
  id
  createdAt
  data
  lat
  lon
  radius
}
    `;
export const ExternalVoucherFragmentDoc = gql`
    fragment externalVoucher on ExternalVoucher {
  id
  createdAt
  type
  voucherId
}
    `;
export const GameStepCluesFragmentDoc = gql`
    fragment gameStepClues on StepElement {
  gameStepClues {
    id
    filename
    title
    description
    penalizationTime
    penalizationPoints
    createdAt
    updatedAt
  }
}
    `;
export const ContentElementsFragmentDoc = gql`
    fragment contentElements on GameStep {
  contentElements {
    id
    orderIndex
    active
    location
    type
    settings
    ...gameStepClues
  }
}
    ${GameStepCluesFragmentDoc}`;
export const FooterElementsFragmentDoc = gql`
    fragment footerElements on GameStep {
  footerElements {
    id
    orderIndex
    active
    location
    type
    settings
    ...gameStepClues
  }
}
    ${GameStepCluesFragmentDoc}`;
export const BeforeStepLeftElementsFragmentDoc = gql`
    fragment beforeStepLeftElements on GameStep {
  beforeStepLeftElements {
    id
    orderIndex
    active
    location
    type
    settings
    ...gameStepClues
  }
}
    ${GameStepCluesFragmentDoc}`;
export const AfterStepLoadedElementsFragmentDoc = gql`
    fragment afterStepLoadedElements on GameStep {
  afterStepLoadedElements {
    id
    orderIndex
    active
    location
    type
    settings
    ...gameStepClues
  }
}
    ${GameStepCluesFragmentDoc}`;
export const GameStepFragmentDoc = gql`
    fragment gameStep on GameStep {
  id
  parent {
    id
  }
  isParentVariant
  isVariant
  sortId
  selectedForTest
  type
  state
  inactiveFrom
  inactiveTo
  contentType
  title
  question
  answer
  answerLabel
  info
  infoTitle
  internalNote
  puzzleRatio
  puzzlePhotoFromCamera
  nophoto
  isSharing
  isPhoto
  isStarting
  isEnding
  isRating
  showSummary
  hasTimeRestrictions
  minTime
  maxTime
  summaryInstructions
  photoSource
  videoSource
  mapPhotoSource
  mapText
  skipPhotoSource
  skipInfo
  deletedAt
  textInventoryItems
  createdAt
  updatedAt
  ...contentElements
  ...footerElements
  ...beforeStepLeftElements
  ...afterStepLoadedElements
}
    ${ContentElementsFragmentDoc}
${FooterElementsFragmentDoc}
${BeforeStepLeftElementsFragmentDoc}
${AfterStepLoadedElementsFragmentDoc}`;
export const GameStepsFragmentDoc = gql`
    fragment gameSteps on Game {
  gameSteps {
    ...gameStep
  }
}
    ${GameStepFragmentDoc}`;
export const InventoryItemsFragmentDoc = gql`
    fragment inventoryItems on Game {
  inventoryItems {
    id
    source
  }
}
    `;
export const GameSaleSourcesFragmentDoc = gql`
    fragment gameSaleSources on GameSaleSource {
  id
  price
  saleSource {
    id
  }
}
    `;
export const GameFragmentDoc = gql`
    fragment game on Game {
  id
  type
  hash
  hasAlternativeSteps
  variantAlgorithm
  note
  gameGroup {
    id
  }
  productPartner {
    id
  }
  gpsEntry {
    ...gpsEntry
  }
  playerGames {
    player {
      id
      fullname
      email
    }
  }
  attributes {
    id
    type
  }
  entityAttachments {
    id
    sendType
    attachment {
      id
    }
  }
  externalVouchers {
    ...externalVoucher
  }
  averageRating
  averageRatingThreeMonths
  averageRatingYear
  activeDownloadFrom
  activeFrom
  canPurchaseTo
  synchKey
  authorId
  state
  order
  public
  sendQrCodes
  name
  photoSource
  difficulty
  difficultyNote
  duration
  isForFree
  price
  appPurchaseId
  distance
  resultType
  maxTime
  minTime
  phoneNumber
  predefinedNotes
  language
  startingPosition
  restrictions
  purchaseNote
  teamInfo
  textRating
  textEmail
  teamNamePopupActive
  teamNamePopupText
  size
  congratsContent
  labelDifficulty
  labelContinue
  labelSubmit
  labelPrevious
  labelNext
  labelSkip
  labelSkipquestion
  labelPlay
  labelDownload
  labelCancel
  labelOk
  labelHelper
  labelHelperquestion
  labelNotes
  labelBuy
  labelSkipphoto
  labelTakephoto
  labelShareonfacebook
  labelComplete
  labelNoclues
  labelClosehelper
  labelActualize
  labelSize
  labelBought
  labelStartgame
  labelStartgamequestion
  labelRightansweris
  labelTelephonesupport
  labelTelephonesupportquestion
  deletedAt
  createdAt
  updatedAt
  players
  randomCode
  multiplayerGame
  mixStats
  extraSettings
  maxPoints
  stats
  ...gameSteps
  ...inventoryItems
  gameSaleSources {
    ...gameSaleSources
  }
}
    ${GpsEntryFragmentDoc}
${ExternalVoucherFragmentDoc}
${GameStepsFragmentDoc}
${InventoryItemsFragmentDoc}
${GameSaleSourcesFragmentDoc}`;
export const UpdateGameDocument = gql`
    mutation updateGame($input: GameInput!) {
  updateGame(input: $input) {
    game {
      ...game
    }
    gameDeleted
    checkAppId
    gameDownloadLink
  }
}
    ${GameFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGameGQL extends Apollo.Mutation<UpdateGameMutation, UpdateGameMutationVariables> {
    document = UpdateGameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }