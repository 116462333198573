import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {BaseElementSettings} from '../element-types';
import {FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';
import {StepElement} from '../../../../graphql/types.graphql-gen';
import {generateUUID} from '../../../forms/helpers';
import {lastValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {UpdateSentenceComponent} from './update-sentence/update-sentence.component';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import * as _ from 'lodash';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    cancelConfirmDialogAction,
    removeFileFromBufferAction,
    showConfirmDialogAction
} from '../../../../store/general/actions';
import {firstUpper} from '../../../helpers';
import {StepElementForm} from '../../../forms/types';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';

@Component({
    selector: 'app-pair-sentence',
    templateUrl: './pair-sentence.component.html',
    styleUrls: ['./pair-sentence.component.scss']
})
export class PairSentenceComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    ngOnInit(): void {
        super.ngOnInit();
    }

    static getSettingsFormGroup(game: GameFragment, stepElement?: StepElement): MyFormGroupV2<SettingsControl> {
        const fg = new MyFormGroupV2<SettingsControl>({
            sentences: new MyFormArray<MyFormGroupV2<{
                id: FormControl<string>;
                left: MyFormGroupV2<{
                    text: FormControl<string | null>;
                    src: FormControl<string | null>;
                    fontSize: FormControl<number>
                }>;
                right: MyFormGroupV2<{
                    text: FormControl<string | null>;
                    src: FormControl<string | null>;
                    fontSize: FormControl<number>
                }>
            }>>([])
        });
        let settings = stepElement?.settings as PairSentenceSettings | null;
        if (settings) {
            settings.sentences.forEach((s) => {
                const sentence = PairSentenceComponent.createSentenceFormGroup();
                sentence.setValue(s);
                fg.controls.sentences.push(sentence);

            });
        }
        return fg;
    }

    static createSentenceFormGroup(fontSize = 20) {
        const createSentenceGroup = () => {
            const fg = new MyFormGroupV2({
                text: new FormControl<string | null>(null, {validators: Validators.compose([Validators.minLength(1)])}),
                src: new FormControl<string | null>(null),
                fontSize: new FormControl<number>(fontSize, {nonNullable: true})
            });
            setTimeout(() => {
                // @ts-ignore
                fg.addValidators(CustomValidatorsService.customValidator((g: SentenceForm) => {
                    if (!fg) {
                        return null;
                    }
                    const errors: ValidationErrors = {};
                    if (!g.controls.src.value && !g.controls.text.value) {
                        errors['imageOrTextRequired'] = 'elements.pairSentence.imageOrTextRequired'
                    }
                    if (_.isEmpty(errors)) {
                        return null;
                    }
                    return errors;
                }));
                fg.updateValueAndValidity({onlySelf: true});
            });
            return fg;
        };


        return new MyFormGroupV2({
            id: new FormControl(generateUUID(), {nonNullable: true}),
            left: createSentenceGroup(),
            right: createSentenceGroup(),
        });
    }

    async updateSentence(group?: PairSentenceForm) {
        const isEdit = !!group;

        // WE create this only because user can cancel modal window, and in that case, we dont want to change original formGroup
        const candidateGroup = PairSentenceComponent.createSentenceFormGroup();
        if (group) {
            candidateGroup.setValue(group.getRawValue());
        }

        const oldValues = candidateGroup.getRawValue();
        const ref = this.dialogService.open(UpdateSentenceComponent, {
            data: {sentence: candidateGroup, readonly: this.readonly},
            header: isEdit ? this.translateService.instant('elements.pairSentence.editSentence') : this.translateService.instant('elements.pairSentence.addSentence'),
            width: '700px',
            contentStyle: {'max-height': '500px', 'overflow': 'auto'},
            baseZIndex: 10000,
            dismissableMask: true
        });
        const res: { fileA?: File, fileB?: File } | undefined = await lastValueFrom(ref.onClose.pipe(take(1)));


        if (!res) {
            return;
        }
        if (candidateGroup.getRawValue()?.left?.src !== oldValues.left.src) {
            await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: oldValues.left.src ?? ''})));
        }
        if (candidateGroup.getRawValue()?.right?.src !== oldValues.right.src) {
            await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: oldValues.right.src ?? ''})));
        }

        if (res.fileA) {
            candidateGroup.controls.left.controls.src.setValue((await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file: res.fileA}), [addFileToBufferSuccessAction]))).filename);
        }
        if (res.fileB) {
            candidateGroup.controls.right.controls.src.setValue((await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file: res.fileB}), [addFileToBufferSuccessAction]))).filename);
        }
        if (group) {
            group.patchValue(candidateGroup.getRawValue());
        } else {
            this.stepElementForm.controls.settings.controls.sentences.push(candidateGroup);
        }
        this.stepElementForm.controls.settings.controls.sentences.markAsTouched();
    }

    async removeSentence(index: number) {
        const res = await lastValueFrom(this.appActions.dispatch(showConfirmDialogAction({
            header: firstUpper(this.translateService.instant('general.attention')),
            message: firstUpper(this.translateService.instant('elements.pairSentence.removeSentenceMessage')),
            confirmButton: firstUpper(this.translateService.instant('general.yes')),
            cancelButton: firstUpper(this.translateService.instant('general.no')),
        }), [cancelConfirmDialogAction]))
        if(!res.confirmed) {
            return;
        }
        this.stepElementForm.controls.settings.controls.sentences.removeAt(index);
        this.stepElementForm.controls.settings.controls.sentences.markAsTouched();
    }

    static getValidators(game: GameFragment, element?: StepElement): ValidatorFn | null {
        return CustomValidatorsService.customValidator((control) => {
            // @ts-ignore
            const currentForm = control as StepElementForm<PairSentenceSettings, SettingsControl>;
            if(!currentForm) {
                return null;
            }
            const errors: ValidationErrors = {};
            if(currentForm.controls.settings.controls.sentences.length < 2) {
                errors['twoPairSentenceRequired'] = 'games.validatorErrors.elements.pairSentence.twoPairSentenceRequired';
            }
            if (!_.isEmpty(errors)) {
                return errors;
            }
            return null;
        });
    }
}

type SettingsControl = {
    sentences: MyFormArray<PairSentenceForm>
}


export type SentenceForm = MyFormGroupV2<{
    text: FormControl<string | null>,
    src: FormControl<string | null>,
    fontSize: FormControl<number>
}>;

export type PairSentenceForm = MyFormGroupV2<{
    id: FormControl<string>,
    left: SentenceForm,
    right: SentenceForm
}>;

type PairSentenceSettings = BaseElementSettings & {
    sentences: {
        id: string,
        left: Sentence,
        right: Sentence,
    }[];
};

type Sentence = {
    text: string | null,
    src: string | null,
    fontSize: number
};
