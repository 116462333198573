<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="text-{{uniqueId}}" class="pb-2">
            Text
            <app-tooltip image="element-text-text.png"
                         value="{{'elements.tooltip.text.text'|translate|firstUpper}}"></app-tooltip>
        </label>
<!--        <p-editor appQuillEditor #editor id="text-{{uniqueId}}" formControlName="text"-->
<!--                  [style]="{'height':'250px'}">-->
<!--            <ng-template pTemplate="header">-->
<!--                <app-editor-header></app-editor-header>-->
<!--            </ng-template>-->
<!--        </p-editor>-->
<!--        <p-editor appQuillEditor #editor id="text-{{uniqueId}}" formControlName="text"-->
<!--                  [style]="{'height':'250px'}">-->
<!--            <ng-template pTemplate="header">-->
<!--                <app-editor-header></app-editor-header>-->
<!--            </ng-template>-->
<!--        </p-editor>-->
        <app-text-editor id="text-{{uniqueId}}" [control]="stepElementForm.controls.settings.controls.text"></app-text-editor>
    </div>
</div>
