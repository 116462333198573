import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {SelectButton} from 'primeng/selectbutton';

@Directive({
    selector: '[appScrollToInvalidControl]'
})
export class ScrollToInvalidControlDirective {

    @Input() tabview?: TabView;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    public scrollToFirstInvalidControl() {
        let firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
            'form .ng-invalid'
        );

        if (!firstInvalidControl) {
            return;
        }

        let timeout = 100;
        if (this.tabview) {
            const tabPanel = firstInvalidControl.closest('p-tabpanel');
            // @ts-ignore
            const index = Array.from(tabPanel.parentNode.children).indexOf(tabPanel);
            if (tabPanel) {
                if (this.tabview.el.nativeElement.querySelector('.p-tabview-nav').children[index].querySelector('a').ariaSelected !== 'true') {
                    timeout = 300;
                }
                this.tabview.el.nativeElement.querySelector('.p-tabview-nav').children[index].querySelector('a').click();
            }
        }
        setTimeout(() => {
            firstInvalidControl = this.findMostNested(firstInvalidControl);
            if (firstInvalidControl.tagName.toLowerCase() === 'p-selectbutton') {
                firstInvalidControl.children[0].classList.add('animated', 'wobble-small');
                setTimeout(() => {
                    firstInvalidControl.children[0].classList.remove('animated', 'wobble-small');
                }, 1500);
            }
            firstInvalidControl.focus();
            firstInvalidControl?.querySelector('input')?.focus();
            firstInvalidControl.querySelector('.ng-invalid');
            firstInvalidControl.scrollIntoView({behavior: 'smooth', block: 'center'});
        }, timeout);
    }

    findMostNested(element: HTMLElement): HTMLElement {
        const nested = element.querySelector('.ng-invalid') as HTMLElement;
        if (nested) {
            const parent = (nested.parentNode as HTMLElement);
            if (parent.classList.contains('collapse') && !parent.classList.contains('show')) {
                // @ts-ignore
                parent.parentNode.children[0].querySelector('span[data-bs-toggle="collapse"]')?.click();
            }
            return this.findMostNested(nested);
        }
        return element;
    }
}
