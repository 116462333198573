import {Pipe, PipeTransform} from '@angular/core';
import {Game} from '../../graphql/types.graphql-gen';

@Pipe({
    name: 'gameEditors',
    pure: true
})
export class GameEditorsPipe implements PipeTransform {

    transform(game: Game): string {
        console.log(game);
        return (game.playerGames ?? []).map((pg) => pg.player.email).join(', ');
    }

}
