import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchReviewAction,
    fetchReviewFailAction,
    fetchReviewsAction,
    fetchReviewsFailAction,
    fetchReviewsSuccessAction,
    fetchReviewSuccessAction, updateReviewAction, updateReviewFailAction, updateReviewSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {GetReviewGQL, GetReviewsGQL} from '../../graphql/queries/getReviews/getReviews.graphql-gen';
import {UpdateReviewGQL} from '../../graphql/mutations/updateReview/updateReview.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class ReviewEffect implements OnDestroy {


    subSink = new SubSink();



    updateReview$ = createEffect(() => this.actions$.pipe(
        ofType(updateReviewAction),
        concatMap((a) => {
            return fixMutation(this.updateReviewGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateReviewFailAction({message: res.errors[0].message, header: ''});
            }
            return updateReviewSuccessAction({result: res.data.updateReview});
        })
    ));

    fetchReviews$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchReviewsAction
        ),
        concatMap((a) => {
            return this.getReviewsGQL.fetch({filter: a.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchReviewsFailAction({header: '', message: res.errors[0].message});
            }
            return fetchReviewsSuccessAction({result: res.data.getReviews});
        })
    ));

    fetchReview$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchReviewAction
        ),
        concatMap((a: ReturnType<typeof fetchReviewAction>) => {
            return this.getReviewGQL.fetch({id: a.id.toString(10)});
        }),
        map((res) => {
            if (res.errors) {
                return fetchReviewFailAction({header: '', message: res.errors[0].message});
            }
            if(!res.data.getReviews.result[0]) {
                return fetchReviewFailAction({header: '', message: 'review_found'});
            }
            return fetchReviewSuccessAction({review: res.data.getReviews.result[0]});
        })
    ));


    constructor(
        private actions$: Actions,
        private getReviewsGQL: GetReviewsGQL,
        private getReviewGQL: GetReviewGQL,
        private updateReviewGQL: UpdateReviewGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
