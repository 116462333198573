import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {ContinueTimerSettings} from '../element-types';
import {MyFormGroupV2} from '../../../forms/forms';

@Component({
    selector: 'app-continue-timer',
    templateUrl: './continue-timer.component.html',
    styleUrls: ['./continue-timer.component.scss']
})
export class ContinueTimerComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({});
    }
}


type SettingsControl = {}
