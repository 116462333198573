import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from './store/state';
import {AppActions} from './store/app-actions.service';
import {userStateSelector} from './store/user/selectors';
import {take} from 'rxjs/operators';
import {initialDataSelector} from './store/data/selector';
import {fetchInitialDataAction, fetchInitialDataFailAction, fetchInitialDataSuccessAction} from './store/data/actions';
import {MyStorageService} from './services/my-storage.service';
import {saveFilterAction} from './store/helperData/actions';

@Injectable({
    providedIn: 'root'
})
export class LoggedSectionGuard  {
    constructor(
        private store: Store<IAppState>,
        private appActions: AppActions,
        private router: Router,
        private storageService: MyStorageService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise(async (resolve) => {
            const userState = await lastValueFrom(this.store.select(userStateSelector).pipe(take(1)));
            await this.loadFilters();
            if (userState.user) {
                const initialData = await lastValueFrom(this.store.select(initialDataSelector).pipe(take(1)))
                if (!initialData.cities || !initialData.languages) {
                    this.appActions.dispatch(fetchInitialDataAction(), [fetchInitialDataSuccessAction, fetchInitialDataFailAction]).subscribe((a) => {
                        if (a.type === fetchInitialDataSuccessAction.type) {
                            resolve(true);
                        } else {
                            // TODO nějaká hláška
                            resolve(false);
                        }
                    });
                } else {
                    resolve(true);
                }
            } else {
                this.router.navigate(['/', 'login']).then();
                resolve(false);
            }
        });
    }

    async loadFilters() {
        const s = await this.storageService.loadState();
        if (s?.data.helperData.filters) {
            Object.values(s.data.helperData.filters).forEach((filter) => {
                LoggedSectionGuard.loadDateFilter(filter);
            });
            await lastValueFrom(this.appActions.dispatch(saveFilterAction(s?.data.helperData.filters)));
        }
        return;
    }

    static loadDateFilter(filter: any) {
        if (filter?.createdAt?.[1]) {
            filter.createdAt[1] = new Date(filter?.createdAt?.[1]);
        }
        if (filter?.updatedAt?.[1]) {
            filter.updatedAt[1] = new Date(filter?.updatedAt?.[1]);
        }
        if (filter?.createdAt?.[0]) {
            filter.createdAt[0] = new Date(filter?.createdAt?.[0]);
        }
        if (filter?.updatedAt?.[0]) {
            filter.updatedAt[0] = new Date(filter?.updatedAt?.[0]);
        }
        if(filter?.validTo?.[0]) {
            filter.validTo[0] = new Date(filter?.validTo?.[0]);
        }
        if(filter?.validTo?.[1]) {
            filter.validTo[1] = new Date(filter?.validTo?.[1]);
        }
        if (filter?.playerGameFilter?.date?.[0]) {
            filter.playerGameFilter.date[0] = new Date(filter?.playerGameFilter?.date?.[0]);
        }
        if (filter?.playerGameFilter?.date?.[1]) {
            filter.playerGameFilter.date[1] = new Date(filter?.playerGameFilter?.date?.[1]);
        }
    }

}
