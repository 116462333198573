import {lastValueFrom, Observable} from 'rxjs';
import {FetchResult} from '@apollo/client/core';
import * as _ from 'lodash';
import {Game, GameStepClue, GameStepClueInput, StepElement, StepElementTypeEnum} from '../graphql/types.graphql-gen';
import {AudioComponent} from './components/elements/audio/audio.component';
import {ClueEndComponent} from './components/elements/clue-end/clue-end.component';
import {ClueStartComponent} from './components/elements/clue-start/clue-start.component';
import {VideoComponent} from './components/elements/video/video.component';
import {TimeoutComponent} from './components/elements/timeout/timeout.component';
import {CompleteGameComponent} from './components/elements/complete-game/complete-game.component';
import {ContinueButtonComponent} from './components/elements/continue-button/continue-button.component';
import {ContinueTimerComponent} from './components/elements/continue-timer/continue-timer.component';
import {TeamNameComponent} from './components/elements/team-name/team-name.component';
import {ImageComponent} from './components/elements/image/image.component';
import {SelectOptionComponent} from './components/elements/select-option/select-option.component';
import {InputComponent} from './components/elements/input/input.component';
import {InventoryNewItemComponent} from './components/elements/inventory-new-item/inventory-new-item.component';
import {LocationComponent} from './components/elements/location/location.component';
import {PauseTimerComponent} from './components/elements/pause-timer/pause-timer.component';
import {PhoneNumberComponent} from './components/elements/phone-number/phone-number.component';
import {RateWindowComponent} from './components/elements/rate-window/rate-window.component';
import {ShareButtonComponent} from './components/elements/share-button/share-button.component';
import {SkipStepButtonComponent} from './components/elements/skip-step-button/skip-step-button.component';
import {SlidingPuzzleComponent} from './components/elements/sliding-puzzle/sliding-puzzle.component';
import {SmallInfoPopupComponent} from './components/elements/small-info-popup/small-info-popup.component';
import {StartGameButtonComponent} from './components/elements/start-game-button/start-game-button.component';
import {StepSummaryComponent} from './components/elements/step-summary/step-summary.component';
import {TakePhotoButtonComponent} from './components/elements/take-photo-button/take-photo-button.component';
import {TextComponent} from './components/elements/text/text.component';
import {AppActions} from '../store/app-actions.service';
import {readFileUrlAction, readFileUrlSuccessAction} from '../store/general/actions';
import {getAudioPath, getThumbnailPath, getVideoPath, getVoucherPath} from './forms/helpers';
import {ActionCreator, TypedAction} from '@ngrx/store/src/models';
import {QrCodeComponent} from './components/elements/qr-code/qr-code.component';
import {GraphQLError} from 'graphql/error/GraphQLError';
import {GameFragment} from '../graphql/mutations/updateGame/updateGame.graphql-gen';
import {MutationResult} from 'apollo-angular';
import {PairSentenceComponent} from './components/elements/pair-sentence/pair-sentence.component';

export const convertClueToInputType = (clue: GameStepClue): GameStepClueInput => {
    return {
        filename: clue.filename ? {
            name: clue.filename
        } : null,
        id: clue.id,
        description: clue.description,
        title: clue.title,
        penalizationTime: clue.penalizationTime,
        penalizationPoints: clue.penalizationPoints
    };
};

export const readFileUrl = _.memoize(async (filename: string, appActions: AppActions, type: 'file' | 'voucher' = 'file') => {
    if (!filename.includes('tempUploadFile')) {
        if (filename.endsWith('.mp3')) {
            return getAudioPath(filename);
        }
        if (filename.endsWith('.mp4')) {
            return getVideoPath(filename);
        }
        if (type === 'voucher') {
            return getVoucherPath(filename);
        }
        return getThumbnailPath(filename);
    }
    return (await lastValueFrom(appActions.dispatch(readFileUrlAction({filename}), [readFileUrlSuccessAction]))).url;
});

export const convertFileToUrl = (() => {
    const reader = new FileReader();
    return (file: File, base64 = false): Promise<string> => {
        // @ts-ignore
        return new Promise<string>((resolve, reject) => {
            reader.onload = (e) => {
                if (typeof e.target?.result === 'string') {
                    resolve(e.target.result);
                } else {
                    reject('Something went wrong');
                }
            };
            reader.readAsDataURL(file as Blob);
        });
    };
})();

export type FormFilterRule = 'like' | 'likeStarts' | 'likeEnds' | 'between' | 'in' | 'plain' | { [key: string]: 'like' | 'likeStarts' | 'likeEnds'  |  'between' | 'in' | 'plain' | FormFilterRule }

export const convertToFormFilter = (values: any, rules: FormFilterRule | null = null) => {
    const filter: any = {};
    let rule: FormFilterRule | null;
    for (const [key, value] of Object.entries(values)) {
        if (value === '%NULL%' || value === '%NOT_NULL%') {
            filter[key] = value;
            continue;
        }
        if (typeof value === 'boolean') {
            filter[key] = value;
            continue;
        }
        if (value === null || (typeof value === 'string' && value.length === 0)) {
            continue;
        }
        if (typeof value === 'object' && !Array.isArray(value)) {
            const valRule = rules && typeof rules === 'object' && rules[key] ? rules[key] : null;
            const newFilter = convertToFormFilter(value, valRule);
            if (newFilter) {
                filter[key] = newFilter;
            }
        } else {
            if (rules && typeof rules === 'object') {
                rule = rules[key];
            } else {
                rule = rules;
            }
            if(rule === 'plain') {
                filter[key] = value;
            }
            else if (typeof value === 'string' && (!rule || ['like', 'likeStarts', 'likeEnds'].includes(rule as any))) {
                if(rule === 'likeStarts') {
                    filter[key] = '%LIKE_%' + value;
                } else if(rule === 'likeEnds') {
                    filter[key] = '%_LIKE%' + value;
                } else {
                    filter[key] = '%LIKE%' + value;
                }
            } else if (typeof value === 'number' && !rule) {
                filter[key] = value;
            } else if (Array.isArray(value) && value.length) {
                if ((value[0] instanceof Date || value[1] instanceof Date || typeof value[0] === 'number' || typeof value[1] === 'number') && rule !== 'in') {
                    let between = '%BETWEEN%';
                    if (value[0] === null) {
                        between += 'null';
                    }
                    if (typeof value[0] === 'number') {
                        between += value[0];
                    }
                    if (value[0] instanceof Date) {
                        between += value[0].toISOString();
                    }
                    between += '|&|';
                    if (value[1] === null) {
                        between += 'null';
                    }
                    if (typeof value[1] === 'number') {
                        between += value[1];
                    }
                    if (value[1] instanceof Date) {
                        between += value[1].toISOString();
                    }
                    filter[key] = between;
                } else {
                    filter[key] = '%IN%' + value.join('|&|');
                }
            }
        }
    }
    if (_.isEmpty(filter)) {
        return null;
    }
    return filter;
};

export const fixMutation = <T>(mutation: Observable<MutationResult<T>>): Observable<MyFetchResult<T>> => {
    // @ts-ignore
    return mutation;
};
// @ts-ignore
export interface MyFetchResult<TData = Record<string, any>, TContext = Record<string, any>, TExtensions = Record<string, any>> extends MutationResult {
    data: TData;
    errors?: ReadonlyArray<GraphQLError>;
}

export const firstUpper = (s: string) => {
    if (s.length === 0) {
        return s;
    }
    return s[0].toUpperCase() + s.substr(1);
};

export const getStepElementValidators = (type: StepElementTypeEnum, game: GameFragment, element?: StepElement) => {
    switch (type) {
        case StepElementTypeEnum.Audio:
            return AudioComponent.getValidators();
        case StepElementTypeEnum.ClueEnd:
            return ClueEndComponent.getValidators();
        case StepElementTypeEnum.ClueStart:
            return ClueStartComponent.getValidators(game, element);
        case StepElementTypeEnum.Video:
            return VideoComponent.getValidators();
        case StepElementTypeEnum.Timeout:
            return TimeoutComponent.getValidators();
        case StepElementTypeEnum.CompleteGame:
            return CompleteGameComponent.getValidators();
        case StepElementTypeEnum.ContinueButton:
            return ContinueButtonComponent.getValidators();
        case StepElementTypeEnum.ContinueTimer:
            return ContinueTimerComponent.getValidators();
        case StepElementTypeEnum.TeamNameInput:
            return TeamNameComponent.getValidators();
        case StepElementTypeEnum.Image:
            return ImageComponent.getValidators();
        case StepElementTypeEnum.SelectOption:
            return SelectOptionComponent.getValidators();
        case StepElementTypeEnum.InputField:
            return InputComponent.getValidators();
        case StepElementTypeEnum.InventoryNewItemAlert:
            return InventoryNewItemComponent.getValidators();
        case StepElementTypeEnum.Location:
            return LocationComponent.getValidators();
        case StepElementTypeEnum.PauseTimer:
            return PauseTimerComponent.getValidators();
        case StepElementTypeEnum.PhoneNumberInput:
            return PhoneNumberComponent.getValidators();
        case StepElementTypeEnum.PairSentence:
            return PairSentenceComponent.getValidators(game, element);
        case StepElementTypeEnum.RateInlineElement:
        case StepElementTypeEnum.RateWindow:
            return RateWindowComponent.getValidators();
        case StepElementTypeEnum.ShareButton:
        case StepElementTypeEnum.SharingButton:
            return ShareButtonComponent.getValidators();
        case StepElementTypeEnum.SkipStepButton:
            return SkipStepButtonComponent.getValidators();
        case StepElementTypeEnum.SlidingPuzzle:
            return SlidingPuzzleComponent.getValidators();
        case StepElementTypeEnum.SmallInfoPopup:
            return SmallInfoPopupComponent.getValidators();
        case StepElementTypeEnum.StartGameButton:
            return StartGameButtonComponent.getValidators();
        case StepElementTypeEnum.StepSummary:
            return StepSummaryComponent.getValidators();
        case StepElementTypeEnum.TakePhotoButton:
            return TakePhotoButtonComponent.getValidators();
        case StepElementTypeEnum.Text:
            return TextComponent.getValidators();
        case StepElementTypeEnum.QrCode:
            return QrCodeComponent.getValidators(game, element);
        default:
            throw new Error('ELEMENT ' + type + ' validators not implemented YET');
    }
};

export const getStepElementFormGroup = (type: StepElementTypeEnum, game: GameFragment, element?: StepElement) => {
    switch (type) {
        case StepElementTypeEnum.Audio:
            return AudioComponent.getSettingsFormGroup();
        case StepElementTypeEnum.ClueEnd:
            return ClueEndComponent.getSettingsFormGroup();
        case StepElementTypeEnum.ClueStart:
            return ClueStartComponent.getSettingsFormGroup();
        case StepElementTypeEnum.Video:
            return VideoComponent.getSettingsFormGroup();
        case StepElementTypeEnum.Timeout:
            return TimeoutComponent.getSettingsFormGroup();
        case StepElementTypeEnum.CompleteGame:
            return CompleteGameComponent.getSettingsFormGroup();
        case StepElementTypeEnum.ContinueButton:
            return ContinueButtonComponent.getSettingsFormGroup();
        case StepElementTypeEnum.ContinueTimer:
            return ContinueTimerComponent.getSettingsFormGroup();
        case StepElementTypeEnum.TeamNameInput:
            return TeamNameComponent.getSettingsFormGroup();
        case StepElementTypeEnum.Image:
            return ImageComponent.getSettingsFormGroup();
        case StepElementTypeEnum.SelectOption:
            return SelectOptionComponent.getSettingsFormGroup(game, element);
        case StepElementTypeEnum.PairSentence:
            return PairSentenceComponent.getSettingsFormGroup(game, element);
        case StepElementTypeEnum.InputField:
            return InputComponent.getSettingsFormGroup();
        case StepElementTypeEnum.InventoryNewItemAlert:
            return InventoryNewItemComponent.getSettingsFormGroup(game);
        case StepElementTypeEnum.Location:
            return LocationComponent.getSettingsFormGroup(game, element);
        case StepElementTypeEnum.PauseTimer:
            return PauseTimerComponent.getSettingsFormGroup();
        case StepElementTypeEnum.PhoneNumberInput:
            return PhoneNumberComponent.getSettingsFormGroup();
        case StepElementTypeEnum.RateInlineElement:
        case StepElementTypeEnum.RateWindow:
            return RateWindowComponent.getSettingsFormGroup();
        case StepElementTypeEnum.ShareButton:
        case StepElementTypeEnum.SharingButton:
            return ShareButtonComponent.getSettingsFormGroup();
        case StepElementTypeEnum.SkipStepButton:
            return SkipStepButtonComponent.getSettingsFormGroup();
        case StepElementTypeEnum.SlidingPuzzle:
            return SlidingPuzzleComponent.getSettingsFormGroup();
        case StepElementTypeEnum.SmallInfoPopup:
            return SmallInfoPopupComponent.getSettingsFormGroup();
        case StepElementTypeEnum.StartGameButton:
            return StartGameButtonComponent.getSettingsFormGroup();
        case StepElementTypeEnum.StepSummary:
            return StepSummaryComponent.getSettingsFormGroup();
        case StepElementTypeEnum.TakePhotoButton:
            return TakePhotoButtonComponent.getSettingsFormGroup();
        case StepElementTypeEnum.Text:
            return TextComponent.getSettingsFormGroup();
        case StepElementTypeEnum.QrCode:
            return QrCodeComponent.getSettingsFormGroup()
        default:
            throw new Error('ELEMENT ' + type + ' not implemented YET');
    }
};

export const typeOfAction = <T extends string>(creator: ActionCreator<T, () => TypedAction<T>>) => {
    return {} as TypedAction<T>;
};

export const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(() => {
        resolve(true);
    }, milliseconds));
};


export const copyToClipBoard = (value: any) => {
    navigator.clipboard.writeText(value.toString());
}
