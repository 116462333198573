import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateTranslationMutationVariables = Types.Exact<{
  input: Types.TranslationInput;
}>;


export type UpdateTranslationMutation = (
  { __typename?: 'Mutation' }
  & { updateTranslation: (
    { __typename?: 'UpdateTranslationResult' }
    & { translation?: Types.Maybe<(
      { __typename?: 'ManagerTranslation' }
      & Pick<Types.ManagerTranslation, 'id'>
    )> }
  ) }
);

export const UpdateTranslationDocument = gql`
    mutation updateTranslation($input: TranslationInput!) {
  updateTranslation(input: $input) {
    translation {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTranslationGQL extends Apollo.Mutation<UpdateTranslationMutation, UpdateTranslationMutationVariables> {
    document = UpdateTranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }