import {Pipe, PipeTransform} from '@angular/core';
import {AttributeTypeEnum, GameVoucher, Purchase} from '../../graphql/types.graphql-gen';

@Pipe({name: 'toPurchase'})
export class ToPurchasePipe implements PipeTransform {
    transform(s: any): Purchase {
        return s;
    }
}

@Pipe({name: 'toGameVoucher'})
export class ToGameVoucherPipePipe implements PipeTransform {
    transform(s: any): GameVoucher {
        return s;
    }
}

@Pipe({name: 'toAny'})
export class ToAny implements PipeTransform {
    transform(s: any): any {
        return s;
    }
}

@Pipe({name: 'toDate'})
export class ToDate implements PipeTransform {
    transform(s: any): Date {
        if(s) {
            return new Date(s);
        }
        return s;
    }
}


@Pipe({name: 'toAttributeType'})
export class ToAttributeType implements PipeTransform {
    transform(s: any): AttributeTypeEnum {
        return s;
    }
}
