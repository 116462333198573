
import * as Quill from "quill";
import {DeltaStatic} from 'quill';
const Delta = Quill.default.import('delta');
const Embed = Quill.default.import('blots/embed');


export class SoftLineBreakBlot extends Embed {
    static blotName = 'softbreak';
    static tagName = 'br';
    static className = 'softbreak';
}
Quill.default.register(SoftLineBreakBlot);



export function shiftEnterHandler(this: any, range:any) {
    const currentLeaf = this.quill.getLeaf(range.index)[0];
    const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
    this.quill.insertEmbed(range.index, "softbreak", true, 'user');
    // Insert a second break if:
    // At the end of the editor, OR next leaf has a different parent (<p>)
    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
        this.quill.insertEmbed(range.index, "softbreak", true, 'user');
    }
    // Now that we've inserted a line break, move the cursor forward
    this.quill.setSelection(range.index + 1, 'silent');
}

export function brMatcher(node: any, delta: any) {
    console.log('wtf');
    let newDelta = new Delta();
    newDelta.insert({softbreak: true});
    return newDelta;
}


export const initializeQuill = () => {
    Quill.default.register('formats/align', Quill.default.import('attributors/style/align'), true);

    // Quill.default.clipboard.addMatcher (Node.ELEMENT_NODE, function (node:any, delta: DeltaStatic) {
    //     console.log(node);
    //     return delta;
    //     // return new Delta().insert (plaintext);
    // });
}
