import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { PartnerFragment } from '../getInitialData/getinitialData.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { PartnerFragmentDoc } from '../getInitialData/getinitialData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductPartnersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductPartnersQuery = (
  { __typename?: 'Query' }
  & { getProductPartners: (
    { __typename?: 'ProductPartnersResult' }
    & { result: Array<(
      { __typename?: 'ProductPartner' }
      & ProductPartnerFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type ProductPartnerFragment = (
  { __typename?: 'ProductPartner' }
  & Pick<Types.ProductPartner, 'id' | 'name' | 'notificationEmails' | 'defaultCommission' | 'createdAt' | 'updatedAt' | 'allowedPayments'>
  & { productPartnerSaleSources: Array<(
    { __typename?: 'ProductPartnerSaleSource' }
    & ProductPartnerSaleSourceFragment
  )>, partners: Array<(
    { __typename?: 'Partner' }
    & PartnerFragment
  )>, apiTokens: Array<(
    { __typename?: 'ApiToken' }
    & ApiTokenFragment
  )> }
);

export type ApiTokenFragment = (
  { __typename?: 'ApiToken' }
  & Pick<Types.ApiToken, 'id' | 'type' | 'token'>
);

export type ProductPartnerSaleSourceFragment = (
  { __typename?: 'ProductPartnerSaleSource' }
  & Pick<Types.ProductPartnerSaleSource, 'id' | 'commission' | 'commissionExternalGame' | 'createdAt' | 'updatedAt'>
  & { saleSource: (
    { __typename?: 'SaleSource' }
    & Pick<Types.SaleSource, 'id'>
  ), productPartner: (
    { __typename?: 'ProductPartner' }
    & Pick<Types.ProductPartner, 'id'>
  ) }
);

export const ProductPartnerSaleSourceFragmentDoc = gql`
    fragment productPartnerSaleSource on ProductPartnerSaleSource {
  id
  commission
  commissionExternalGame
  createdAt
  updatedAt
  saleSource {
    id
  }
  productPartner {
    id
  }
}
    `;
export const ApiTokenFragmentDoc = gql`
    fragment apiToken on ApiToken {
  id
  type
  token
}
    `;
export const ProductPartnerFragmentDoc = gql`
    fragment productPartner on ProductPartner {
  id
  name
  notificationEmails
  defaultCommission
  createdAt
  updatedAt
  productPartnerSaleSources {
    ...productPartnerSaleSource
  }
  partners {
    ...partner
  }
  allowedPayments
  apiTokens {
    ...apiToken
  }
}
    ${ProductPartnerSaleSourceFragmentDoc}
${PartnerFragmentDoc}
${ApiTokenFragmentDoc}`;
export const GetProductPartnersDocument = gql`
    query getProductPartners {
  getProductPartners {
    result {
      ...productPartner
    }
    meta {
      ...metaFragment
    }
  }
}
    ${ProductPartnerFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductPartnersGQL extends Apollo.Query<GetProductPartnersQuery, GetProductPartnersQueryVariables> {
    document = GetProductPartnersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }