<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="pt-2 col-4 col-xxl-6">
        <label for="skipButtonDisabled" class="pb-2">
            {{'elements.takePhotoButton.skipButtonDisabled'|translate|firstUpper}}*
            <app-tooltip image="element-takePhotoButton-skipButtonDisabled.png"
                         value="{{'elements.tooltip.takePhotoButton.skipButtonDisabled'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton id="skipButtonDisabled" [options]="boolOptionsAlt"
                        formControlName="skipButtonDisabled" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="col-12 pt-2">
        <p><strong>UniqueId</strong><app-tooltip [value]="'elements.tooltip.takePhotoButton.uniqueId'|translate|firstUpper"></app-tooltip>: {{stepElementForm.controls.id.value ?? stepElementForm.controls.tempId.value}}</p>
    </div>

</div>
