<p-dropdown *ngIf="loaded && !multiSelect && !tree" [showClear]="true" placeholder="- - -" class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}" [options]="attributes" [formControl]="control" [filter]="true"
            optionValue="id" optionLabel="name">
    <ng-template pTemplate="selectedItem" let-attribute>
        <div class="flex align-items-center" *ngIf="control.value">
            {{attribute.name}} - <p-tag severity="{{attribute.active ? 'success' : 'danger'}}">{{(attribute.active ? 'general.active' : 'general.inactive')|translate}}</p-tag>
        </div>
        <div *ngIf="!control.value">
            &nbsp;
        </div>
    </ng-template>
    <ng-template let-attribute pTemplate="item">
        <div class="flex align-items-center">
            {{attribute.name}} - <p-tag severity="{{attribute.active ? 'success' : 'danger'}}">{{(attribute.active ? 'general.active' : 'general.inactive')|translate}}</p-tag>
        </div>
    </ng-template>
</p-dropdown>

<p-multiSelect *ngIf="loaded && multiSelect && !tree" placeholder="- - -"  class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}" [options]="attributes" [formControl]="control" [filter]="true"
            optionValue="id" optionLabel="name">
    <ng-template pTemplate="selectedItem" let-attribute>
        <div class="flex align-items-center" *ngIf="control.value">
            {{attribute.name}} - <p-tag severity="{{attribute.active ? 'success' : 'danger'}}">{{(attribute.active ? 'general.active' : 'general.inactive')|translate}}</p-tag>
        </div>
        <div *ngIf="!control.value">
            &nbsp;
        </div>
    </ng-template>
    <ng-template let-attribute pTemplate="item">
        <div class="flex align-items-center">
            {{attribute.name}} -&nbsp;<p-tag severity="{{attribute.active ? 'success' : 'danger'}}">{{(attribute.active ? 'general.active' : 'general.inactive')|translate}}</p-tag>
        </div>
    </ng-template>
</p-multiSelect>
<p-treeSelect
    *ngIf="loaded && tree"
    [filter]="true"
    [showClear]="true"
    class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}"
    selectionMode="multiple"
    [ngModel]="selectedAttributes"
    (ngModelChange)="selectedAttributesChanged($event)"
    [metaKeySelection]="false"
    [options]="attributesTree"
    placeholder="- - -">
</p-treeSelect>
<small *ngIf="control.errors?.message" class="p-error">
    {{control.errors?.message|translate|firstUpper}}
</small>
