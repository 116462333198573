<div class="p-fluid grid align-items-center" [formGroup]="stepElementForm.controls.settings">
    <div class="col-6">
        <label for="latitude-{{uniqueId}}" class="pb-2">
            {{'elements.location.latitude'|translate|firstUpper}}
        </label>
        <input id="latitude-{{uniqueId}}"
               formControlName="latitude" type="number" pInputText
               class="{{stepElementForm.controls.settings.controls.latitude.touched && stepElementForm.controls.settings.controls.latitude.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-6">
        <label for="longitude-{{uniqueId}}" class="pb-2">{{'elements.location.longitude'|translate|firstUpper}}</label>
        <input id="longitude-{{uniqueId}}"
               formControlName="longitude" type="number" pInputText
               class="{{stepElementForm.controls.settings.controls.longitude.touched && stepElementForm.controls.settings.controls.longitude.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-6">
        <label for="radius-{{uniqueId}}" class="pb-2">
            {{'elements.location.radius'|translate|firstUpper}}*
            <app-tooltip value="{{'elements.tooltip.location.radius'|translate|firstUpper}}"></app-tooltip>
            </label>
        <p-inputNumber
            [showButtons]="true"
            inputId="radius-{{uniqueId}}"
            locale="cs-CS"
            [suffix]="' m.'"
            [min]="1"
            class="{{stepElementForm.controls.settings.controls.radius.touched && stepElementForm.controls.settings.controls.radius.invalid ? 'ng-invalid ng-dirty' : ''}}"
            formControlName="radius"></p-inputNumber>
    </div>
    <div class="col-12 pb-3">
        <button
            pButton
            label="{{'elements.location.searchAddress'|translate|firstUpper}}"
            class="p-button-sm p-button-success auto-width mt-2"
            icon="pi pi-search"
            (click)="openGoogleMap()"
        ></button>
    </div>
    <ng-container *ngIf="true" formGroupName="options">
<!--        <div class="col-4">-->
<!--            <label for="counter-{{uniqueId}}" class="pb-2">Show counter</label>-->
<!--            <p-selectButton class="on-off-select-button" id="counter-{{uniqueId}}" [options]="boolOptions"-->
<!--                            formControlName="counter" optionLabel="label"-->
<!--                            optionValue="value"></p-selectButton>-->
<!--        </div>-->
        <div class="col-6 col-xxl-4">
            <label for="showDirection-{{uniqueId}}" class="pb-2">
                {{'elements.location.showDirection'|translate|firstUpper}}
                <app-tooltip image="element-location-showDirection.png"
                             value="{{'elements.tooltip.location.showDirection'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton class="on-off-select-button" id="showDirection-{{uniqueId}}" [options]="boolOptions"
                            formControlName="showDirection" optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
        <div class="col-6 col-xxl-4">
            <label for="showDistance-{{uniqueId}}" class="pb-2">
                {{'elements.location.showDistance'|translate|firstUpper}}
                <app-tooltip image="element-location-showDistance.png"
                             value="{{'elements.tooltip.location.showDistance'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton class="on-off-select-button" id="showDistance-{{uniqueId}}" [options]="boolOptions"
                            formControlName="showDistance" optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
    </ng-container>
</div>
