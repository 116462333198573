import {createAction, props} from '@ngrx/store';
import {
    LangInput,
    ManagerTranslationsFilter,
    ManagerTranslationValueFilter,
    TranslationInput
} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {GetTranslationsQuery} from '../../graphql/queries/getTranslations/getTranslations.graphql-gen';
import {UpdateLangMutation} from '../../graphql/mutations/updateLang/updateLang.graphql-gen';
import {GetTranslationsMetadataQuery} from '../../graphql/queries/metaData/metaData.graphql-gen';
import {UpdateTranslationMutation} from '../../graphql/mutations/updateTranslation/updateTranslation.graphql-gen';

export const fetchTranslationsAction = createAction('[Translation] LOAD_TRANSLATIONS', props<FetchTranslationsActionProps>());
export const fetchTranslationsSuccessAction = createAction('[Translation] LOAD_TRANSLATIONS_SUCCESS', props<FetchTranslationsSuccessActionProps>());
export const fetchTranslationsFailAction = createAction('[Translation] LOAD_TRANSLATIONS_FAIL', props<FetchTranslationsFailActionProps>());

export const fetchTranslationsHelperDataAction = createAction('[Translation] LOAD_TRANSLATIONS_HELPER_DATA');
export const fetchTranslationsHelperDataSuccessAction = createAction('[Translation] LOAD_TRANSLATIONS_HELPER_DATA_SUCCESS', props<FetchTranslationsHelperDataSuccessActionProps>());
export const fetchTranslationsHelperDataFailAction = createAction('[Translation] LOAD_TRANSLATIONS_HELPER_DATA_FAIL', props<FetchTranslationsHelperDataFailActionProps>());

export const updateLangAction = createAction('[Translation] UPDATE_LANG', props<UpdateLangActionProps>());
export const updateLangFailAction = createAction('[Translation] UPDATE_LANG_FAIL', props<UpdateLangFailActionProps>());
export const updateLangSuccessAction = createAction('[Translation] UPDATE_LANG_SUCCESS', props<UpdateLangSuccessActionProps>());

export const updateTranslationAction = createAction('[Translation] UPDATE_TRANSLATION', props<UpdateTranslationActionProps>());
export const updateTranslationSuccessAction = createAction('[Translation] UPDATE_TRANSLATION_SUCCESS', props<UpdateTranslationSuccessActionProps>());
export const updateTranslationFailAction = createAction('[Translation] UPDATE_TRANSLATION_FAIL', props<UpdateTranslationFailActionProps>());

type UpdateTranslationActionProps = {input: TranslationInput};
type UpdateTranslationSuccessActionProps = {res: UpdateTranslationMutation['updateTranslation']};
type UpdateTranslationFailActionProps = DefaultFailMessage;

export type UpdateLangActionProps = {input: LangInput}
export type UpdateLangSuccessActionProps = {res: UpdateLangMutation['updateLang']}
export type UpdateLangFailActionProps = DefaultFailMessage;

export type FetchTranslationsHelperDataSuccessActionProps = {
    langsMeta: GetTranslationsMetadataQuery['getLanguages']['langsMetadata'] | null;
    translationsMeta: GetTranslationsMetadataQuery['getManagerTranslations']['translationsMetadata'] | null;
};
export type FetchTranslationsHelperDataFailActionProps = DefaultFailMessage;

export type FetchTranslationsActionProps = { filter: ManagerTranslationsFilter, translationValueFilter: ManagerTranslationValueFilter };
export type FetchTranslationsSuccessActionProps = { translations: GetTranslationsQuery['getManagerTranslations'] | null };
export type FetchTranslationsFailActionProps = DefaultFailMessage;
