import {LocationSettings} from '../components/elements/element-types';
import * as _ from 'lodash';

export class CoordinatesHelper {

    /**
     * Returns amount of metres betweeen two coordinates
     * @param c1
     * @param c2
     */
    public static calculateDistance(c1: Coordinates, c2: Coordinates): number {
        const lat1 = this.degToRad(c1.lat);
        const lon1 = this.degToRad(c1.lng);
        const lat2 = this.degToRad(c2.lat);
        const lon2 = this.degToRad(c2.lng);
        return (6371 * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos((lon2 - lon1)))) * 1000;
    }

    public static calculateBounds(pointsInMap: Coordinates[]): google.maps.LatLngBoundsLiteral|null {
        if(pointsInMap.length <= 1) {
            return null;
        }
        const allLat: number[] = [];
        const allLng: number[] = [];
        pointsInMap.forEach((point) => {
            allLat.push(point.lat);
            allLng.push(point.lng);
        })
        return {
            east: _.max(allLng) as number,
            west: _.min(allLng) as number,
            north: _.max(allLat) as number,
            south: _.min(allLat) as number,
        };
    }

    // public static calculateZoom(pointsInMap: Coordinates[]) {
    //     if (pointsInMap.length <= 1) {
    //         return 12
    //     }
    //     const product: { a: Coordinates, b: Coordinates }[] = [];
    //     pointsInMap.forEach((point, index) => {
    //         // potentionalSet.push({a: point, b: point});
    //         pointsInMap.slice(index + 1).forEach((pointB) => {
    //             product.push({a: point, b: pointB});
    //         });
    //     })
    //     const max = _.maxBy(product, (pair) => {
    //         return this.calculateDistance(pair.a, pair.b);
    //     }) as { a: Coordinates, b: Coordinates }
    //     const distance = this.calculateDistance(max.a, max.b);
    //     if(distance < 100) {
    //         return 16;
    //     } else {
    //
    //     }
    //     return 12;
    // }

    public static calculateNextPosition(position: Coordinates, zoom: number): Coordinates {
        const newCoordinates = {...position};
        // newCoordinates.lat = position.lat + (0.005 / 6378) * (180 / Math.PI);
        newCoordinates.lng = position.lng + (0.01 / 6378) * (180 / Math.PI) / Math.cos(position.lat * (Math.PI / 180));
        return newCoordinates;
    }


    public static getRoutePoint(settings: { longitude: number; latitude: number; radius: number; }, label: string, animate: boolean = false) {
        const point = new google.maps.Marker({
            position: {
                lat: settings.latitude,
                lng: settings.longitude
            },
            label: label,
            title: '',
            draggable: true,
        });
        if(animate) {
            point.setAnimation(google.maps.Animation.DROP);
        }
        const radius = new google.maps.Circle({
            center: {
                lat: settings.latitude,
                lng: settings.longitude
            },
            fillColor: '#1976D2',
            fillOpacity: 0.35,
            strokeWeight: 1,
            radius: settings.radius,
        });
        return {
            point,
            radius
        }
    }


    protected static degToRad(angle: number): number {
        return angle * Math.PI / 180.0;
    }

    protected static radToDeg(rad: number): number {
        return rad * (180 / Math.PI);
    }
}

type Coordinates = google.maps.LatLngLiteral;
