import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';
import {getAttachmentPath} from '../forms/helpers';

@Pipe({
  name: 'ratingClass'
})
export class RatingClassPipe implements PipeTransform {

  transform(value: number): string {

      if(value >= 4) {
          return 'text-success';
      }
      if(value >= 3) {
          return 'text-warning';
      }
      return 'text-danger';
  }

}
