<p-dropdown *ngIf="loaded" class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}" [options]="languages" id="groupLangId" [formControl]="control" [filter]="true"
            optionValue="id" optionLabel="name">
    <ng-template pTemplate="selectedItem" let-language>
        <div class="flex align-items-center" *ngIf="control.value">
            <img style="height: 1rem" *ngIf="language.flagPath"
                 src="{{('/admin/assets/img/flags/svg/' + language.flagPath)|url}}" alt="">
            <span class="ps-2" style="margin-top: 2px">{{language.name}}</span>
        </div>
        <div *ngIf="!control.value">
            &nbsp;
        </div>
    </ng-template>
    <ng-template let-language pTemplate="item">
        <div class="flex align-items-center">
            <img style="height: 1rem" *ngIf="language.flagPath"
                 src="{{('/admin/assets/img/flags/svg/' + language.flagPath)|url}}" alt="">
            <span class="ps-2" style="margin-top: 2px">{{language.name}}</span>
        </div>
    </ng-template>
</p-dropdown>
<small *ngIf="control.errors?.message" class="p-error">
    {{control.errors?.message|translate|firstUpper}}
</small>
