import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AudioComponent} from './audio/audio.component';
import {StepWrapperComponent} from './step-wrapper/step-wrapper.component';
import {TextComponent} from './text/text.component';
import {ClueEndComponent} from './clue-end/clue-end.component';
import {ClueStartComponent} from './clue-start/clue-start.component';
import {CompleteGameComponent} from './complete-game/complete-game.component';
import {ContinueButtonComponent} from './continue-button/continue-button.component';
import {ContinueTimerComponent} from './continue-timer/continue-timer.component';
import {ImageComponent} from './image/image.component';
import {InputComponent} from './input/input.component';
import {InventoryNewItemComponent} from './inventory-new-item/inventory-new-item.component';
import {LocationComponent} from './location/location.component';
import {PauseTimerComponent} from './pause-timer/pause-timer.component';
import {PhoneNumberComponent} from './phone-number/phone-number.component';
import {RateWindowComponent} from './rate-window/rate-window.component';
import {SelectOptionComponent} from './select-option/select-option.component';
import {ShareButtonComponent} from './share-button/share-button.component';
import {SkipStepButtonComponent} from './skip-step-button/skip-step-button.component';
import {SlidingPuzzleComponent} from './sliding-puzzle/sliding-puzzle.component';
import {SmallInfoPopupComponent} from './small-info-popup/small-info-popup.component';
import {StartGameButtonComponent} from './start-game-button/start-game-button.component';
import {StepSummaryComponent} from './step-summary/step-summary.component';
import {TakePhotoButtonComponent} from './take-photo-button/take-photo-button.component';
import {TeamNameComponent} from './team-name/team-name.component';
import {TimeoutComponent} from './timeout/timeout.component';
import {VideoComponent} from './video/video.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {EditorModule} from 'primeng/editor';
import {SelectButtonModule} from 'primeng/selectbutton';
import {PipesModule} from '../../pipes/pipes.module';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {CustomModule} from '../custom/custom.module';
import {ElementListComponent} from './element-list/element-list.component';
import {PrimeModule} from '../../prime.module';
// import {GMapModule} from 'primeng/gmap';
import { QrCodeComponent } from './qr-code/qr-code.component';
import {DirectivesModule} from '../../directives/directives.module';
import { NgxEditorModule } from 'ngx-editor';
import {GoogleMapsModule} from '@angular/google-maps';
import { PairSentenceComponent } from './pair-sentence/pair-sentence.component';
import { UpdateSentenceComponent } from './pair-sentence/update-sentence/update-sentence.component';
import { SentenceFormComponent } from './pair-sentence/update-sentence/sentence-form/sentence-form.component';
import { UpdateOptionComponent } from './select-option/update-option/update-option.component';


@NgModule({
    declarations: [
        AudioComponent,
        StepWrapperComponent,
        TextComponent,
        ClueEndComponent,
        ClueStartComponent,
        CompleteGameComponent,
        ContinueButtonComponent,
        ContinueTimerComponent,
        ImageComponent,
        InputComponent,
        InventoryNewItemComponent,
        LocationComponent,
        PauseTimerComponent,
        PhoneNumberComponent,
        RateWindowComponent,
        SelectOptionComponent,
        ShareButtonComponent,
        SkipStepButtonComponent,
        SlidingPuzzleComponent,
        SmallInfoPopupComponent,
        StartGameButtonComponent,
        StepSummaryComponent,
        TakePhotoButtonComponent,
        TeamNameComponent,
        TimeoutComponent,
        VideoComponent,
        ElementListComponent,
        QrCodeComponent,
        PairSentenceComponent,
        UpdateSentenceComponent,
        SentenceFormComponent,
        UpdateOptionComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        EditorModule,
        SelectButtonModule,
        PipesModule,
        ButtonModule,
        TooltipModule,
        TranslateModule,
        CustomModule,
        PrimeModule,
        GoogleMapsModule,
        // GMapModule,
        DirectivesModule,
        NgxEditorModule,
        FormsModule
    ],
    exports: [
        StepWrapperComponent,
        ElementListComponent,
        TextComponent,
        ImageComponent
    ]
})
export class ElementsModule {
}
