import {Component, OnInit, ViewChild} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {SwalComponent, SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';
import {AppActions} from './store/app-actions.service';
import {
    cancelConfirmDialogAction, closeModalAction,
    showAlertDialogAction,
    showAlertProgressAction,
    showConfirmDialogAction
} from './store/general/actions';
import {TranslateService} from '@ngx-translate/core';
import {ProgressType} from './store/types';
import {ProgressService} from './services/progress.service';
import {initializeQuill} from './shared/quill-editor';
initializeQuill();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'manager';
    @ViewChild(`confirmAlert`) private confirmAlert: SwalComponent;
    @ViewChild(`confirmAlertSelectOption`) private confirmAlertSelectOption: SwalComponent;
    @ViewChild(`progressAlert`) private progressAlert: SwalComponent;
    @ViewChild(`alert`) private alert: SwalComponent;

    footer = 'test';

    progress: ProgressType | null = null;

    initialLoading = true;

    constructor(
        private primengConfig: PrimeNGConfig,
        private appActions: AppActions,
        private translateService: TranslateService,
        public swalTargets: SwalPortalTargets,
        private progressService: ProgressService
    ) {
        this.primengConfig.ripple = true;
    }

    ngOnInit(): void {
        this.waitForNextConfirmAlert();
        this.waitForNextAlert();
        this.waitForNextProgressAlert();
    }

    waitForNextProgressAlert() {
        this.progress = null;
        this.appActions.ofType(showAlertProgressAction).subscribe((action) => {
            const subscription = this.progressService.progress$.subscribe((progress) => {
                if (!this.progress) {
                    this.progress = progress;
                    this.progressAlert.title = this.translateService.instant(progress.title);
                    this.progressAlert.progressSteps = progress.steps.length > 1 ? progress.steps : undefined;
                    this.progressAlert.currentProgressStep = progress.steps.length > 1 ? progress.currentStep : undefined;

                    this.progressAlert.fire().then();
                    return;
                }
                this.progress = progress;
                if (progress.forceClose) {
                    this.appActions.dispatch(closeModalAction()).subscribe();
                    this.progressAlert.close().then();
                    subscription.unsubscribe();
                    this.waitForNextProgressAlert();
                    return;
                }
                this.progressAlert.update({
                    progressSteps: progress.steps.length > 1 ? progress.steps : undefined,
                    currentProgressStep: progress.steps.length > 1 ? progress.currentStep : undefined,
                    title: progress.title
                }).then();
            });
        });
    }

    waitForNextConfirmAlert() {
        this.appActions.ofType(showConfirmDialogAction).subscribe((a) => {
            const alert = a.selectOptions ? this.confirmAlertSelectOption : this.confirmAlert;
            alert.title = this.translateService.instant(a.header);
            alert.text = a.message ? this.translateService.instant(a.message) : '';
            alert.confirmButtonText = this.translateService.instant(a.confirmButton);
            alert.cancelButtonText = this.translateService.instant(a.cancelButton);
            if(a.footer) {
                alert.footer = a.footer;
            } else {
                alert.footer = '';
            }

            if(a.selectOptions) {
                alert.inputOptions = a.selectOptions;
                if(a.selectedValue) {
                    // @ts-ignore
                    alert.inputValue = a.selectedValue;
                }
            }

            alert.fire().then((res) => {
                this.appActions.dispatch(closeModalAction()).subscribe()
                this.appActions.dispatch(cancelConfirmDialogAction({confirmed: res.isConfirmed, selectedValue: res.value})).subscribe();
                this.waitForNextConfirmAlert();
            });
        });
    }

    waitForNextAlert() {
        this.appActions.ofType(showAlertDialogAction).subscribe((a) => {
            this.alert.title = a.header ? this.translateService.instant(a.header) : '';
            this.alert.text = a.message ? this.translateService.instant(a.message) : '';
            this.alert.icon = a.icon ?? 'error';
            this.alert.fire().then((res) => {
                this.appActions.dispatch(closeModalAction()).subscribe();
                this.waitForNextAlert();
            });
        });
    }

    defaultSelectValidator = (result: any) => {
        if(!result) {
            return this.translateService.instant('general.selectOption');
        }
    };

    routerOutletActive(e: any) {
        this.initialLoading = false;
    }

    protected readonly parseFloat = parseFloat;
}
