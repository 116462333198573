import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetLicensesQueryVariables = Types.Exact<{
  filter: Types.LicenseFilter;
}>;


export type GetLicensesQuery = (
  { __typename?: 'Query' }
  & { getLicenses: (
    { __typename?: 'LicensesResult' }
    & { result: Array<(
      { __typename?: 'License' }
      & LicenseFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetLicenseQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetLicenseQuery = (
  { __typename?: 'Query' }
  & { getLicenses: (
    { __typename?: 'LicensesResult' }
    & { result: Array<(
      { __typename?: 'License' }
      & FullLicenseFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type FullLicenseFragment = (
  { __typename?: 'License' }
  & { licenseKeys: Array<(
    { __typename?: 'LicenseKey' }
    & LicenseKeyFragment
  )>, licenseContacts: Array<(
    { __typename?: 'LicenseContact' }
    & LicenseContactFragment
  )> }
  & LicenseFragment
);

export type LicenseContactFragment = (
  { __typename?: 'LicenseContact' }
  & Pick<Types.LicenseContact, 'id' | 'name' | 'note' | 'email' | 'createdAt' | 'updatedAt' | 'phone'>
);

export type LicenseFragment = (
  { __typename?: 'License' }
  & Pick<Types.License, 'id' | 'name' | 'type' | 'city' | 'note' | 'photoSource' | 'createdAt' | 'updatedAt'>
);

export type LicenseKeyFragment = (
  { __typename?: 'LicenseKey' }
  & Pick<Types.LicenseKey, 'id' | 'name' | 'key' | 'maxActiveUsers' | 'validFrom' | 'validTo' | 'createdAt' | 'updatedAt'>
  & { permissionGroup: (
    { __typename?: 'PermissionGroup' }
    & PermissionGroupFragment
  ) }
);

export type PermissionGroupFragment = (
  { __typename?: 'PermissionGroup' }
  & Pick<Types.PermissionGroup, 'id' | 'type' | 'name' | 'createdAt' | 'updatedAt'>
  & { players: Array<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'fullname' | 'email'>
  )> }
);

export type FullPermissionGroupFragment = (
  { __typename?: 'PermissionGroup' }
  & Pick<Types.PermissionGroup, 'permissions'>
  & PermissionGroupFragment
);

export const LicenseFragmentDoc = gql`
    fragment license on License {
  id
  name
  type
  city
  note
  photoSource
  createdAt
  updatedAt
}
    `;
export const PermissionGroupFragmentDoc = gql`
    fragment permissionGroup on PermissionGroup {
  id
  type
  name
  createdAt
  updatedAt
  players {
    id
    fullname
    email
  }
}
    `;
export const LicenseKeyFragmentDoc = gql`
    fragment licenseKey on LicenseKey {
  id
  name
  key
  maxActiveUsers
  validFrom
  validTo
  createdAt
  updatedAt
  permissionGroup {
    ...permissionGroup
  }
}
    ${PermissionGroupFragmentDoc}`;
export const LicenseContactFragmentDoc = gql`
    fragment licenseContact on LicenseContact {
  id
  name
  note
  email
  createdAt
  updatedAt
  phone
}
    `;
export const FullLicenseFragmentDoc = gql`
    fragment fullLicense on License {
  ...license
  licenseKeys {
    ...licenseKey
  }
  licenseContacts {
    ...licenseContact
  }
}
    ${LicenseFragmentDoc}
${LicenseKeyFragmentDoc}
${LicenseContactFragmentDoc}`;
export const FullPermissionGroupFragmentDoc = gql`
    fragment fullPermissionGroup on PermissionGroup {
  ...permissionGroup
  permissions
}
    ${PermissionGroupFragmentDoc}`;
export const GetLicensesDocument = gql`
    query getLicenses($filter: LicenseFilter!) {
  getLicenses(filter: $filter) {
    result {
      ...license
    }
    meta {
      ...metaFragment
    }
  }
}
    ${LicenseFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicensesGQL extends Apollo.Query<GetLicensesQuery, GetLicensesQueryVariables> {
    document = GetLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLicenseDocument = gql`
    query getLicense($id: String!) {
  getLicenses(filter: {id: $id}) {
    result {
      ...fullLicense
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullLicenseFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicenseGQL extends Apollo.Query<GetLicenseQuery, GetLicenseQueryVariables> {
    document = GetLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }