import * as Types from '../../types.graphql-gen';

import { CityFragment, FullCityFragment } from '../../mutations/updateCity/updateCity.graphql-gen';
import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { CityFragmentDoc, FullCityFragmentDoc } from '../../mutations/updateCity/updateCity.graphql-gen';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetCitiesQueryVariables = Types.Exact<{
  filter: Types.ManagerCitiesFilter;
}>;


export type GetCitiesQuery = (
  { __typename?: 'Query' }
  & { getManagerCities: (
    { __typename?: 'ManagerCitiesResult' }
    & { result: Array<(
      { __typename?: 'City' }
      & CityFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetCityQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetCityQuery = (
  { __typename?: 'Query' }
  & { getManagerCities: (
    { __typename?: 'ManagerCitiesResult' }
    & { result: Array<(
      { __typename?: 'City' }
      & FullCityFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export const GetCitiesDocument = gql`
    query getCities($filter: ManagerCitiesFilter!) {
  getManagerCities(filter: $filter) {
    result {
      ...city
    }
    meta {
      ...metaFragment
    }
  }
}
    ${CityFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCitiesGQL extends Apollo.Query<GetCitiesQuery, GetCitiesQueryVariables> {
    document = GetCitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCityDocument = gql`
    query getCity($id: String!) {
  getManagerCities(filter: {id: $id}) {
    result {
      ...fullCity
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullCityFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCityGQL extends Apollo.Query<GetCityQuery, GetCityQueryVariables> {
    document = GetCityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }