import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AutoComplete} from 'primeng/autocomplete';
import {generateUUID} from '../../../forms/helpers';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-select-position',
    templateUrl: './select-position.component.html',
    styleUrls: ['./select-position.scss']
})
export class SelectPositionComponent implements OnInit, OnDestroy {

    searchComponent: AutoComplete;
    text = '';
    results: any[] = [];
    inputId = generateUUID();
    radius: number = 15;
    @Input() coordinates: google.maps.LatLngLiteral | null = null;

    options: any;
    map: google.maps.Map;
    marker: google.maps.Marker;
    circle: google.maps.Circle;
    input: HTMLInputElement;
    searchBox: google.maps.places.SearchBox;

    constructor(
        private dynamicDialogService: DynamicDialogConfig,
        private ref: DynamicDialogRef,
    ) {
    }

    ngOnInit(): void {
        this.options = {
            center: this.dynamicDialogService.data.coordinates ?? {lat: 49.55733658116199, lng: 18.53796354611451},
            zoom: this.dynamicDialogService.data.coordinates ? 13 : 6,
        };

    }

    ngOnDestroy(): void {
        if (this.searchBox) {
            this.searchBox.unbindAll();
        }
    }


    setMap(event: any) {
        setTimeout(() => {
            this.map = event;
            this.map.setOptions({gestureHandling: 'greedy'})
            this.radius = this.dynamicDialogService.data.radius ?? 15;
            if (!this.coordinates) {
                this.coordinates = this.dynamicDialogService.data?.coordinates;
            }
            if (this.coordinates) {
                this.changeMarker(this.coordinates);
            }
        });
    }

    changeMarker(coordinates: google.maps.LatLngLiteral) {
        if (!this.marker) {
            this.marker = new google.maps.Marker({
                map: this.map,
                title: 'Selected value',
                position: coordinates
            });
            this.circle = new google.maps.Circle({
                map: this.map,
                center: coordinates,
                fillColor: '#1976D2',
                fillOpacity: 0.35,
                strokeWeight: 1,
                radius: this.radius
            });
        } else {
            this.marker.setPosition(coordinates);
            this.circle.setCenter(coordinates);
        }
        this.coordinates = coordinates;
    }

    handleMapClick(e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
        if(e.latLng?.toJSON()) {
            this.changeMarker(e.latLng?.toJSON());
        }
    }

    save() {
        this.ref.close({coordinates: this.coordinates, radius: this.radius});
    }

    search(search: any) {
        this.input.value = search.query;
        google.maps.event.trigger(this.input, 'focus', {});
        google.maps.event.trigger(this.input, 'keydown', {keyCode: 13});
    }

    setSearch(searchComponent: AutoComplete) {
        // console.log(input.value);
        if (this.searchComponent) {
            return;
        }
        const input = document.createElement('INPUT') as HTMLInputElement;
        input.setAttribute('type', 'text');
        input.setAttribute('id', this.inputId);
        this.searchComponent = searchComponent;
        this.input = input;
        const searchBox = new google.maps.places.SearchBox(this.input);
        this.searchBox = searchBox;
        searchBox.addListener('places_changed', () => {
            let places = searchBox.getPlaces();
            if (!places) {
                places = [];
            }
            places.forEach(p => {
                p.toString = () => p.formatted_address ?? 'undefined';
            });
            this.results = places;
        });
    }

    selectPlace(place: google.maps.places.PlaceResult) {
        const coordinates = place.geometry?.location?.toJSON();
        if (!coordinates) {
            return;
        }
        this.changeMarker(coordinates);
        this.map.setOptions({center: coordinates});
    }

    changeRadius(event: any) {
        if(!this.circle) {
            return;
        }
        this.circle.setRadius(event.value);
    }
}
