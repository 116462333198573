import {Pipe, PipeTransform} from '@angular/core';
import {Rule} from '../directives/is-allowed.directive';
import {AclService} from '../../services/acl.service';

@Pipe({
    name: 'isAllowed',
    pure: true
})
export class IsAllowedPipe implements PipeTransform {

    constructor(
        private aclService: AclService
    ) {
    }

    transform(rule: Rule): unknown {
        return this.aclService.isAllowed(rule.right, rule.extraData)
    }

}
