<div class="container p-0" [formGroup]="stepElementForm.controls.settings">
    <app-error-list [errors]="stepElementForm.controls.settings.errors"></app-error-list>

    <div class="row">
        <div class="col-12">
            <h4>{{'general.settings'|translate|firstUpper}}</h4>
        </div>
        <div class="col-6">
            <label for="displayMode-{{uniqueId}}" class="pb-2">
                {{'elements.selectOption.displayMode'|translate|firstUpper}}
                <app-tooltip
                    value="{{'elements.selectOption.displayMode.tooltip'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton class="normal" id="displayMode-{{uniqueId}}" [options]="displayModeOptions"

                            [formControl]="stepElementForm.controls.settings.controls.displayMode" optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
        <div class="col-6">
            <label for="displayMode-{{uniqueId}}" class="pb-2">
                {{'elements.selectOption.shuffleOptions'|translate|firstUpper}}
                <app-tooltip
                    value="{{'elements.selectOption.shuffleOptions.tooltip'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton class="normal" id="shuffleOptions-{{uniqueId}}" [options]="boolOptions"
                            [formControl]="stepElementForm.controls.settings.controls.shuffleOptions"
                            optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
        <div class="col-6">
            <label for="evaluationMode-{{uniqueId}}" class="pb-2">
                {{'elements.selectOption.evaluationMode'|translate|firstUpper}}
                <app-tooltip
                    value="{{'elements.selectOption.evaluationMode.tooltip'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton class="normal" id="evaluationMode-{{uniqueId}}" [options]="evaluationModeOptions"

                            [formControl]="stepElementForm.controls.settings.controls.evaluationMode"
                            optionLabel="label"
                            optionValue="value"></p-selectButton>
        </div>
    </div>


    <div class="row mt-4">
        <div class="col-12">
            <h4>{{'elements.selectOption.options'|translate|firstUpper}}</h4>
        </div>
    </div>
    <div class="row align-items-center mt-2" *ngIf="stepElementForm.controls.settings.controls.options.controls.length">
        <div class="col">
        </div>
        <div class="col-auto"></div>
    </div>
    <div class="row mb-4 align-items-center"
         *ngFor="let selectOption of stepElementForm.controls.settings.controls.options.controls; let index = index;"
         [formGroup]="selectOption">
        <div class="col pt-4 pb-4 box {{selectOption.value.isCorrect ? 'box-correct' : 'box-wrong'}} text-center">
            <ng-container *ngIf="selectOption.value.value">
                <strong
                    style="{{selectOption.value.fontSize ? ('font-size: ' + selectOption.value.fontSize + 'px') : ''}}">{{selectOption.value.value}}</strong><br>
            </ng-container>
            <ng-container *ngIf="selectOption.value.src">
                <img [src]="selectOption!.value!.src!|resourcePath|async">
            </ng-container>
        </div>
        <div class="col-auto">
            <button pButton
                    [disabled]="readonly"
                    icon="pi pi-cog"
                    class="p-button-sm p-button-success mr-2"
                    (click)="updateOption(selectOption)"></button>
            <button
                [disabled]="readonly"
                (click)="removeSelectOption(index)"
                class="p-button-sm p-button-outlined me-2 p-button-danger"
                pButton
                type="button"
                icon="pi pi-times"
                pTooltip="{{'games.steps.remove'|translate|firstUpper}}"
                tooltipPosition="bottom">
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button
                [disabled]="readonly"
                pButton
                label="{{'elements.selectOption.addSelectOption'|translate|firstUpper}}"
                class="p-button-sm p-button-success auto-width mt-2"
                icon="pi pi-plus"
                (click)="updateOption()"
            ></button>
        </div>
    </div>
</div>
