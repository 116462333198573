import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GetLicenseGQL, GetLicensesGQL} from '../../graphql/queries/getLicenses/getLicenses.graphql-gen';
import {
    fetchLicenseAction,
    fetchLicenseFailAction,
    fetchLicensesAction,
    fetchLicensesFailAction,
    fetchLicensesSuccessAction,
    fetchLicenseSuccessAction, updateLicenseAction, updateLicenseFailAction, updateLicenseSuccessAction
} from './actions';
import {concatMap, map} from 'rxjs/operators';
import {UpdateLicenseGQL} from '../../graphql/mutations/updateLicense/updateLicense.graphql-gen';
import {fixMutation} from '../../shared/helpers';
import {UpdateLicenseResult} from '../../graphql/types.graphql-gen';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LicenseEffect implements OnDestroy {


    subSink = new SubSink();

    getLicenses$ = createEffect(() => this.actions$.pipe(
        ofType(fetchLicensesAction),
        concatMap((action) => {
            return this.getLicensesGQL.fetch({filter: action.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchLicensesFailAction({message: res.errors[0].message, header: ''})
            }
            return fetchLicensesSuccessAction({result: res.data.getLicenses});
        })
    ));

    getLicense$ = createEffect(() => this.actions$.pipe(
        ofType(fetchLicenseAction),
        concatMap((action) => {
            return this.getLicenseGQL.fetch({id: action.id.toString()});
        }),
        map((res) => {
            if (res.errors) {
                return fetchLicenseFailAction({message: res.errors[0].message, header: ''});
            }
            if(!res.data.getLicenses.result[0]) {
                return fetchLicenseFailAction({message: this.translateService.instant('license.messages.licenseNotFound'), header: ''});
            }
            return fetchLicenseSuccessAction({license: res.data.getLicenses.result[0]});
        })
    ));

    updateLicense$ = createEffect(() => this.actions$.pipe(
        ofType(updateLicenseAction),
        concatMap((action) => {
            return fixMutation(this.updateLicenseGQL.mutate(action.input,action.context));
        }),
        map((res) => {
            if (res.errors) {
                return updateLicenseFailAction({header: '', message: res.errors[0].message});
            }
            return updateLicenseSuccessAction({result: res.data.updateLicense as UpdateLicenseResult});
        })
    ));

    constructor(
        private actions$: Actions,
        private getLicensesGQL: GetLicensesGQL,
        private getLicenseGQL: GetLicenseGQL,
        private updateLicenseGQL: UpdateLicenseGQL,
        private translateService: TranslateService
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
