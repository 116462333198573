import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {fetchTranslationsSuccessAction} from './actions';
import {on} from '@ngrx/store';
import {fetchPlayersSuccessAction} from '../player/actions';

export const translationReducer = createImmerReducer(
    defaultDataState,
    // on(fetchPlayersSuccessAction, (state, action) => {
    //     if (action.result) {
    //         state.player.players = action.result;
    //     }
    //     return state;
    // }),
    on(fetchTranslationsSuccessAction, (state: IDataState, action: ReturnType<typeof fetchTranslationsSuccessAction>) => {
        if(action.translations) {
            state.translation.translations = action.translations;
        }
        return state;
    })
);

