import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchTranslationsAction,
    fetchTranslationsFailAction,
    fetchTranslationsHelperDataAction,
    fetchTranslationsHelperDataFailAction,
    fetchTranslationsHelperDataSuccessAction,
    fetchTranslationsSuccessAction,
    updateLangAction,
    updateLangFailAction,
    updateLangSuccessAction, updateTranslationAction, updateTranslationFailAction, updateTranslationSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {GetTranslationsGQL} from '../../graphql/queries/getTranslations/getTranslations.graphql-gen';
import {map} from 'rxjs/operators';
import {UpdateLangGQL} from '../../graphql/mutations/updateLang/updateLang.graphql-gen';
import {fixMutation} from '../../shared/helpers';
import {GetTranslationsMetadataGQL} from '../../graphql/queries/metaData/metaData.graphql-gen';
import {UpdateTranslationGQL} from '../../graphql/mutations/updateTranslation/updateTranslation.graphql-gen';

@Injectable()
export class TranslationEffect implements OnDestroy {


    subSink = new SubSink();

    fetchTranslationsHelpersData$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchTranslationsHelperDataAction
        ),
        concatMap((a) => {
            return this.translationsMetadataGQL.fetch();
        }),
        map((res) => {
            if(res.errors) {
                return fetchTranslationsHelperDataFailAction({header: '', message: res.errors[0].message});
            }
            res.data.getManagerTranslations.translationsMetadata
            return fetchTranslationsHelperDataSuccessAction({langsMeta: res.data.getLanguages.langsMetadata, translationsMeta: res.data.getManagerTranslations.translationsMetadata});
        })
    ));

    fetchTranslations$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchTranslationsAction
        ),
        concatMap((a) => {
            return this.getTranslationsGQL.fetch({filter: a.filter, translationValueFilter: a.translationValueFilter});
        }),
        map((res) => {
            if(res.errors) {
                return fetchTranslationsFailAction({header: '', message: res.errors[0].message});
            }
            return fetchTranslationsSuccessAction({translations: res.data.getManagerTranslations});
        })
    ));

    updateLang$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateLangAction
        ),
        concatMap((a) => {
            return fixMutation(this.updateLangGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateLangFailAction({header: '', message: res.errors[0].message});
            }
            return updateLangSuccessAction({res: res.data.updateLang});
        })
    ));

    updateTranslation$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateTranslationAction
        ),
        concatMap((a) => {
            return fixMutation(this.updateTranslationGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateTranslationFailAction({header: '', message: res.errors[0].message});
            }
            return updateTranslationSuccessAction({res: res.data.updateTranslation});
        })
    ));

    constructor(
        private actions$: Actions,
        private getTranslationsGQL: GetTranslationsGQL,
        private translationsMetadataGQL: GetTranslationsMetadataGQL,
        private updateLangGQL: UpdateLangGQL,
        private updateTranslationGQL: UpdateTranslationGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
