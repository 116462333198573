import {StepElementTypeEnum} from '../graphql/types.graphql-gen';

export const contentElementTypes = [
    StepElementTypeEnum.Image,
    StepElementTypeEnum.Video,
    StepElementTypeEnum.Audio,
    StepElementTypeEnum.Text,
    StepElementTypeEnum.InputField,
    StepElementTypeEnum.SlidingPuzzle,
    StepElementTypeEnum.SelectOption,
    StepElementTypeEnum.Location,
    StepElementTypeEnum.ClueStart,
    StepElementTypeEnum.ClueEnd,
    StepElementTypeEnum.Timeout,
    StepElementTypeEnum.PauseTimer,
    StepElementTypeEnum.ContinueTimer,
    StepElementTypeEnum.SmallInfoPopup,
    StepElementTypeEnum.InventoryNewItemAlert,
    StepElementTypeEnum.RateWindow,
    StepElementTypeEnum.RateInlineElement,
    // StepElementTypeEnum.SkipStepButton,
    StepElementTypeEnum.StartGameButton,
    StepElementTypeEnum.ContinueButton,
    StepElementTypeEnum.ShareButton,
    StepElementTypeEnum.TakePhotoButton,
    StepElementTypeEnum.QrCode,
    StepElementTypeEnum.PairSentence
];

export const footerElementTypes = [
    StepElementTypeEnum.InputField,
    StepElementTypeEnum.StartGameButton,
    StepElementTypeEnum.ContinueButton,
    StepElementTypeEnum.ShareButton,
    StepElementTypeEnum.TakePhotoButton,
    // StepElementTypeEnum.SkipStepButton,
];

export const afterStepLoadedEffectTypes = [
    StepElementTypeEnum.StepSummary,
    StepElementTypeEnum.PhoneNumberInput,
    StepElementTypeEnum.TeamNameInput,
    StepElementTypeEnum.RateWindow,
];

export const beforeStepLeftEffectTypes = [
    StepElementTypeEnum.StepSummary,
    StepElementTypeEnum.PhoneNumberInput,
    StepElementTypeEnum.TeamNameInput,
    StepElementTypeEnum.RateWindow,
    StepElementTypeEnum.CompleteGame,
    StepElementTypeEnum.Timeout,
    StepElementTypeEnum.SmallInfoPopup,
    StepElementTypeEnum.PauseTimer,
    StepElementTypeEnum.ContinueTimer,
    StepElementTypeEnum.InventoryNewItemAlert,
];

export const blockingElementTypes = [
    StepElementTypeEnum.InputField,
    StepElementTypeEnum.SlidingPuzzle,
    StepElementTypeEnum.Location,
    StepElementTypeEnum.SelectOption,
    StepElementTypeEnum.ContinueButton,
    StepElementTypeEnum.StartGameButton,
    StepElementTypeEnum.QrCode,
    StepElementTypeEnum.PairSentence
];

