import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../../store/state';
import {combineLatest} from 'rxjs';
import {getAvailableMapOptionsSelector} from '../../../../../store/helperData/selectors';
import {take} from 'rxjs/operators';
import {MapOptionFragment} from '../../../../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-map-selection',
    templateUrl: './map-selection.component.html',
    styleUrls: ['./map-selection.component.scss']
})
export class MapSelectionComponent extends BaseComponent implements OnInit {


    @Input() control: UntypedFormControl;

    @Input() selectionMode: 'single'|'multiple'|'checkbox' = 'single' as const;

    selected: any = null;

    loaded = false;

    mapOptions: MapOptionFragment[];

    data: any[];

    constructor(
        private store: Store<IAppState>,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subSink.sink = this.control.statusChanges.subscribe(() => {
            if(!this.control.touched) {
                this.selected = [];
            }
        });
        this.subSink.sink = combineLatest([
            this.store.select(getAvailableMapOptionsSelector).pipe(take(1))
        ]).subscribe(([availableMapOptions]) => {
            this.mapOptions = availableMapOptions;
            this.selected = this.control.value;
            const selected: any = {id: this.selected, node: []};
            this.data = convertToTree(availableMapOptions, null, selected);
            if (selected.node) {
                this.selected = selected.node;
            }
        });
    }

    setMapId($event: any) {
        this.control.markAsTouched({onlySelf: true});
        if(this.selectionMode === 'checkbox') {
            this.control.setValue($event?.map((v: any) => v.data).filter((v: any) => !!v));
            return;
        }
        this.control.setValue($event?.data ?? null)
    }
}


const convertToTree = (options: MapOptionFragment[], parent: number | null = null, selected: { id: number | null | number[], node: any[] }) => {
    const res: any[] = [];
    const roots = options.filter((o) => o.parent === parent);
    roots.forEach((o) => {
        const object: any = {
            data: o.id,
            label: o.name,
            expandedIcon: "pi pi-map",
            collapsedIcon: "pi pi-map",
        };
        const children = options.filter((n) => n.parent === o.id);
        if (children.length) {
            object.children = convertToTree(options, o.id, selected);
        }
        if(Array.isArray(selected.id)) {
            if(selected.id.includes(o.id)) {
                selected.node.push(object);
            }
        } else if (o.id === selected.id) {
            selected.node.push(object);
        }
        res.push(object);
    });
    return res;

}
