import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GetInitialDataGQL} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {
    fetchGameGroupsDataAction,
    fetchGameGroupsDataFailAction,
    fetchGameGroupsDataSuccessAction,
    fetchInitialDataAction,
    fetchInitialDataFailAction,
    fetchInitialDataSuccessAction
} from './actions';
import {map, switchMap} from 'rxjs/operators';
import {GetGameGroupsDataGQL} from '../../graphql/queries/getGameGroupsData/getGameGroupsData.graphql-gen';

@Injectable()
export class DataEffect implements OnDestroy {


    subSink = new SubSink();

    getInitialData$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchInitialDataAction
        ),
        switchMap((a) => {
            return this.getInitialDataGQL.fetch();
        }),
        map((res) => {
            if(res.errors) {
                return fetchInitialDataFailAction({header: '', message: res.errors[0].message});
            }
            return fetchInitialDataSuccessAction({initialData: res.data});
        })
    ));

    fetchGameGroupsData$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchGameGroupsDataAction
        ),
        switchMap((a) => {
            return this.getGameGroupsDataGQL.fetch();
        }),
        map((res) => {
            if (res.errors) {
                return fetchGameGroupsDataFailAction({header: '', message: res.errors[0].message});
            }
            return fetchGameGroupsDataSuccessAction({data: res.data.getGameGroups.availableGroupOptionsMeta});
        })
    ));


    constructor(
        private actions$: Actions,
        private getInitialDataGQL: GetInitialDataGQL,
        private getGameGroupsDataGQL: GetGameGroupsDataGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
