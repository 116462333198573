import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { getSettings: (
    { __typename?: 'Settings' }
    & { appSettings: (
      { __typename?: 'AppSettings' }
      & AppSettingsFragment
    ) }
    & EmailSettingsFragment
    & GatewaySettingsFragment
    & GeneralSettingsFragment
  ) }
);

export type AppSettingsFragment = (
  { __typename?: 'AppSettings' }
  & Pick<Types.AppSettings, 'generalButtonLink'>
);

export type EmailSettingsFragment = (
  { __typename?: 'Settings' }
  & { emailSettings: (
    { __typename?: 'EmailSettings' }
    & Pick<Types.EmailSettings, 'active' | 'host' | 'username' | 'secure' | 'port'>
  ) }
);

export type GatewaySettingsFragment = (
  { __typename?: 'Settings' }
  & { gatewaySettings: (
    { __typename?: 'GatewaySettings' }
    & GopayFragment
    & SphereFragment
    & UpFragment
    & BenefitPlusFragment
    & SodexoFragment
    & BenefityFragment
    & CodFragment
  ) }
);

export type GeneralSettingsFragment = (
  { __typename?: 'Settings' }
  & { generalSettings: (
    { __typename?: 'GeneralSettings' }
    & Pick<Types.GeneralSettings, 'androidVersionUrl' | 'androidVersion' | 'iOSVersionUrl' | 'iOSVersion' | 'gaId' | 'codPurchaseEmail'>
  ) }
);

export type GopayFragment = (
  { __typename?: 'GatewaySettings' }
  & { gopay: (
    { __typename?: 'GopaySettings' }
    & Pick<Types.GopaySettings, 'testMode' | 'goId' | 'clientId' | 'clientSecret' | 'gatewayCharge' | 'gatewayChargePercent' | 'active'>
  ) }
);

export type SphereFragment = (
  { __typename?: 'GatewaySettings' }
  & { sphere: (
    { __typename?: 'SphereSettings' }
    & Pick<Types.SphereSettings, 'partnerLocationId' | 'userId' | 'posId' | 'commodityId' | 'connectorUser' | 'gatewayCharge' | 'gatewayChargePercent' | 'active' | 'gameDiscountPercent'>
  ) }
);

export type UpFragment = (
  { __typename?: 'GatewaySettings' }
  & { up: (
    { __typename?: 'UpSettings' }
    & Pick<Types.UpSettings, 'testMode' | 'merchantId' | 'gatewayCharge' | 'gatewayChargePercent' | 'active'>
  ) }
);

export type BenefitPlusFragment = (
  { __typename?: 'GatewaySettings' }
  & { benefitPlus: (
    { __typename?: 'BenefitPlusSettings' }
    & Pick<Types.BenefitPlusSettings, 'testMode' | 'eshopId' | 'gatewayCharge' | 'gatewayChargePercent' | 'active'>
  ) }
);

export type SodexoFragment = (
  { __typename?: 'GatewaySettings' }
  & { sodexo: (
    { __typename?: 'SodexoSettings' }
    & Pick<Types.SodexoSettings, 'testMode' | 'benefitId' | 'gatewayCharge' | 'gatewayChargePercent' | 'active'>
  ) }
);

export type BenefityFragment = (
  { __typename?: 'GatewaySettings' }
  & { benefity: (
    { __typename?: 'BenefitySettings' }
    & Pick<Types.BenefitySettings, 'merchantNumber' | 'username' | 'benefitGroup' | 'gatewayCharge' | 'gatewayChargePercent' | 'active'>
  ) }
);

export type CodFragment = (
  { __typename?: 'GatewaySettings' }
  & { cod: (
    { __typename?: 'CodSettings' }
    & Pick<Types.CodSettings, 'active' | 'price'>
  ) }
);

export const AppSettingsFragmentDoc = gql`
    fragment appSettings on AppSettings {
  generalButtonLink
}
    `;
export const EmailSettingsFragmentDoc = gql`
    fragment emailSettings on Settings {
  emailSettings {
    active
    host
    username
    secure
    port
  }
}
    `;
export const GopayFragmentDoc = gql`
    fragment gopay on GatewaySettings {
  gopay {
    testMode
    goId
    clientId
    clientSecret
    gatewayCharge
    gatewayChargePercent
    active
  }
}
    `;
export const SphereFragmentDoc = gql`
    fragment sphere on GatewaySettings {
  sphere {
    partnerLocationId
    userId
    posId
    commodityId
    connectorUser
    gatewayCharge
    gatewayChargePercent
    active
    gameDiscountPercent
  }
}
    `;
export const UpFragmentDoc = gql`
    fragment up on GatewaySettings {
  up {
    testMode
    merchantId
    gatewayCharge
    gatewayChargePercent
    active
  }
}
    `;
export const BenefitPlusFragmentDoc = gql`
    fragment benefitPlus on GatewaySettings {
  benefitPlus {
    testMode
    eshopId
    gatewayCharge
    gatewayChargePercent
    active
  }
}
    `;
export const SodexoFragmentDoc = gql`
    fragment sodexo on GatewaySettings {
  sodexo {
    testMode
    benefitId
    gatewayCharge
    gatewayChargePercent
    active
  }
}
    `;
export const BenefityFragmentDoc = gql`
    fragment benefity on GatewaySettings {
  benefity {
    merchantNumber
    username
    benefitGroup
    gatewayCharge
    gatewayChargePercent
    active
  }
}
    `;
export const CodFragmentDoc = gql`
    fragment cod on GatewaySettings {
  cod {
    active
    price
  }
}
    `;
export const GatewaySettingsFragmentDoc = gql`
    fragment gatewaySettings on Settings {
  gatewaySettings {
    ...gopay
    ...sphere
    ...up
    ...benefitPlus
    ...sodexo
    ...benefity
    ...cod
  }
}
    ${GopayFragmentDoc}
${SphereFragmentDoc}
${UpFragmentDoc}
${BenefitPlusFragmentDoc}
${SodexoFragmentDoc}
${BenefityFragmentDoc}
${CodFragmentDoc}`;
export const GeneralSettingsFragmentDoc = gql`
    fragment generalSettings on Settings {
  generalSettings {
    androidVersionUrl
    androidVersion
    iOSVersionUrl
    iOSVersion
    gaId
    codPurchaseEmail
  }
}
    `;
export const GetSettingsDocument = gql`
    query getSettings {
  getSettings {
    ...emailSettings
    ...gatewaySettings
    ...generalSettings
    appSettings {
      ...appSettings
    }
  }
}
    ${EmailSettingsFragmentDoc}
${GatewaySettingsFragmentDoc}
${GeneralSettingsFragmentDoc}
${AppSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingsGQL extends Apollo.Query<GetSettingsQuery, GetSettingsQueryVariables> {
    document = GetSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }