import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {MyStorageService} from '../services/my-storage.service';
import {environment} from '../../environments/environment';
import {FileHttpHeaders} from '../shared/utils/FileHttpHeaders';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    first = true;
    counter = 1;

    constructor(
        private storage: MyStorageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (([environment.graphQLOrigin, environment.fileUploadOrigin].includes(request.url)) && (request.body?.operationName !== 'Login' || request.body?.operationName !== 'Register')) {
            return from((request.body?.operationName !== 'RefreshToken') ? this.storage.get('accessTokenGQL') : this.storage.get('refreshTokenGQL'))
                .pipe(
                    switchMap(token => {
                        if (!token) {
                            token = 'NOT_SET';
                        }
                        let uploadFormData = null;
                        if(request.headers instanceof FileHttpHeaders) {
                            const headers = request.headers;
                            uploadFormData = new FormData();
                            uploadFormData.append('payload', JSON.stringify(request.body));
                            if(headers.files) {
                                for (const f of headers.files) {
                                    uploadFormData.append('files[]', f.file, f.name);
                                }
                            }
                        }
                        let newHeaders = request.headers.append('Authorization', token);
                        if (environment.SHOW_DEBUGGER) {
                            if (!this.first) {
                                newHeaders = newHeaders.append('X-Requested-With', 'XMLHttpRequest');
                                const value = environment.test + '_' + this.counter;
                                this.counter++;
                                newHeaders = newHeaders.append('x-tracy-ajax', value);
                            } else {
                                this.first = false;
                            }
                            newHeaders = newHeaders.append('show-debug-bar', 'true');
                        }
                        const modifiedRequest = request.clone({
                            headers: newHeaders,
                            body: uploadFormData ?? request.body
                        });
                        return next.handle(modifiedRequest);
                    })
                );
        }
        return next.handle(request);
    }

}


export const createFilesContext = (files: File[]) => {
    const headers = new FileHttpHeaders();
    headers.files = files.map((file) => ({file: file, name: file.name}));
    return {
        context: {
            headers
        }
    };
};
