import {AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {generateUUID} from './helpers';


export class MyFormArray<ControlType extends AbstractControl = FormControl> extends FormArray<ControlType> {
    arrayOrderChanged$ = new Subject();
}

export class MyFormGroupV2<TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
} = any> extends FormGroup<TControl> {
    meta: {
        [key: string]: any,
        highlight: boolean
    } = {
        highlight: false
    };

    uniqueId = generateUUID();

    /**
     * Call validation for all children components
     */
    validateAll() {
        validateRecursively(this);
    }
}

export class MyFormGroup<T extends AbstractControl> extends UntypedFormGroup {
    controls: {
        [key: string]: T;
    };
}

const validateRecursively = (control: AbstractControl, level = 1) => {
    if (level > 20) {
        return;
    }
    if (control instanceof UntypedFormGroup) {
        for (const c of Object.values(control.controls)) {
            validateRecursively(c, level + 1);
        }
    } else if (control instanceof UntypedFormArray) {
        for (const c of control.controls) {
            validateRecursively(c, level + 1);
        }
    } else {
        control.updateValueAndValidity();
    }
}
