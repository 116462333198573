<form [formGroup]="form" appScrollToInvalidControl (keyup.enter)="save()">
    <div class="pb-4 row pt-4 p-fluid">
        <div class="col-12">
            <label for="title" class="pb-2">
                {{'games.steps.form.title'|translate|firstUpper}}
                <app-tooltip image="game-step-title.png"></app-tooltip>
            </label>
            <app-text-editor id="title" [control]="form.controls.title"></app-text-editor>
<!--            <p-editor appQuillEditor id="title" formControlName="title"-->
<!--                      [style]="{'height':'160px'}">-->
<!--                <ng-template pTemplate="header">-->
<!--                    <app-editor-header></app-editor-header>-->
<!--                </ng-template>-->
<!--            </p-editor>-->
        </div>
        <div class="pt-4 col-12 col-md-6">
            <label for="state" class="pb-2">
                {{'games.steps.form.state'|translate|firstUpper}}*
                <app-tooltip value="{{'games.steps.form.tooltip.state'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-dropdown [options]="gameStepStateOptions" id="state" formControlName="state"
                        [filter]="false"
                        optionValue="value" optionLabel="label">
            </p-dropdown>
        </div>
        <div class="pt-4 col-6" *ngIf="form.controls.state?.value === 'inactive_range'">
            <label for="state" class="pb-2">{{'games.steps.form.inactiveFromTo'|translate|firstUpper}}</label>
            <p-calendar
                appendTo="body"
                class="{{form.controls.inactiveFromTo.touched && form.controls.inactiveFromTo.invalid ? 'ng-invalid ng-dirty' : ''}}"
                dateFormat="dd.mm.yy" hourFormat="24" [monthNavigator]="true" [yearNavigator]="true"
                [showTime]="true"
                yearRange="2015:2025" selectionMode="range"
                formControlName="inactiveFromTo"></p-calendar>
        </div>
        <div class="col-12 pt-4">
            <label for="infoTitle" class="pb-2">
                {{'games.steps.form.infoTitle'|translate|firstUpper}}
                <app-tooltip image="game-step-infoTitle.png"></app-tooltip>
            </label>
            <input id="infoTitle"
                   formControlName="infoTitle" type="text" pInputText
                   class="{{form.controls.infoTitle.touched && form.controls.infoTitle.invalid ? 'ng-invalid ng-dirty' : ''}}">
        </div>
    </div>
    <div class="pb-4 row p-fluid">
        <div class="col-12">
            <label for="info" class="pb-2">
                {{'games.steps.form.info'|translate|firstUpper}}
                <app-tooltip image="game-step-info.png"></app-tooltip>
            </label>
            <app-text-editor id="info" [control]="form.controls.info"></app-text-editor>
<!--            <p-editor appQuillEditor id="info" formControlName="info"-->
<!--                      [style]="{'height':'160px'}">-->
<!--                <ng-template pTemplate="header">-->
<!--                    <app-editor-header></app-editor-header>-->
<!--                </ng-template>-->
<!--            </p-editor>-->
        </div>
<!--        <div class="pt-4 col-12">-->
<!--            <label for="mapText" class="pb-2">Text in the map view</label>-->
<!--            <p-editor appQuillEditor id="mapText" formControlName="mapText"-->
<!--                      [style]="{'height':'160px'}">-->
<!--                <ng-template pTemplate="header">-->
<!--                    <app-editor-header></app-editor-header>-->
<!--                </ng-template>-->
<!--            </p-editor>-->
<!--        </div>-->
    </div>
</form>
<app-dialog-footer>
    <p-button
        [loading]="loading"
        [disabled]="loading"
        label="{{'general.create'|translate}}"
        styleClass="p-button-sm p-button-success"
        icon="pi pi-save"
        (onClick)="save()"
    ></p-button>
</app-dialog-footer>
