<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="label-{{uniqueId}}" class="pb-2">
            {{'elements.startGameButton.label'|translate|firstUpper}}
            <app-tooltip image="element-startGameButton-label.png"
                         value="{{'elements.tooltip.startGameButton.showConfirmPopup'|translate|firstUpper}}"></app-tooltip>
        </label>
        <input id="label-{{uniqueId}}"
               formControlName="label" type="text" pInputText
               class="{{stepElementForm.controls.settings.controls.label.touched && stepElementForm.controls.settings.controls.label.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <label for="showConfirmPopup-{{uniqueId}}" class="pb-2 col-12 pt-4">
        {{'elements.startGameButton.showConfirmPopup'|translate|firstUpper}}
        <app-tooltip image="element-startGameButton-showConfirmPopup.png"
                     value="{{'elements.tooltip.startGameButton.showConfirmPopup'|translate|firstUpper}}"></app-tooltip>
    </label>
    <div class="col-4">
        <p-selectButton id="showConfirmPopup-{{uniqueId}}" [options]="boolOptions"
                        class="on-off-select-button"
                        formControlName="showConfirmPopup" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
</div>
