import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {TakePhotoSettings} from '../element-types';
import { MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-take-photo-button',
    templateUrl: './take-photo-button.component.html',
    styleUrls: ['./take-photo-button.component.scss']
})
export class TakePhotoButtonComponent extends ElementBaseComponent<SettingsControl> implements OnInit {


    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            skipButtonDisabled: new FormControl<'0'|'1'>('1', {nonNullable: true}),
        });
    }




}


type SettingsControl = {
    skipButtonDisabled: FormControl<'0'|'1'>,
}
