import {createAction, props} from '@ngrx/store';
import {GetSettingsQuery} from '../../graphql/queries/getSettings/getSettings.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {Settings, SettingsInput} from '../../graphql/types.graphql-gen';


export const fetchSettingsAction = createAction('[Settings] FETCH_SETTINGS');
export const fetchSettingsSuccessAction = createAction('[Settings] FETCH_SETTINGS_SUCCESS', props<FetchSettingsSuccessProps>());
export const fetchSettingsFailAction = createAction('[Settings] FETCH_SETTINGS_FAIL', props<FetchSettingsFailProps>());
export const updateSettingsAction = createAction('[Settings] UPDATE_SETTINGS', props<UpdateSettingsProps>());
export const updateSettingsFailAction = createAction('[Settings] UPDATE_SETTINGS_FAIL', props<UpdateSettingsFailProps>());
export const updateSettingsSuccessAction = createAction('[Settings] UPDATE_SETTINGS_SUCCESS', props<UpdateSettingsSuccessProps>());


export type FetchSettingsSuccessProps = {settings: GetSettingsQuery};
export type FetchSettingsFailProps = DefaultFailMessage;
export type UpdateSettingsProps = {settings: SettingsInput};
export type UpdateSettingsFailProps = DefaultFailMessage;
export type UpdateSettingsSuccessProps = {settings: Settings};
