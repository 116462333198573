import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPurchaseQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetPurchaseQuery = (
  { __typename?: 'Query' }
  & { getPurchases: (
    { __typename?: 'PurchasesResult' }
    & { result: Array<(
      { __typename?: 'Purchase' }
      & FullPurchaseFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type FullPurchaseFragment = (
  { __typename?: 'Purchase' }
  & { deliveryAddress?: Types.Maybe<(
    { __typename?: 'PurchaseAddress' }
    & FullPurchaseAddressFragment
  )> }
  & PurchaseFragment
);

export type FullPurchaseAddressFragment = (
  { __typename?: 'PurchaseAddress' }
  & Pick<Types.PurchaseAddress, 'id' | 'addressType' | 'firstName' | 'lastName' | 'phone' | 'email' | 'city' | 'country' | 'street' | 'houseNumber' | 'zip' | 'note' | 'createdAt' | 'updatedAt'>
);

export const FullPurchaseAddressFragmentDoc = gql`
    fragment fullPurchaseAddress on PurchaseAddress {
  id
  addressType
  firstName
  lastName
  phone
  email
  city
  country
  street
  houseNumber
  zip
  note
  createdAt
  updatedAt
}
    `;
export const FullPurchaseFragmentDoc = gql`
    fragment fullPurchase on Purchase {
  ...purchase
  deliveryAddress {
    ...fullPurchaseAddress
  }
}
    ${PurchaseFragmentDoc}
${FullPurchaseAddressFragmentDoc}`;
export const GetPurchaseDocument = gql`
    query getPurchase($id: Int!) {
  getPurchases(filter: {id: $id}) {
    result {
      ...fullPurchase
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullPurchaseFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPurchaseGQL extends Apollo.Query<GetPurchaseQuery, GetPurchaseQueryVariables> {
    document = GetPurchaseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }