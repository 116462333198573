import {createAction, props} from '@ngrx/store';
import {SafeUrl} from '@angular/platform-browser';
import {DefaultFailMessage} from '../user/actions';
import {ProgressType} from '../types';
import {SweetAlertIcon, SweetAlertOptions} from 'sweetalert2';

export const showConfirmDialogAction = createAction('[General] SHOW_CONFIRM_DIALOG', props<ShowConfirmDialogProps>());
export const cancelConfirmDialogAction = createAction('[General] CANCEL_CONFIRM_DIALOG', props<CancelConfirmDialogProps>());
export const showAlertDialogAction = createAction('[General] SHOW_ALERT_DIALOG', props<AlertDialogActionProps>());
export const showAlertProgressAction = createAction('[General] SHOW_ALERT_PROGRESS');
export const closeModalAction = createAction('[General] CLOSE_MODAL');


export const addFileToBufferAction = createAction('[General] ADD_FILE_TO_BUFFER', props<AddFileToBufferActionProps>());
export const addFileToBufferSuccessAction = createAction('[General] ADD_FILE_TO_BUFFER_SUCCESS', props<AddFileToBufferSuccessActionProps>());
export const removeFileFromBufferAction = createAction('[General] REMOVE_FILE_FROM_BUFFER', props<RemoveFileFromBufferActionProps>());
export const clearFileBufferAction = createAction('[General] CLEAR_FILE_BUFFER');
export const readFileBufferAction = createAction('[General] READ_FILE_BUFFER');
export const readFileBufferSuccessAction = createAction('[General] READ_FILE_BUFFER_SUCCESS', props<ReadFileBufferSuccessActionProps>());
export const readFileUrlAction = createAction('[General] READ_FILE_URL', props<ReadFileUrlActionProps>());
export const readFileUrlSuccessAction = createAction('[General] READ_FILE_URL_SUCCESS', props<ReadFileUrlSuccessActionProps>());


export const uploadBufferFilesAction = createAction('[General] UPLOAD_BUFFER_FILES', props<UploadBufferFilesActionProps>());
export const uploadBufferFilesSuccessAction = createAction('[General] UPLOAD_BUFFER_FILES_SUCCESS');
export const uploadBufferFilesFailAction = createAction('[General] UPLOAD_BUFFER_FAIL', props<UploadBufferFilesFailActionProps>());
export const uploadFilesAction = createAction('[General] UPLOAD_FILES', props<UploadFilesActionProps>());
export const uploadFilesSuccessAction = createAction('[General] UPLOAD_FILES_SUCCESS');
export const uploadFilesFailAction = createAction('[General] UPLOAD_FILES_FAIL', props<UploadFilesFailActionProps>());

export type ShowConfirmDialogProps = { header: string, message: string, confirmButton: string, cancelButton: string, footer?: string, selectOptions?: SweetAlertOptions['inputOptions'], selectedValue?: string};
export type CancelConfirmDialogProps = { confirmed: boolean, selectedValue?: string};

export type AddFileToBufferActionProps = { file: File };
export type AlertDialogActionProps = { message: string; header: string; icon?: SweetAlertIcon}
export type ReadFileUrlActionProps = { filename: string };
export type ReadFileUrlSuccessActionProps = { url: string | SafeUrl };
export type ReadFileBufferSuccessActionProps = { files: File[] };
export type AddFileToBufferSuccessActionProps = { filename: string };
export type RemoveFileFromBufferActionProps = { filename: string };
export type UploadFilesActionProps = { files: File[] };
// export type UploadFilesSuccessActionProps = { success: boolean };
export type UploadFilesFailActionProps = DefaultFailMessage;
export type UploadBufferFilesFailActionProps = DefaultFailMessage;
export type UploadBufferFilesActionProps = { initialProgress: ProgressType };

