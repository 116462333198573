import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchProgressesSuccessAction, fetchProgressSuccessAction, updateProgressSuccessAction} from './action';

export const progressReducer = createImmerReducer(
    defaultDataState,
    on(fetchProgressesSuccessAction, (state: IDataState, action) => {
        if (action.result) {
            state.progresses.progresses = action.result;
        }
        return state;
    }),
    on(fetchProgressSuccessAction, (state: IDataState, action) => {
        state.progresses.activeProgress = action.progress;
        return state;
    }),
    on(updateProgressSuccessAction, (state: IDataState, action) => {
        if(action.result.removedPlayerProgressId && state.progresses.progresses?.result) {
            state.progresses.progresses.result = state.progresses.progresses.result.filter((r) => r.id !== action.result.removedPlayerProgressId);
        }
        return state;
    })
);
