import {Injectable} from '@angular/core';
import {lastValueFrom, Subject} from 'rxjs';
import {ProgressType} from '../store/types';
import {AppActions} from '../store/app-actions.service';
import {
    readFileBufferAction,
    readFileBufferSuccessAction,
    showAlertDialogAction,
    showAlertProgressAction
} from '../store/general/actions';
import {GameHelper} from '../shared/forms/game-helpers';
import {sleep} from '../shared/helpers';

@Injectable({
    providedIn: 'root'
})
export class ProgressService {

    public progress$: Subject<ProgressType> = new Subject();

    constructor(
        private appActions: AppActions
    ) {
    }

    public async showBasicProgress(data: {title: string}) {
        await lastValueFrom(this.appActions.dispatch(showAlertProgressAction()));
        this.progress$.next({forceClose: true, showProgressBar: false, uploaded: 0, steps: [], currentStep: 1, title: data.title, filesAmount: 0, showSpinner: true})
    }

    public async showSuccessDialog(data: {message: string, header: string}) {
        await lastValueFrom(this.appActions.dispatch(showAlertDialogAction({
            header: data.header,
            message: data.message,
            icon: 'success'
        })));
    }

    public async showErrorDialog(data: {message: string, header: string}) {
        await lastValueFrom(this.appActions.dispatch(showAlertDialogAction({
            header: data.header,
            message: data.message,
            icon: 'error'
        })));
    }

    public async cancelProgress() {
        this.progress$.next({forceClose: true, showProgressBar: false, uploaded: 0, steps: [], currentStep: 1, title: '', filesAmount: 0});
    }

    uploadBufferedFiles = async (params: {alertTitle: string}) => {
        await lastValueFrom(this.appActions.dispatch(showAlertProgressAction()));
        const progressSubject: Subject<ProgressType> = this.progress$;
        const files = (await lastValueFrom(this.appActions.dispatch(readFileBufferAction(), [readFileBufferSuccessAction]))).files;
        if (files.length) {
            if (!await GameHelper.uploadFiles(files, progressSubject, this.appActions)) {
                return;
            }
            await sleep(500);
        }
        const step2Progress: ProgressType = {
            filesAmount: 0,
            steps: files.length ? ['1', '2'] : ['1'],
            currentStep: files.length ? 1 : 0,
            showProgressBar: false,
            forceClose: false,
            uploaded: 0,
            title: params.alertTitle
        };
        progressSubject.next(step2Progress);
    }
}
