import {NgModule} from '@angular/core';
import {QuillEditorDirective} from './quill-editor.directive';
import { TabChangeDetectorDirective } from './tab-change-detector.directive';
import { ExtendedGalleriaDirective } from './extended-galleria.directive';


@NgModule({
    declarations: [
        QuillEditorDirective,
        TabChangeDetectorDirective,
        ExtendedGalleriaDirective
    ],
    imports: [

    ],
    exports: [
        QuillEditorDirective,
        TabChangeDetectorDirective,
        ExtendedGalleriaDirective
    ]
})
export class DirectivesModule {
}
