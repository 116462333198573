import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameStateEnum} from '../../../../../graphql/types.graphql-gen';
import {UntypedFormControl} from '@angular/forms';
import {BaseComponent} from '../../../base/base.component';
import {combineLatest} from 'rxjs';
import {getGameGroupOptionsSelector} from '../../../../../store/gameVoucher/selectors';
import {map, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../../store/state';
import * as _ from 'lodash';

@Component({
    selector: 'app-game-options',
    templateUrl: './game-options.component.html',
    styleUrls: ['./game-options.component.scss']
})
export class GameOptionsComponent extends BaseComponent implements OnInit {

    gameOptions: GameOption[];
    @Input() control: UntypedFormControl;
    @Input() multiSelect = false;
    @Input() withoutLicenses = false;
    @Input() placeholder = '';
    @Output() onChange = new EventEmitter<unknown>();
    @Input() onlyGroup = false;
    loaded = false;

    constructor(
        private store: Store<IAppState>
    ) {
        super();
    }

    ngOnInit(): void {
        this.subSink.sink = combineLatest([
            this.store.select(getGameGroupOptionsSelector).pipe(
                take(1),
                map((res) => this.withoutLicenses ? (res?.filter((gg) => !gg.license)) : res)),
        ]).subscribe((res) => {
            this.gameOptions = _.cloneDeep((res[0] ?? []).map((o) => {
                return {
                    id: o.id,
                    name: o.name + ' (' + o?.city?.name + ')',
                    photoSource: o.photoSource ?? null,
                    state: o.state,
                    flagPath: o.lang?.flagPath ?? '',
                    items: (o.games ?? []).map((g) => ({
                        id: g.id,
                        name: g.name + ' (' + o?.city?.name + ')' as string,
                        keywords: (g.name ?? '') + (o.name ?? ''),
                        difficulty: g.difficulty as number,
                        price: g.price,
                        state: g.state,
                        photoSource: o.photoSource ?? null
                    })),
                };
            }));
            this.reorderGameGroups();
            this.loaded = true;
        });
    }

    toggleGroup(group: any) {
        const currentValue = this.control.value;
        if (!_.isArray(currentValue) || !_.isArray(group.items) || !group.items.length) {
            return;
        }
        const gameIdsToAddOrRemove = group.items.map((i: any) => i.id);
        const intersection = _.intersection(currentValue, gameIdsToAddOrRemove);
        if(intersection.length === gameIdsToAddOrRemove.length) {
            this.control.setValue(_.difference(currentValue, gameIdsToAddOrRemove));
        } else {
            this.control.setValue([...currentValue, ..._.difference(gameIdsToAddOrRemove, currentValue)]);
        }
    }

    reorderGameGroups() {
        this.gameOptions.sort((a, b) => {
            let aChecked = false;
            let bChecked = false;
            if(this.onlyGroup) {
                aChecked = !this.control.value ? false : this.control.value.includes(a.id)
                bChecked = !this.control.value ? false : this.control.value.includes(b.id);
            } else {
                aChecked = !this.control.value ? false : _.intersection(this.control.value, a.items.map((i) => i.id)).length > 0;
                bChecked = !this.control.value ? false : _.intersection(this.control.value, b.items.map((i) => i.id)).length > 0;
            }
            if(aChecked && !bChecked) {
                return -1;
            }
            if(!aChecked && bChecked) {
                return 1;
            }
            if(a.state !== GameStateEnum.Hidden && b.state === GameStateEnum.Hidden) {
                return -1;
            }
            if(a.state === GameStateEnum.Hidden && b.state !== GameStateEnum.Hidden) {
                return 1;
            }
            return (a.name ?? '').localeCompare((b.name ?? ''), 'cs');
        });
    }
}


export type GameOption = {
    id: number,
    name: string,
    photoSource: string | null,
    state: string,
    flagPath: string,
    items: {
        id: number,
        name: string,
        keywords: string,
        difficulty: number,
        price: number,
        state: GameStateEnum,
        photoSource: string | null
    }[]
};
