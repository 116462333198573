import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {PlayerProgress} from '../../graphql/types.graphql-gen';
import {
    GetPlayerProgressesGQL,
    GetPlayerProgressGQL
} from '../../graphql/queries/getPlayerProgresses/getPlayerProgresses.graphql-gen';
import {
    fetchProgressAction,
    fetchProgressesAction,
    fetchProgressesFailAction,
    fetchProgressesSuccessAction,
    fetchProgressFailAction,
    fetchProgressSuccessAction, updateProgressAction, updateProgressFailAction, updateProgressSuccessAction
} from './action';
import {fixMutation} from '../../shared/helpers';
import {UpdatePlayerProgressGQL} from '../../graphql/mutations/updatePlayerProgress/updatePlayerProgress.graphql-gen';

@Injectable()
export class ProgressEffect implements OnDestroy {


    subSink = new SubSink();

    fetchProgresses$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchProgressesAction
        ),
        concatMap((action) => {
            return this.getPlayerProgressesGQL.fetch({filter: action.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchProgressesFailAction({header: '', message: res.errors[0].message});
            }
            return fetchProgressesSuccessAction({result: res.data.getManagerPlayerProgresses});
        })
    ));

    fetchProgress$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchProgressAction
        ),
        concatMap((action) => {
            return this.getPlayerProgressGQL.fetch({id: action.id.toString()});
        }),
        map((res) => {
            if (res.errors) {
                return fetchProgressFailAction({header: '', message: res.errors[0].message});
            }
            return fetchProgressSuccessAction({progress: res.data.getManagerPlayerProgresses.result[0] as PlayerProgress})
        })
    ));

    updateProgress$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateProgressAction
        ),
        concatMap((action) => {
            return fixMutation(this.updateProgressGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if (res.errors) {
                return updateProgressFailAction({header: '', message: res.errors[0].message});
            }
            return updateProgressSuccessAction({result: res.data.updateManagerPlayerProgress});
        })
    ))

    constructor(
        private actions$: Actions,
        private getPlayerProgressesGQL: GetPlayerProgressesGQL,
        private getPlayerProgressGQL: GetPlayerProgressGQL,
        private updateProgressGQL: UpdatePlayerProgressGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
