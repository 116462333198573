import {createSelector} from '@ngrx/store';
import {IAppState} from '../state';
import {Player} from '../../graphql/types.graphql-gen';

export const userStateSelector = createSelector(
    (state: IAppState) => state.user,
    (user) => user
);

export const userSelector = createSelector(
    userStateSelector,
    user => user.user as Player
);


export const preferencesSelector = createSelector(
    userStateSelector,
    user => user.preferences
);
