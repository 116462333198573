import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RefreshTokenMutationVariables = Types.Exact<{
  onlyAccessToken: Types.Scalars['Boolean'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken?: Types.Maybe<(
    { __typename?: 'RefreshTokenResult' }
    & Pick<Types.RefreshTokenResult, 'accessToken' | 'refreshToken' | 'implicitPermissions' | 'managedGameIds'>
  )> }
);

export const RefreshTokenDocument = gql`
    mutation RefreshToken($onlyAccessToken: Boolean!) {
  refreshToken(onlyAccessToken: $onlyAccessToken) {
    accessToken
    refreshToken
    implicitPermissions
    managedGameIds
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshTokenGQL extends Apollo.Mutation<RefreshTokenMutation, RefreshTokenMutationVariables> {
    document = RefreshTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }