import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-small-info-popup',
    templateUrl: './small-info-popup.component.html',
    styleUrls: ['./small-info-popup.component.scss']
})
export class SmallInfoPopupComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            title: new FormControl('', {nonNullable: true}),
            text: new FormControl('', {nonNullable: true, validators: Validators.required}),
            btnLabel: new FormControl('', {nonNullable: true, validators: Validators.required}),
        });
    }


    ngOnInit() {
        super.ngOnInit();
    }
}

type SettingsControl = { title: FormControl<string>, text: FormControl<string>, btnLabel: FormControl<string> }
