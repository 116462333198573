import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

    transform(value: string|null|undefined): any {
        return stripHtml(value);
    }

}

export const stripHtml = (value: string|null|undefined) => {
    if(value === null || value === undefined) {
        return '';
    }
    return value.replace(/<.*?>/g, ''); // replace tags
}
