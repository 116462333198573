import {createAction, props} from '@ngrx/store';

export const toggleNavCollapsedAction = createAction('[HelperData] TOGGLE_NAV_COLLAPSED');
export const saveFilterAction = createAction('[HelperData] SAVE_FILTER', props<SaveFilterActionProps>());
export type SaveFilterActionProps = {
    gameVouchers?: null|object,
    gameGroups?: null|object,
    players?: null|object,
    permissionGroups?: null|object,
    progresses?: null|object,
    licenses?: null|object,
    purchases?: null|object,
    reviews?: null|object,
    translations?: null|object,
    pushPlayers?: null|object
};
