import {createAction, props} from '@ngrx/store';
import {PageDataFilter, PageDataInput} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {GetPagesDataQuery, PageDataFragment} from '../../graphql/queries/getPagesData/getPagesData.graphql-gen';
import {UpdatePageDataMutation} from '../../graphql/mutations/updatePageData/updatePageData.graphql-gen';

export const fetchPageDataAction = createAction('[Page] FETCH_PAGE_DATA', props<FetchPageDataActionProps>());
export const fetchPageDataSuccessAction = createAction('[Page] FETCH_PAGE_DATA_SUCCESS', props<FetchPageDataSuccessActionProps>());
export const fetchPageDataFailAction = createAction('[Page] FETCH_PAGE_DATA_FAIL', props<FetchPageDataFailActionProps>());

export const updatePageDataAction = createAction('[Page] UPDATE_PAGE_DATA', props<UpdatePageDataAction>());
export const updatePageDataSuccessAction = createAction('[Page] UPDATE_PAGE_DATA_SUCCESS', props<UpdatePageDataSuccessAction>());
export const updatePageDataFailAction = createAction('[Page] UPDATE_PAGE_DATA_FAIL', props<UpdatePageDataFailAction>());


export type UpdatePageDataAction = {input: PageDataInput};
export type UpdatePageDataSuccessAction = {result: UpdatePageDataMutation['updatePageData']};
export type UpdatePageDataFailAction = DefaultFailMessage;

export type FetchPageDataActionProps = {input: PageDataFilter};
export type FetchPageDataSuccessActionProps = {result: PageDataFragment | null};
export type FetchPageDataFailActionProps = DefaultFailMessage;
