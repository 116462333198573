import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {UntypedFormControl} from '@angular/forms';
import {AttributeOptionFragment} from '../../../../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../../store/state';
import {AttributeTypeEnum} from '../../../../../graphql/types.graphql-gen';
import {combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {AttributeTreeOptionType, getAttributesTreeSelector} from '../../../../../store/attributes/selectors';
import {flattenTreeArray} from '../../../../forms/helpers';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-attribute-options',
    templateUrl: './attribute-options.component.html',
    styleUrls: ['./attribute-options.component.scss']
})
export class AttributeOptionsComponent extends BaseComponent implements OnInit {

    @Input() multiSelect = false;
    @Input() control: UntypedFormControl;

    @Input() type: AttributeTypeEnum;
    attributes: AttributeOptionFragment[];
    attributesTree: AttributeTreeOptionType[];
    selectedAttributes: AttributeTreeOptionType[] = [];
    tree = false;

    loaded = false;

    constructor(
        private store: Store<IAppState>
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.type) {
            throw new Error('type is required');
        }

        this.subSink.sink = combineLatest([this.store.select(getAttributesTreeSelector).pipe(take(1))])
            .subscribe(([attributes]) => {
                if(environment.options.allowedAttributeTreeTypes.find((a) => a === this.type)) {
                    this.attributesTree = attributes[this.type];
                    this.selectedAttributes = flattenTreeArray(attributes[this.type]).filter((a) => this.control.value.find((v: number) => v === a.id));
                    this.tree = true;
                } else {
                    this.attributes = flattenTreeArray(attributes[this.type]);
                }
                this.loaded = true;
        })

    }

    selectedAttributesChanged(selectedAttributes: AttributeTreeOptionType[]) {
        this.selectedAttributes = selectedAttributes;
        this.control.setValue(this.selectedAttributes ? this.selectedAttributes.map((a) => a.id) : []);
    }
}
