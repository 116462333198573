import {Component, Input, OnInit} from '@angular/core';
import {StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {ControlContainer} from '@angular/forms';
import {StepElementForm} from '../../../forms/types';

@Component({
    selector: 'app-step-wrapper',
    templateUrl: './step-wrapper.component.html',
    styleUrls: ['./step-wrapper.component.scss']
})
export class StepWrapperComponent implements OnInit {

    @Input() readonly = false;
    elementTypes = StepElementTypeEnum;
    form: StepElementForm<any>;
    loaded = false;

    constructor(
        private controlContainer: ControlContainer
    ) {
    }

    ngOnInit(): void {
        this.form = this.controlContainer.control as StepElementForm;
        this.loaded = true;
    }

}
