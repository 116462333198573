import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchPushNotificationAction,
    fetchPushNotificationFailAction,
    fetchPushNotificationsAction,
    fetchPushNotificationsFailAction,
    fetchPushNotificationsSuccessAction,
    fetchPushNotificationSuccessAction,
    updatePushNotificationAction,
    updatePushNotificationFailAction,
    updatePushNotificationSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {fixMutation} from '../../shared/helpers';
import {
    UpdatePushNotificationGQL
} from '../../graphql/mutations/updatePushNotification/updatePushNotification.graphql-gen';
import {
    GetPushNotificationGQL,
    GetPushNotificationsGQL
} from '../../graphql/queries/getPushNotifications/getPushNotifications.graphql-gen';

@Injectable()
export class PushNotificationEffect implements OnDestroy {


    subSink = new SubSink();

    updatePushNotification$ = createEffect(() => this.actions$.pipe(
        ofType(
            updatePushNotificationAction
        ),
        concatMap((a) => {
            return fixMutation(this.updatePushNotificationGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updatePushNotificationFailAction({header: '', message: res.errors[0].message});
            }
            return updatePushNotificationSuccessAction({res: res.data.updatePushNotification});
        })
    ));

    fetchPushNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPushNotificationsAction
        ),
        concatMap((a) => {
            return this.getPushNotificationsGQL.fetch({filter: a.input}).pipe(map((res) => ({res, originalAction: a})));
        }),
        map((res) => {
            if(res.res.errors) {
                return fetchPushNotificationsFailAction({header: '', message: res.res.errors[0].message});
            }
            return fetchPushNotificationsSuccessAction({res: res.res.data.getPushNotifications, activeType: res.originalAction.activeType});
        })
    ));

    fetchPushNotification$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPushNotificationAction
        ),
        concatMap((a) => {
            return this.getPushNotificationGQL.fetch({id: a.id.toString()});
        }),
        map((res) => {
            if(res.errors) {
                return fetchPushNotificationFailAction({header: '', message: res.errors[0].message});
            }
            if(!res.data.getPushNotifications.result[0]) {
                return fetchPushNotificationFailAction({header: '', message: 'license_not_found'});
            }
            return fetchPushNotificationSuccessAction({pushNotification: res.data.getPushNotifications.result[0]});
        })
    ));

    constructor(
        private actions$: Actions,
        private updatePushNotificationGQL: UpdatePushNotificationGQL,
        private getPushNotificationGQL: GetPushNotificationGQL,
        private getPushNotificationsGQL: GetPushNotificationsGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
