import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PanelModule} from 'primeng/panel';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {CardModule} from 'primeng/card';
import {BadgeModule} from 'primeng/badge';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {RippleModule} from 'primeng/ripple';
import {TooltipModule} from 'primeng/tooltip';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DividerModule} from 'primeng/divider';
import {PasswordModule} from 'primeng/password';
import {ToastModule} from 'primeng/toast';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CheckboxModule} from 'primeng/checkbox';
import {TagModule} from 'primeng/tag';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {EditorModule} from 'primeng/editor';
import {ChipsModule} from 'primeng/chips';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InputNumberModule} from 'primeng/inputnumber';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {OrderListModule} from 'primeng/orderlist';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {ProgressBarModule} from 'primeng/progressbar';
import {SkeletonModule} from 'primeng/skeleton';
import {ImageModule} from 'primeng/image';
import {SplitButtonModule} from 'primeng/splitbutton';
import {DialogModule} from 'primeng/dialog';
import {TreeSelectModule} from 'primeng/treeselect';
import {ChipModule} from 'primeng/chip';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        InputTextModule,
        DropdownModule,
        TooltipModule,
        DynamicDialogModule,
        EditorModule,
        ToastModule,
        ChipsModule,
        ChipModule,
        FileUploadModule,
        GalleriaModule,
        InputNumberModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        OrderListModule,
        MenuModule,
        FieldsetModule,
        PanelModule,
        CalendarModule,
        ImageModule,
        DialogModule
    ],
    exports: [
        PanelModule,
        MenuModule,
        ButtonModule,
        TableModule,
        CardModule,
        BadgeModule,
        CalendarModule,
        MultiSelectModule,
        InputTextModule,
        RippleModule,
        TooltipModule,
        TabMenuModule,
        TabViewModule,
        DropdownModule,
        InputSwitchModule,
        SelectButtonModule,
        DividerModule,
        PasswordModule,
        ToastModule,
        MessagesModule,
        MessageModule,
        CheckboxModule,
        TagModule,
        DynamicDialogModule,
        DialogModule,
        EditorModule,
        ChipsModule,
        ChipModule,
        FileUploadModule,
        GalleriaModule,
        InputNumberModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        OrderListModule,
        AccordionModule,
        FieldsetModule,
        SkeletonModule,
        ImageModule,
        TreeSelectModule,
        SplitButtonModule
    ]
})
export class PrimeModule {
}
