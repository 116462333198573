import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UpdateAttributeGQL} from '../../graphql/mutations/updateAttribute/updateAttribute.graphql-gen';
import {GetAttributeGQL, GetAttributesGQL} from '../../graphql/queries/getAttributes/getAttributes.graphql-gen';
import {
    fetchAttributeAction, fetchAttributeFailAction,
    fetchAttributesAction,
    fetchAttributesFailAction,
    fetchAttributesSuccessAction, fetchAttributeSuccessAction,
    updateAttributeAction, updateAttributeFailAction, updateAttributeSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class AttributeEffect implements OnDestroy {


    subSink = new SubSink();

    fetchAttributes$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchAttributesAction
        ),
        concatMap((a) => {
            return this.getAttributesGQL.fetch({filter: a.input});
        }),
        map((res) => {
            if(res.errors) {
                return fetchAttributesFailAction({header: '', message: res.errors[0].message});
            }
            return fetchAttributesSuccessAction({res: res.data.getAttributes});
        })
    ));

    fetchAttribute$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchAttributeAction
        ),
        concatMap((a) => {
            return this.getAttributeGQL.fetch({id: a.id.toString()});
        }),
        map((res) => {
            if(res.errors) {
                return fetchAttributeFailAction({header: '', message: res.errors[0].message});
            }
            if(!res.data.getAttributes.result[0]) {
                return fetchAttributeFailAction({header: '', message: 'license_not_found'});
            }
            return fetchAttributeSuccessAction({attribute: res.data.getAttributes.result[0]});
        })
    ));

    updateAttribute$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateAttributeAction
        ),
        concatMap((a) => {
            return fixMutation(this.updateAttributeGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateAttributeFailAction({header: '', message: res.errors[0].message});
            }
            return updateAttributeSuccessAction({res: res.data.updateAttribute});
        })
    ));

    constructor(
        private actions$: Actions,
        private updateAttributeGQL: UpdateAttributeGQL,
        private getAttributesGQL: GetAttributesGQL,
        private getAttributeGQL: GetAttributeGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
