import {Component, OnInit} from '@angular/core';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {AppActions} from '../../../../store/app-actions.service';
import {
    updateSaleSourceAction,
    updateSaleSourceFailAction,
    updateSaleSourceSuccessAction
} from '../../../../store/saleSource/actions';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-new-sale-source',
    templateUrl: './new-sale-source.component.html',
    styleUrls: ['./new-sale-source.component.scss']
})
export class NewSaleSourceComponent implements OnInit {

    loaded = false;
    loading = {
        form: false
    };
    form: MyFormGroupV2<{
        name: FormControl<string>
    }>;

    constructor(
        private appActions: AppActions,
        private messageService: MessageService,
        public ref: DynamicDialogRef,
    ) {
    }

    ngOnInit(): void {
        this.form = new MyFormGroupV2({
            name: new UntypedFormControl('', Validators.required)
        });
        this.loaded = true;
    }

    async createSaleSource() {
        this.loading.form = true;
        const action = await lastValueFrom(this.appActions.dispatch(updateSaleSourceAction({
            input: {
                updateSaleSource: {
                    name: this.form.getRawValue().name,
                    identifiers: [],
                    isForFree: false
                }
            }
        }), [updateSaleSourceFailAction, updateSaleSourceSuccessAction]));
        if (action.type === updateSaleSourceFailAction.type) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: action.message});
            this.loading.form = false;
            return;
        }
        this.ref.close();
    }

}
