<ng-template [ngIf]="loaded">
    <div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
        <div class="col-12">
            <label for="title-{{uniqueId}}" class="pb-2">
                {{'elements.inventoryNewItem.title'|translate|firstUpper}}
                <app-tooltip image="element-inventoryNewItem-title.png"
                             value="{{'elements.tooltip.inventoryNewItem.title'|translate|firstUpper}}"></app-tooltip>
            </label>
            <input id="title-{{uniqueId}}"
                   formControlName="title" type="text" pInputText
                   class="{{stepElementForm.get('settings.title')?.touched && stepElementForm.get('settings.title')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
        </div>
        <div class="col-12">
            <label for="text-{{uniqueId}}" class="pb-2">
                {{'elements.inventoryNewItem.text'|translate|firstUpper}}
                <app-tooltip image="element-inventoryNewItem-text.png"
                             value="{{'elements.tooltip.inventoryNewItem.text'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-text-editor id="text-{{uniqueId}}" [control]="stepElementForm.controls.settings.controls.text"></app-text-editor>
<!--            <p-editor appQuillEditor id="text-{{uniqueId}}" formControlName="text"-->
<!--                      [style]="{'height':'250px'}">-->
<!--                <ng-template pTemplate="header">-->
<!--                    <app-editor-header></app-editor-header>-->
<!--                </ng-template>-->
<!--            </p-editor>-->
        </div>
        <div class="col-12">
            <label for="title-{{uniqueId}}" class="pb-2">
                {{'elements.inventoryNewItem.selectedItems'|translate|firstUpper}}
                <app-tooltip image="element-inventoryNewItem-selectedItems.gif"
                             value="{{'elements.tooltip.inventoryNewItem.selectedItems'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-inventory-item-selector [inventoryItems]="game.inventoryItems" [selectedItemIds]="selectedItems" (selectedItemChangedEvent)="selectedItemsChanged($event)"></app-inventory-item-selector>
        </div>
        <div class="col-12">
            <app-error-list [errors]="stepElementForm.controls.settings.controls.assignedItems.errors"></app-error-list>
        </div>
    </div>
</ng-template>
