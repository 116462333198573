import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {RateWindowSettings} from '../element-types';
import {MyFormGroupV2} from '../../../forms/forms';

@Component({
    selector: 'app-rate-window',
    templateUrl: './rate-window.component.html',
    styleUrls: ['./rate-window.component.scss']
})
export class RateWindowComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({});
    }

}

type SettingsControl = {}
