import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchCitiesSuccessAction, fetchCitySuccessAction, updateCitySuccessAction} from './actions';
import {sortArrayByKey} from '../saleSource/reducer';

export const cityReducer = createImmerReducer(
    defaultDataState,
    on(fetchCitiesSuccessAction, (state: IDataState, action: ReturnType<typeof fetchCitiesSuccessAction>) => {
        state.city.cities = action.res;
        return state;
    }),
    on(fetchCitySuccessAction, (state: IDataState, action: ReturnType<typeof fetchCitySuccessAction>) => {
        state.city.activeCity = action.city;
        return state;
    }),
    on(updateCitySuccessAction,(state: IDataState, action: ReturnType<typeof updateCitySuccessAction>) => {
        if(action.res.city && state.city.activeCity?.id === action.res.city.id) {
            state.city.activeCity = action.res.city;
        }
        if(action.res.changeOrder && state.city.cities?.result) {
            state.city.cities.result.forEach((city) => {
                city.order = (action.res.changeOrder?.find((o) => o.entityId === city.id)?.sortId ?? city.order);
            });
            state.city.cities.result = state.city.cities.result.sort(sortArrayByKey('ordering'))
        }
        if(action.res.removeCityId && state.city.cities?.result) {
            state.city.cities.result = state.city.cities.result.filter((a) => a.id !== action.res.removeCityId);
        }
        return state;
    }),
);

