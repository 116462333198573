<p-fileUpload
    #fileUpload
    *ngIf="multiple"
    [disabled]="disabled || this.readonly"
    [multiple]="multiple"
    [customUpload]="true"
    [auto]="true"
    (uploadHandler)="upload($event)"
    (onProgress)="updateProgress($event)"
    accept="{{accept}}"
    chooseLabel="Nahrát obrázky">
</p-fileUpload>


<div class="mt-2"  *ngIf="!multiple">
    <video #video *ngIf="file.url && type === 'video'" width="220" controls>
        <source [src]="file.url">
    </video>
    <audio #audio *ngIf="file.url && type === 'audio'" controls>
        <source [src]="file.url" type="audio/mpeg">
    </audio>
    <img class="mb-2" *ngIf="file.url && type === 'image'" style="width: 208px" [src]="file.url" alt="">
    <div *ngIf="file.url && type === 'zip'" class="mb-2 mt-3 ml-2">
        <i class="pi pi-file"></i>
        {{this.lastFilename}}
    </div>
    <div>
        <button *ngIf="isString(file.fullUrl ?? file.url)" (click)="download(file.fullUrl ?? file.url)"
                label="{{'gameVoucher.download'|translate|firstUpper}}"
                pButton pRipple class="p-button-success p-button-text inline-block" style="width: inherit">

        </button>
        <button [disabled]="file.loading || this.readonly" *ngIf="file.url"
                (click)="removeSingle()"
                pButton
                pRipple
                [loading]="file.loading && loading.remove"
                type="button"
                label="{{'general.remove'|translate|firstUpper}}"
                class="p-button-danger p-button-text inline-block"
                style="width: inherit">

        </button>
        <p-fileUpload
            #fileUpload
            [disabled]="file.loading || this.readonly"
            class=""
            styleClass="p-button-text"
            id="groupPhotoSource"
            accept="{{accept}}"
            [customUpload]="true"
            mode="advanced"
            chooseLabel="Nahrát"
            [auto]="true"
            [chooseIcon]="file.loading && loading.upload ? 'pi pi-spinner pi-spin' : 'pi pi-plus'"
            (uploadHandler)="uploadSingle($event)">
            <ng-template pTemplate="content">
                <div class="text-center drag-drop"><i class="pi pi-upload"></i> </div>
            </ng-template>
        </p-fileUpload>
    </div>
</div>
