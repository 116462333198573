import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {PauseTimerSettings} from '../element-types';
import {MyFormGroupV2} from '../../../forms/forms';

@Component({
    selector: 'app-pause-timer',
    templateUrl: './pause-timer.component.html',
    styleUrls: ['./pause-timer.component.scss']
})
export class PauseTimerComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({});
    }

}

type SettingsControl = {}
