import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoginMutationVariables = Types.Exact<{
  loginCredentials: Types.LoginCredentialsInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<Types.LoginResult, 'accessToken' | 'refreshToken'>
    & LoginPlayerFragmentFragment
  ) }
);

export type LoginPlayerFragmentFragment = (
  { __typename?: 'LoginResult' }
  & { player: (
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'fullname' | 'email' | 'isEditor' | 'role' | 'language' | 'implicitPermissions' | 'managedGameIds' | 'pushToken'>
    & { license?: Types.Maybe<(
      { __typename?: 'License' }
      & Pick<Types.License, 'id' | 'name'>
    )> }
    & LangFragmentFragment
  ) }
);

export type LangFragmentFragment = (
  { __typename?: 'Player' }
  & { lang?: Types.Maybe<(
    { __typename?: 'Lang' }
    & Pick<Types.Lang, 'id' | 'active' | 'name' | 'urlCode' | 'code'>
  )> }
);

export const LangFragmentFragmentDoc = gql`
    fragment langFragment on Player {
  lang {
    id
    active
    name
    urlCode
    code
  }
}
    `;
export const LoginPlayerFragmentFragmentDoc = gql`
    fragment loginPlayerFragment on LoginResult {
  player {
    id
    fullname
    email
    isEditor
    role
    language
    ...langFragment
    implicitPermissions
    managedGameIds
    pushToken
    license {
      id
      name
    }
  }
}
    ${LangFragmentFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($loginCredentials: loginCredentialsInput!) {
  login(loginCredentials: $loginCredentials) {
    accessToken
    refreshToken
    ...loginPlayerFragment
  }
}
    ${LoginPlayerFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }