import {
    FileInputHelper,
    Game, GameStateEnum,
    GameStep,
    GameStepStateEnum,
    LocationEnum,
    StepElement,
    StepElementInput,
    StepElementTypeEnum, UpdateGameStepInput
} from '../../graphql/types.graphql-gen';
import {StepBasicSettingsFormType, StepElementForm, StepFormType} from './types';
import {createGameStepClueFormGroup, generateUUID, mapStepElementsToInputType} from './helpers';
import {StepLocation} from './game-validator';
import {MyFormArray, MyFormGroupV2} from './forms';
import {AbstractControl, FormControl, UntypedFormArray, UntypedFormControl, Validators} from '@angular/forms';
import {convertClueToInputType, getStepElementFormGroup, getStepElementValidators} from '../helpers';
import {CustomValidatorsService} from './custom-validators.service';
import * as _ from 'lodash';
import {lastValueFrom, Subject} from 'rxjs';
import {ProgressType} from '../../store/types';
import {
    showAlertDialogAction,
    uploadBufferFilesAction,
    uploadBufferFilesFailAction,
    uploadBufferFilesSuccessAction
} from '../../store/general/actions';
import {AppActions} from '../../store/app-actions.service';
import {GameFragment, GameStepFragment} from '../../graphql/mutations/updateGame/updateGame.graphql-gen';


export class GameHelper {
    static elementsWithoutSettings: StepElementTypeEnum[] = [StepElementTypeEnum.ClueEnd, StepElementTypeEnum.PauseTimer, StepElementTypeEnum.RateWindow, StepElementTypeEnum.ContinueTimer];
    static getAllStepElements = (step: GameStepFragment): StepElement[] => {
        return [...(step.contentElements ?? []), ...(step.footerElements ?? []), ...(step.beforeStepLeftElements ?? []), ...(step.afterStepLoadedElements ?? [])] as StepElement[];
    }

    static isStepActive = (step: GameStepFragment): boolean => {
        if (step.state === GameStepStateEnum.Inactive) {
            return false;
        }
        if (step.state === GameStepStateEnum.InactiveRange) {
            const current = new Date();
            if (!step.inactiveFrom || !step.inactiveTo) {
                return true;
            }
            return !(current >= new Date(step.inactiveFrom) && current <= new Date(step.inactiveTo));
        }
        return true;
    }

    static getAllGameSteps = (game?: GameFragment, stepForms?: StepFormType[]): (StepElementInput[][]) => {
        const steps: StepElementInput[][] = [];
        if(game && stepForms || !game && !stepForms) {
            throw new Error('')
        }
        if(game) {
            game.gameSteps.forEach((gs) => {
                steps.push([
                    ...mapStepElementsToInputType(gs.afterStepLoadedElements),
                    ...mapStepElementsToInputType(gs.contentElements),
                    ...mapStepElementsToInputType(gs.footerElements),
                    ...mapStepElementsToInputType(gs.beforeStepLeftElements),
                ]);
            });
        }
        if(stepForms) {
            stepForms.forEach((sf) => {
                steps.push(this.getAllStepFormElements(sf, 'values'));
            })
        }

        return steps;
    }

    static getAllGameStepElements: {
        (type: 'values', game?: GameFragment, form?: StepFormType|StepFormType[]): (StepElementInput)[],
        // @ts-ignore
        (type: 'controls', game?: undefined, form: StepFormType[]): StepElementForm[],
        (type: 'controls', game?: GameFragment, form?: StepFormType|StepFormType[]): (StepElement | StepElementForm)[],
    } = (type: 'controls' | 'values', game?: GameFragment, form?: StepFormType|StepFormType[]): any => {
        let allElements: (StepElementForm | StepElementInput)[] = [];
        if(Array.isArray(form)) {
            form.forEach((stepForm) => {
                allElements.push(...this.getAllStepFormElements(stepForm, type));
            })
        } else if(game) {
            const formControlIds = form ? [form.controls.id.value] : [];
            game.gameSteps.filter((gs) => GameHelper.isStepActive(gs)).forEach((gs) => {
                if (form && formControlIds.includes(gs.id)) {
                    allElements.push(...this.getAllStepFormElements(form, type))
                    return;
                }
                allElements.push(...mapStepElementsToInputType(gs.contentElements));
                allElements.push(...mapStepElementsToInputType(gs.afterStepLoadedElements));
                allElements.push(...mapStepElementsToInputType(gs.beforeStepLeftElements));
                allElements.push(...mapStepElementsToInputType(gs.footerElements));
            });
        } else {
            throw new Error('you have to pass array of stepForms or game');
        }
        return allElements;
    }

    static getAllStepFormElements: {
        (form: StepFormType, type: 'values'): StepElementInput[],
        (form: StepFormType, type: 'controls'): StepElementForm[],
        (form: StepFormType, type: 'controls'|'values'): (StepElementForm[]|StepElementInput[]),
    } = (form: StepFormType, type: 'controls' | 'values' = 'values'): any => {
        return [
            ...form.controls.stepElements.controls.afterStepLoadedElements[type === 'controls' ? 'controls' : 'value'],
            ...form.controls.stepElements.controls.contentElements[type === 'controls' ? 'controls' : 'value'],
            ...form.controls.stepElements.controls.beforeStepLeftElements[type === 'controls' ? 'controls' : 'value'],
            ...form.controls.stepElements.controls.footerElements[type === 'controls' ? 'controls' : 'value']
        ];
    }


    static getStepElementInputId = (stepElement: { id?: number | null, tempId?: string }): string | number | undefined => {
        if (stepElement.id) {
            return stepElement.id;
        }
        // @ts-ignore
        if (stepElement.tempId) {
            // @ts-ignore
            return stepElement.tempId;
        }
        return undefined;
    }

    static addStepElement(form: StepFormType, location: StepLocation, type: StepElementTypeEnum, game: GameFragment, element?: StepElement) {
        const l: LocationEnum =
            location === 'afterStepLoadedElements' ? LocationEnum.AfterStepLoadedElements
                : location === 'beforeStepLeftElements' ? LocationEnum.BeforeStepLeftElements
                    : location === 'contentElements' ? LocationEnum.ContentElements
                        : LocationEnum.FooterElements;
        const group: StepElementForm = new MyFormGroupV2({
            id: new FormControl<number | null>(null),
            orderIndex: new FormControl<any>(null, {nonNullable: true}),
            active: new FormControl(true, {nonNullable: true}),
            location: new FormControl<LocationEnum>(l, {nonNullable: true}),
            type: new FormControl(type, {nonNullable: true}),
            gameStepClues: new MyFormArray(element ? element.gameStepClues.map((gsc) => createGameStepClueFormGroup(convertClueToInputType(gsc))) : []),
            tempId: new FormControl<string>(generateUUID(), {nonNullable: true}),
            settings: new MyFormGroupV2({}),
        });
        group.setParent((form.get('stepElements.' + location) as MyFormArray));
        group.removeControl('settings');
        group.addControl('settings', getStepElementFormGroup(type, game, element));
        const customValidator = getStepElementValidators(type, game, element);
        if (customValidator) {
            group.addValidators(customValidator);
        }
        if (element) {
            group.patchValue({
                ...element,
                active: element.active ?? false,
                location: element.location ?? l,
                gameStepClues: undefined
            });
        }
        (form.get('stepElements.' + location) as UntypedFormArray).push(group);
        // // otherwise ERROR Error: NG0100
        group.validateAll();
    }



    static getStepBasicSettingsForm: () => StepBasicSettingsFormType = () => {
        const bs: StepBasicSettingsFormType = new MyFormGroupV2({
            state: new FormControl<GameStepStateEnum>(GameStepStateEnum.Active, {validators: Validators.compose([Validators.required, CustomValidatorsService.requiredIf(['this.inactiveFromTo'], GameStepStateEnum.InactiveRange)]), nonNullable: true}),
            inactiveFromTo: new FormControl<Date[] | null>(null),
            title: new FormControl('', {nonNullable: true}),
            info: new FormControl('', {nonNullable: true}),
            infoTitle: new FormControl('', {nonNullable: true}),
            internalNote: new FormControl('', {nonNullable: true}),
            mapText: new FormControl('', {nonNullable: true}),
            mapPhotoSource: new FormControl<undefined | null | FileInputHelper>(undefined),
            parentId: new FormControl<number|null>(null, {nonNullable: true}),
        });
        bs.patchValue({
            mapPhotoSource: undefined
        })
        return bs;
    };

    static getStepSettingsForm: (game: GameFragment, gameStep?: GameStepFragment) => StepFormType = (game: GameFragment, gameStep?: GameStepFragment) => {
        const form: StepFormType = new MyFormGroupV2({
            id: new FormControl<number | null>(null, Validators.required),
            basicSettings: GameHelper.getStepBasicSettingsForm(),
            stepElements: new MyFormGroupV2({
                contentElements: new MyFormArray<StepElementForm>([]),
                footerElements: new MyFormArray<StepElementForm>([]),
                beforeStepLeftElements: new MyFormArray<StepElementForm>([]),
                afterStepLoadedElements: new MyFormArray<StepElementForm>([])
            }),
            tempId: new FormControl(generateUUID(), {nonNullable: true})
        });
        if (gameStep) {
            gameStep.contentElements.forEach((e) => GameHelper.addStepElement(form, 'contentElements', e.type, game, e));
            gameStep.footerElements.forEach((e) => GameHelper.addStepElement(form, 'footerElements', e.type, game, e));
            gameStep.afterStepLoadedElements.forEach((e) => GameHelper.addStepElement(form, 'afterStepLoadedElements', e.type, game, e));
            gameStep.beforeStepLeftElements.forEach((e) => GameHelper.addStepElement(form, 'beforeStepLeftElements', e.type, game, e));
            const inactiveFromTo: Date[] = [];
            gameStep.inactiveFrom ? inactiveFromTo.push(new Date(gameStep.inactiveFrom)) : '';
            gameStep.inactiveTo ? inactiveFromTo.push(new Date(gameStep.inactiveTo)) : '';
            form.patchValue({
                id: gameStep.id,
                basicSettings: {
                    state: (gameStep.state ?? undefined) as GameStepStateEnum|undefined,
                    inactiveFromTo: inactiveFromTo.length ? inactiveFromTo : null,
                    title: gameStep.title ?? undefined,
                    info: gameStep.info ?? undefined,
                    infoTitle: gameStep.infoTitle ?? undefined,
                    internalNote: gameStep.internalNote ?? undefined,
                    mapText: gameStep.mapText ?? undefined,
                    parentId: gameStep.parent?.id
                },
            });
        }

        return form;
    };

    static getZedaStepSettingsForm = (game: GameFragment, middleStep: StepElementTypeEnum): StepFormType => {
        const form = this.getStepSettingsForm(game);
        form.controls.id.setValidators(null);
        this.addStepElement(form, 'contentElements', StepElementTypeEnum.Image, game);
        this.addStepElement(form, 'contentElements', StepElementTypeEnum.Text, game);
        this.addStepElement(form, 'contentElements', middleStep, game);
        this.addStepElement(form, 'contentElements', StepElementTypeEnum.Text, game);
        this.addStepElement(form, 'contentElements', StepElementTypeEnum.Location, game);
        return form;
    }

    static getAvailableTakePhotoButtonIds<SettingsControls extends {
        [key: string]: AbstractControl;
    }>(stepElementForm: StepElementForm<SettingsControls>, stepForms: StepFormType[]|StepFormType, game?: GameFragment) {
        // if(!Array.isArray(stepForms)) {
        //     stepForms = [stepForms];
        // }
        const allStepElements = this.getAllGameStepElements('controls', game, stepForms);
        const stepElementId = stepElementForm.value.id ?? stepElementForm.value.tempId;
        const index = allStepElements.findIndex((el) => (el instanceof AbstractControl) ? el.controls.tempId.value === stepElementForm.controls.tempId.value : el.id === stepElementId)
        return allStepElements
            .slice(0, index)
            .filter((el) => ((el instanceof AbstractControl) ? el.value.type : el.type) === StepElementTypeEnum.TakePhotoButton)
            .map((el) => (el instanceof AbstractControl) ? (el.value.id ?? el.value.tempId) : el.id)
            .map((id) => id?.toString() ?? '');
    }

    static fillUpdateGameStepInput = (data: UpdateGameStepInput, form: StepFormType): UpdateGameStepInput => {
        // @ts-ignore
        if (Array.isArray(data.inactiveFromTo)) {
            // @ts-ignore
            data.inactiveFrom = data.inactiveFromTo[0] ?? data.inactiveFromTo[1];
            // @ts-ignore
            data.inactiveTo = data.inactiveFromTo[1] ?? data.inactiveFromTo[0];
        }
        if (data.state !== GameStepStateEnum.InactiveRange) {
            data.inactiveTo = null;
            data.inactiveFrom = null;
        }
        // @ts-ignore
        delete data.inactiveFromTo;
        const allStepElements: StepElementInput[] = [];
        if (form.controls.stepElements.controls.afterStepLoadedElements.touched) {
            const test = _.cloneDeep(form.controls.stepElements.controls.afterStepLoadedElements.getRawValue());
            data.afterStepLoadedElements = _.cloneDeep(form.controls.stepElements.controls.afterStepLoadedElements.getRawValue());
            allStepElements.push(...data.afterStepLoadedElements!);
        }
        if (form.controls.stepElements.controls.contentElements.touched) {
            data.contentElements = _.cloneDeep(form.controls.stepElements.controls.contentElements.getRawValue());
            allStepElements.push(...data.contentElements!);
        }
        if (form.controls.stepElements.controls.beforeStepLeftElements.touched) {
            data.beforeStepLeftElements = _.cloneDeep(form.controls.stepElements.controls.beforeStepLeftElements.getRawValue());
            allStepElements.push(...data.beforeStepLeftElements!);
        }
        if (form.controls.stepElements.controls.footerElements.touched) {
            data.footerElements = _.cloneDeep(form.controls.stepElements.controls.footerElements.getRawValue());
            allStepElements.push(...data.footerElements!);
        }
        let index = 0;
        let lastLocation: LocationEnum | null | undefined = null;
        allStepElements.forEach((se) => {
            if (lastLocation !== se.location) {
                index = 0;
                lastLocation = se.location;
            }
            se.orderIndex = index;
            index++;
            // @ts-ignore
            delete se.tempId;
        });
        return data;
    }

    static async uploadFiles(files: File[], progressSubject: Subject<ProgressType>, appActions: AppActions): Promise<boolean> {
        const uploadResAction = await lastValueFrom(appActions.dispatch(uploadBufferFilesAction({
                initialProgress: {
                    filesAmount: files.length,
                    currentStep: 0,
                    showProgressBar: true,
                    uploaded: 0,
                    steps: ['1', '2'],
                    forceClose: false,
                    title: 'games.steps.uploadingFiles'
                }
            }),
            [uploadBufferFilesSuccessAction, uploadBufferFilesFailAction]));
        if (uploadResAction.type === uploadBufferFilesFailAction.type) {
            progressSubject.next({
                filesAmount: 0,
                steps: ['1'],
                currentStep: 0,
                showProgressBar: false,
                forceClose: true,
                uploaded: 0,
                title: ''
            });
            await lastValueFrom(appActions.dispatch(showAlertDialogAction({
                header: 'Error',
                message: uploadResAction.message
            })));
            return false;
        }
        return true;
    }

}

