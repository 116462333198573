import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchPurchasesSuccessAction, fetchPurchaseSuccessAction, updatePurchaseSuccessAction} from './actions';

export const purchaseReducer = createImmerReducer(
    defaultDataState,
    on(fetchPurchasesSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPurchasesSuccessAction>) => {
        if (action.purchases) {
            state.purchase.purchases = action.purchases.getPurchases;
        }
        return state;
    }),
    on(fetchPurchaseSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPurchaseSuccessAction>) => {
        state.purchase.activePurchase = action.purchase;
        return state;
    }),
    on(updatePurchaseSuccessAction, (state: IDataState, action: ReturnType<typeof updatePurchaseSuccessAction>) => {
        if (action.result.purchase) {
            state.purchase.activePurchase = action.result.purchase;
        }
        if(action.result.purchaseRemoved) {
            if(state.purchase.activePurchase?.id === action.result.purchaseRemoved) {
                state.purchase.activePurchase = null;
            }
            if(state.purchase.purchases) {
                state.purchase.purchases.result = state.purchase.purchases.result.filter((p) => p.id !== action.result.purchaseRemoved);
            }
        }
        return state;
    })
);
