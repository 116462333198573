import {createAction, props} from '@ngrx/store';
import {AttributeFilter, AttributeInput} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {UpdateAttributeMutation} from '../../graphql/mutations/updateAttribute/updateAttribute.graphql-gen';
import {
    AttributeFragment,
    FullAttributeFragment,
    GetAttributesQuery
} from '../../graphql/queries/getAttributes/getAttributes.graphql-gen';

export const fetchAttributesAction = createAction('[Attribute] LOAD_ATTRIBUTES', props<FetchAttributesActionProps>());
export const fetchAttributesSuccessAction = createAction('[Attribute] LOAD_ATTRIBUTES_SUCCESS', props<FetchAttributesSuccessActionProps>());
export const fetchAttributesFailAction = createAction('[Attribute] LOAD_ATTRIBUTES_FAIL', props<FetchAttributesFailActionProps>());

export const updateAttributeAction = createAction('[Attribute] UPDATE_ATTRIBUTE', props<UpdateAttributeActionProps>());
export const updateAttributeSuccessAction = createAction('[Attribute] UPDATE_ATTRIBUTE_SUCCESS', props<UpdateAttributeSuccessActionProps>());
export const updateAttributeFailAction = createAction('[Attribute] UPDATE_ATTRIBUTE_FAIL', props<UpdateAttributeFailActionProps>());

export const fetchAttributeAction = createAction('[Attribute] LOAD_ATTRIBUTE', props<{id: number}>());
export const fetchAttributeSuccessAction = createAction('[Attribute] LOAD_ATTRIBUTE_SUCCESS', props<{attribute: FullAttributeFragment|null}>());
export const fetchAttributeFailAction = createAction('[Attribute] LOAD_ATTRIBUTE_FAIL', props<DefaultFailMessage>());

export type FetchAttributesActionProps = {input: AttributeFilter}
export type FetchAttributesSuccessActionProps = {res: GetAttributesQuery['getAttributes']}
export type FetchAttributesFailActionProps = DefaultFailMessage;

export type UpdateAttributeActionProps = {input: AttributeInput};
export type UpdateAttributeSuccessActionProps = {res: UpdateAttributeMutation['updateAttribute']};
export type UpdateAttributeFailActionProps = DefaultFailMessage;
