import {Action, ActionCreator, Creator, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {asap, Observable, Subject} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {IAppState} from './state';
import {Actions} from '@ngrx/effects';
import {Mutex} from 'async-mutex';
import { asapScheduler } from 'rxjs';


@Injectable(
    {providedIn: 'root'}
)
export class AppActions {
    actions$ = new Subject<Action>();
    private mutexes: { [key: string]: Mutex } = {};

    constructor(
        private store: Store<IAppState>,
        private actions: Actions
    ) {
        this.actions.pipe(tap((a) => {
            this.actions$.next(a);
        })).subscribe();
    }

    ofType<AC extends ActionCreator<string, Creator>, V = ReturnType<AC>>(type: AC): Observable<V>;
    ofType<AC extends ActionCreator<string, Creator>[], V = ReturnType<AC[number]>>(type: AC): Observable<V>;
    /**
     * WARNING!!! call this method before you dispatch new Action
     * returns first one
     */
    ofType<AC extends ActionCreator<string, Creator>>(type: string | string[] | AC | AC[] | (AC | string)[]): Observable<Action | AC> {
        let types: string[] = [];
        if (typeof type === 'string') {
            types = [type];
        } else if (!Array.isArray(type)) {
            types = [type.type];
        } else {
            type.forEach((t: string | AC) => {
                if (typeof t === 'string') {
                    types.push(t);
                } else {
                    types.push(t.type);
                }
            });
        }
        return this.actions$.pipe(filter((action: Action) => types.includes(action.type)), take(1));
    }

    dispatch<AC extends ActionCreator<string, Creator>[], U extends Action = Action, V = ReturnType<AC[number]>>(action: Action, actionToListen: AC, ...otherActions: AC[]): Observable<V>;
    dispatch<T extends Action>(action: T): Observable<T>;
    dispatch(action: Action, actionToListen: string, ...otherActions: string[]): Observable<Action>;
    dispatch(action: Action, actionsToListen: string[]): Observable<Action>;

    /**
     * It is not necessary to unsubscribe
     */
    dispatch<AC extends ActionCreator<string, Creator>>(action: Action, actionsToListen: string[] | AC | AC[] | (AC | string)[] | string = [], ...otherActions: string[] | AC[]): Observable<Action | AC> {
        if (Array.isArray(actionsToListen) && actionsToListen.length === 0) {
            actionsToListen = [action.type];
        }
        if (Array.isArray(actionsToListen)) {
            actionsToListen = [...actionsToListen, ...otherActions];
        } else {
            actionsToListen = [actionsToListen, ...otherActions];
        }
        return new Observable((subscriber) => {
            // @ts-ignore
            this.ofType(actionsToListen).subscribe((a) => {
                // @ts-ignore
                subscriber.next(a);
                subscriber.complete();
            });
            asapScheduler.schedule(() => {
                this.store.dispatch(action);
            });
        });
    }

    dispatchSync<AC extends ActionCreator<string, Creator>[], U extends Action = Action, V = ReturnType<AC[number]>>(action: Action, actionToListen: AC, ...otherActions: AC[]): Observable<V>;
    dispatchSync<T extends Action>(action: T): Observable<T>;
    dispatchSync(action: Action, actionToListen: string, ...otherActions: string[]): Observable<Action>;
    dispatchSync(action: Action, actionsToListen: string[]): Observable<Action>;

    /**
     * It is not necessary to unsubscribe
     */
    dispatchSync<AC extends ActionCreator<string, Creator>>(action: Action, actionsToListen: string[] | AC | AC[] | (AC | string)[] | string = [], ...otherActions: string[] | AC[]): Observable<Action | AC> {
        return new Observable((subscriber) => {
            if (!this.mutexes[action.type]) {
                this.mutexes[action.type] = new Mutex();
            }
            this.mutexes[action.type].acquire().then((release) => {
                // @ts-ignore
                this.dispatch(action, actionsToListen, ...otherActions).subscribe((res) => {
                    // @ts-ignore
                    subscriber.next(res);
                    subscriber.complete();
                    release();
                })
            });
        });
    }

}
