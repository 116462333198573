import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchSettingsAction,
    fetchSettingsFailAction,
    fetchSettingsSuccessAction,
    updateSettingsAction,
    updateSettingsFailAction,
    updateSettingsSuccessAction
} from './actions';
import {map, switchMap} from 'rxjs/operators';
import {GetSettingsGQL} from '../../graphql/queries/getSettings/getSettings.graphql-gen';
import {UpdateSettingsGQL} from '../../graphql/mutations/updateSettings/updateSettings.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class SettingsEffect implements OnDestroy {


    subSink = new SubSink();

    fetchSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchSettingsAction
        ),
        switchMap((a) => {
            return this.getSettingsGQL.fetch();
        }),
        map((res) => {
            console.log(res);
            if(res.errors) {
                return fetchSettingsFailAction({header: '', message: res.errors[0].message});
            }
            return fetchSettingsSuccessAction({settings: res.data});
        })
    ));

    updateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(updateSettingsAction),
        switchMap((a) => {
            return fixMutation(this.updateSettingsGQL.mutate({settings: a.settings}));
        }),
        map((res) => {
            if(res.errors) {
                return updateSettingsFailAction({header: '', message: res.errors[0].message});
            }
            return updateSettingsSuccessAction({settings: res.data.updateSettings});
        })
    ))

    // fetchPurchases$ = createEffect(() => this.actions$.pipe(
    //     ofType(
    //         fetchPurchasesAction
    //     ),
    //     switchMap((a) => {
    //         return this.getPurchasesGQL.fetch({filter: a.filter});
    //     }),
    //     map((res) => {
    //         if (res.errors) {
    //             return fetchPurchasesFailAction({header: '', message: res.errors[0].message});
    //         }
    //         return fetchPurchasesSuccessAction({purchases: res.data});
    //     })
    // ));

    constructor(
        private actions$: Actions,
        private getSettingsGQL: GetSettingsGQL,
        private updateSettingsGQL: UpdateSettingsGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
