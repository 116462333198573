import {Component, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {ACLRights} from '../../../services/acl.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-base-component',
    template: '',
    styles: [],
})
// @ts-ignore
export abstract class BaseComponent implements OnDestroy {
    public subSink: SubSink = new SubSink();

    memoizedFunctions: _.MemoizedFunction[] = [];

    public aclRights = ACLRights;

    ngOnDestroy() {
        this.subSink.unsubscribe();
        this.clearMemoizeCache();
    }

    clearMemoizeCache() {
        this.memoizedFunctions.forEach((f) => {
            f.cache.clear && f.cache.clear();
        })
    }

    trackByFn(index: any, item: any) {
        if (item?.controls?.tempId?.value) {
            return item?.controls?.tempId?.value;
        }
        return index; // or item.id
    }

    myInefficientHashFunction() {
        // not really an efficient hash function
        return JSON.stringify(arguments);
    }

    memoize<T extends (...args: any) => any>(callback: T, cacheAllArguments = false) {
        const memoizedFunction = _.memoize(callback, cacheAllArguments ? this.myInefficientHashFunction : undefined);
        this.memoizedFunctions.push(memoizedFunction);
        return memoizedFunction;
    }

}
