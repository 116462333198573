import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {SafeUrl} from '@angular/platform-browser';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    removeFileFromBufferAction
} from '../../../../store/general/actions';
import {readFileUrl} from '../../../helpers';
import {getGameSelector} from '../../../../store/games/selectors';
import {take} from 'rxjs/operators';
import {StepElementForm} from '../../../forms/types';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-sliding-puzzle',
    templateUrl: './sliding-puzzle.component.html',
    styleUrls: ['./sliding-puzzle.component.scss']
})
export class SlidingPuzzleComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    image: { loading: boolean, url: string | SafeUrl | null } = {loading: false, url: null};
    file: File | null;
    lastBufferedFilename: string|null = null;
    ratios = ['3:4', '4:6', '3:3'];
    availableTakePhotoButtonIds: (string)[] = [];

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            ratio: new FormControl('3:4', {validators: Validators.required, nonNullable: true}),
            usePhotoFromCamera: new FormControl(false, {validators: Validators.compose([
                    Validators.required,
                    CustomValidatorsService.customValidator((control) => {
                        const parent = (control.parent?.parent) as StepElementForm<SettingsControl>;
                        if (parent) {
                            if (!parent.controls.settings.controls.usePhotoFromCamera.value && parent.controls.settings.controls.takePhotoButtonId.value) {
                                parent.controls.settings.controls.takePhotoButtonId.setValue(null);
                            }
                        }
                        return null;
                    }),
                    CustomValidatorsService.requiredIf(['this.takePhotoButtonId'])
                ]), nonNullable: true}),
            takePhotoButtonId: new UntypedFormControl(null, CustomValidatorsService.customValidator((c) => {
                if (c.value === '') {
                    c.setValue(null);
                }
                return null;
            })),
            imageSrc: new UntypedFormControl('', Validators.required)
        });
    }

    async ngOnInit() {
        const game = (await lastValueFrom(this.store.select(getGameSelector).pipe(take(1))))!;
        // this.stepElementForm.controls.settings.controls.takePhotoButtonId.markAsTouched();
        // this.stepElementForm.controls.settings.controls.takePhotoButtonId.markAsDirty();
        super.ngOnInit();
        this.image = {
            url: this.stepElementForm.getRawValue().settings.imageSrc ? await readFileUrl(this.stepElementForm.getRawValue().settings.imageSrc, this.appActions) : null,
            loading: false
        };
        let parent: any = this.stepElementForm.parent?.parent?.parent;
        if (!parent || !(parent instanceof MyFormGroupV2)) {
            parent = this.stepElementForm.root;
        }
        this.availableTakePhotoButtonIds = this.getAvailableTakePhotoButtonIds(game);
        const fa = this.stepElementForm.parent as MyFormArray;
        if (fa) {
            this.subSink.sink = fa.arrayOrderChanged$.subscribe(() => {
                this.availableTakePhotoButtonIds = this.getAvailableTakePhotoButtonIds(game);
                if (!this.availableTakePhotoButtonIds.includes(this.stepElementForm.controls.settings.controls.takePhotoButtonId.value ?? '')) {
                    this.stepElementForm.controls.settings.controls.takePhotoButtonId.setValue(null);
                }
            })
        }
    }

    onFileUpload(): (file: File | null) => Promise<true> {
        return (file: File | null) => {
            return new Promise(async (resolve) => {
                let filename = null;
                if (file) {
                    filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
                    this.stepElementForm.controls.settings.controls.imageSrc.setValue(filename);
                } else {
                    this.stepElementForm.controls.settings.controls.imageSrc.setValue('');
                }
                this.stepElementForm.controls.settings.controls.imageSrc.markAsTouched();
                if (this.lastBufferedFilename) {
                    await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: this.lastBufferedFilename})));
                }
                this.file = file;
                this.lastBufferedFilename = filename;
                this.image.url = filename ? await readFileUrl(filename, this.appActions) : null;
                resolve(true);
                console.log(this.stepElementForm);
            });
        };
    }

    // p dropdown has ng 100 error, when destroy this element, it mark form as touched....
    dropdownChanged($event: any) {
        this.stepElementForm.controls.settings.controls.ratio.setValue($event.value);
    }


}

type SettingsControl = {
    ratio: FormControl<string>,
    usePhotoFromCamera: FormControl<boolean>,
    takePhotoButtonId: FormControl<string | null>,
    imageSrc: FormControl<string>,
}
