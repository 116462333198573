import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import { MyFormGroupV2} from '../../../forms/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileInputHelper, GameStepClueInput} from '../../../../graphql/types.graphql-gen';
import * as _ from 'lodash';
import {ScrollToInvalidControlDirective} from '../../../directives/scroll-to-invalid-control.directive';
import {AppActions} from '../../../../store/app-actions.service';
import {readFileUrlAction, readFileUrlSuccessAction} from '../../../../store/general/actions';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {lastValueFrom} from 'rxjs';
import {GameStepClueForm} from '../../../forms/types';

@Component({
    selector: 'app-update-clue',
    templateUrl: './update-clue.component.html',
    styleUrls: ['./update-clue.component.scss']
})
export class UpdateClueComponent implements OnInit {

    @ViewChildren(ScrollToInvalidControlDirective) scrollDirective: QueryList<ScrollToInvalidControlDirective>;
    image: { loading: boolean, url: string | SafeUrl | null } = {loading: false, url: null};
    file: File | null;
    readonly = false;

    form: GameStepClueForm;

    loaded = false;

    constructor(
        public dynamicDialogConfig: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private appActions: AppActions,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.initForm().then();
    }

    async initForm() {
        this.form = new MyFormGroupV2({
            id: new FormControl<number|null>(null),
            filename: new FormControl<FileInputHelper|null>(null),
            title: new FormControl<string>('', {validators: Validators.required, nonNullable: true}),
            description: new FormControl<string|null>(''),
            penalizationPoints: new FormControl(0, {validators: Validators.required, nonNullable: true}),
            penalizationTime: new FormControl(0, {validators: Validators.required, nonNullable: true}),
        });
        const clue = this.dynamicDialogConfig.data.clue as GameStepClueInput | undefined;
        if (this.dynamicDialogConfig.data.readonly !== undefined) {
            this.readonly = this.dynamicDialogConfig.data.readonly;
        }
        if (clue) {
            this.form.patchValue({...clue, title: clue.title ?? ''});
            if (clue.filename) {
                this.image.url = (await lastValueFrom(this.appActions.dispatch(readFileUrlAction({filename: clue.filename.name}), [readFileUrlSuccessAction]))).url;
            }
        }
        if(this.readonly) {
            this.form.disable();
        }
        this.loaded = true;
    }

    updateClue() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            if (!this.form.valid) {
                this.scrollDirective.first.scrollToFirstInvalidControl();
                return;
            }
            return;
        }
        const value = _.cloneDeep(this.form.value);
        // if(value.filename?.name && !this.file) {
        //     delete value.filename;
        // }
        if (value.filename?.name === null) {
            value.filename = null;
        }
        this.ref.close({value, file: this.file});
    }

    onFileUpload(): (file: File | null) => Promise<true> {
        return (file: File | null) => {
            return new Promise(async (resolve) => {
                if (file) {
                    try {
                        this.image.url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
                        this.image.loading = false;
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.image.url = null;
                }
                this.image.loading = false;
                this.file = file;
                this.form.controls.filename.setValue(this.file?.name ? {name: this.file.name} : null);
                resolve(true);
            });
        };
    }
}
