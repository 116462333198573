import {Injectable, OnDestroy} from '@angular/core';
import {GameGroup, Player} from '../graphql/types.graphql-gen';
import {Store} from '@ngrx/store';
import {IAppState} from '../store/state';
import {SubSink} from 'subsink';
import {userSelector} from '../store/user/selectors';
import * as _ from 'lodash';
import {MessageService} from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class AclService implements OnDestroy {
    user: Player | null = null;
    currentPermissions: string[] = [];
    managedGames: number[] = [];
    public subSink: SubSink = new SubSink();

    constructor(
        private store: Store<IAppState>,
        private messageService: MessageService
    ) {
        this.subSink.sink = this.store.select(userSelector).subscribe((user: Player) => {
            this.user = user;
            if (user) {
                this.currentPermissions = user.implicitPermissions.map(([role, module, action]) => module + '.' + action);
                this.managedGames = user.managedGameIds;
            } else {
                this.currentPermissions = [];
                this.managedGames = [];
            }
        });
    }

    initialize() {
    }

    canUserAccessManager(player: Player): boolean {
        const managerAccessRights = [
            ACLRights.games.general.readAssigned,
            ACLRights.players.general.read,
            ACLRights.licenses.general.all,
            ACLRights.purchases.general.readAssigned,
            ACLRights.settings.general.all,
            ACLRights.vouchers.general.readAssigned,
            ACLRights.permissionGroups.general.all
        ];
        const playerPermissions = (player.implicitPermissions ?? []).map(([role, module, action]) => module + '.' + action);
        return _.intersection(managerAccessRights, playerPermissions).length > 0;
    }


    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

    isAllowed(right: AclRightType, extraData?: { gameId?: number, group?: { games: { id: number }[] }, displayErrorMessage?: boolean }): boolean {
        if ((extraData?.group || extraData?.gameId) && right.toString().includes('Assigned')) {
            const rightForAllGames = right.toString().replace('Assigned', '');
            if (this.currentPermissions.includes(rightForAllGames as string)) {
                return true;
            }
            const gameIds = extraData?.group ? extraData.group.games.map((g) => g.id) : (extraData.gameId ? [extraData.gameId] : []);
            if (this.currentPermissions.includes(right as string) && _.intersection(this.managedGames, gameIds).length > 0) {
                return true;
            }
            if (extraData?.displayErrorMessage) {
                this.showErrorMessage(right);
            }
            return false;
        }
        const allowed = this.currentPermissions.includes(right as string);
        if (!allowed && extraData?.displayErrorMessage) {
            this.showErrorMessage(right);
        }
        return allowed;
    }

    showErrorMessage(right: AclRightType) {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'noRightsFor-' + right.toString()});
    }

}


enum Vouchers {
    all = 'managerVouchers.all',
    create = 'managerVouchers.create',
    createAssigned = 'managerVouchers.createAssigned',
    update = 'managerVouchers.update',
    updateAssigned = 'managerVouchers.updateAssigned',
    read = 'managerVouchers.read',
    readAssigned = 'managerVouchers.readAssigned',
    delete = 'managerVouchers.delete',
}

enum Settings {
    all = 'managerSettings.all'
}

enum PushNotifications {
    all = 'managerPushNotifications.all'
}

enum Cities {
    all = 'managerCities.all'
}

enum Purchases {
    all = 'managerPurchases.all',
    read = 'managerPurchases.read',
    readAssigned = 'managerPurchases.readAssigned',
    delete = 'managerPurchases.delete',
    update = 'managerPurchases.update',
    updateAssigned = 'managerPurchases.updateAssigned',
}

enum Reviews {
    all = 'managerReviews.all',
    read = 'managerReviews.read',
    readAssigned = 'managerReviews.readAssigned',
    update = 'managerReviews.update',
    updateAssigned = 'managerReviews.updateAssigned',
    delete = 'managerReviews.delete',
}

enum Licenses {
    all = 'managerLicenses.all'
}

enum Pages {
    all = 'managerFrontendPages.all'
}

enum Players {
    all = 'managerPlayers.all',
    read = 'managerPlayers.read',
    update = 'managerPlayers.update',
}

enum SetRoleToPlayer {
    all = 'managerPlayers.setRoleToPlayer-all',
    editor = 'managerPlayers.setRoleToPlayer-editor'
}

enum Game {
    all = 'managerGames.all',
    create = 'managerGames.create',
    read = 'managerGames.read',
    readAssigned = 'managerGames.readAssigned',
    delete = 'managerGames.delete',
    deleteAssigned = 'managerGames.deleteAssigned',
    update = 'managerGames.update',
    updateAssigned = 'managerGames.updateAssigned'
}

enum CreateGameType {
    all = 'managerGames.createGameType-all',
    zeda = 'managerGames.createGameType-zeda',
    full = 'managerGames.createGameType-full'
}

enum GameExtensions {
    stepVariants= 'managerGames.gameExtension-stepVariants'
}

enum GameGroupFormScheme {
    all = 'managerGames.gameGroupFormScheme-all',
    full = 'managerGames.gameGroupFormScheme-full',
    license = 'managerGames.gameGroupFormScheme-license',
    simplifiedGlitr = 'managerGames.gameGroupFormScheme-simplifiedGlitr',
}

enum GameFormScheme {
    all = 'managerGames.gameFormScheme-all',
    full = 'managerGames.gameFormScheme-full',
    license = 'managerGames.gameFormScheme-license',
    simplifiedGlitr = 'managerGames.gameFormScheme-simplifiedGlitr',
}


enum SetPlayerGame {
    all = 'managerGames.setPlayerGame-all'
}

enum SetGameAttributes {
    all = 'managerGames.setGameAttributes-all'
}


enum PermissionGroups {
    all = 'managerPermissionGroups.all'
}

enum SaleSources {
    all = 'managerSaleSources.all'
}

enum ProductPartners {
    all = 'managerProductPartners.all'
}

enum Partners {
    all = 'managerPartners.all'
}

enum Translations {
    all = 'managerTranslations.all'
}

enum Attributes {
    all = 'managerAttributes.all'
}

enum Progresses {
    all = 'managerProgresses.all',
    read = 'managerProgresses.read',
    readAssigned = 'managerProgresses.readAssigned',
    delete = 'managerProgresses.delete',
    deleteAssigned = 'managerProgresses.deleteAssigned',
}


export type AclRightType =
    Vouchers
    | Settings
    | Purchases
    | Reviews
    | Players
    | SetRoleToPlayer
    | Licenses
    | Pages
    | Game
    | CreateGameType
    | GameExtensions
    | GameFormScheme
    | GameGroupFormScheme
    | Progresses
    | PermissionGroups
    | ProductPartners
    | Translations
    | PushNotifications
    | Cities
    | Partners
    | SetPlayerGame
    | SetGameAttributes
    | Attributes
    | SaleSources;

export const ACLRights = {
    settings: {
        general: Settings
    },
    pushNotifications: {
        general: PushNotifications
    },
    purchases: {
        general: Purchases
    },
    reviews: {
        general: Reviews
    },
    players: {
        general: Players,
        setRoleToPlayer: SetRoleToPlayer
    },
    cities: {
        general: Cities
    },
    licenses: {
        general: Licenses
    },
    pages: {
        general: Pages
    },
    vouchers: {
        general: Vouchers
    },
    games: {
        general: Game,
        createGameType: CreateGameType,
        gameExtensions: GameExtensions,
        setPlayerGame: SetPlayerGame,
        gameGroupFormScheme: GameGroupFormScheme,
        gameFormScheme: GameFormScheme,
        setGameAttributes: SetGameAttributes
    },
    progresses: {
        general: Progresses
    },
    permissionGroups: {
        general: PermissionGroups
    },
    productPartners: {
        general: ProductPartners
    },
    partners: {
        general: Partners
    },
    saleSources: {
        general: SaleSources
    },
    translations: {
        general: Translations
    },
    attributes: {
        general: Attributes
    }
};


