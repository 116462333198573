import * as Types from '../../types.graphql-gen';

import { GameStepFragment } from '../updateGame/updateGame.graphql-gen';
import { gql } from 'apollo-angular';
import { GameStepFragmentDoc } from '../updateGame/updateGame.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateGameStepMutationVariables = Types.Exact<{
  input: Types.GameStepInput;
}>;


export type UpdateGameStepMutation = (
  { __typename?: 'Mutation' }
  & { updateGameStep: (
    { __typename?: 'UpdateGameStepResult' }
    & Pick<Types.UpdateGameStepResult, 'gameStepDeleted'>
    & { gameStep?: Types.Maybe<(
      { __typename?: 'GameStep' }
      & GameStepFragment
    )>, gameSteps?: Types.Maybe<Array<(
      { __typename?: 'GameStep' }
      & GameStepFragment
    )>>, changeOrder?: Types.Maybe<Array<(
      { __typename?: 'GameStepsChangeOrder' }
      & Pick<Types.GameStepsChangeOrder, 'sortId' | 'stepId'>
    )>> }
  ) }
);

export const UpdateGameStepDocument = gql`
    mutation updateGameStep($input: GameStepInput!) {
  updateGameStep(input: $input) {
    gameStep {
      ...gameStep
    }
    gameSteps {
      ...gameStep
    }
    gameStepDeleted
    changeOrder {
      sortId
      stepId
    }
  }
}
    ${GameStepFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGameStepGQL extends Apollo.Mutation<UpdateGameStepMutation, UpdateGameStepMutationVariables> {
    document = UpdateGameStepDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }