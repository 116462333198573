import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormControl, NG_VALUE_ACCESSOR, FormControl} from '@angular/forms';
import {Editor, Toolbar} from 'ngx-editor';


const TEXTBOX_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextEditorComponent),
    multi: true,
};

const toolbar: Toolbar = [
    [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['text_color', 'background_color'],
    // ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
];

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    providers: [TEXTBOX_VALUE_ACCESSOR],
})
export class TextEditorComponent implements OnInit, OnDestroy, OnChanges {

    editor: Editor
    toolbar = toolbar;
    disabled = false;
    colorPresets = [
        '#000000', '#e60e00', '#ff9901', '#fffb00', '#008a06', '#9934ff', '#0266cc', '#306969',
        '#ffffff', '#facccc', '#ffebcc', '#fffdcc', '#cce8cc', '#ebd6ff', '#cce0f5', '#dcefef',
        '#bbbbbb', '#f06667', '#ffc266', '#fffc66', '#66b966', '#c285ff', '#65a3e0', '#85c7c7',
        '#888', '#a10700', '#b26b00', '#b2b200', '#006103', '#6b24b2', '#0047b2', '#50afaf',
        '#444', '#5c0200', '#663d00', '#666600', '#003701', '#3d1466', '#002966', '#183434'
    ];

    @Input() control: UntypedFormControl|AbstractControl|FormControl<any>|null
    formControl: UntypedFormControl

    ngOnDestroy(): void {
        this.editor.destroy();
    }

    ngOnInit(): void {
        this.initEditor();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.formControl !== this.control) {
            this.initEditor();
        }
    }

    initEditor() {
        this.editor = new Editor();
        this.formControl = this.control as UntypedFormControl;
    }




}
