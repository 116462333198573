import {IUserState} from '../state';
import {
    loadUserStateAction,
    loginSuccessAction,
    logoutAction,
    saveElementPreferencesAction,
    updateUserPermissionsAction
} from './actions';
import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {fetchInitialDataSuccessAction} from '../data/actions';
import {updateGameGroupSuccessAction, updateGameSuccessAction} from '../games/actions';

const defaultUserState: IUserState = {
    user: null,
    preferences: null
};

export const userReducer = createImmerReducer(
    defaultUserState,
    on(loadUserStateAction, (state: IUserState, action) => {
        return {...state, ...action.userState};
    }),
    on(logoutAction, (state: IUserState, action) => {
        state.user = null;
        return state;
    }),
    on(loginSuccessAction, (state: IUserState, action) => {
        state.user = action.loginResult.player;
        return state;
    }),
    on(updateUserPermissionsAction, (state, action: ReturnType<typeof updateUserPermissionsAction>) => {
        if (state.user) {
            state.user.implicitPermissions = action.implicitPermissions;
            state.user.managedGameIds = action.managedGameIds;
        }
        return state;
    }),
    on(fetchInitialDataSuccessAction, (state: IUserState, action: ReturnType<typeof fetchInitialDataSuccessAction>) => {
        if (!state.user) {
            return state;
        }
        if (action?.initialData?.getPlayerInfo?.implicitPermissions) {
            state.user.implicitPermissions = action?.initialData?.getPlayerInfo?.implicitPermissions;
        }
        if (action?.initialData?.getPlayerInfo?.managedGameIds) {
            state.user.managedGameIds = action?.initialData?.getPlayerInfo?.managedGameIds;
        }
        return state;
    }),
    on(updateGameGroupSuccessAction, (state: IUserState, action: ReturnType<typeof updateGameGroupSuccessAction>) => {
        if (action.gameGroup?.games?.length && state.user) {
            for (const game of action.gameGroup.games) {
                if (!state.user.managedGameIds.find((gId) => gId === game.id)) {
                    state.user.managedGameIds.push(game.id);
                }
            }
        }
        return state;
    }),
    on(updateGameSuccessAction, (state: IUserState, action: ReturnType<typeof updateGameSuccessAction>) => {
        if (action.game?.id && state.user) {
            if (!state.user.managedGameIds.find((gId) => gId === action.game?.id)) {
                state.user.managedGameIds.push(action.game.id);
            }
        }
        return state;
    }),
    on(saveElementPreferencesAction, (state: IUserState, action: ReturnType<typeof saveElementPreferencesAction>) => {
        if (!state.preferences) {
            state.preferences = {elements: {}};
        }
        state.preferences.elements[action.name] = action.preferences;
        return state;

    })
);
