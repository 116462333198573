import {defaultDataState} from '../state';
import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {
    fetchGameVouchersSuccessAction,
    fetchGameVoucherSuccessAction,
    updateGameVoucherSuccessAction,
    UpdateGameVoucherSuccessActionType
} from './actions';
import {GameVoucher} from "../../graphql/types.graphql-gen";

export const gameVoucherReducer = createImmerReducer(
    defaultDataState,
    on(fetchGameVouchersSuccessAction, (state, action) => {
        state.gameVoucher.gameVouchers = action.result;
        return state;
    }),
    on(fetchGameVoucherSuccessAction, (state, action) => {
        state.gameVoucher.activeGameVoucher = action.gameVoucher;
        return state;
    }),
    on(updateGameVoucherSuccessAction, (state, action: UpdateGameVoucherSuccessActionType) => {
        if(action.result.gameVouchers?.length || action.result.gameVoucher) {
            const updtedVouchers = action.result.gameVouchers?.length ? action.result.gameVouchers as GameVoucher[] : [action.result.gameVoucher as GameVoucher];
            updtedVouchers.forEach((gameVoucher) => {
                state.gameVoucher.activeGameVoucher = gameVoucher;
                const gameVoucherToUpdate = state.gameVoucher.gameVouchers?.result.findIndex((gv) => gv.id === gameVoucher.id);
                if(gameVoucherToUpdate && gameVoucherToUpdate !== -1) {
                    // @ts-ignore
                    state.gameVoucher.gameVouchers.result[gameVoucherToUpdate] = gameVoucher;
                }
            });
        }
        if(action.result.gameVoucherDeleted) {
            if(state.gameVoucher.activeGameVoucher?.id === action.result.gameVoucherDeleted) {
                state.gameVoucher.activeGameVoucher = null;
            }
            if (state.gameVoucher.gameVouchers) {
               state.gameVoucher.gameVouchers.result = state.gameVoucher.gameVouchers.result.filter((gv) => gv.id !== action.result.gameVoucherDeleted);
            }
        }
        return state;
    })
);
