import {Component, OnInit} from '@angular/core';
import {MyFormGroupV2} from '../../../forms/forms';
import {ElementBaseComponent} from '../element-base.component';
import {QRCodeSettings} from '../element-types';
import {FormControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {StepElementForm, StepFormType} from '../../../forms/types';
import * as _ from 'lodash';
import {StepElement, StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {GameHelper} from '../../../forms/game-helpers';
import {sha512} from 'js-sha512';
import {environment} from '../../../../../environments/environment';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    currentCode: string = '';
    code: string = '';
    timeout: any;
    allowHashedVersion = environment.options.elements.allowHashedVersion;

    ngOnInit(): void {
        super.ngOnInit();
        this.code = this.stepElementForm.getRawValue().settings.code;
        if(!this.stepElementForm.controls.settings.controls.hashedVersion.value) {
            this.currentCode = this.stepElementForm.getRawValue().settings.code;
        }
    }

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            code: new FormControl('', {validators: Validators.compose([Validators.required, Validators.pattern("[^\\s]*$")]), nonNullable: true}),
            hashedVersion: new FormControl(false, {nonNullable: true})
        });
    }

    static getValidators(game: GameFragment, element?: StepElement): ValidatorFn | null {
        return CustomValidatorsService.customValidator((control) => {
            // @ts-ignore
            const currentForm = control as StepElementForm<QRCodeSettings, SettingsControl>;
            if (!currentForm.controls.settings.controls.code.valid) {
                return null;
            }
            const errors: ValidationErrors = {};
            const root = control.root as StepFormType;
            const parent = control?.parent?.parent;
            if (!root?.controls?.basicSettings || !parent) {
                return null;
            }
            const allGameElements = GameHelper.getAllGameStepElements('values', game, root);
            const allCodes = allGameElements
                .filter((se) => se.type === StepElementTypeEnum.QrCode && GameHelper.getStepElementInputId(se) !== GameHelper.getStepElementInputId(currentForm.value))
                .map((se) => se.settings.code.toLowerCase());
            if (allCodes.includes(currentForm.value.settings.code.toLowerCase())) {
                errors['codeInAnotherElement'] = 'games.validatorErrors.elements.qrCode.codeInAnotherElement';
            }
            if (!_.isEmpty(errors)) {
                return errors;
            }
            return null;
        });
    }

    codeChanged() {
        let newCode = this.code;
        if(this.stepElementForm.controls.settings.controls.hashedVersion.value) {
            newCode = sha512(newCode);
        }
        this.stepElementForm.controls.settings.controls.code.setValue(newCode)
        this.regenerateQrCode();
    }

    regenerateQrCode() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.currentCode = this.code;
        }, 1000);
    }

    hashedVersionChanged() {
        this.stepElementForm.controls.settings.controls.code.setValue('');
        this.code = '';
        this.currentCode = '';
    }
}


type SettingsControl = {
    code: FormControl<string>,
    hashedVersion: FormControl<boolean>
}
