import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-skip-step-button',
    templateUrl: './skip-step-button.component.html',
    styleUrls: ['./skip-step-button.component.scss']
})
export class SkipStepButtonComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    // TODO deprecated element
    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            label: new FormControl('', {nonNullable: true}),
            btnCancelLabel: new FormControl('', {nonNullable: true}),
            popupText: new FormControl('', {nonNullable: true}),
            popupTitle: new FormControl('', {nonNullable: true}),
        });
    }



}


type SettingsControl = {
    label: FormControl<string>,
    btnCancelLabel: FormControl<string>,
    popupTitle: FormControl<string>,
    popupText: FormControl<string>,
}
