import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {loginAction, loginFailAction, loginSuccessAction, logoutAction} from './actions';
import {map, switchMap, tap} from 'rxjs/operators';
import {LoginGQL} from '../../graphql/mutations/login/login.graphql-gen';
import {fixMutation} from '../../shared/helpers';
import {LoginResult, Player} from '../../graphql/types.graphql-gen';
import {TranslateService} from '@ngx-translate/core';
import {MyStorageService} from '../../services/my-storage.service';
import {Router} from '@angular/router';
import {AclService} from '../../services/acl.service';
import {environment} from '../../../environments/environment';
import {SocialAuthService} from '@abacritt/angularx-social-login';

@Injectable()
export class UserEffect implements OnDestroy {

    login$ = createEffect(() => this.actions$.pipe(
        ofType(loginAction),
        switchMap((a) => {
            return fixMutation(this.loginGQL.mutate({loginCredentials: a.loginCredentials}));
        }),
        map((res) => {
            if(res.errors) {
                return loginFailAction({header: 'Chyba', message: res.errors[0].message})
            }
            if(!this.aclService.canUserAccessManager(res.data.login.player as Player)) {
                return loginFailAction({header: '', message: this.translateService.instant('errors.missingRights')});
            }
            this.storageService.set('accessTokenGQL', res.data.login.accessToken).then();
            this.storageService.set('refreshTokenGQL', res.data.login.refreshToken).then();
            return loginSuccessAction({loginResult: res.data.login as LoginResult})
        })
    ));

    logout$ = createEffect(() => this.actions$.pipe(
        ofType(logoutAction),
        tap(async () => {
            this.storageService.clear();
            this.storageService.saveSyncKey(environment.syncKey).then();
            try {
                await this.socialAuthService.signOut(true);
            } catch (e) {

            }
        }),
        map((a) => {
            this.router.navigate(['/login']).then()
        })
    ), {dispatch: false});


    private subscriptions$: Subscription[] = [];

    constructor(
        private actions$: Actions,
        private loginGQL: LoginGQL,
        private translateService: TranslateService,
        private storageService: MyStorageService,
        private router: Router,
        private aclService: AclService,
        private socialAuthService: SocialAuthService,
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions$.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
