import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchGameAction,
    fetchGameFailAction, fetchGameGroupAction, fetchGameGroupFailAction,
    fetchGameGroupsAction,
    fetchGameGroupsFailAction,
    fetchGameGroupsSuccessAction, fetchGameGroupSuccessAction,
    fetchGameSuccessAction,
    updateGameAction,
    updateGameFailAction,
    updateGameGroupAction,
    updateGameGroupFailAction,
    updateGameGroupSuccessAction,
    updateGameStepAction, updateGameStepFailAction, updateGameStepSuccessAction,
    updateGameSuccessAction
} from './actions';
import {GetGameGroupsGQL} from '../../graphql/queries/getGameGroups/getGameGroups.graphql-gen';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {UpdateGameGroupGQL} from '../../graphql/mutations/updateGameGroup/updateGameGroup.graphql-gen';
import {fixMutation} from '../../shared/helpers';
import {Game, GameGroup} from '../../graphql/types.graphql-gen';
import {UpdateGameGQL} from '../../graphql/mutations/updateGame/updateGame.graphql-gen';
import {GetGameGQL} from '../../graphql/queries/getGame/getGame.graphql-gen';
import {UpdateGameStepGQL} from '../../graphql/mutations/updateGameStep/updateGameStep.graphql-gen';

@Injectable()
export class GamesEffect implements OnDestroy {


    subSink = new SubSink();

    fetchGameGroups$ = createEffect(() => this.actions$.pipe(
        ofType(fetchGameGroupsAction),
        switchMap((a: ReturnType<typeof fetchGameGroupsAction>) => {
            return this.getGameGroupsGQL.fetch({filter: a.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchGameGroupsFailAction({message: res.errors[0].message, header: ''});
            }
            return fetchGameGroupsSuccessAction({gameGroups: res.data});
        })
    ));

    fetchGameGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fetchGameGroupAction),
        concatMap((a) => {
            return this.getGameGroupsGQL.fetch({filter: {id: a.id,}})
        }),
        map((res) => {
            if(res.errors) {
                return fetchGameGroupFailAction({message: res.errors[0].message, header: ''});
            }
            if(!res.data.getGameGroups.result[0]) {
                return fetchGameGroupFailAction({message: 'game_group_not_found', header: ''});
            }
            return  fetchGameGroupSuccessAction({gameGroup: res.data.getGameGroups.result[0]})
        })
    ));

    fetchGame$ = createEffect(() => this.actions$.pipe(
        ofType(fetchGameAction),
        switchMap((a) => {
            return this.getGameGQL.fetch({gameId: a.id});
        }),
        map((res) => {
            if(res.errors) {
                return fetchGameFailAction({header: '', message: res.errors[0].message});
            }
            return fetchGameSuccessAction({game: res.data.getGame as Game});
        })
    ));

    updateGameGroup$ = createEffect(() => this.actions$.pipe(
        ofType(updateGameGroupAction),
        switchMap((a) => {
            return fixMutation(this.updateGameGroupGQL.mutate({input: a.input}, a.context));
        }),
        map((res) => {
            if (res.errors) {
                return updateGameGroupFailAction({
                    header: '',
                    message: res.errors[0].message,
                    category: res.errors[0]?.extensions?.category as string|undefined
                });
            }
            return updateGameGroupSuccessAction({
                gameGroup: (res.data.updateGameGroup.gameGroup as GameGroup) ?? undefined,
                groupDeleted: (res.data.updateGameGroup.groupDeleted) ?? undefined
            });
        })
    ));

    updateGame$ = createEffect(() => this.actions$.pipe(
        ofType(updateGameAction),
        switchMap((a) => {
            return fixMutation(this.updateGameGQL.mutate({input: a.input}, a.context)).pipe(map((res) => ({mutation: res, originalAction: a})));
        }),
        map((res) => {
            if (res.mutation.errors) {
                return updateGameFailAction({
                    header: '',
                    message: res.mutation.errors[0].message,
                    category: res.mutation.errors[0]?.extensions?.category as string|undefined
                });
            }
            return updateGameSuccessAction({
                game: (res.mutation.data.updateGame.game as Game) ?? undefined,
                gameDeleted: res.mutation.data.updateGame.gameDeleted ?? undefined,
                gameGroupId: res.originalAction.groupId,
                checkAppId: res.mutation.data.updateGame.checkAppId ?? undefined,
                gameDownloadLink: res.mutation.data.updateGame.gameDownloadLink ?? undefined

            });
        })
    ));

    updateGameStep$ = createEffect(() => this.actions$.pipe(
        ofType(updateGameStepAction),
        switchMap((a) => {
            return fixMutation(this.updateGameStepGQL.mutate({input: a.input}, a.context)).pipe(
                map(res => {
                    return {
                        mutation: res,
                        originalAction: a
                    }
                })
            )
        }),
        map((res) => {
            if(res.mutation.errors) {
                return updateGameStepFailAction({header: 'error', message: res.mutation.errors[0].message});
            }
            return updateGameStepSuccessAction({result: res.mutation.data.updateGameStep, gameId: res.originalAction.gameId, groupId: res.originalAction.groupId});
        })
    ));


    constructor(
        private actions$: Actions,
        private getGameGroupsGQL: GetGameGroupsGQL,
        private getGameGQL: GetGameGQL,
        private updateGameGroupGQL: UpdateGameGroupGQL,
        private updateGameGQL: UpdateGameGQL,
        private updateGameStepGQL: UpdateGameStepGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
