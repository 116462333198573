import {createAction, props} from '@ngrx/store';
import {DefaultFailMessage} from '../user/actions';
import {SaleSourceInput, SaleSourceResult, UpdateSaleSourceResult} from '../../graphql/types.graphql-gen';


export const fetchSaleSourcesAction = createAction('[SaleSource] FETCH_SALE_SOURCES');
export const fetchSaleSourceSuccessAction = createAction('[SaleSource] FETCH_SALE_SOURCE_SUCCESS', props<FetchSaleSourceSuccessActionProps>());
export const fetchSaleSourceFailAction = createAction('[SaleSource] FETCH_SALE_SOURCE_FAIL', props<FetchSaleSourceFailActionProps>());

export const updateSaleSourceAction = createAction('[SaleSource] UPDATE_SALE_SOURCE', props<UpdateSaleSourceActionProps>());
export const updateSaleSourceSuccessAction = createAction('[SaleSource] UPDATE_SALE_SOURCE_SUCCESS', props<UpdateSaleSourceSuccessActionProps>());
export const updateSaleSourceFailAction = createAction('[SaleSource] UPDATE_SALE_SOURCE_FAIL', props<UpdateSaleSourceFailActionProps>());

export type FetchSaleSourceSuccessActionProps = { result: SaleSourceResult, availableIdentifiers: string[]};
export type FetchSaleSourceFailActionProps = DefaultFailMessage;

export type UpdateSaleSourceActionProps = { input: SaleSourceInput };
export type UpdateSaleSourceSuccessActionProps = { result: UpdateSaleSourceResult };
export type UpdateSaleSourceFailActionProps = DefaultFailMessage;
