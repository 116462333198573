import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {sleep} from '../../../helpers';
import {DomSanitizer} from '@angular/platform-browser';
import {lastValueFrom} from 'rxjs';
import {AppActions} from '../../../../store/app-actions.service';
import {CropperPosition, Dimensions, ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
    GameGroup,
    ImageTextBoxData,
    ImageTextBoxDataInput,
    VoucherImage,
    VoucherImageExtraSettingsInput
} from '../../../../graphql/types.graphql-gen';
import {getImageVoucherPath} from '../../../forms/helpers';
import * as _ from 'lodash';
import {
    updateGameGroupAction,
    updateGameGroupFailAction,
    updateGameGroupSuccessAction
} from '../../../../store/games/actions';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Entries} from 'type-fest';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-voucher-image',
    templateUrl: './voucher-image.component.html',
    styleUrls: ['./voucher-image.component.scss']
})
export class VoucherImageComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('imageWrapper', {read: ElementRef}) imageWrapper: ElementRef;
    loading = false;
    file: File | null;
    image: { loading: boolean, url: string, fullUrl?: string } = {loading: false, url: ''};
    cropper: CropperPosition = {
        x1: 0,
        x2: 100,
        y1: 0,
        y2: 100
    }

    previewLink: string | null = null;

    activeCodeBoxSettings: ImageTextBoxDataInput = defaultBoxSettings;
    voucherTextStyle?: {
        left: string,
        top: string,
        width: string,
        'font-size': string,
        'line-height': string,
        color: string,
        'font-family': string
    }
    extraLeft = 0;

    lastCropperPosition: ImageCroppedEvent;

    voucherImage: VoucherImage | null;
    gameGroup: GameGroup;
    lastBufferedFilename: string | null = null;


    showText = true;

    originalSize: { width: number, height: number };
    scaledSize: { width: number, height: number };
    scaleRatio: number;

    previewCount = 1;

    parameters: { name: string, code: string }[];
    allowedParameters: (keyof VoucherImageExtraSettingsInput)[] = [];

    fonts: { name: string, code: string }[];

    imageCropperLoaded = false;


    voucherImageExtraSettings: VoucherImageExtraSettingsInput;

    selectedFont = 'voucher-openSans';
    maintainAspectRatio = false;

    constructor(
        private appActions: AppActions,
        private domSanitizer: DomSanitizer,
        public dynamicDialogConfig: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        public translator: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeFontsAndParameters();
        this.gameGroup = this.dynamicDialogConfig.data?.gameGroup;
        if (!this.gameGroup) {
            throw new Error('gameGroup not found');
        }
        this.voucherImage = this.dynamicDialogConfig.data?.gameGroup?.voucherImage;
        this.voucherImageExtraSettings = {
            codeBox: _.cloneDeep(defaultBoxSettings),
            gameNameBox: _.cloneDeep(defaultBoxSettings),
            groupNameBox: _.cloneDeep(defaultBoxSettings),
            qrBox: _.cloneDeep(defaultBoxSettings),
            validToBox: _.cloneDeep(defaultBoxSettings),
        };
        this.voucherImageExtraSettings.validToBox!.testText = formatDate(new Date(), 'dd.MM.yyyy', 'en-US');
        this.voucherImageExtraSettings.qrBox!.testText = 'QR';
        this.voucherImageExtraSettings.groupNameBox!.testText = this.gameGroup.name;
        this.voucherImageExtraSettings.gameNameBox!.testText = this.translator.instant('gameGroups.voucherImage.gameName');
        this.voucherImageExtraSettings.codeBox!.active = true;
        if (this.voucherImage) {
            this.image.url = getImageVoucherPath(this.voucherImage.name);
            if (this.voucherImage.extraSettings) {
                for(const [key, value] of Object.entries(_.cloneDeep(this.voucherImage.extraSettings)) as Entries<typeof this.voucherImage.extraSettings> ) {
                    if(!value || typeof value === 'string') {
                        continue;
                    }
                    if(value.__typename) {
                        delete value.__typename;
                    }
                    // @ts-ignore
                    this.voucherImageExtraSettings[key] = value;
                    // @ts-ignore
                    if(this.voucherImageExtraSettings[key].active === undefined || this.voucherImageExtraSettings[key].active === null) {
                        // @ts-ignore
                        this.voucherImageExtraSettings[key].active = true;
                    }
                    // @ts-ignore
                    if(this.voucherImageExtraSettings[key].font === undefined) {
                        // @ts-ignore
                        this.voucherImageExtraSettings[key].font = 'voucher-openSans';
                    }
                }
                this.previewLink = environment.webOrigin + '/voucher-preview/' + this.gameGroup?.voucherImage?.name.replace('.jpeg', '').replace('.jpg', '')

            }
        }
        this.activeCodeBoxSettings = this.voucherImageExtraSettings.codeBox;
        for(const [key, value] of Object.entries(this.voucherImageExtraSettings)) {
            if(value && value.active) {
                // @ts-ignore
                this.allowedParameters.push(key);
            }
        }
        this.imageCropperLoaded = true;
    }


    // async voucherImageLoaded($event: any, fileUpload: FileUpload) {
    //     const file: File = $event?.files[0];
    //     if (!file) {
    //         return;
    //     }
    //     if (this.lastBufferedFilename) {
    //         await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: this.lastBufferedFilename})));
    //     }
    //     this.codeBoxSettings = defaultBoxSettings;
    //     const filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
    //     const safeUrl = (await readFileUrl(filename, this.appActions));
    //     const fileUrl = this.domSanitizer.sanitize(SecurityContext.URL, safeUrl)
    //     this.lastBufferedFilename = filename;
    //
    //     this.image.url = '';
    //     fileUpload.clear()
    //     setTimeout(() => {
    //         this.image.url = fileUrl ?? '';
    //         this.image.fullUrl = undefined;
    //     }, 100);
    // }

    imageLoaded($event: LoadedImage) {
        this.originalSize = {
            width: $event.original.size.width,
            height: $event.original.size.height
        };
    }

    imageCropped(imageCroppedEvent: ImageCroppedEvent) {
        this.lastCropperPosition = imageCroppedEvent;
        this.recalculateText(imageCroppedEvent);
    }

    recalculateText(imageCroppedEvent: ImageCroppedEvent) {
        const width = imageCroppedEvent.cropperPosition.x2 - imageCroppedEvent.cropperPosition.x1;
        const fontSize = this.activeCodeBoxSettings.fontSize / this.scaleRatio;
        const top = imageCroppedEvent.cropperPosition.y1;
        const left = imageCroppedEvent.cropperPosition.x1;
        this.voucherTextStyle = {
            'font-size': fontSize + 'px',
            top: top + 'px',
            left: (left + this.extraLeft) + 'px',
            width: width + 'px',
            color: this.activeCodeBoxSettings.color,
            'line-height': imageCroppedEvent.cropperPosition.y2 - imageCroppedEvent.cropperPosition.y1 + 'px',
            'font-family': this.activeCodeBoxSettings.font ?? 'voucher-openSans'
        }
    }

    cropperReady($event: Dimensions) {
        this.scaledSize = {width: $event.width, height: $event.height};
        this.scaleRatio = this.originalSize.width / this.scaledSize.width;
        if(!this.voucherImage?.extraSettings?.codeBox) {
            this.cropper = {
                x1: 10,
                x2: Math.round($event.width / 2),
                y1: 10,
                y2: Math.round($event.height / 2),
            }
        } else {
            this.cropper = {
                x1: this.activeCodeBoxSettings.x1 / this.scaleRatio,
                x2: this.activeCodeBoxSettings.x2 / this.scaleRatio,
                y1: this.activeCodeBoxSettings.y1 / this.scaleRatio,
                y2: this.activeCodeBoxSettings.y2 / this.scaleRatio
            }
        }
        if (this.originalSize.width < this.imageWrapper.nativeElement.offsetWidth) {
            this.extraLeft = Math.round((this.imageWrapper.nativeElement.offsetWidth - this.originalSize.width) / 2)
        } else {
            this.extraLeft = 0;
        }
    }

    async saveVoucherImage() {
        this.activeCodeBoxSettings.x1 = this.lastCropperPosition.imagePosition.x1;
        this.activeCodeBoxSettings.x2 = this.lastCropperPosition.imagePosition.x2;
        this.activeCodeBoxSettings.y1 = this.lastCropperPosition.imagePosition.y1;
        this.activeCodeBoxSettings.y2 = this.lastCropperPosition.imagePosition.y2;
        this.loading = true;
        for(const [key, value] of Object.entries(this.voucherImageExtraSettings)) {
            if(value) {
                value.active = false;
            }
        }
        this.allowedParameters.forEach((p: keyof VoucherImageExtraSettingsInput) => {
            if(this.voucherImageExtraSettings[p]) {
                this.voucherImageExtraSettings[p]!.active = true;
            }
        });
        const resAction = await lastValueFrom(this.appActions.dispatch(updateGameGroupAction({
            input: {
                updateGroupVoucherImagePhoto: {
                    groupId: this.gameGroup.id,
                    extraSettings: _.cloneDeep(this.voucherImageExtraSettings)
                }
            }
        }), [updateGameGroupSuccessAction, updateGameGroupFailAction]))
        if (!this.previewLink) {
            this.previewLink = environment.webOrigin + '/voucher-preview/' + this.gameGroup?.voucherImage?.name.replace('.jpeg', '').replace('.jpg', '')
        }
        if(resAction.type === updateGameGroupSuccessAction.type) {
            this.voucherImage  = resAction.gameGroup!.voucherImage
        }
        this.loading = false;
        this.previewCount++;
        // this.ref.close();
    }

    initializeFontsAndParameters() {
        this.parameters = [
            {
                name: this.translator.instant('gameGroups.voucherImage.parameter.codeBox'),
                code: 'codeBox'
            },
            {
                name: this.translator.instant('gameGroups.voucherImage.parameter.qrBox'),
                code: 'qrBox'
            },
            {
                name: this.translator.instant('gameGroups.voucherImage.parameter.validToBox'),
                code: 'validToBox'
            },
            {
                name: this.translator.instant('gameGroups.voucherImage.parameter.groupNameBox'),
                code: 'groupNameBox'
            },
            {
                name: this.translator.instant('gameGroups.voucherImage.parameter.gameNameBox'),
                code: 'gameNameBox'
            }
        ];
        this.fonts = [
            {
                name: 'Open Sans',
                code: 'voucher-openSans'
            },
            {
                name: 'Bebas',
                code: 'voucher-bebas'
            },
            {
                name: 'Dancing Script',
                code: 'voucher-dancingScript'
            },
            {
                name: 'Pacifico',
                code: 'voucher-pacifico'
            },
            {
                name: 'Roboto',
                code: 'voucher-roboto'
            }
        ];
    }

    async selectedParameterChanged(selectedOption: any) {
        this.activeCodeBoxSettings.x1 = this.lastCropperPosition.imagePosition.x1;
        this.activeCodeBoxSettings.x2 = this.lastCropperPosition.imagePosition.x2;
        this.activeCodeBoxSettings.y1 = this.lastCropperPosition.imagePosition.y1;
        this.activeCodeBoxSettings.y2 = this.lastCropperPosition.imagePosition.y2;
        this.imageCropperLoaded = false;
        this.voucherTextStyle = undefined;
        this.maintainAspectRatio = selectedOption.code === 'qrBox';
        await sleep(50);
        // @ts-ignore
        this.activeCodeBoxSettings = this.voucherImageExtraSettings[selectedOption.code];

        this.imageCropperLoaded = true;
    }

    selectedFontChanged(selectedOption: any) {
        this.voucherTextStyle!['font-family'] = selectedOption.code;
    }
}


const defaultBoxSettings: ImageTextBoxData = {
    x1: 0,
    x2: 100,
    y1: 0,
    y2: 100,
    fontSize: 70,
    color: '#000000',
    testText: 'TEST6W',
    active: false,
    font: 'voucher-openSans'
};
