import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state';
import {AppActions} from '../../../../store/app-actions.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AclService} from '../../../../services/acl.service';
import {GameFormSchemeType, SchemeService} from '../../../../services/scheme.service';
import {BaseComponent} from '../../base/base.component';
import {combineLatest, lastValueFrom, Subject} from 'rxjs';
import {getGameGroupOptionsSelector} from '../../../../store/games/selectors';
import {take} from 'rxjs/operators';
import {MyFormGroupV2} from '../../../forms/forms';
import {FileInputHelper} from '../../../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../../../../store/user/actions';
import * as _ from 'lodash';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    readFileBufferAction,
    readFileBufferSuccessAction,
    removeFileFromBufferAction,
    showAlertDialogAction,
    showAlertProgressAction
} from '../../../../store/general/actions';
import {readFileUrl, sleep} from '../../../helpers';
import {SafeUrl} from '@angular/platform-browser';
import {ProgressService} from '../../../../services/progress.service';
import {GameHelper} from '../../../forms/game-helpers';
import {ProgressType} from '../../../../store/types';
import {updateGameAction, updateGameFailAction, updateGameSuccessAction} from '../../../../store/games/actions';

@Component({
    selector: 'app-import-game',
    templateUrl: './import-game.component.html',
    styleUrls: ['./import-game.component.scss']
})
export class ImportGameComponent extends BaseComponent implements OnInit, OnDestroy {

    activeFormScheme: GameFormSchemeType;
    zipFile: { loading: boolean, url: string | null | SafeUrl, fullUrl?: string } = {loading: false, url: null}
    loaded = false;
    loading = {
        form: false
    };
    groupOptions: { id: number, name: string }[];

    form: MyFormGroupV2<{
        groupId: FormControl<number>,
        zipFile: FormControl<FileInputHelper>
    }>

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAppState>,
        private appActions: AppActions,
        private messageService: MessageService,
        private translateService: TranslateService,
        public ref: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private aclService: AclService,
        private schemeService: SchemeService,
        private progressService: ProgressService
    ) {
        super();
    }

    ngOnInit(): void {
        this.activeFormScheme = this.schemeService.gameFormScheme;
        this.subSink.sink = combineLatest([
            this.store.select(getGameGroupOptionsSelector).pipe(take(1))
        ]).subscribe((res) => {
            if (this.loaded) {
                return;
            }
            this.loaded = true;
            this.groupOptions = res[0];
            this.initForm();
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        // @ts-ignore
        this.onFileUpload.cache.clear && this.onFileUpload.cache.clear();
    }

    initForm() {
        this.form = new MyFormGroupV2({
            groupId:
                new FormControl<any>(null, Validators.required),
            zipFile: new FormControl<any>(null, Validators.required)
        });
    }

    async importGame() {
        if(!this.form.valid) {
            return;
        }
        this.loading.form = true;
        const progressSubject: Subject<ProgressType> = this.progressService.progress$;
        await lastValueFrom(this.appActions.dispatch(showAlertProgressAction()));
        const files = (await lastValueFrom(this.appActions.dispatch(readFileBufferAction(), [readFileBufferSuccessAction]))).files;
        if (files.length) {
            if (!await GameHelper.uploadFiles(files, progressSubject, this.appActions)) {
                this.loading.form = false;
                return;
            }
            await sleep(500);
        }
        const step2Progress: ProgressType = {
            filesAmount: 0,
            steps: files.length ? ['1', '2'] : ['1'],
            currentStep: files.length ? 1 : 0,
            showProgressBar: false,
            forceClose: false,
            uploaded: 0,
            title: 'games.steps.uploadingData'
        };
        progressSubject.next(step2Progress);
        await sleep(500);
        const res = await lastValueFrom(this.appActions.dispatch(updateGameAction({groupId: this.form.getRawValue().groupId, input: {gameId: 0, importGame: this.form.getRawValue()}}), [
            updateGameSuccessAction, updateGameFailAction
        ]));
        progressSubject.next({...step2Progress, forceClose: true});
        if (res.type === updateGameFailAction.type) {
            await lastValueFrom(this.appActions.dispatch(showAlertDialogAction({
                header: res.header,
                message: res.message
            })));
        } else {
            this.ref.close({groupId: this.form.value.groupId, gameId: res.game?.id});
            // await this.appActions.dispatch(showAlertDialogAction({
            //     header: '',
            //     message: 'games.gameImported',
            //     icon: 'success'
            // })).toPromise();
        }
        this.loading.form = false;
    }

    onFileUpload: () => (file: File | null) => Promise<true | DefaultFailMessage> = _.memoize(() => {
        let lastBufferedFilename: null | string = null;
        return (file: File | null) => {
            return new Promise(async (resolve) => {
                let filename = null;
                if (file) {
                    filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
                    this.form.controls.zipFile.setValue({name: filename} as FileInputHelper);
                    this.form.controls.zipFile.markAsTouched();
                } else {
                    this.form.controls.zipFile.setValue(null!);
                    this.form.controls.zipFile.markAsTouched();
                }
                if (lastBufferedFilename) {
                    await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: lastBufferedFilename})));
                }
                lastBufferedFilename = filename;
                this.zipFile.url = filename ? await readFileUrl(filename, this.appActions) : null;
                this.zipFile.fullUrl = undefined;
                resolve(true);
            });
        };
    })
}
