import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';
import {StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {ValidationErrors, ValidatorFn} from '@angular/forms';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {StepElementForm, StepFormType} from '../../../forms/types';
import * as _ from 'lodash';

@Component({
    selector: 'app-clue-end',
    templateUrl: './clue-end.component.html',
    styleUrls: ['./clue-end.component.scss']
})
export class ClueEndComponent extends ElementBaseComponent<SettingsControl> implements OnInit {


    ngOnInit() {
        super.ngOnInit();
        const parent = this.stepElementForm.parent as MyFormArray<StepElementForm>;
        this.subSink.sink = parent.arrayOrderChanged$.subscribe(() => {
            this.stepElementForm.updateValueAndValidity();
        });
    }

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({});
    }

    static getValidators(): ValidatorFn | null {
        return CustomValidatorsService.customValidator((control) => {
            const errors: ValidationErrors = {};
            const root = control.root as StepFormType;
            const parent = control?.parent as MyFormArray<StepElementForm>;
            if (!root?.controls?.basicSettings || !parent) {
                return null;
            }
            const currentIndex = parent.controls.findIndex((c) => c.value.tempId === control.value.tempId);
            const controlsToSearch = parent.controls.slice(0, currentIndex);
            const clueStartIndex = _.findLastIndex(controlsToSearch, (c) => c.value.type === StepElementTypeEnum.ClueStart);
            const clueEndIndex = _.findLastIndex(controlsToSearch,(c) => c.value.type === StepElementTypeEnum.ClueEnd);
            if(clueStartIndex === -1) {
                errors['clueStartMustBeBeforeClueEnd'] = 'games.validatorErrors.elements.clueEnd.clueStartMustBeBeforeClueEnd';
            } else if(clueEndIndex !== -1 && clueStartIndex < clueEndIndex) {
                errors['clueStartMustBeBeforeClueEnd'] = 'games.validatorErrors.elements.clueEnd.clueStartMustBeBeforeClueEnd';
            }

            if (!_.isEmpty(errors)) {
                return errors;
            }
            return null;
        });
    }
}


type SettingsControl = {}
