<div class="container p-0" [formGroup]="stepElementForm.controls.settings">
    <div class="row mb-4">
        <div class="col-12">
            <p-message class="inline-flex" [severity]="'info'" [text]="'POZOR - tento prvek ještě není v aplikaci dostupný, pokud jej použijete při hře se element přeskočí'">
            </p-message>
            <br>
            <app-tutorial title="Jak to funguje?" buttonLabel="Jak to funguje?" class="inline-flex mt-2">
                <div class="row">
                    <div class="col-12">
                        Pří spuštění hry dojde k zamíchání jednotlivých dvojic, cílem bude správně spojit dvojice, které k sobě patří.<br><br>
                        <strong>Příklad: </strong>První položce nahrajete na levou stranu obrázek jablka, na pravou stranu dáte text "jablko", Ve druhé položce na levou stranu nahrajete obrázek hrušky, na pravou stranu nastavíte text "hruška",
                        pří spuštění se obrázky na levé straně rozmístí v náhodném pořadí, na pravé straně se texty také vypíšou v náhodném pořadí
                    </div>
                    <div class="col-12">
                        <img src="/assets/images/tooltip/element-pairSentence-howItWorks.png" style="height: 100%; width: 100%">
                    </div>
                </div>
            </app-tutorial>
        </div>
    </div>


    <div class="row m-0 align-items-center text-center mb-4" style="gap: 12px"
         *ngFor="let pairSentence of stepElementForm.controls.settings.controls.sentences.controls; let i = index">
        <div class="col box">
            <ng-container *ngIf="pairSentence.value.left?.text">
                <span
                    style="font-size: {{pairSentence.value.left?.fontSize ?? 20}}px">{{pairSentence.value.left?.text}}</span><br>
            </ng-container>
            <ng-container *ngIf="pairSentence.value.left?.src">
                <img [src]="pairSentence.value!.left!.src!|resourcePath|async">
            </ng-container>
        </div>
        <div class="col box">
            <span
                style="font-size: {{pairSentence.value.right?.fontSize ?? 20}}px">{{pairSentence.value.right?.text}}</span><br>
            <ng-container *ngIf="pairSentence.value.right?.src">
                <img [src]="pairSentence.value!.right!.src!|resourcePath|async">
            </ng-container>
        </div>
        <div class="col-auto">
            <button pButton
                    [disabled]="readonly"
                    icon="pi pi-cog"
                    class="p-button-sm"
                    (click)="updateSentence(pairSentence)"></button>
            <button pButton
                    [disabled]="readonly"
                    icon="pi pi-times"
                    class="p-button-sm p-button-danger ml-2"
                    (click)="removeSentence(i)"></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button
                pButton
                [disabled]="readonly"
                label="{{'elements.pairSentence.addSentence'|translate|firstUpper}}"
                class="p-button-sm p-button-success auto-width mt-2"
                icon="pi pi-plus"
                (click)="updateSentence()"
            ></button>
        </div>
    </div>
</div>
