import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FileBufferService {

    private files: File[] = [];
    private removedFiles: File[] = [];

    constructor() {
    }

    /**
     * @param file
     * @return string unique file name
     */
    addFile(file: File): string {
        file = new File([file], 'tempUploadFile' + Date.now() + file.name, {type: file.type});
        this.files.push(file);
        return file.name;
    }

    removeFile(fileName: string) {
        const file = this.files.find((f) => f.name === fileName);
        if (file) {
            this.files = this.files.filter((f) => f !== file);
            this.removedFiles.push(file);
        }
    }

    getFiles(includeRemoved: boolean = false) {
        if (includeRemoved) {
            return [...this.files, ...this.removedFiles];
        }
        return [...this.files];
    }

    clearBuffer() {
        this.removedFiles = [];
        this.files = [];
    }


}
