import * as Types from '../../types.graphql-gen';

import { PartnerFragment } from '../../queries/getInitialData/getinitialData.graphql-gen';
import { gql } from 'apollo-angular';
import { PartnerFragmentDoc } from '../../queries/getInitialData/getinitialData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePartnerMutationVariables = Types.Exact<{
  input: Types.PartnerInput;
}>;


export type UpdatePartnerMutation = (
  { __typename?: 'Mutation' }
  & { updatePartner: (
    { __typename?: 'UpdatePartnerResult' }
    & Pick<Types.UpdatePartnerResult, 'productPartnerDeleted'>
    & { partner?: Types.Maybe<(
      { __typename?: 'Partner' }
      & PartnerFragment
    )> }
  ) }
);

export const UpdatePartnerDocument = gql`
    mutation updatePartner($input: PartnerInput!) {
  updatePartner(input: $input) {
    partner {
      ...partner
    }
    productPartnerDeleted
  }
}
    ${PartnerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePartnerGQL extends Apollo.Mutation<UpdatePartnerMutation, UpdatePartnerMutationVariables> {
    document = UpdatePartnerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }