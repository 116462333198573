import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {UntypedFormControl} from '@angular/forms';
import {LangFragment} from '../../../../../graphql/queries/getInitialData/getinitialData.graphql-gen';

@Component({
    selector: 'app-lang-options',
    templateUrl: './lang-options.component.html',
    styleUrls: ['./lang-options.component.scss']
})
export class LangOptionsComponent extends BaseComponent implements OnInit {

    @Input() languages: LangFragment[];
    @Input() control: UntypedFormControl;
    loaded = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.loaded = true;
    }

}
