<i *ngIf="!image" [hideDelay]="100" class="{{icon}}" style="cursor: {{cursor}};" [pTooltip]="value"
   [tooltipPosition]="position"
   [escape]="false"></i>
<span class="image-tooltip" *ngIf="image">
    <p-image *ngIf="!isArray(image)" #pImage (click)="appendToBody(pImage)" src="assets/images/tooltip/{{image}}"
             alt="Image" height="20"
             [preview]="true">
    <ng-template pTemplate="indicator">
        <i [hideDelay]="100" class="{{icon}}" style="cursor: {{cursor}};" [pTooltip]="value"
           [tooltipPosition]="position"></i>
    </ng-template>
    </p-image>
    <ng-container *ngIf="isArray(image)">
        <i [hideDelay]="100" class="{{icon}}" style="cursor: {{cursor}};" [pTooltip]="value"
           (click)="showGallery = !showGallery"
           [tooltipPosition]="position"></i>
        <p-galleria #extended="extended"
                    appExtendedGalleria
                    [value]="images"
                    [(visible)]="showGallery"
                    [responsiveOptions]="responsiveOptions"
                    [containerStyle]="{'max-width': '90%'}" [numVisible]="9"
                    [circular]="true" [showThumbnails]="false" [fullScreen]="true" [showItemNavigators]="true">
            <ng-template pTemplate="item" let-item>
                <img [src]="item" style="width: 100%; display: block;"/>
            </ng-template>
        </p-galleria>
    </ng-container>
</span>
