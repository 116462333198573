import {createAction, props} from '@ngrx/store';
import {Player, PlayerFilter, PlayerInput, PlayersResult, UpdatePlayerResult} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {
    GetAvailableEditorsMetaDataQuery,
    GetAvailablePlayersMetaDataQuery
} from '../../graphql/queries/metaData/metaData.graphql-gen';
import {GetPlayerIdsGQL, GetPlayerIdsQuery} from '../../graphql/queries/getPlayers/getPlayers.graphql-gen';


export const fetchPlayersAction = createAction('[Player] FETCH_PLAYERS_ACTION', props<FetchPlayersActionProps>());
export const fetchPlayersSuccessAction = createAction('[Player] FETCH_PLAYERS_SUCCESS_ACTION', props<FetchPlayersSuccessActionProps>());
export const fetchPlayersFailAction = createAction('[Player] FETCH_PLAYERS_FAIL_ACTION', props<FetchPlayersFailActionProps>());

export const fetchPlayerIdsAction = createAction('[Player] FETCH_PLAYER_IDS_ACTION', props<FetchPlayerIdsActionProps>());
export const fetchPlayerIdsSuccessAction = createAction('[Player] FETCH_PLAYER_IDS_SUCCESS_ACTION', props<FetchPlayerIdsSuccessActionProps>());
export const fetchPlayerIdsFailAction = createAction('[Player] FETCH_PLAYER_IDS_FAIL_ACTION', props<FetchPlayerIdsFailActionProps>());

export const fetchAvailableEditorsAction = createAction('[Player] FETCH_AVAILABLE_EDITORS');
export const fetchAvailableEditorsSuccessAction = createAction('[Player] FETCH_AVAILABLE_EDITORS_SUCCESS', props<FetchAvailableEditorsSuccessActionProps>())
export const fetchAvailableEditorsFailAction = createAction('[Player] FETCH_AVAILABLE_EDITORS_FAIL', props<FetchAvailableEditorsFailActionProps>())

export const fetchAvailablePlayersAction = createAction('[Player] FETCH_AVAILABLE_PLAYERS', props<FetchAvailablePlayersActionProps>());
export const fetchAvailablePlayersSuccessAction = createAction('[Player] FETCH_AVAILABLE_PLAYERS_SUCCESS', props<FetchAvailablePlayersSuccessActionProps>())
export const fetchAvailablePlayersFailAction = createAction('[Player] FETCH_AVAILABLE_PLAYERS_FAIL', props<FetchAvailablePlayersFailActionProps>())


export const fetchPlayerAction = createAction('[Player] FETCH_PLAYER_ACTION', props<FetchPlayerActionProps>());
export const fetchPlayerSuccessAction = createAction('[Player] FETCH_PLAYER_SUCCESS_ACTION', props<FetchPlayerSuccessActionProps>());
export const fetchPlayerFailAction = createAction('[Player] FETCH_PLAYER_FAIL_ACTION', props<FetchPlayerFailActionProps>());

export const updatePlayerAction = createAction('[Player] UPDATE_PLAYER', props<UpdatePlayerActionProps>());
export const updatePlayerSuccessAction = createAction('[Player] UPDATE_PLAYER_SUCCESS', props<UpdatePlayerSuccessActionProps>());
export const updatePlayerFailAction = createAction('[Player] UPDATE_PLAYER_FAIL', props<UpdatePlayerFailActionProps>());


export type FetchAvailableEditorsSuccessActionProps = {editors: GetAvailableEditorsMetaDataQuery['getPlayers']['availableEditors'] | null}
export type FetchAvailableEditorsFailActionProps = DefaultFailMessage;


export type FetchAvailablePlayersSuccessActionProps = {players: GetAvailablePlayersMetaDataQuery['getPlayers']['result'] | null}
export type FetchAvailablePlayersFailActionProps = DefaultFailMessage;
export type FetchAvailablePlayersActionProps = {value: string|null, onlyWithPushToken?: boolean};

export type UpdatePlayerActionProps = {input: PlayerInput};
export type UpdatePlayerFailActionProps = DefaultFailMessage;
export type UpdatePlayerSuccessActionProps = {result: UpdatePlayerResult};

export type FetchPlayerIdsActionProps = { filter: PlayerFilter };
export type FetchPlayerIdsSuccessActionProps = { result: GetPlayerIdsQuery['getPlayers'] };
export type FetchPlayerIdsFailActionProps = DefaultFailMessage;

export type FetchPlayersActionProps = { filter: PlayerFilter };
export type FetchPlayersSuccessActionProps = { result: PlayersResult };
export type FetchPlayersFailActionProps = DefaultFailMessage;

export type FetchPlayerActionProps = { id: number };
export type FetchPlayerSuccessActionProps = { player: Player|null };
export type FetchPlayerFailActionProps = DefaultFailMessage;
