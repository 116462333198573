import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {fetchSaleSourceSuccessAction, updateSaleSourceSuccessAction} from './actions';
import {defaultDataState, IDataState} from '../state';
import {GameStep, SaleSource} from '../../graphql/types.graphql-gen';

export const saleSourceReducer = createImmerReducer(
    defaultDataState,
    on(fetchSaleSourceSuccessAction, (state, action) => {
        state.saleSources = action.result.result;
        if(action.availableIdentifiers) {
            state.helperData.availableSaleSourceIdentifiers = action.availableIdentifiers;
        }
        return state;
    }),
    on(updateSaleSourceSuccessAction, (state: IDataState, action) => {
        const saleSource = action.result.saleSource;
        if(saleSource && state.saleSources) {
            const index = state.saleSources.findIndex((ss) => ss.id === saleSource.id);
            if (index === -1) {
                state.saleSources.push(saleSource);
            } else {
                state.saleSources[index] = saleSource;
            }
        }
        if(typeof action.result.saleSourceDeleted === 'number' && state.saleSources) {
            state.saleSources = state.saleSources.filter((ss) => ss.id !== action.result.saleSourceDeleted);
            state.productPartners = null;
            state.gameVoucher.gameVouchers = null;
        }
        if(saleSource?.identifiers && state.helperData.availableSaleSourceIdentifiers) {
            state.helperData.availableSaleSourceIdentifiers = state.helperData.availableSaleSourceIdentifiers.filter((identif) => saleSource.identifiers.find((identifier) => identifier.identifier === identif) === undefined);
        }
        if(action.result.changeOrder && state.saleSources) {
            state.saleSources.forEach((ss) => {
                ss.order = (action.result.changeOrder?.find((o) => o.entityId === ss.id)?.sortId ?? ss.order);
            });
            state.saleSources = state.saleSources.sort(sortSaleSources)
        }
        return state;
    })
);



export const sortArrayByKey = (entityParamName: string) => {
    return (a: any, b: any) => (a[entityParamName] ?? 0) > (b[entityParamName] ?? 0) ? 1 : (((a[entityParamName] ?? 0) < (b[entityParamName] ?? 0)) ? -1 : 0);
}
const sortSaleSources = sortArrayByKey('order');
