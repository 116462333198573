import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { SaleSourceFragmentFragment } from '../../mutations/updateSaleSource/updateSaleSource.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { SaleSourceFragmentFragmentDoc } from '../../mutations/updateSaleSource/updateSaleSource.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetSaleSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSaleSourcesQuery = (
  { __typename?: 'Query' }
  & { getSaleSources: (
    { __typename?: 'SaleSourceResult' }
    & Pick<Types.SaleSourceResult, 'availableIdentifiers'>
    & { meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
    & SaleSourceResultFragmentFragment
  ) }
);

export type SaleSourceResultFragmentFragment = (
  { __typename?: 'SaleSourceResult' }
  & { result: Array<(
    { __typename?: 'SaleSource' }
    & SaleSourceFragmentFragment
  )> }
);

export const SaleSourceResultFragmentFragmentDoc = gql`
    fragment saleSourceResultFragment on SaleSourceResult {
  result {
    ...saleSourceFragment
  }
}
    ${SaleSourceFragmentFragmentDoc}`;
export const GetSaleSourcesDocument = gql`
    query getSaleSources {
  getSaleSources {
    ...saleSourceResultFragment
    availableIdentifiers
    meta {
      ...metaFragment
    }
  }
}
    ${SaleSourceResultFragmentFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSaleSourcesGQL extends Apollo.Query<GetSaleSourcesQuery, GetSaleSourcesQueryVariables> {
    document = GetSaleSourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }