import * as Types from '../../types.graphql-gen';

import { PageDataFragment } from '../../queries/getPagesData/getPagesData.graphql-gen';
import { gql } from 'apollo-angular';
import { PageDataFragmentDoc } from '../../queries/getPagesData/getPagesData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePageDataMutationVariables = Types.Exact<{
  input: Types.PageDataInput;
}>;


export type UpdatePageDataMutation = (
  { __typename?: 'Mutation' }
  & { updatePageData: (
    { __typename?: 'PageDataResult' }
    & { pageData: (
      { __typename?: 'FrontendPageData' }
      & PageDataFragment
    ) }
  ) }
);

export const UpdatePageDataDocument = gql`
    mutation updatePageData($input: PageDataInput!) {
  updatePageData(input: $input) {
    pageData {
      ...pageData
    }
  }
}
    ${PageDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageDataGQL extends Apollo.Mutation<UpdatePageDataMutation, UpdatePageDataMutationVariables> {
    document = UpdatePageDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }