import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {GameStepClueInput} from '../../../../graphql/types.graphql-gen';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    cancelConfirmDialogAction,
    removeFileFromBufferAction,
    showConfirmDialogAction
} from '../../../../store/general/actions';
import {UpdateClueComponent} from '../update-clue/update-clue.component';
import {AppActions} from '../../../../store/app-actions.service';
import {DialogService} from 'primeng/dynamicdialog';
import {take} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-clue-list',
    templateUrl: './clue-list.component.html',
    styleUrls: ['./clue-list.component.scss']
})
export class ClueListComponent implements OnInit {

    @Input() gameStepClues: GameStepClueInput[] = [];
    @Input() readonly  = false;
    @Input() deprecated = false;
    @Output() removeClueEvent = new EventEmitter<{index: number}>();
    @Output() updateClueEvent = new EventEmitter<{index: number, clue: GameStepClueInput}>();
    @Output() addClueEvent = new EventEmitter<GameStepClueInput>();

    constructor(
        private appActions: AppActions,
        private dialogService: DialogService
    ) {
    }

    ngOnInit(): void {
    }

    async removeClue(clue: GameStepClueInput) {

        this.appActions.dispatch(showConfirmDialogAction({
            header: 'Pozor',
            message: 'Opravdu chcete odstranit tuto nápovědu?',
            confirmButton: 'Ano',
            cancelButton: 'Ne'
        }), [cancelConfirmDialogAction]).subscribe(async (res) => {
            if (res.confirmed) {
                if (clue.filename) {
                    await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: clue.filename.name})));
                }
                this.removeClueEvent.emit({index: this.gameStepClues.indexOf(clue) as number});
                // this.gameStepClues = this.gameStepClues.filter((gsc) => gsc !== clue);
                // this.gameStepCluesChange.emit(this.gameStepClues);
            }
        });
    }

    async editClue(clue?: GameStepClueInput) {
        const ref = this.dialogService.open(UpdateClueComponent, {
            data: {clue, readonly: this.readonly},
            header: clue ? 'Editace nápovědy' : 'Nová nápověda',
            width: '700px',
            contentStyle: {'max-height': '500px', 'overflow': 'auto'},
            baseZIndex: 10000,
            dismissableMask: true
        });
        const res: { file?: File, value: GameStepClueInput } | undefined = await lastValueFrom(ref.onClose.pipe(take(1)));

        if (!res) {
            return;
        }
        if ( res.value.filename?.name !== clue?.filename?.name) {
            await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: clue?.filename?.name ?? ''})));
        }

        if (res.file && res.value.filename) {
            res.value.filename.name = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file: res.file}), [addFileToBufferSuccessAction]))).filename;
        }
        if (clue) {
            // const clues = [...this.gameStepClues];
            const index = this.gameStepClues.indexOf(clue) as number;
            this.updateClueEvent.emit({index, clue: res.value});
            // clues[index] = res.value;
            // this.gameStepClues = clues;
        } else {
            this.addClueEvent.emit(res.value);
            // this.gameStepClues.push(res.value);
        }
        // this.gameStepCluesChange.emit(this.gameStepClues);
    }
}
