import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPermissionGroupsMetaDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPermissionGroupsMetaDataQuery = (
  { __typename?: 'Query' }
  & { getPermissionGroups: (
    { __typename?: 'PermissionGroupsResult' }
    & Pick<Types.PermissionGroupsResult, 'allPermissions'>
    & { result: Array<(
      { __typename?: 'PermissionGroup' }
      & Pick<Types.PermissionGroup, 'id' | 'type' | 'name'>
    )> }
  ) }
);

export type GetAvailableEditorsMetaDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAvailableEditorsMetaDataQuery = (
  { __typename?: 'Query' }
  & { getPlayers: (
    { __typename?: 'PlayersResult' }
    & { availableEditors: Array<(
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id' | 'email' | 'fullname'>
    )> }
  ) }
);

export type GetAvailablePlayersMetaDataQueryVariables = Types.Exact<{
  filter: Types.PlayerFilter;
}>;


export type GetAvailablePlayersMetaDataQuery = (
  { __typename?: 'Query' }
  & { getPlayers: (
    { __typename?: 'PlayersResult' }
    & { result: Array<(
      { __typename?: 'Player' }
      & AvailablePlayerFragment
    )> }
  ) }
);

export type AvailablePlayerFragment = (
  { __typename?: 'Player' }
  & Pick<Types.Player, 'id' | 'email' | 'fullname' | 'pushToken'>
);

export type GetTranslationsMetadataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTranslationsMetadataQuery = (
  { __typename?: 'Query' }
  & { getLanguages: (
    { __typename?: 'LanguagesResult' }
    & { langsMetadata: (
      { __typename?: 'LangsMetadata' }
      & Pick<Types.LangsMetadata, 'flags'>
      & { langSelections: Array<Types.Maybe<(
        { __typename?: 'LangSelection' }
        & Pick<Types.LangSelection, 'code' | 'name'>
      )>> }
    ) }
  ), getManagerTranslations: (
    { __typename?: 'ManagerTranslationsResult' }
    & { translationsMetadata: (
      { __typename?: 'ManagerTranslationsMetadata' }
      & { translationProjects: Array<(
        { __typename?: 'TranslationProject' }
        & Pick<Types.TranslationProject, 'type' | 'projects'>
      )> }
    ) }
  ) }
);

export const AvailablePlayerFragmentDoc = gql`
    fragment availablePlayer on Player {
  id
  email
  fullname
  pushToken
}
    `;
export const GetPermissionGroupsMetaDataDocument = gql`
    query getPermissionGroupsMetaData {
  getPermissionGroups {
    result {
      id
      type
      name
    }
    allPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPermissionGroupsMetaDataGQL extends Apollo.Query<GetPermissionGroupsMetaDataQuery, GetPermissionGroupsMetaDataQueryVariables> {
    document = GetPermissionGroupsMetaDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailableEditorsMetaDataDocument = gql`
    query getAvailableEditorsMetaData {
  getPlayers {
    availableEditors {
      id
      email
      fullname
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailableEditorsMetaDataGQL extends Apollo.Query<GetAvailableEditorsMetaDataQuery, GetAvailableEditorsMetaDataQueryVariables> {
    document = GetAvailableEditorsMetaDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailablePlayersMetaDataDocument = gql`
    query getAvailablePlayersMetaData($filter: PlayerFilter!) {
  getPlayers(filter: $filter) {
    result {
      ...availablePlayer
    }
  }
}
    ${AvailablePlayerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailablePlayersMetaDataGQL extends Apollo.Query<GetAvailablePlayersMetaDataQuery, GetAvailablePlayersMetaDataQueryVariables> {
    document = GetAvailablePlayersMetaDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTranslationsMetadataDocument = gql`
    query getTranslationsMetadata {
  getLanguages {
    langsMetadata {
      flags
      langSelections {
        code
        name
      }
    }
  }
  getManagerTranslations {
    translationsMetadata {
      translationProjects {
        type
        projects
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTranslationsMetadataGQL extends Apollo.Query<GetTranslationsMetadataQuery, GetTranslationsMetadataQueryVariables> {
    document = GetTranslationsMetadataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }