<p-tabView styleClass="tabview-custom" *ngIf="loaded">
    <p-tabPanel *ngFor="let lang of languages; let i = index">
        <ng-template pTemplate="header">
            <div class="flex align-items-center">
                <img style="height: 2rem" *ngIf="lang.flagPath"
                     src="{{('/admin/assets/img/flags/svg/' + lang.flagPath)|url}}" alt="">
                <span class="ps-3">{{lang.name}}</span>
            </div>
        </ng-template>
        <div class="mt-4">
            <div class="p-fluid grid">
                <div class="col-12" *ngFor="let property of translationProperties">
                    <label for="name" class="pb-2">{{translationPrefix + '.name'|translate|firstUpper}}
                        <app-tooltip [value]="translationPrefix + '.name.tooltip'|translate"></app-tooltip>
                    </label>
                    <input
                        id="name"
                        type="text" pInputText [formControl]="form.controls[property].controls[i].controls.value">
                    <app-form-error-message [c]="form.controls[property].controls[i].controls.value"></app-form-error-message>
                </div>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>
