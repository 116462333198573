<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="btnLabel-{{uniqueId}}" class="pb-2">
            {{'elements.smallInfoPopup.btnLabel'|translate|firstUpper}}
            <app-tooltip image="element-smallInfoPopup-btnLabel.png"></app-tooltip>
        </label>
        <input id="btnLabel-{{uniqueId}}"
               formControlName="btnLabel" type="text" pInputText
               class="{{stepElementForm.controls.settings.controls.btnLabel.touched && stepElementForm.controls.settings.controls.btnLabel.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-12">
        <label for="title-{{uniqueId}}" class="pb-2">
            {{'elements.smallInfoPopup.title'|translate|firstUpper}}
            <app-tooltip image="element-smallInfoPopup-title.png"></app-tooltip>
        </label>
        <input id="title-{{uniqueId}}"
               formControlName="title" type="text" pInputText
               class="{{stepElementForm.controls.settings.controls.title.touched && stepElementForm.controls.settings.controls.title.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-12">
        <label for="text-{{uniqueId}}" class="pb-2">
            {{'elements.smallInfoPopup.text'|translate|firstUpper}}
            <app-tooltip image="element-smallInfoPopup-text.png"></app-tooltip>
        </label>
        <app-text-editor id="text-{{uniqueId}}" [control]="stepElementForm.controls.settings.controls.text"></app-text-editor>
<!--        <p-editor appQuillEditor id="text" formControlName="text"-->
<!--                  [style]="{'height':'160px'}">-->
<!--            <ng-template pTemplate="header">-->
<!--                <app-editor-header></app-editor-header>-->
<!--            </ng-template>-->
<!--        </p-editor>-->
    </div>
</div>
