<form *ngIf="loaded" [formGroup]="form" appScrollToInvalidControl>
    <div class="p-fluid">
        <div>
            <label for="title" class="pb-2">
                {{'elements.updateClue.title'|translate|firstUpper}}
                <app-tooltip image="element-updateClue-title.png" value="{{'elements.tooltip.updateClue.title'|translate|firstUpper}}"></app-tooltip>
            </label>
            <input
                id="title"
                class="{{form.controls.title.touched && form.controls.title.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="title"
                type="text"
                pInputText>
        </div>
        <div class="mt-4">
            <label for="description" class="pb-2">
                {{'elements.updateClue.description'|translate|firstUpper}}
                <app-tooltip image="element-updateClue-description.png" value="{{'elements.tooltip.updateClue.description'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-text-editor id="description" [control]="form.controls.description"></app-text-editor>
<!--            <p-editor appQuillEditor id="description" formControlName="description"-->
<!--                      [style]="{'height':'160px'}">-->
<!--                <ng-template pTemplate="header">-->
<!--                    <app-editor-header></app-editor-header>-->
<!--                </ng-template>-->
<!--            </p-editor>-->
        </div>
        <div class="mt-4">
            <label for="penalizationPoints" class="pb-2">
                {{'elements.updateClue.penalizationPoints'|translate|firstUpper}}
                <app-tooltip value="{{'elements.tooltip.updateClue.penalizationPoints'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-inputNumber
                id="penalizationPoints"
                [showButtons]="true"
                locale="cs-CS"
                [min]="0"
                class="{{form.controls.penalizationPoints.touched && form.controls.penalizationPoints.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="penalizationPoints"
            ></p-inputNumber>
        </div>
        <div class="mt-4">
            <label for="penalizationTime" class="pb-2">
                {{'elements.updateClue.penalizationTime'|translate|firstUpper}}
                <app-tooltip value="{{'elements.tooltip.updateClue.penalizationTime'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-inputNumber
                id="penalizationTime"
                [showButtons]="true"
                locale="cs-CS"
                [min]="0"
                class="{{form.controls.penalizationTime.touched && form.controls.penalizationTime.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="penalizationTime"
            ></p-inputNumber>
        </div>
        <div class="pt-4 col-12">
            <div style="width: 220px">
                <label for="image" class="pb-2">
                    {{'elements.updateClue.image'|translate|firstUpper}}
                    <app-tooltip image="element-updateClue-image.png" value="{{'elements.tooltip.updateClue.image'|translate|firstUpper}}"></app-tooltip>
                </label>
                <app-file-upload id="image" [readonly]="readonly" [file]="image" [uploadHandler]="onFileUpload()"
                                 [multiple]="false"></app-file-upload>
            </div>
        </div>
    </div>
</form>
<app-dialog-footer>
    <div class="pt-3">
        <button
            *ngIf="!readonly"
            icon="pi pi-plus"
            class="p-button-success p-button-sm"
            pButton
            pRipple
            type="button"
            label="Uložit"
            (click)="updateClue()">
        </button>
    </div>
</app-dialog-footer>

