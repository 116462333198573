import {Component, OnInit, ViewChild} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {EditorComponent} from 'ngx-monaco-editor-v2';

@Component({
  selector: 'app-source-editor',
  templateUrl: './source-editor.component.html',
  styleUrls: ['./source-editor.component.scss']
})
export class SourceEditorComponent implements OnInit {
    editorOptions = {theme: 'vs-dark', language: 'html', minimap: {enabled: false}, "autoIndent": true,
        "formatOnPaste": true,
        "formatOnType": true};
    code = '';
    initCode = '';
    @ViewChild(`editor`) private editor: EditorComponent;

    constructor(
        private ref: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
    ) {
    }

    ngOnInit(): void {
        this.code = this.dynamicDialogConfig.data.text;
        this.initCode = this.code;
    }


    saveCode() {
        this.ref.close(this.code);
    }

    afterEditorInit(editor: EditorComponent) {
        setTimeout(() => {
            // @ts-ignore
            editor._editor.getAction('editor.action.formatDocument').run().then()
        }, 50);
    }
}
