<form *ngIf="loaded" [formGroup]="form" appScrollToInvalidControl>
    <div class="p-fluid pb-4">
        <div>
            <label for="groupName" class="pb-2">{{'gameGroups.form.name'|translate|firstUpper}}</label>
            <input
                #groupName
                id="groupName"
                (keyup)="nameChanged(groupName.value)"
                class="{{form.controls.name.touched && form.controls.name.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="name" type="text" pInputText>
        </div>
        <div class="pt-4" *ngIf="activeFormScheme.showSlug">
            <label for="groupSlug" class="pb-2">
                {{'gameGroups.form.slug'|translate|firstUpper}}
                <app-tooltip [value]="'gameGroups.tooltip.slug'|translate|firstUpper"></app-tooltip>
<!--                <i class="pi pi-question-circle" pTooltip="Slug is the name of city in url, it should be something like 'uherske-hradiste' without accents"></i>-->
            </label>
            <input
                id="groupSlug"
                class="{{form.controls.slug.touched && form.controls.slug.invalid ? 'ng-invalid ng-dirty' : ''}}"
                formControlName="slug" type="text" pInputText>
            <small *ngIf="form.controls.slug.invalid && form.controls.slug.touched" id="username2-help" class="p-error">
                {{form.controls.slug.errors?.slugNotValid ? 'Slug is not unique' : 'Slug must contain only letters, numbers and \'-\' in between words and must have at least 3 letters'}}
            </small>
        </div>
        <div class="pt-4">
            <label for="groupLangId" class="pb-2">{{'gameGroups.form.language'|translate|firstUpper}} <app-tooltip [value]="'gameGroups.tooltip.language'|translate|firstUpper"></app-tooltip></label>
            <p-dropdown [options]="languages" id="groupLangId" formControlName="langId" appendTo="body" [filter]="true" optionValue="id" optionLabel="name">
                <ng-template pTemplate="selectedItem" let-language>
                    <div class="flex align-items-center" *ngIf="form.value.langId">
                        <img style="height: 1rem" *ngIf="language.flagPath" src="{{('/admin/assets/img/flags/svg/' + language.flagPath)|url}}" alt="">
                        <span class="ps-2" style="margin-top: 2px">{{language.name}}</span>
                    </div>
                    <div *ngIf="!form.value.langId">
                        &nbsp;
                    </div>
                </ng-template>
                <ng-template let-language pTemplate="item">
                    <div class="flex align-items-center">
                        <img style="height: 1rem" *ngIf="language.flagPath" src="{{('/admin/assets/img/flags/svg/' + language.flagPath)|url}}" alt="">
                        <span class="ps-2" style="margin-top: 2px">{{language.name}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="pt-4" *ngIf="activeFormScheme.showCity">
            <label for="groupCityId" class="pb-2">{{'gameGroups.form.city'|translate|firstUpper}}</label>
            <p-dropdown [options]="cities" id="groupCityId" formControlName="cityId" [filter]="true" appendTo="body" optionValue="id" optionLabel="name">
            </p-dropdown>
        </div>
    </div>

</form>
<app-dialog-footer>
    <div>
        <button [disabled]="loading.form" [loading]="loading.form" icon="pi pi-plus" class="p-button-success p-button-sm" pButton pRipple type="button" label="{{'general.create'|translate|firstUpper}}" (click)="createGroup()"></button>
    </div>
</app-dialog-footer>
