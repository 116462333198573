import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTranslationsQueryVariables = Types.Exact<{
  filter: Types.ManagerTranslationsFilter;
  translationValueFilter?: Types.InputMaybe<Types.ManagerTranslationValueFilter>;
}>;


export type GetTranslationsQuery = (
  { __typename?: 'Query' }
  & { getManagerTranslations: (
    { __typename?: 'ManagerTranslationsResult' }
    & { meta: (
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    ), result: Array<(
      { __typename?: 'ManagerTranslation' }
      & TranslationFragment
    )> }
  ) }
);

export type TranslationFragment = (
  { __typename?: 'ManagerTranslation' }
  & Pick<Types.ManagerTranslation, 'id' | 'identifier' | 'type' | 'createdAt' | 'updatedAt' | 'description'>
  & { translationValues: Array<(
    { __typename?: 'ManagerTranslationValue' }
    & TranslationValueFragment
  )> }
);

export type TranslationValueFragment = (
  { __typename?: 'ManagerTranslationValue' }
  & Pick<Types.ManagerTranslationValue, 'id' | 'value' | 'dateCreated' | 'dateUpdated'>
  & { lang: (
    { __typename?: 'Lang' }
    & Pick<Types.Lang, 'id'>
  ) }
);

export const TranslationValueFragmentDoc = gql`
    fragment translationValue on ManagerTranslationValue {
  id
  value
  dateCreated
  dateUpdated
  lang {
    id
  }
}
    `;
export const TranslationFragmentDoc = gql`
    fragment translation on ManagerTranslation {
  id
  identifier
  type
  createdAt
  updatedAt
  description
  translationValues(filter: $translationValueFilter) {
    ...translationValue
  }
}
    ${TranslationValueFragmentDoc}`;
export const GetTranslationsDocument = gql`
    query getTranslations($filter: ManagerTranslationsFilter!, $translationValueFilter: ManagerTranslationValueFilter) {
  getManagerTranslations(filter: $filter) {
    meta {
      ...metaFragment
    }
    result {
      ...translation
    }
  }
}
    ${MetaFragmentFragmentDoc}
${TranslationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTranslationsGQL extends Apollo.Query<GetTranslationsQuery, GetTranslationsQueryVariables> {
    document = GetTranslationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }