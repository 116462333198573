<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="label-{{uniqueId}}" class="pb-2">
            {{'elements.phoneNumberInput.placeholder'|translate|firstUpper}}
            <app-tooltip image="element-phoneNumber-placeholder.png"></app-tooltip>
        </label>
        <input id="label-{{uniqueId}}"
               formControlName="placeholder" type="text" pInputText
               class="{{stepElementForm.get('settings.placeholder')?.touched && stepElementForm.get('settings.placeholder')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-12">
        <label for="title-{{uniqueId}}" class="pb-2">
            {{'elements.phoneNumberInput.title'|translate|firstUpper}}
            <app-tooltip image="element-phoneNumber-title.png"></app-tooltip>
        </label>
        <input id="title-{{uniqueId}}"
               formControlName="title" type="text" pInputText
               class="{{stepElementForm.get('settings.title')?.touched && stepElementForm.get('settings.title')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="col-12">
        <label for="text-{{uniqueId}}" class="pb-2">
            {{'elements.phoneNumberInput.text'|translate|firstUpper}}
            <app-tooltip image="element-phoneNumber-text.png"></app-tooltip>
        </label>
        <app-text-editor id="text-{{uniqueId}}" [control]="stepElementForm.controls.settings.controls.text"></app-text-editor>
<!--        <p-editor appQuillEditor id="text-{{uniqueId}}" formControlName="text"-->
<!--                  [style]="{'height':'250px'}">-->
<!--            <ng-template pTemplate="header">-->
<!--                <app-editor-header></app-editor-header>-->
<!--            </ng-template>-->
<!--        </p-editor>-->
    </div>
</div>
