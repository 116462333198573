import * as Types from '../../types.graphql-gen';

import { FullPlayerFragment } from '../../queries/getPlayers/getPlayers.graphql-gen';
import { gql } from 'apollo-angular';
import { FullPlayerFragmentDoc } from '../../queries/getPlayers/getPlayers.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePlayerMutationVariables = Types.Exact<{
  input: Types.PlayerInput;
}>;


export type UpdatePlayerMutation = (
  { __typename?: 'Mutation' }
  & { updatePlayer: (
    { __typename?: 'UpdatePlayerResult' }
    & Pick<Types.UpdatePlayerResult, 'oneTimePassword'>
    & { player?: Types.Maybe<(
      { __typename?: 'Player' }
      & FullPlayerFragment
    )> }
  ) }
);

export const UpdatePlayerDocument = gql`
    mutation updatePlayer($input: PlayerInput!) {
  updatePlayer(input: $input) {
    player {
      ...fullPlayer
    }
    oneTimePassword
  }
}
    ${FullPlayerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePlayerGQL extends Apollo.Mutation<UpdatePlayerMutation, UpdatePlayerMutationVariables> {
    document = UpdatePlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }