import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateCityMutationVariables = Types.Exact<{
  input: Types.CityInput;
}>;


export type UpdateCityMutation = (
  { __typename?: 'Mutation' }
  & { updateCity: (
    { __typename?: 'UpdateCityResult' }
    & Pick<Types.UpdateCityResult, 'removeCityId'>
    & { city?: Types.Maybe<(
      { __typename?: 'City' }
      & FullCityFragment
    )>, changeOrder?: Types.Maybe<Array<(
      { __typename?: 'ChangeOrderResult' }
      & Pick<Types.ChangeOrderResult, 'entityId' | 'sortId'>
    )>> }
  ) }
);

export type CityFragment = (
  { __typename?: 'City' }
  & Pick<Types.City, 'id' | 'name' | 'nameEn' | 'nameCz' | 'photoSource' | 'order' | 'createdAt' | 'updatedAt' | 'hidden'>
);

export type FullCityFragment = (
  { __typename?: 'City' }
  & { translation: (
    { __typename?: 'CityTranslation' }
    & { name: Array<(
      { __typename?: 'EntityTranslation' }
      & EntityTranslationFragment
    )> }
  ), gameGroups: Array<(
    { __typename?: 'GameGroup' }
    & Pick<Types.GameGroup, 'id' | 'name' | 'hidden' | 'order' | 'state' | 'createdAt'>
  )> }
  & CityFragment
);

export type EntityTranslationFragment = (
  { __typename?: 'EntityTranslation' }
  & Pick<Types.EntityTranslation, 'langCode' | 'value'>
);

export const CityFragmentDoc = gql`
    fragment city on City {
  id
  name
  nameEn
  nameCz
  photoSource
  order
  createdAt
  updatedAt
  hidden
}
    `;
export const EntityTranslationFragmentDoc = gql`
    fragment entityTranslation on EntityTranslation {
  langCode
  value
}
    `;
export const FullCityFragmentDoc = gql`
    fragment fullCity on City {
  ...city
  translation {
    name {
      ...entityTranslation
    }
  }
  gameGroups {
    id
    name
    hidden
    order
    state
    createdAt
  }
}
    ${CityFragmentDoc}
${EntityTranslationFragmentDoc}`;
export const UpdateCityDocument = gql`
    mutation updateCity($input: CityInput!) {
  updateCity(input: $input) {
    removeCityId
    city {
      ...fullCity
    }
    changeOrder {
      entityId
      sortId
    }
  }
}
    ${FullCityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCityGQL extends Apollo.Mutation<UpdateCityMutation, UpdateCityMutationVariables> {
    document = UpdateCityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }