import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {fixMutation} from '../../shared/helpers';
import {UpdateCityGQL} from '../../graphql/mutations/updateCity/updateCity.graphql-gen';
import {GetCitiesGQL, GetCityGQL} from '../../graphql/queries/getCities/getCities.graphql-gen';
import {
    fetchCitiesAction,
    fetchCitiesFailAction, fetchCitiesSuccessAction, fetchCityAction, fetchCityFailAction, fetchCitySuccessAction,
    updateCityAction,
    updateCityFailAction,
    updateCitySuccessAction
} from './actions';

@Injectable()
export class CityEffect implements OnDestroy {


    subSink = new SubSink();

    updateCity$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateCityAction
        ),
        concatMap((a) => {
            return fixMutation(this.updateCityGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateCityFailAction({header: '', message: res.errors[0].message});
            }
            return updateCitySuccessAction({res: res.data.updateCity});
        })
    ));

    fetchCities$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchCitiesAction
        ),
        concatMap((a) => {
            return this.getCitiesGQL.fetch({filter: a.input}).pipe(map((res) => ({res, originalAction: a})));
        }),
        map((res) => {
            if(res.res.errors) {
                return fetchCitiesFailAction({header: '', message: res.res.errors[0].message});
            }
            return fetchCitiesSuccessAction({res: res.res.data.getManagerCities});
        })
    ));

    fetchCity$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchCityAction
        ),
        concatMap((a) => {
            return this.getCityGQL.fetch({id: a.id.toString()});
        }),
        map((res) => {
            if(res.errors) {
                return fetchCityFailAction({header: '', message: res.errors[0].message});
            }
            if(!res.data.getManagerCities.result[0]) {
                return fetchCityFailAction({header: '', message: 'license_not_found'});
            }
            return fetchCitySuccessAction({city: res.data.getManagerCities.result[0]});
        })
    ));

    constructor(
        private actions$: Actions,
        private updateCityGQL: UpdateCityGQL,
        private getCityGQL: GetCityGQL,
        private getCitiesGQL: GetCitiesGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
