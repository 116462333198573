import {createAction, props} from '@ngrx/store';
import {DefaultFailMessage} from '../user/actions';
import {UpdatePartnerMutation} from '../../graphql/mutations/updatePartner/updatePartner.graphql-gen';
import {PartnerInput} from '../../graphql/types.graphql-gen';
import {GetPartnersQuery} from '../../graphql/queries/getPartners/getPartners.graphql-gen';


export const fetchPartnersAction = createAction('[Partner] FETCH_PARTNERS');
export const fetchPartnersSuccessAction = createAction('[Partner] FETCH_PARTNERS_SUCCESS', props<FetchPartnersSuccessActionProps>());
export const fetchPartnersFailAction = createAction('[Partner] FETCH_PARTNERS_FAIL', props<FetchPartnersFailActionProps>());

export const updatePartnerAction = createAction('[Partner] UPDATE_PARTNER', props<UpdatePartnerActionProps>());
export const updatePartnerSuccessAction = createAction('[Partner] UPDATE_PARTNER_SUCCESS', props<UpdatePartnerSuccessActionProps>());
export const updatePartnerFailAction = createAction('[Partner] UPDATE_PARTNER_FAIL', props<UpdatePartnerFailActionProps>());

export type UpdatePartnerActionProps = { input: PartnerInput };
export type UpdatePartnerSuccessActionProps = { result: UpdatePartnerMutation['updatePartner']};
export type UpdatePartnerFailActionProps = DefaultFailMessage;

export type FetchPartnersFailActionProps = DefaultFailMessage;
export type FetchPartnersSuccessActionProps = { result: GetPartnersQuery['getPartners']}

