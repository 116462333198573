import {createAction, props} from '@ngrx/store';
import {DefaultFailMessage} from '../user/actions';
import {GetGameGroupsQuery} from '../../graphql/queries/getGameGroups/getGameGroups.graphql-gen';
import {
    Game,
    GameGroup, GameGroupFilter,
    GameGroupInput,
    GameInput,
    GameStepInput,
    UpdateGameStepResult
} from '../../graphql/types.graphql-gen';
import {UpdateGameStepMutation} from '../../graphql/mutations/updateGameStep/updateGameStep.graphql-gen';
import {GameGroupFragment} from '../../graphql/mutations/updateGameGroup/updateGameGroup.graphql-gen';
import {GameFragment} from '../../graphql/mutations/updateGame/updateGame.graphql-gen';

export const fetchGameGroupsAction = createAction('[Games] FETCH_GAME_GROUPS', props<FetchGameGroupsProps>());
export const fetchGameGroupsSuccessAction = createAction('[Games] FETCH_GAME_GROUPS_SUCCESS', props<FetchGameGroupsSuccessProps>());
export const fetchGameGroupsFailAction = createAction('[Games] FETCH_GAME_GROUPS_FAIL', props<FetchGameGroupsFailProps>());

export const fetchGameGroupAction = createAction('[Games] FETCH_GAME_GROUP', props<FetchGameGroupProps>());
export const fetchGameGroupSuccessAction = createAction('[Games] FETCH_GAME_GROUP_SUCCESS', props<FetchGameGroupSuccessProps>());
export const fetchGameGroupFailAction = createAction('[Games] FETCH_GAME_GROUP_FAIL', props<FetchGameGroupFailProps>());

export const updateGameGroupAction = createAction('[Games] UPDATE_GAME_GROUP', props<UpdateGameGroupProps>());
export const updateGameGroupSuccessAction = createAction('[Games] UPDATE_GAME_GROUP_SUCCESS', props<UpdateGameGroupSuccessProps>());
export const updateGameGroupFailAction = createAction('[Games] UPDATE_GAME_GROUP_FAIL', props<UpdateGameGroupFailProps>());

export const fetchGameAction = createAction('[Games] FETCH_GAME', props<FetchGameProps>());
export const fetchGameSuccessAction = createAction('[Games] FETCH_GAME_SUCCESS', props<FetchGameSuccessProps>());
export const fetchGameFailAction = createAction('[Games] FETCH_GAME_FAIL', props<FetchGameFailProps>());

export const updateGameAction = createAction('[Games] UPDATE_GAME', props<UpdateGameProps>());
export const updateGameSuccessAction = createAction('[Games] UPDATE_GAME_SUCCESS', props<UpdateGameSuccessProps>());
export const updateGameFailAction = createAction('[Games] UPDATE_GAME_FAIL', props<UpdateGameFailProps>());

export const updateGameStepAction = createAction('[Games] UPDATE_GAME_STEP', props<UpdateGameStepProps>());
export const updateGameStepSuccessAction = createAction('[Games] UPDATE_GAME_STEP_SUCCESS', props<UpdateGameStepSuccessProps>());
export const updateGameStepFailAction = createAction('[Games] UPDATE_GAME_STEP_FAIL', props<UpdateGameStepFailProps>());


export type FetchGameGroupsProps = {filter: GameGroupFilter};
export type FetchGameGroupsSuccessProps = { gameGroups: GetGameGroupsQuery };
export type FetchGameGroupsFailProps = DefaultFailMessage;

export type FetchGameGroupProps = {id: number};
export type FetchGameGroupSuccessProps = { gameGroup: GameGroupFragment | null};
export type FetchGameGroupFailProps = DefaultFailMessage;

export type UpdateGameGroupProps = { input: GameGroupInput, context?: any };
export type UpdateGameGroupSuccessProps = { gameGroup?: GameGroup, groupDeleted?: number};
export type UpdateGameGroupFailProps = DefaultFailMessage;

export type UpdateGameProps = { input: GameInput, context?: any, groupId: number};
export type UpdateGameSuccessProps = {game?: Game, gameDeleted?: number, gameGroupId: number, checkAppId?: boolean, gameDownloadLink?: string};
export type UpdateGameFailProps = DefaultFailMessage;

export type UpdateGameStepProps = { input: GameStepInput, context?: any, gameId: number, groupId: number};
export type UpdateGameStepSuccessProps = {result: UpdateGameStepMutation['updateGameStep'], gameId: number, groupId: number};
export type UpdateGameStepFailProps = DefaultFailMessage;

export type FetchGameProps = {id: number};
export type FetchGameSuccessProps = {game: GameFragment};
export type FetchGameFailProps = DefaultFailMessage;
