<form *ngIf="loaded" [formGroup]="form">
    <div class="p-fluid pb-4">
        <div>
            <label for="gameGroup" class="pb-2">{{'games.form.gameGroup'|translate|firstUpper}}</label>
            <p-dropdown
                placeholder="{{'games.form.gameGroup.placeholder'|translate|firstUpper}}"
                class="{{form.controls.groupId.invalid ? 'ng-invalid ng-dirty' : ''}}"
                [options]="groupOptions" id="gameGroup"  appendTo="body" formControlName="groupId" [filter]="true" optionValue="id" optionLabel="name">
            </p-dropdown>
        </div>
        <div class="pt-4">
            <div style="width: 220px">
                <label for="zipFile" class="pb-2">
                    {{'games.form.zipFile'|translate|firstUpper}}
                </label>
                <app-file-upload
                    class="{{form.controls.zipFile.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    [file]="zipFile" [type]="'zip'" id="zipFile" [uploadHandler]="onFileUpload()"
                                 [multiple]="false"></app-file-upload>
            </div>
        </div>
    </div>

</form>
<app-dialog-footer>
    <div>
        <button [disabled]="loading.form" [loading]="loading.form" icon="pi pi-plus" class="p-button-success p-button-sm" pButton pRipple type="button" label="{{'games.import'|translate|firstUpper}}" (click)="importGame()"></button>
    </div>
</app-dialog-footer>
