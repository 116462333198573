<div class="grid" *ngIf="loaded">
    <span style="display: none">
        <p-inputNumber
            #radiusControl
            [showButtons]="true"
            locale="cs-CS"
            [prefix]="'radius: '"
            [suffix]="' m.'"
            [(ngModel)]="radius"
        ></p-inputNumber>
    </span>
    <div class="col-12">
        <google-map
            [height]="'600px'"
            width="'100%'"
            [style]="{'margin-bottom': '1em'}"
            [options]="options"

            (mapClick)="handleMapClick($event)"
            (mapInitialized)="initializeMap($event)"></google-map>
<!--        <p-gmap-->
<!--            #gmap-->
<!--            [style]="{'width':'100%','height':'600px', 'margin-bottom': '1em'}"-->
<!--            [options]="options"-->
<!--            [overlays]="overlays"-->
<!--            (onMapClick)="handleMapClick($event, true)"-->
<!--            (onOverlayDragEnd)="pointPositionChanged($event)"-->
<!--            (onMapReady)="initializeMap($event)">-->
<!--        </p-gmap>-->
        <div #menuDiv style="position: fixed; top: 0; left: 0; z-index: 999; display: none">
            <p-menu [popup]="false" [model]="items"></p-menu>
        </div>
    </div>
    <div class="col-auto mx-3">
        <img src="/assets/images/start.png"> - {{'games.zedaSteps.startPosition'|translate|firstUpper}}
    </div>
    <div class="col-auto">
        <img src="/assets/images/finish.png"> - {{'games.zedaSteps.finishPosition'|translate|firstUpper}}
    </div>
</div>
