import * as Types from '../../types.graphql-gen';

import { FullPurchaseFragment } from '../../queries/getPurchase/getPurchase.graphql-gen';
import { gql } from 'apollo-angular';
import { FullPurchaseFragmentDoc } from '../../queries/getPurchase/getPurchase.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePurchaseMutationVariables = Types.Exact<{
  input: Types.PurchaseInput;
}>;


export type UpdatePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { updatePurchase: (
    { __typename?: 'UpdatePurchaseResult' }
    & Pick<Types.UpdatePurchaseResult, 'resendNotificationMailSuccess' | 'purchaseRemoved' | 'purchaseExportLink'>
    & { purchase?: Types.Maybe<(
      { __typename?: 'Purchase' }
      & FullPurchaseFragment
    )> }
  ) }
);

export const UpdatePurchaseDocument = gql`
    mutation updatePurchase($input: PurchaseInput!) {
  updatePurchase(input: $input) {
    purchase {
      ...fullPurchase
    }
    resendNotificationMailSuccess
    purchaseRemoved
    purchaseExportLink
  }
}
    ${FullPurchaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePurchaseGQL extends Apollo.Mutation<UpdatePurchaseMutation, UpdatePurchaseMutationVariables> {
    document = UpdatePurchaseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }