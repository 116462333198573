<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="text-{{uniqueId}}" class="pb-2">Text</label>
        <app-text-editor id="text-{{uniqueId}}" [control]="stepElementForm.controls.settings.controls.text"></app-text-editor>
<!--        <p-editor appQuillEditor id="text-{{uniqueId}}" formControlName="text"-->
<!--                  [style]="{'height':'250px'}">-->
<!--            <ng-template pTemplate="header">-->
<!--                <app-editor-header></app-editor-header>-->
<!--            </ng-template>-->
<!--        </p-editor>-->
    </div>
</div>
