import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchPageDataSuccessAction, updatePageDataSuccessAction} from './actions';

export const pageReducer = createImmerReducer(
    defaultDataState,
    on(fetchPageDataSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPageDataSuccessAction>) => {
        state.page.activePage = action.result;
        return state;
    }),
    on(updatePageDataSuccessAction, (state: IDataState, action: ReturnType<typeof updatePageDataSuccessAction>) => {
        state.page.activePage = action.result.pageData;
        return state;
    })
);
