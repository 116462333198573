import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';

@Component({
    selector: 'app-complete-game',
    templateUrl: './complete-game.component.html',
    styleUrls: ['./complete-game.component.scss']
})
export class CompleteGameComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({});
    }

}

type SettingsControl = {}
