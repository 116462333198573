import {Component, Input, OnInit} from '@angular/core';
import {Image} from 'primeng/image';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

    @Input() value: string = '';
    @Input() position: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
    @Input() icon: string = 'pi pi-question-circle';
    @Input() cursor: string = 'help';
    @Input() image: string|string[]|null = null;

    isArray = Array.isArray;

    showGallery = false;

    images: string[] = [];

    imageElement: any;

    responsiveOptions: any[] = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    constructor(
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        if(this.icon === 'pi pi-question-circle' && this.image) {
            this.icon = 'pi pi-image';
        }
        if(this.image) {
            this.cursor = 'pointer';
        }
        if(Array.isArray(this.image)) {
            this.images = this.image.map((i) => '/assets/images/tooltip/' + i);
        }
    }

    /**
     * This method move expanded element to body, because if p-image is inside fixed (modal element) it wont have 100% width and height
     * @param image
     */
    appendToBody(image: Image) {
        this.imageElement = image.mask!.nativeElement;
        document.body.appendChild(this.imageElement);
    }

    appendBack(imagee: Image) {

    }
}
