import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';
import {StepElement} from '../../../../graphql/types.graphql-gen';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {generateUUID} from '../../../forms/helpers';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';
import {ArrElement} from '../../../forms/types';
import {firstUpper} from '../../../helpers';
import {lastValueFrom} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {UpdateOptionComponent} from './update-option/update-option.component';
import {saveElementPreferencesAction} from '../../../../store/user/actions';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    removeFileFromBufferAction
} from '../../../../store/general/actions';
import * as _ from 'lodash';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent extends ElementBaseComponent<SelectOptionSettingsControl, Preferences | undefined> implements OnInit {

    displayModeOptions = [
        {
            label: firstUpper(this.translateService.instant('elements.selectOption.displayMode.normal')),
            value: 'normal'
        },
        {
            label: firstUpper(this.translateService.instant('elements.selectOption.displayMode.squared')),
            value: 'squared'
        }
    ];

    evaluationModeOptions = [
        {
            label: firstUpper(this.translateService.instant('elements.selectOption.evaluationMode.normal')),
            value: 'normal'
        },
        {
            label: firstUpper(this.translateService.instant('elements.selectOption.evaluationMode.allCorrectAnswers')),
            value: 'allCorrectAnswers'
        }
    ];


    static getSettingsFormGroup(game: GameFragment, stepElement?: StepElement): MyFormGroupV2<SelectOptionSettingsControl> {
        const formGroup: MyFormGroupV2<SelectOptionSettingsControl> = new MyFormGroupV2<SelectOptionSettingsControl>({
            displayMode: new FormControl<'normal' | 'squared'>('normal', {nonNullable: true}),
            shuffleOptions: new FormControl<boolean>(false, {nonNullable: true}),
            evaluationMode: new FormControl<'normal' | 'allCorrectAnswers'>('normal', {nonNullable: true}),
            options: new MyFormArray<MyFormGroupV2<{
                id: FormControl<string>,
                value: FormControl<string>,
                isCorrect: FormControl<boolean>,
                fontSize: FormControl<number | null>,
                src: FormControl<string | null>,
            }>>([]),
        }, settingsFormGroupValidator);
        const options = stepElement?.settings?.options;
        if (options) {
            options.forEach((o: ArrElement<SelectOptionSettingsControl['options']['controls']>['value']) => {
                const fg = SelectOptionComponent.createOptionFormGroup();
                fg.patchValue(o);
                formGroup.controls.options.push(fg);
            });
        }
        return formGroup;
    }

    static createOptionFormGroup(preferences?: Preferences): ArrElement<SelectOptionSettingsControl['options']['controls']> {
        const fg = new MyFormGroupV2({
            id: new FormControl(generateUUID(), {nonNullable: true}),
            value: new FormControl('', {nonNullable: true}),
            isCorrect: new FormControl(false, {nonNullable: true}),
            fontSize: new FormControl<number | null>(preferences?.fontSize ?? null, {validators: Validators.max(200)}),
            src: new FormControl<string | null>(null)
        });
        setTimeout(() => {
            // @ts-ignore
            fg.addValidators(CustomValidatorsService.customValidator((g: ArrElement<SelectOptionSettingsControl['options']['controls']>) => {
                if (!fg) {
                    return null;
                }
                const errors: ValidationErrors = {};
                if (!g.controls.src.value && !g.controls.value.value) {
                    errors['imageOrTextRequired'] = 'elements.selectOption.imageOrTextRequired'
                }
                if (_.isEmpty(errors)) {
                    return null;
                }
                return errors;
            }));
            fg.updateValueAndValidity({onlySelf: true});
        });
        return fg;
    }


    ngOnInit() {
        super.ngOnInit();
        if (!this.stepElementForm.value.id) {
            if (this.preferences) {
                this.stepElementForm.controls.settings.patchValue(this.preferences);
            }
            this.subSink.sink = this.stepElementForm.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
                if (!val?.settings) {
                    return;
                }
                const preferences: Preferences = {
                    ...(this.preferences ?? {}),
                    shuffleOptions: this.stepElementForm.controls.settings.getRawValue().shuffleOptions,
                    displayMode: this.stepElementForm.controls.settings.getRawValue().displayMode,
                    evaluationMode: this.stepElementForm.controls.settings.getRawValue().evaluationMode,
                };
                this.store.dispatch(saveElementPreferencesAction({preferences, name: this.constructor.name}));
            });
        }
    }

    addSelectOption() {
        this.stepElementForm.controls.settings.controls.options.push(SelectOptionComponent.createOptionFormGroup());
    }

    removeSelectOption(index: number) {
        this.stepElementForm.controls.settings.controls.options.removeAt(index);
        this.stepElementForm.controls.settings.controls.options.markAsTouched();
    }

    async updateOption(selectOption?: ArrElement<SelectOptionSettingsControl['options']['controls']>) {
        const isEdit = !!selectOption;

        // WE create this only because user can cancel modal window, and in that case, we dont want to change original formGroup
        const candidateGroup = SelectOptionComponent.createOptionFormGroup(this.preferences);
        if (selectOption) {
            candidateGroup.setValue(selectOption.getRawValue());
        }

        const oldValues = candidateGroup.getRawValue();
        const ref = this.dialogService.open(UpdateOptionComponent, {
            data: {sentence: candidateGroup, readonly: this.readonly},
            header: isEdit ? this.translateService.instant('elements.selectOption.editOption') : this.translateService.instant('elements.selectOption.addOption'),
            width: '700px',
            contentStyle: {'max-height': '500px', 'overflow': 'auto'},
            baseZIndex: 10000,
            dismissableMask: false
        });
        const res: {form: any, file: File|null} = await lastValueFrom(ref.onClose.pipe(take(1)));

        if (!res) {
            return;
        }

        if (candidateGroup.getRawValue().src !== oldValues.src) {
            await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: oldValues.src ?? ''})));
        }

        if (res.file) {
            candidateGroup.controls.src.setValue((await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file: res.file}), [addFileToBufferSuccessAction]))).filename);
        }

        const preferences: Preferences = {
            ...(this.preferences ?? {}),
            fontSize: candidateGroup.controls.fontSize.value
        }
        if (selectOption) {
            selectOption.patchValue(candidateGroup.getRawValue());
        } else {
            this.stepElementForm.controls.settings.controls.options.push(candidateGroup);
            this.store.dispatch(saveElementPreferencesAction({preferences, name: this.constructor.name}));
            this.loadPreferences();
        }
        this.stepElementForm.controls.settings.controls.options.markAsTouched();
    }
}

export type SelectOptionSettingsControl = {
    displayMode: FormControl<'normal' | 'squared'>,
    evaluationMode: FormControl<'normal' | 'allCorrectAnswers'>,
    shuffleOptions: FormControl<boolean>,
    options: MyFormArray<MyFormGroupV2<{
        id: FormControl<string>,
        value: FormControl<string>,
        isCorrect: FormControl<boolean>,
        fontSize: FormControl<number | null>,
        src: FormControl<string | null>
    }>>
}

const settingsFormGroupValidator = CustomValidatorsService.customValidator((control: AbstractControl) => {
    const fg = control as MyFormGroupV2<SelectOptionSettingsControl>;
    const errors: ValidationErrors = {};
    if (fg.controls.options.controls.length === 0) {
        errors['oneOptionRequired'] = 'games.validatorErrors.elements.selectOption.oneOptionRequired';
    } else {
        if (!fg.controls.options.value.find((v) => v.isCorrect)) {
            errors['oneOptionMustBeCorrect'] = 'games.validatorErrors.elements.selectOption.oneOptionMustBeCorrect'
        }
    }
    if (Object.values(errors).length) {
        return errors;
    }
    return null;
});

type Preferences = {
    fontSize?: number | null,
    shuffleOptions?: boolean,
    evaluationMode?: 'normal' | 'allCorrectAnswers',
    displayMode?: 'normal' | 'squared'
}
