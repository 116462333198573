import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { MediaFileFragment } from '../../mutations/updatePushNotification/updatePushNotification.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { MediaFileFragmentDoc } from '../../mutations/updatePushNotification/updatePushNotification.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAttributesQueryVariables = Types.Exact<{
  filter: Types.AttributeFilter;
}>;


export type GetAttributesQuery = (
  { __typename?: 'Query' }
  & { getAttributes: (
    { __typename?: 'AttributesResult' }
    & { meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )>, result: Array<(
      { __typename?: 'Attribute' }
      & AttributeFragment
    )> }
  ) }
);

export type GetAttributeQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetAttributeQuery = (
  { __typename?: 'Query' }
  & { getAttributes: (
    { __typename?: 'AttributesResult' }
    & { result: Array<(
      { __typename?: 'Attribute' }
      & FullAttributeFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type AttributeFragment = (
  { __typename?: 'Attribute' }
  & Pick<Types.Attribute, 'id' | 'name' | 'active' | 'ordering'>
);

export type FullAttributeFragment = (
  { __typename?: 'Attribute' }
  & Pick<Types.Attribute, 'id' | 'name' | 'updatedAt' | 'createdAt' | 'active' | 'type' | 'ordering' | 'level' | 'parentId'>
  & { gameGroups: Array<(
    { __typename?: 'GameGroup' }
    & Pick<Types.GameGroup, 'id' | 'name'>
  )>, games: Array<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name'>
  )>, photoSource?: Types.Maybe<(
    { __typename?: 'MediaFile' }
    & MediaFileFragment
  )> }
);

export const AttributeFragmentDoc = gql`
    fragment attribute on Attribute {
  id
  name
  active
  ordering
}
    `;
export const FullAttributeFragmentDoc = gql`
    fragment fullAttribute on Attribute {
  id
  name
  updatedAt
  createdAt
  active
  type
  ordering
  level
  parentId
  gameGroups {
    id
    name
  }
  games {
    id
    name
  }
  photoSource {
    ...mediaFile
  }
}
    ${MediaFileFragmentDoc}`;
export const GetAttributesDocument = gql`
    query getAttributes($filter: AttributeFilter!) {
  getAttributes(filter: $filter) {
    meta {
      ...metaFragment
    }
    result {
      ...attribute
    }
  }
}
    ${MetaFragmentFragmentDoc}
${AttributeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttributesGQL extends Apollo.Query<GetAttributesQuery, GetAttributesQueryVariables> {
    document = GetAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAttributeDocument = gql`
    query getAttribute($id: String!) {
  getAttributes(filter: {id: $id}) {
    result {
      ...fullAttribute
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullAttributeFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttributeGQL extends Apollo.Query<GetAttributeQuery, GetAttributeQueryVariables> {
    document = GetAttributeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }