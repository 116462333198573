import {createAction, props} from '@ngrx/store';
import {CityInput, ManagerCitiesFilter} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {FullCityFragment, UpdateCityMutation} from '../../graphql/mutations/updateCity/updateCity.graphql-gen';
import {GetCitiesQuery} from '../../graphql/queries/getCities/getCities.graphql-gen';


export const updateCityAction = createAction('[City] UPDATE_CITY', props<{input: CityInput}>());
export const updateCitySuccessAction = createAction('[City] UPDATE_CITY_SUCCESS', props<{res: UpdateCityMutation['updateCity']}>());
export const updateCityFailAction = createAction('[City] UPDATE_CITY_FAIL', props<DefaultFailMessage>());


export const fetchCitiesAction = createAction('[City] LOAD_CITIES', props<{ input: ManagerCitiesFilter}>());
export const fetchCitiesSuccessAction = createAction('[City] LOAD_CITIES_SUCCESS', props<{res: GetCitiesQuery['getManagerCities']}>());
export const fetchCitiesFailAction = createAction('[City] LOAD_CITIES_FAIL', props<DefaultFailMessage>());


export const fetchCityAction = createAction('[City] LOAD_CITY', props<{id: number}>());
export const fetchCitySuccessAction = createAction('[City] LOAD_CITY_SUCCESS', props<{city: FullCityFragment|null}>());
export const fetchCityFailAction = createAction('[City] LOAD_CITY_FAIL', props<DefaultFailMessage>());
