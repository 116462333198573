
<div class="row align-items-stretch">
    <div class="col-6">
        <div *ngIf="image.url && imageCropperLoaded" style="position: relative" #imageWrapper>
            <span [ngStyle]="voucherTextStyle" class="voucher-text" *ngIf="showText && voucherTextStyle">{{activeCodeBoxSettings.testText}}</span>
            <image-cropper
                class="p-0"
                [ngStyle]="{'display': 'block'}"
                [imageURL]="image.url"
                [maintainAspectRatio]="maintainAspectRatio"
                [autoCrop]="true"
                (imageLoaded)="imageLoaded($event)"
                (imageCropped)="imageCropped($event)"
                (cropperReady)="cropperReady($event)"
                [cropper]="cropper"
                format="jpeg"
            >
            </image-cropper>
            <!--    <img class="mb-2" *ngIf="image?.url" [src]="image.url" alt="">-->
        </div>
        <div class="row">
            <div class="col-auto mt-2">
                <label>{{'gameGroups.voucherImage.editParameter'|translate|firstUpper}}</label>
                <div>
                    <p-dropdown #p (onChange)="selectedParameterChanged(p.selectedOption)"  appendTo="body" [options]="parameters" optionLabel="name"></p-dropdown>
                </div>
            </div>
            <div class="col-auto mt-2">
                <label>{{'gameGroups.voucherImage.allowedParameters'|translate|firstUpper}}</label>
                <div>
                    <p-multiSelect #p appendTo="body" [options]="parameters" [(ngModel)]="allowedParameters" optionValue="code" optionLabel="name"></p-multiSelect>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 text-center">
        <img *ngIf="previewLink" style="max-width: 100%" src="{{previewLink}}?t={{previewCount}}">
        <label class="mt-4">{{'gameGroups.voucherImage.generatedPreview'|translate|firstUpper}}</label>
    </div>
</div>
<ng-container *ngIf="image.url">
    <div class="row mt-2">
        <div class="col-auto">
            <label>{{'gameGroups.voucherImage.font'|translate|firstUpper}}</label>
            <div>
                <p-dropdown #f (onChange)="selectedFontChanged(f.selectedOption)" [(ngModel)]="activeCodeBoxSettings.font" optionValue="code"  appendTo="body" [options]="fonts" optionLabel="name">
                    <ng-template pTemplate="selectedItem">
                        <div style="font-family: {{f.selectedOption.code}}">{{ f.selectedOption.name }}</div>
                    </ng-template>
                    <ng-template let-font pTemplate="item">
                        <div style="font-family: {{font.code}}">{{ font.name }}</div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="col-auto">
            <label>{{'gameGroups.voucherImage.color'|translate|firstUpper}}</label>
            <p><p-colorPicker (onChange)="recalculateText(this.lastCropperPosition)" appendTo="body" [(ngModel)]="activeCodeBoxSettings.color"></p-colorPicker>&nbsp;
                <input type="text" pInputText [(ngModel)]="activeCodeBoxSettings.color">
        </div>
        <div class="col-auto">
            <label>{{'gameGroups.voucherImage.testText'|translate|firstUpper}}<app-tooltip value="{{'gameGroups.tooltip.voucherImage.testText'|translate|firstUpper}}"></app-tooltip></label>
            <p><input type="text" pInputText [(ngModel)]="activeCodeBoxSettings.testText"></p>
        </div>
        <div class="col-auto">
            <label>{{'gameGroups.voucherImage.fontSize'|translate|firstUpper}}</label>
            <p>
                <p-inputNumber (ngModelChange)="recalculateText(this.lastCropperPosition)" [(ngModel)]="activeCodeBoxSettings.fontSize" mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="10" [max]="1000">
                </p-inputNumber>
            </p>
        </div>
    </div>
</ng-container>
<app-dialog-footer>
    <div class="">
        <button [disabled]="loading" [loading]="loading" icon="pi pi-plus" class="p-button-success p-button-sm" pButton pRipple type="button" label="{{'general.save'|translate|firstUpper}}" (click)="saveVoucherImage()"></button>
    </div>
</app-dialog-footer>
