import {Component, OnInit} from '@angular/core';
import {MyFormGroupV2} from '../../../forms/forms';
import {AppActions} from '../../../../store/app-actions.service';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {
    updateProductPartnerAction,
    updateProductPartnerFailAction,
    updateProductPartnerSuccessAction
} from '../../../../store/productPartner/actions';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-new-product-partner',
  templateUrl: './new-product-partner.component.html',
  styleUrls: ['./new-product-partner.component.scss']
})
export class NewProductPartnerComponent implements OnInit {

    loaded = false;
    loading = {
        form: false
    };
    form: MyFormGroupV2<{
        name: FormControl<string>,
    }>;

    constructor(
        private appActions: AppActions,
        private messageService: MessageService,
        public ref: DynamicDialogRef,
    ) {
    }

    ngOnInit(): void {
        this.form = new MyFormGroupV2({
            name: new UntypedFormControl('', Validators.required)
        });
        this.loaded = true;
    }

    async createProductPartner() {
        this.loading.form = true;
        const action = await lastValueFrom(this.appActions.dispatch(updateProductPartnerAction({
            input: {
                updateProductPartner: {
                    name: this.form.getRawValue().name,
                    defaultCommission: 33,
                    productPartnerSaleSources: [],
                    notificationEmails: '',
                    allowedPayments: []

                }
            }
        }), [updateProductPartnerSuccessAction, updateProductPartnerFailAction]));
        if (action.type === updateProductPartnerFailAction.type) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: action.message});
            this.loading.form = false;
            return;
        }
        this.ref.close();
    }

}
