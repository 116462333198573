import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPagesDataQueryVariables = Types.Exact<{
  input: Types.PageDataFilter;
}>;


export type GetPagesDataQuery = (
  { __typename?: 'Query' }
  & { getPagesData: (
    { __typename?: 'PagesDataResult' }
    & { pagesData: Array<(
      { __typename?: 'FrontendPageData' }
      & PageDataFragment
    )> }
  ) }
);

export type PageDataFragment = (
  { __typename?: 'FrontendPageData' }
  & { pageGameGroups: Array<(
    { __typename?: 'PageGameGroup' }
    & PageGameGroupFragment
  )>, pageLangs: Array<(
    { __typename?: 'PageLang' }
    & PageLangFragment
  )> }
);

export type PageGameGroupFragment = (
  { __typename?: 'PageGameGroup' }
  & Pick<Types.PageGameGroup, 'page'>
  & { gameGroup: (
    { __typename?: 'GameGroup' }
    & Pick<Types.GameGroup, 'id' | 'name'>
  ) }
);

export type PageLangFragment = (
  { __typename?: 'PageLang' }
  & Pick<Types.PageLang, 'page' | 'isDefault'>
  & { lang: (
    { __typename?: 'Lang' }
    & Pick<Types.Lang, 'id'>
  ) }
);

export const PageGameGroupFragmentDoc = gql`
    fragment pageGameGroup on PageGameGroup {
  page
  gameGroup {
    id
    name
  }
}
    `;
export const PageLangFragmentDoc = gql`
    fragment pageLang on PageLang {
  page
  isDefault
  lang {
    id
  }
}
    `;
export const PageDataFragmentDoc = gql`
    fragment pageData on FrontendPageData {
  pageGameGroups {
    ...pageGameGroup
  }
  pageLangs {
    ...pageLang
  }
}
    ${PageGameGroupFragmentDoc}
${PageLangFragmentDoc}`;
export const GetPagesDataDocument = gql`
    query getPagesData($input: PageDataFilter!) {
  getPagesData(input: $input) {
    pagesData {
      ...pageData
    }
  }
}
    ${PageDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPagesDataGQL extends Apollo.Query<GetPagesDataQuery, GetPagesDataQueryVariables> {
    document = GetPagesDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }