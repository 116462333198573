import {Injectable, OnDestroy} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    loginSuccessAction,
    logoutAction,
    saveElementPreferencesAction,
    updateUserPermissionsAction
} from '../user/actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {defaultDataState, IAppState} from '../state';
import {Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import {MyStorageService} from '../../services/my-storage.service';
import {FileBufferService} from '../../services/file-buffer.service';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    clearFileBufferAction,
    readFileBufferAction,
    readFileBufferSuccessAction,
    readFileUrlAction,
    readFileUrlSuccessAction,
    removeFileFromBufferAction,
    uploadBufferFilesAction,
    uploadBufferFilesFailAction,
    uploadBufferFilesSuccessAction,
    uploadFilesAction,
    uploadFilesFailAction,
    uploadFilesSuccessAction
} from './actions';
import {getThumbnailPath} from '../../shared/forms/helpers';
import {from, lastValueFrom, of} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {fixMutation} from '../../shared/helpers';
import {createFilesContext} from '../../interceptors/request.interceptor';
import {AppActions} from '../app-actions.service';
import {ProgressService} from '../../services/progress.service';
import {saveFilterAction} from '../helperData/actions';
import * as _ from 'lodash';
import {UploadFilesGQL} from '../../graphql/mutations/uploadFiles/uploadFiles.graphql-gen';

@Injectable()
export class GeneralEffect implements OnDestroy {

    private state?: IAppState;
    subSink = new SubSink();

    saveState$ = createEffect(() => this.actions$.pipe(
        ofType(
            loginSuccessAction,
            logoutAction,
            saveFilterAction,
            updateUserPermissionsAction,
            saveElementPreferencesAction
        ),
        tap((a) => {
            if (!this.state) {
                return;
            }
            const data = _.cloneDeep(defaultDataState);
            data.helperData.filters = this.state.data.helperData.filters;
            this.storageService.saveState({
                user: this.state.user,
                data
            }).then();
        })
    ), {dispatch: false});

    addFileToBuffer$ = createEffect(() => this.actions$.pipe(
        ofType(
            addFileToBufferAction
        ),
        map((action) => {
            return addFileToBufferSuccessAction({filename: this.fileBufferService.addFile(action.file)});
        })
    ));

    removeFileFromBuffer$ = createEffect(() => this.actions$.pipe(
        ofType(
            removeFileFromBufferAction
        ),
        tap((action) => {
            this.fileBufferService.removeFile(action.filename);
        })
    ), {dispatch: false});

    clearFileBuffer$ = createEffect(() => this.actions$.pipe(
        ofType(
            clearFileBufferAction
        ),
        tap((action) => {
            this.fileBufferService.clearBuffer();
        })
    ), {dispatch: false});

    readFileBuffer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                readFileBufferAction
            ),
            map((a) => {
                return readFileBufferSuccessAction({files: [...this.fileBufferService.getFiles()]});
            })
        );
    });

    readFileUrl$ = createEffect(() => this.actions$.pipe(
        ofType(readFileUrlAction),
        switchMap((action) => {
            const file = this.fileBufferService.getFiles(true).find((f) => f.name === action.filename);
            let url = getThumbnailPath(action.filename);
            if (file) {
                // return convertFileToUrl(file)
                return of(this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)));
            }
            return of(url);
        }),
        map((url) => {
            return readFileUrlSuccessAction({url});
        })
    ));

    uploadFiles$ = createEffect(() => this.actions$.pipe(
        ofType(uploadFilesAction),
        switchMap((action) => {
            return fixMutation(this.uploadFilesGQL.mutate({input: {files: action.files.map((f) => ({name: f.name}))}}, createFilesContext(action.files)));
        }),
        map((res) => {
            if (res.errors) {
                return uploadFilesFailAction({header: '', message: res.errors[0].message});
            }
            return uploadFilesSuccessAction();
        })
    ));

    uploadBufferFiles = createEffect(() => this.actions$.pipe(
        ofType(uploadBufferFilesAction),
        switchMap((action) => {
            // const type = typeOfAction(uploadBufferFilesSuccessAction);
            const files = [...this.fileBufferService.getFiles()];
            let amount = files.length;
            let uploaded = 0;
            this.progressService.progress$.next({...action.initialProgress, filesAmount: amount, uploaded});
            return from((new Promise(async (resolve, reject) => {
                for (const file of files) {
                    const uploadResAction = await lastValueFrom(this.appActions$.dispatch(uploadFilesAction({files: [file]}), [uploadFilesSuccessAction, uploadFilesFailAction]));
                    if (uploadResAction.type === uploadFilesFailAction.type) {
                        reject(uploadResAction.message);
                        return;
                    }
                    this.fileBufferService.removeFile(file.name);
                    uploaded++;
                    this.progressService.progress$.next({...action.initialProgress, filesAmount: amount, uploaded, forceClose: false});
                }
                resolve(uploadBufferFilesSuccessAction());
            })) as Promise<ReturnType<typeof uploadBufferFilesSuccessAction>>).pipe(
                catchError((error) => of(uploadBufferFilesFailAction({header: '', message: error}))),
            );
        }),
        map((res) => {
            return res;
        })
    ));


    constructor(
        private actions$: Actions,
        private appActions$: AppActions,
        private store: Store<IAppState>,
        private storageService: MyStorageService,
        private fileBufferService: FileBufferService,
        private domSanitizer: DomSanitizer,
        private uploadFilesGQL: UploadFilesGQL,
        private progressService: ProgressService
    ) {
        this.subSink.sink = this.store.select((state) => state).subscribe((s) => {
            this.state = s;
        });
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

}
