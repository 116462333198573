<ng-container *ngIf="loaded">
    <p-dropdown *ngIf="!multiSelect" [options]="gameOptions" [formControl]="control" appendTo="body"
                [placeholder]="'gameVoucher.form.gamePlaceholder'|translate|firstUpper" [filter]="true" [group]="true"
                scrollHeight="400px"
                (onChange)="onChange.emit($event)"
                class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}"
                optionValue="id" optionLabel="keywords">
        <ng-template pTemplate="selectedItem" let-gameOption>
            <div class="flex align-items-center {{gameOption.state === 'hidden' ? 'game-hidden' : ''}}">
                <span
                    class="ps-2 custom-badge-alt custom-badge-{{gameOption.difficulty}}">{{'games.difficulty.' + gameOption.difficulty|translate|firstUpper}}</span>
                &nbsp;-&nbsp;
                <span style="margin-top: 2px; display: inline-block">{{gameOption.name}} - <strong>{{gameOption.price}}
                    ,-</strong></span>
            </div>
        </ng-template>
        <ng-template let-group pTemplate="group">
            <div class="flex align-items-center {{group.state === 'hidden' ? 'group-hidden' : ''}}">
                <img style="height: 4rem" *ngIf="group.photoSource" [src]="group.photoSource|resourcePath|async" alt="">
                <span class="ps-2" style="margin-top: 2px">{{group.name}}</span>
                &nbsp;&nbsp;
                <img style="height: 1.5rem" *ngIf="group.flagPath"
                     src="{{('/admin/assets/img/flags/svg/' + group.flagPath)|url}}" alt="">
            </div>
        </ng-template>
        <ng-template let-gameOption pTemplate="item">
            <div class="flex align-items-center {{gameOption.state === 'hidden' ? 'game-hidden' : ''}}">
                <span style="margin-left: 4.5rem;"
                      class="ps-2 custom-badge-alt custom-badge-{{gameOption.difficulty}}">{{'games.difficulty.' + gameOption.difficulty|translate|firstUpper}}</span>
                &nbsp;-&nbsp;
                <span style="margin-top: 2px; display: inline-block">{{gameOption.name}} - <strong>{{gameOption.price}}
                    ,-</strong></span>
            </div>
        </ng-template>
    </p-dropdown>
    <p-multiSelect
        *ngIf="multiSelect && onlyGroup"
        (onPanelShow)="reorderGameGroups()"
        [formControl]="control"
        appendTo="body"
        scrollHeight="400px"
        class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}"
        [options]="gameOptions"
        [filter]="true"
        optionValue="id" optionLabel="name"
    >
        <ng-template let-group pTemplate="item">
            <div class="flex align-items-center {{group.state === 'hidden' ? 'group-hidden' : ''}}">
                <img style="height: 4rem" *ngIf="group.photoSource" [src]="group.photoSource|resourcePath|async" alt="">
                <span class="ps-2" style="margin-top: 2px">{{group.name}}</span>
                &nbsp;&nbsp;
                <img style="height: 1.5rem" *ngIf="group.flagPath"
                     src="{{('/admin/assets/img/flags/svg/' + group.flagPath)|url}}" alt="">
            </div>
        </ng-template>
    </p-multiSelect>
    <p-multiSelect
        (onChange)="onChange.emit($event)"
        (onPanelShow)="reorderGameGroups()"
        *ngIf="multiSelect && !onlyGroup"
        appendTo="body"
        [formControl]="control"
        [placeholder]="placeholder"
        scrollHeight="400px"
        class="{{control.invalid ? 'ng-invalid ng-dirty' : ''}}"
        [options]="gameOptions"
        [filter]="true" [group]="true"
        optionValue="id" optionLabel="keywords"
    >
        <ng-template pTemplate="selectedItem" let-gameOption>
            <div class="flex align-items-center {{gameOption.state === 'hidden' ? 'game-hidden' : ''}}">
                <span
                    class="ps-2 custom-badge-alt custom-badge-{{gameOption.difficulty}}">{{'games.difficulty.' + gameOption.difficulty|translate|firstUpper}}</span>
                &nbsp;-&nbsp;
                <span style="margin-top: 2px; display: inline-block">{{gameOption.name}} - <strong>{{gameOption.price}}
                    ,-</strong></span>
            </div>
        </ng-template>
        <ng-template let-group pTemplate="group">
            <div class="flex align-items-center cursor-pointer {{group.state === 'hidden' ? 'group-hidden' : ''}}" (click)="toggleGroup(group)">
                <img style="height: 4rem" *ngIf="group.photoSource" [src]="group.photoSource|resourcePath|async" alt="">
                <span class="ps-2" style="margin-top: 2px">{{group.name}}</span>
                &nbsp;&nbsp;
                <img style="height: 1.5rem" *ngIf="group.flagPath"
                     src="{{('/admin/assets/img/flags/svg/' + group.flagPath)|url}}" alt="">
            </div>
        </ng-template>
        <ng-template let-gameOption pTemplate="item">
            <div class="flex align-items-center {{gameOption.state === 'hidden' ? 'game-hidden' : ''}}">
                <span style="margin-left: 4.5rem;"
                      class="ps-2 custom-badge-alt custom-badge-{{gameOption.difficulty}}">{{'games.difficulty.' + gameOption.difficulty|translate|firstUpper}}</span>
                &nbsp;-&nbsp;
                <span style="margin-top: 2px; display: inline-block">{{gameOption.name}} - <strong>{{gameOption.price}}
                    ,-</strong></span>
            </div>
        </ng-template>
    </p-multiSelect>

</ng-container>
