<p-fieldset legend="{{fieldsetTitle}}" [toggleable]="true">
    <div class="add-element">
        <div>
            <button [disabled]="readonly" pButton pRipple type="button" icon="pi pi-plus" label="{{'elements.addElement'|translate|firstUpper}}" class="p-button-text"
                    (click)="contentElementsMenu.toggle($event)"></button>
        </div>
        <div>
            <p-menu #contentElementsMenu [popup]="true" [model]="allowedElementTypes"
                    appendTo="body"></p-menu>
        </div>
    </div>
    <div cdkDropList class="drop-list drop-list--borders" (cdkDropListDropped)="drop($event)">
        <div class="m-3">
            <ng-template ngFor let-control [ngForOf]="formArray.controls" let-index="index">
                <div class="row drop-box {{control.invalid ? 'element-invalid' : ''}}" cdkDrag>
                    <div class="col-12 p-0 ps-2">
                        <div class="row align-items-center">
                            <div class="col-2 col-md-1">
                                <div class="example-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="col-8 col-md-9">
                                <span #icon (click)="toggle(icon)" style="cursor: pointer"
                                      *ngIf="!elementsWithoutSettings.includes(control.getRawValue().type)"
                                      class="pt-2 pb-2 element-type"
                                      data-bs-toggle="collapse"
                                      attr.data-bs-target="#collapse-{{control.value.tempId}}"
                                      aria-expanded="false"
                                      attr.aria-controls="collapse-{{control.value.tempId}}">
                                    {{('elements.' + control.value.type)|translate|firstUpper}}
                                    <i class="pi pi-chevron-down ps-2 pe-2"
                                    ></i>
                                </span>
                                <span style="cursor: pointer"
                                      *ngIf="elementsWithoutSettings.includes(control.getRawValue().type)"
                                      class="pt-2 pb-2 element-type"
                                >
                                     {{('elements.' + control.value.type)|translate|firstUpper}}
                                    <ng-container *ngIf="control.errors">
                                        <br>
                                        <app-error-list [errors]="control.errors"></app-error-list>
                                    </ng-container>
                                </span>
                            </div>
                            <div class="col-2 col-md-2 text-right">
                                <button *ngIf="!readonly" (click)="removeElement(index)" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapse-{{control.value.tempId}}">
                        <app-step-wrapper [readonly]="readonly" class="block mt-3 border-top-1 pt-4" style="border-color: lightgray"
                                          [formGroup]="control"></app-step-wrapper>
                    </div>
                </div>
                <div class="mb-3"></div>
            </ng-template>
        </div>
    </div>
    <!--    <div class="mt-2 mb-0">-->
    <!--        <div>-->
    <!--            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-success"-->
    <!--                    (click)="contentElementsMenu.toggle($event)"></button>-->
    <!--        </div>-->
    <!--        <div>-->
    <!--            <p-menu #contentElementsMenu [popup]="true" [model]="allowedElementTypes"-->
    <!--                    appendTo="body"></p-menu>-->
    <!--        </div>-->
    <!--    </div>-->
</p-fieldset>
