import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {AppActions} from '../store/app-actions.service';
import {loginAction, loginFailAction, loginSuccessAction} from '../store/user/actions';
import {Router} from '@angular/router';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from '@abacritt/angularx-social-login';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LoginCredentialsInput} from '../graphql/types.graphql-gen';
import {environment} from '../../environments/environment';
import {BaseComponent} from '../shared/components/base/base.component';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit, OnDestroy {

    @ViewChild(`loginFailedAlert`) private loginFailedAlert: SwalComponent;
    isLoading = false;
    projectName = environment.projectName;
    googleAllowed = !!environment.googleClientId;

    constructor(
        private appAction: AppActions,
        private router: Router,
        private socialAuthService: SocialAuthService,
        private messageService: MessageService,
        private translateService: TranslateService
    ) {
        super();
    }

    async ngOnInit() {
        try {
            await this.socialAuthService.signOut(true);
        } catch (e) {

        }
        this.subSink.sink = this.socialAuthService.authState.subscribe((user) => {
            if(!user) {
                return;
            }
            if(user.provider.toLowerCase() === 'google') {
                this.login({email: user.email, googleIdToken: user.idToken});
            }
        })
    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onSubmit(loginForm: NgForm) {
        this.isLoading = true;
        this.login(loginForm.form.value);
    }

    login(creadentials: LoginCredentialsInput) {
        this.isLoading = true;
        this.appAction.dispatch(loginAction({loginCredentials: creadentials}), [loginFailAction, loginSuccessAction])
            .subscribe((a) => {
                console.log(a);
                if(a.type === loginFailAction.type) {
                    this.loginFailedAlert.text = a.message;
                    this.loginFailedAlert.fire().then();
                } else {
                    this.router.navigate(['/', 'admin', 'games']).then();
                }
                this.isLoading = false;
            });
    }

    async facebookLogin() {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
            if(!user.email) {
                this.loginFailedAlert.text = this.translateService.instant('auth.facebookMissingEmail');
                this.loginFailedAlert.fire().then();
                return;
            }
            this.login({email: user.email, facebookAccessToken: user.authToken});

        }, (e) => {
            this.loginFailedAlert.text = e.toString();
            this.loginFailedAlert.fire().then();
        });
    }
}
