// ACTIONS
import {createAction, props} from '@ngrx/store';
import {ReviewFilter, ReviewInput} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {FullReviewFragment, GetReviewsQuery} from '../../graphql/queries/getReviews/getReviews.graphql-gen';
import {UpdateReviewMutation} from '../../graphql/mutations/updateReview/updateReview.graphql-gen';

export const fetchReviewsAction = createAction('[Review] FETCH_REVIEWS', props<{ filter: ReviewFilter }>());
export const fetchReviewsSuccessAction = createAction('[Review] FETCH_REVIEWS_SUCCESS', props<{result: GetReviewsQuery['getReviews']}>());
export const fetchReviewsFailAction = createAction('[Review] FETCH_REVIEWS_FAIL', props<DefaultFailMessage>());

export const fetchReviewAction = createAction('[Review] FETCH_REVIEW', props<{ id: number }>());
export const fetchReviewSuccessAction = createAction('[Review] FETCH_REVIEW_SUCCESS', props<{review: FullReviewFragment}>());
export const fetchReviewFailAction = createAction('[Review] FETCH_REVIEW_FAIL', props<DefaultFailMessage>());

export const updateReviewAction = createAction('[Review] UPDATE_REVIEW', props<{input: ReviewInput}>());
export const updateReviewSuccessAction = createAction('[Review] UPDATE_REVIEW_SUCCESS', props<{result: UpdateReviewMutation['updateReview']}>());
export const updateReviewFailAction = createAction('[Review] UPDATE_REVIEW_FAIL', props<DefaultFailMessage>())
