<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="seconds-{{uniqueId}}" class="pb-2">
            {{'elements.timeout.seconds'|translate|firstUpper}}
            <app-tooltip value="{{'elements.tooltip.timeout.seconds'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-inputNumber id="seconds-{{uniqueId}}"
                       formControlName="seconds"
                       mode="decimal"
                       [min]="1" [showButtons]="true"
                       class="{{stepElementForm.controls.settings.controls.seconds.touched && stepElementForm.controls.settings.controls.seconds.invalid ? 'ng-invalid ng-dirty' : ''}}">
        </p-inputNumber>
    </div>
    <div class="col-6">
        <label for="showSpinner-{{uniqueId}}" class="pb-2">
            {{'elements.timeout.showSpinner'|translate|firstUpper}}
            <app-tooltip value="{{'elements.tooltip.timeout.showSpinner'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton id="showSpinner-{{uniqueId}}" [options]="boolOptions"
                        formControlName="showSpinner" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="col-6">
        <label for="showTimer-{{uniqueId}}" class="pb-2">
            {{'elements.timeout.showTimer'|translate|firstUpper}}
            <app-tooltip value="{{'elements.timeout.showTimer.tooltip'|translate|firstUpper}}" [image]="'game-step-timeout-showTimer.png'"></app-tooltip>
        </label>
        <p-selectButton id="showTimer-{{uniqueId}}" [options]="boolOptions"
                        formControlName="showTimer" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>

</div>
