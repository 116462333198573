import {Component, OnInit} from '@angular/core';
import {AnswerData} from '../element-types';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, Validators} from '@angular/forms';
import {ElementBaseWithCluesComponent} from '../element-base-with-clues.component';
import * as _ from 'lodash';
import {sha512} from 'js-sha512';
import {environment} from '../../../../../environments/environment';
import {debounceTime} from 'rxjs/operators';
import {saveElementPreferencesAction} from '../../../../store/user/actions';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends ElementBaseWithCluesComponent<SettingsControl, Preferences | undefined> implements OnInit {

    allowHashedVersion = environment.options.elements.allowHashedVersion;
    inputmodeOptions = [
        {
            name: this.translateService.instant('elements.input.inputmode.default'),
            value: null
        },
        {
            name: this.translateService.instant('elements.input.inputmode.numeric'),
            value: 'numeric'
        },
        {
            name: this.translateService.instant('elements.input.inputmode.decimal'),
            value: 'decimal'
        },
        {
            name: this.translateService.instant('elements.input.inputmode.tel'),
            value: 'tel'
        }
    ];


    ngOnInit() {
        super.ngOnInit();
        if (!this.stepElementForm.value.id) {
            if (this.preferences) {
                this.stepElementForm.controls.settings.patchValue(this.preferences);
            }
            this.subSink.sink = this.stepElementForm.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
                if(!val?.settings) {
                    return;
                }
                const preferences: Preferences = {
                    hashedVersion: this.stepElementForm.getRawValue().settings.hashedVersion,
                    strictMode: this.stepElementForm.getRawValue().settings.strictMode,
                    allAnswersCorrect: this.stepElementForm.getRawValue().settings.allAnswersCorrect,
                };
                this.store.dispatch(saveElementPreferencesAction({preferences, name: this.constructor.name}));
            });
        }

    }

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            skip_info: new FormControl<string|null>(null),
            placeholder: new FormControl<string>('', {nonNullable: true}),
            answers: new FormControl<AnswerData[]>([], {validators: Validators.required, nonNullable: true}),
            inputmode: new FormControl<null | 'decimal' | 'numeric' | 'tel'>(null),
            hashedVersion: new FormControl(false, {nonNullable: true}),
            allAnswersCorrect: new FormControl<boolean>(false, {nonNullable: true}),
            strictMode: new FormControl<boolean>(false, {nonNullable: true})
        });
    }

    answerAdded($event: any) {
        let answers = _.uniqBy(this.stepElementForm.controls.settings.controls.answers.value
            .map((ad) => typeof ad === 'object' ? {value: _.trim(ad.value)} : {value: _.trim(ad)}), 'value');
        if (this.stepElementForm.controls.settings.controls.hashedVersion.value) {
            answers = answers.filter((v) => v.value !== $event.value);
            if (!answers.find(v => v.value === sha512($event.value))) {
                answers.push({value: sha512($event.value)});
            }
        }
        this.stepElementForm.controls.settings.controls.answers
            .setValue(answers);
    }

    hashedVersionChanged() {
        this.stepElementForm.controls.settings.controls.answers.setValue([]);
    }

    allAnswersCorrectChanged() {
        if (!this.stepElementForm.getRawValue().settings.allAnswersCorrect) {
            return;
        }
        const newValues = this.stepElementForm.getRawValue().settings.answers;
        if (!newValues.find((v) => v.value === 'ALL')) {
            newValues.push({value: 'ALL'});
        }
        this.stepElementForm.controls.settings.controls.answers.setValue(newValues);
        this.stepElementForm.controls.settings.controls.hashedVersion.setValue(false);
        this.stepElementForm.controls.settings.controls.strictMode.setValue(false);
    }
}

type Preferences = {
    hashedVersion: any,
    allAnswersCorrect: any,
    strictMode: any
}


type SettingsControl = {
    answers: FormControl<AnswerData[]>,
    placeholder: FormControl<string>,
    skip_info: FormControl<string | null>,
    inputmode: FormControl<null | 'decimal' | 'numeric' | 'tel'>
    hashedVersion: FormControl<boolean>,
    allAnswersCorrect: FormControl<boolean>,
    strictMode: FormControl<boolean>
}
