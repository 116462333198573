import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AclRightType, AclService} from '../../services/acl.service';

@Directive({
  selector: '[appIsAllowed]'
})
export class IsAllowedDirective {

    rule: Rule;
    constructor(private aclService: AclService,
                private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input()
    set appIsAllowed(rule: Rule) {
        this.rule = rule;
        this.updateView();
    }

    /**
     * Update view to keep or delete current element.
     */
    private updateView() {
        if (this.aclService.isAllowed(this.rule.right, this.rule.extraData)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}


export type Rule = {
    right: AclRightType,
    extraData?: { gameId?: number, group?: { games: { id: number }[] } }
}
