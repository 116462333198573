import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {DefaultFailMessage} from '../../../../store/user/actions';
import {SafeUrl} from '@angular/platform-browser';
import {getVoucherPath} from '../../../forms/helpers';
// @ts-ignore
import {saveAs} from 'file-saver';
import * as _ from 'lodash';
import {lastValueFrom} from 'rxjs';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    removeFileFromBufferAction
} from '../../../../store/general/actions';
import {FileInputHelper} from '../../../../graphql/types.graphql-gen';
import {readFileUrl} from '../../../helpers';
import {AppActions} from '../../../../store/app-actions.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    disabled = false;
    lastFilename: string | null = null;
    @ViewChild('fileUpload') primeFileUpload: FileUpload;
    @ViewChild('audio') audio: ElementRef;
    @ViewChild('video') video: ElementRef;
    @HostBinding('class.multi') multiClass: boolean = false;


    @Input() uploadHandler: (file: File | null) => Promise<true | DefaultFailMessage> = (file) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    };

    @Input() readonly = false;
    @Input() file: { loading: boolean, url: string | SafeUrl | null, fullUrl?: string };
    @Input() multiple = true;
    @Input() accept: string = '.jpg,.jpeg,.png,.gif';
    @Input() type: 'image' | 'video' | 'zip' | 'audio' = 'image';

    @Input() control: FormControl | null = null;
    loading = {
        remove: false,
        upload: true
    };

    constructor(
        private appActions: AppActions
    ) {
    }

    ngOnInit(): void {
        if (this.type === 'zip') {
            this.accept = '.zip';
        }
        if (this.multiple) {
            this.multiClass = true;
        } else {
            if (this.control) {
                if(!this.file) {
                    this.file = {loading: false, url: null};
                }
                this.uploadHandler = this.uploadWithControlHandler();
            }
        }
    }


    reloadFile() {
        if (this.audio) {
            this.audio.nativeElement.load();
            return;
        }
        if (this.video) {
            this.video.nativeElement.load();
            return;
        }
    }

    async upload($event: any) {
        if ($event.files.length === 0) {
            return;
        }
        this.disabled = true;
        let progress = 0;
        const step = 100 / $event.files.length;
        for (const file of ($event.files as File[])) {
            await this.uploadHandler(file);
            progress += step;
            this.primeFileUpload.onProgress.emit({progress, originalEvent: null as any});
        }

        setTimeout((e) => {
            this.primeFileUpload.onProgress.emit({progress: 0, originalEvent: e});
            this.disabled = false;
            this.primeFileUpload.clear();
        }, 1000);
    }

    async uploadSingle($event: any) {
        const file: File = $event?.files[0];
        if (!file) {
            return;
        }
        this.lastFilename = file.name;
        this.loading.upload = true;
        await this.uploadHandler(file);
        this.primeFileUpload.clear();
        this.loading.upload = false;
    }

    async removeSingle() {
        this.loading.remove = true;
        await this.uploadHandler(null);
        this.primeFileUpload.clear();
        this.loading.remove = false;
    }

    isString(val: any) {
        return typeof val === 'string';
    }

    updateProgress(progress: any) {
        this.primeFileUpload.progress = Math.abs(progress - 100) < 0.2 ? 100 : progress;
    }

    download(url: any) {
        saveAs(url.toString());
    }


    uploadWithControlHandler = (): ((file: File | null) => Promise<true>) => {
        let lastBufferedFilename: string | null = null;
        return async (file: File | null) => {
            if (!this.control) {
                throw new Error('control is required if you want to use this method');
            }
            let filename = null;
            if (file) {
                filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
                this.control.setValue({name: filename} as FileInputHelper);
                this.control.markAsTouched();
            } else {
                this.control.setValue(null);
                this.control.markAsTouched();
            }
            if (lastBufferedFilename) {
                await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: lastBufferedFilename})));
            }
            lastBufferedFilename = filename;
            this.file.url = filename ? await readFileUrl(filename, this.appActions) : null;
            this.file.fullUrl = undefined;
            return true;
        }
    }
}
