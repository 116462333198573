import {Subject} from 'rxjs';

export interface UploadFile {
    name: string;
    file: Blob;
}


export const GameDifficultyEnum = {
    SuperEasy: -1,
    Easy: 0,
    Medium: 1,
    Hard: 2,
    SuperHard: 3
}


export type ProgressType = { filesAmount: number, uploaded: number, forceClose: boolean, steps: string[], currentStep: number, showProgressBar: boolean, title: string, showSpinner?: boolean};
