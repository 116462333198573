import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchProductPartnersSuccessAction, updateProductPartnerSuccessAction} from './actions';
import {ProductPartner} from '../../graphql/types.graphql-gen';

export const productPartnerReducer = createImmerReducer(
    defaultDataState,
    on(fetchProductPartnersSuccessAction, (state, action) => {
        state.productPartners = action.result.result;
        return state;
    }),
    on(updateProductPartnerSuccessAction, (state, action) => {
        const productPartner = action.result.productPartner;
        if (productPartner && state.productPartners) {
            const index = state.productPartners.findIndex((pp) => pp.id === productPartner.id);
            if (index === -1) {
                state.productPartners.push(productPartner as ProductPartner);
            } else {
                state.productPartners[index] = productPartner as ProductPartner;
            }
        }
        if (action.result.productPartnerDeleted && state.productPartners) {
            state.productPartners = state.productPartners.filter((pp) => pp.id !== action.result.productPartnerDeleted);
        }
        return state;
    })
);
