import * as Types from '../../types.graphql-gen';

import { GameFragment } from '../../mutations/updateGame/updateGame.graphql-gen';
import { gql } from 'apollo-angular';
import { GameFragmentDoc } from '../../mutations/updateGame/updateGame.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetGameQueryVariables = Types.Exact<{
  gameId: Types.Scalars['Int'];
}>;


export type GetGameQuery = (
  { __typename?: 'Query' }
  & { getGame: (
    { __typename?: 'Game' }
    & GameFragment
  ) }
);

export const GetGameDocument = gql`
    query getGame($gameId: Int!) {
  getGame(gameId: $gameId) {
    ...game
  }
}
    ${GameFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGameGQL extends Apollo.Query<GetGameQuery, GetGameQueryVariables> {
    document = GetGameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }