import {defaultDataState, IDataState} from '../state';
import {createImmerReducer} from 'ngrx-immer/store';
import {on} from '@ngrx/store';
import {fetchSettingsSuccessAction, updateSettingsSuccessAction} from '../settings/actions';
import {fetchGameGroupsDataSuccessAction, fetchInitialDataSuccessAction} from './actions';
import {logoutAction} from '../user/actions';
import {fetchTranslationsHelperDataSuccessAction} from '../translation/actions';

export const dataReducer = createImmerReducer(
    defaultDataState,
    on(fetchSettingsSuccessAction, (state: IDataState, action) => {
        state.settings = action.settings;
        return state;
    }),
    on(updateSettingsSuccessAction, (state: IDataState, action) => {
        if (state.settings) {
            state.settings.getSettings = action.settings;
        }
        return state;
    }),
    on(fetchInitialDataSuccessAction, (state: IDataState, action) => {
        state.helperData.cities = action.initialData.getManagerCities.result;
        state.helperData.languages = action.initialData.getLanguages.result;
        // state.helperData.partners = action.initialData.getPartners.result;
        state.helperData.projectData = action.initialData.getProjectData;
        return state;
    }),
    on(fetchGameGroupsDataSuccessAction, (state: IDataState, action) => {
        state.helperData.gameGroups = action.data;
        return state;
    }),
    on(fetchTranslationsHelperDataSuccessAction, (state: IDataState, action: ReturnType<typeof fetchTranslationsHelperDataSuccessAction>) => {
        if(action.langsMeta !== undefined) {
            state.helperData.translations.langsMeta = action.langsMeta;
        }
        if(action.translationsMeta !== undefined) {
            state.helperData.translations.translationsMeta = action.translationsMeta;
        }
        return state;
    }),
    on(logoutAction, (state: IDataState, action) => {
        state = defaultDataState;
        return state;
    })
);
