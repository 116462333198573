import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchAvailableEditorsAction,
    fetchAvailableEditorsFailAction,
    fetchAvailableEditorsSuccessAction,
    fetchAvailablePlayersAction,
    fetchAvailablePlayersFailAction,
    fetchAvailablePlayersSuccessAction,
    fetchPlayerAction,
    fetchPlayerFailAction, fetchPlayerIdsAction, fetchPlayerIdsFailAction, fetchPlayerIdsSuccessAction,
    fetchPlayersAction,
    fetchPlayersFailAction,
    fetchPlayersSuccessAction,
    fetchPlayerSuccessAction,
    updatePlayerAction,
    updatePlayerFailAction,
    updatePlayerSuccessAction
} from './actions';
import {concatMap, map} from 'rxjs/operators';
import {GetPlayerIdsGQL, GetPlayersGQL} from '../../graphql/queries/getPlayers/getPlayers.graphql-gen';
import {AndOrTypeEnum, OrderByEnum, Player, PlayersResult, UpdatePlayerResult} from '../../graphql/types.graphql-gen';
import {GetPlayerGQL} from '../../graphql/queries/getPlayer/getPlayer.graphql-gen';
import {fixMutation} from '../../shared/helpers';
import {UpdatePlayerGQL} from '../../graphql/mutations/updatePlayer/updatePlayer.graphql-gen';
import {
    GetAvailableEditorsMetaDataGQL,
    GetAvailablePlayersMetaDataGQL
} from '../../graphql/queries/metaData/metaData.graphql-gen';

@Injectable()
export class PlayerEffect implements OnDestroy {


    subSink = new SubSink();


    fetchPlayers$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPlayersAction
        ),
        concatMap((action) => {
            return this.getPlayersGQL.fetch({filter: action.filter})
        }),
        map((res) => {
            if (res.errors) {
                return fetchPlayersFailAction({header: '', message: res.errors[0].message});
            }
            return fetchPlayersSuccessAction({result: res.data.getPlayers as PlayersResult});
        })
    ));

    fetchPlayerIds$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPlayerIdsAction
        ),
        concatMap((action) => {
            return this.getPlayerIdsGQL.fetch({filter: action.filter})
        }),
        map((res) => {
            if (res.errors) {
                return fetchPlayerIdsFailAction({header: '', message: res.errors[0].message});
            }
            return fetchPlayerIdsSuccessAction({result: res.data.getPlayers});
        })
    ));

    fetchPlayer$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPlayerAction
        ),
        concatMap((action) => {
            return this.getPlayerGQL.fetch({id: action.id.toString()});
        }),
        map((res) => {
            if (res.errors) {
                return fetchPlayerFailAction({header: '', message: res.errors[0].message});
            }
            if (!res.data.getPlayers.result[0]) {
                return fetchPlayerFailAction({header: '', message: 'player_not_found'});
            }
            return fetchPlayerSuccessAction({player: res.data.getPlayers.result[0] as Player})
        })
    ));

    updatePlayer$ = createEffect(() => this.actions$.pipe(
        ofType(
            updatePlayerAction
        ),
        concatMap((action) => {
            return fixMutation(this.updatePlayerGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if (res.errors) {
                return updatePlayerFailAction({header: '', message: res.errors[0].message});
            }
            return updatePlayerSuccessAction({result: res.data.updatePlayer as UpdatePlayerResult})
        })
    ));

    fetchAvilableEditors$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchAvailableEditorsAction
        ),
        concatMap((action) => {
            return this.getAvilableEditorsMetadataGQL.fetch()
        }),
        map((res) => {
            if (res.errors) {
                return fetchAvailableEditorsFailAction({header: '', message: res.errors[0].message});
            }
            return fetchAvailableEditorsSuccessAction({editors: res.data.getPlayers.availableEditors});
        })
    ));

    fetchAvailablePlayers$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchAvailablePlayersAction
        ),
        concatMap((action: ReturnType<typeof fetchAvailablePlayersAction>) => {
            return this.getAvailablePlayersMetadataGQL.fetch({
                filter: {
                    email: action.value ? '%LIKE%' + action.value : undefined,
                    fullname: action.value ? '%LIKE%' + action.value : undefined,
                    pushToken: action.onlyWithPushToken ? '%NOT_NULL%' : undefined,
                    pushTokenActive: action.onlyWithPushToken ? true : undefined,
                    filter: {
                        likePropertiesMode: AndOrTypeEnum.Or,
                        limit: 10,
                        orderBy: [
                            {
                                property: 'createdAt',
                                value: OrderByEnum.Desc
                            }
                        ]
                    }
                }
            })
        }),
        map((res) => {
            if (res.errors) {
                return fetchAvailablePlayersFailAction({header: '', message: res.errors[0].message});
            }
            return fetchAvailablePlayersSuccessAction({players: res.data.getPlayers.result});
        })
    ));


    constructor(
        private actions$: Actions,
        private getPlayersGQL: GetPlayersGQL,
        private getPlayerGQL: GetPlayerGQL,
        private updatePlayerGQL: UpdatePlayerGQL,
        private getAvilableEditorsMetadataGQL: GetAvailableEditorsMetaDataGQL,
        private getAvailablePlayersMetadataGQL: GetAvailablePlayersMetaDataGQL,
        private getPlayerIdsGQL: GetPlayerIdsGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
