import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '../../store/state';
import {getNavCollapsedSelector} from '../../store/helperData/selectors';
import {SubSink} from 'subsink';

@Directive({
    selector: '[appScrollableHeaderCard]'
})
export class ScrollableHeaderCardDirective implements AfterViewInit, OnDestroy {

    topOffset: number;
    firstHeaderHeight: number | null = null;
    firstHeaderWidth: number | null = null;
    subSink: SubSink = new SubSink();


    constructor(
        private elementRef: ElementRef,
        private store: Store<IAppState>
    ) {
    }

    @HostListener('window:scroll')
    onScroll() {
        this.process();
    }

    @HostListener('window:resize')
    onResize() {
        this.firstHeaderHeight = null;
        this.firstHeaderWidth = null;
        this.process();
    }

    ngAfterViewInit(): void {
        this.process();
        this.subSink.sink = this.store.select(getNavCollapsedSelector).subscribe((value) => {
            setTimeout(() => {
                this.firstHeaderWidth = null;
                this.firstHeaderHeight = null;
                this.process();
            });
        })
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }


    protected process() {
        const header = this.elementRef.nativeElement.querySelector('.p-card-header');
        const body = this.elementRef.nativeElement.querySelector('.p-card-body');
        const card = this.elementRef.nativeElement.querySelector('.p-card');

        if (!this.firstHeaderHeight) {
            this.firstHeaderHeight = header?.offsetHeight ?? 0
        }

        if (!this.firstHeaderWidth) {
            this.firstHeaderWidth = body?.offsetWidth ?? 0
        }

        let fixedContentWidth = document.querySelector('.p-card--fix-content')?.parentElement?.offsetWidth;
        if (fixedContentWidth) {
            fixedContentWidth -= 14;
        }
        // style="position: fixed; top: 0; z-index: 999; width: 976px; background-color: white"
        this.topOffset = this.elementRef.nativeElement.offsetTop;
        if (window.scrollY > this.topOffset) {
            card.style.paddingTop = this.firstHeaderHeight + 'px';
            header.style.width = this.firstHeaderWidth + 'px';
            this.elementRef.nativeElement.classList.add('p-card--fixed');
            if (fixedContentWidth && window.innerHeight > 800) {
                document.querySelector('.p-card--fix-content')?.classList.add('p-card--fixed-content');
                if ((document.querySelector('.p-card--fix-content') as HTMLElement)?.style) {
                    (document.querySelector('.p-card--fix-content') as HTMLElement).style.width = fixedContentWidth + 'px';
                }
            }
        } else {
            card.style.paddingTop = null;
            header.style.width = null;
            this.elementRef.nativeElement.classList.remove('p-card--fixed');
            if (fixedContentWidth) {
                document.querySelector('.p-card--fix-content')?.classList.remove('p-card--fixed-content');
                if ((document.querySelector('.p-card--fix-content') as HTMLElement)?.style) {
                    // @ts-ignore
                    (document.querySelector('.p-card--fix-content') as HTMLElement).style.width = null;
                }
            }
        }
    }
}
