import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UpdatePartnerGQL} from '../../graphql/mutations/updatePartner/updatePartner.graphql-gen';
import {
    fetchPartnersAction, fetchPartnersFailAction,
    fetchPartnersSuccessAction,
    updatePartnerAction,
    updatePartnerFailAction,
    updatePartnerSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {fixMutation} from '../../shared/helpers';
import {map} from 'rxjs/operators';
import {GetPartnersGQL} from '../../graphql/queries/getPartners/getPartners.graphql-gen';

@Injectable()
export class PartnerEffect implements OnDestroy {


    subSink = new SubSink();

    getPartners$ = createEffect(() => this.actions$.pipe(
        ofType(fetchPartnersAction),
        concatMap((action) => {
            return this.getPartnersGQL.fetch();
        }),
        map((res) => {
            if(res.errors) {
                return fetchPartnersFailAction({message: res.errors[0].message, header: ''})
            }
            return fetchPartnersSuccessAction({result: res.data.getPartners});
        })
    ));

    updatePartner$ = createEffect(() => this.actions$.pipe(
        ofType(updatePartnerAction),
        concatMap((action) => {
            return fixMutation(this.updatePartnerGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updatePartnerFailAction({message: res.errors[0].message, header: ''});
            }
            return updatePartnerSuccessAction({result: res.data.updatePartner});
        })
    ));

    // fetchPageData$ = createEffect(() => this.actions$.pipe(
    //     ofType(fetchPageDataAction),
    //     concatMap((action) => {
    //         return this.getPagesDataGQL.fetch({input: action.input});
    //     }),
    //     map((res) => {
    //         if(res.errors) {
    //             return fetchPageDataFailAction({message: res.errors[0].message, header: ''});
    //         }
    //         return fetchPageDataSuccessAction({result: res.data.getPagesData.pagesData[0]});
    //     })
    // ));


    constructor(
        private actions$: Actions,
        private updatePartnerGQL: UpdatePartnerGQL,
        private getPartnersGQL: GetPartnersGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
