import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FirstUpperPipe} from './first-upper-pipe';
import {LogPipe} from './log.pipe';
import {ToAny, ToAttributeType, ToDate, ToGameVoucherPipePipe, ToPurchasePipe} from './entityTransform.pipes';
import {PlayerPhotoPipe, ResourcePathPipe} from './resource-path.pipe';
import {AttachmentUrlPipe, UrlPipe} from './url.pipe';
import {TruncatePipe} from './truncate.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import {DecodeHtmlString, NoSanitizePipe} from './no-sanitize.pipe';
import { GameEditorsPipe } from './game-editors.pipe';
import { IsAllowedPipe } from './is-allowed.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import {RatingClassPipe} from './rating.pipe';

@NgModule({
    declarations: [
        FirstUpperPipe,
        LogPipe,
        ToPurchasePipe,
        ResourcePathPipe,
        ToGameVoucherPipePipe,
        ToAny,
        ToDate,
        UrlPipe,
        TruncatePipe,
        StripHtmlPipe,
        NoSanitizePipe,
        GameEditorsPipe,
        IsAllowedPipe,
        SecondsToTimePipe,
        DecodeHtmlString,
        AttachmentUrlPipe,
        PlayerPhotoPipe,
        ToAttributeType,
        RatingClassPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FirstUpperPipe,
        LogPipe,
        ToPurchasePipe,
        ToGameVoucherPipePipe,
        ResourcePathPipe,
        UrlPipe,
        AttachmentUrlPipe,
        ToAny,
        TruncatePipe,
        StripHtmlPipe,
        NoSanitizePipe,
        PlayerPhotoPipe,
        GameEditorsPipe,
        IsAllowedPipe,
        DecodeHtmlString,
        ToDate,
        SecondsToTimePipe,
        ToAttributeType,
        RatingClassPipe
    ],
    providers: [
        NoSanitizePipe
    ]
})
export class PipesModule {

}
