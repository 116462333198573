import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UploadFilesMutationVariables = Types.Exact<{
  input: Types.UploadFilesInput;
}>;


export type UploadFilesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'uploadFiles'>
);

export const UploadFilesDocument = gql`
    mutation uploadFiles($input: UploadFilesInput!) {
  uploadFiles(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFilesGQL extends Apollo.Mutation<UploadFilesMutation, UploadFilesMutationVariables> {
    document = UploadFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }