import {createSelector} from '@ngrx/store';
import {dataStateSelector} from '../data/selector';
import * as _ from 'lodash';
import {
    PushNotificationDatagridTypes
} from '../../modules/backend/push-notification/components/push-notifications-datagrid/push-notifications-datagrid.component';




export const getPushNotificationsSelector = _.memoize((type: PushNotificationDatagridTypes) => {
    return createSelector(
        dataStateSelector,
        (data) => data.pushNotification.pushNotifications[type]
    );
});

export const getActivePushNotificationSelector = createSelector(
    dataStateSelector,
    (data) => data.pushNotification.activePushNotification
);
