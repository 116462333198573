import {Injectable} from '@angular/core';
import {IAppState} from '../store/state';


@Injectable({
    providedIn: 'root'
})
export class MyStorageService {
    private storage = localStorage;
    private saveStateTimeout?: any;
    constructor() {
    }

    async saveState(store: IAppState) {
        if (this.saveStateTimeout) {
            clearTimeout(this.saveStateTimeout);
        }
        this.saveStateTimeout = setTimeout(() => {
            this.storage.setItem('store', JSON.stringify(store));
        }, 300);
    }

    async loadState(): Promise<IAppState | null> {
        let store: any = (await this.storage.getItem('store'));
        if (store) {
            store = JSON.parse(store) as IAppState;
        }
        return store;
    }

    async getSyncKey(): Promise<string | null> {
        return this.storage.getItem('sync-key');
    }

    async saveSyncKey(key: string) {
        return this.storage.setItem('sync-key', key);
    }

    set(key: string, value: object | string): Promise<void> {
        return new Promise((resolve) => {
            this.storage.setItem(key, JSON.stringify(value));
            resolve();
        });
    }

    clear() {
        return this.storage.clear();
    }

    remove(key: string) {
        return this.storage.removeItem(key);
    }

    async get(key: string): Promise<object | null | string | any> {
        const value = this.storage.getItem(key);
        try {
            if(typeof value === 'string')
                return JSON.parse(value);
        } catch (e) {
        }
        return value;
    }
}
