import {Component, Input, OnInit} from '@angular/core';
import {GameErrors} from '../../../forms/game-validator';

@Component({
    selector: 'app-error-list',
    templateUrl: './error-list.component.html',
    styleUrls: ['./error-list.component.scss']
})
export class ErrorListComponent implements OnInit {

    @Input() errors: GameErrors | null = null;
    @Input() isTouched: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
    }


}
