import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
// @ts-ignore
import {saveAs} from 'file-saver';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    @Input() photos: GalleryPhoto[] = [];

    @Input() readonly = false;

    @Input() removePhotoCallback: (photoId: number) => Promise<any>

    public activePhoto: GalleryPhoto;

    activeIndex: number = 0;

    displayCustom: boolean;



    responsiveOptions:any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

    setActivePhoto(photo: any) {
        this.activePhoto = photo;
        return '';
    }

    imageClick(index: number) {
        this.activeIndex = index;
        this.displayCustom = true;
    }

    removePhoto(galleryPhoto: GalleryPhoto) {
        galleryPhoto.loading = true;
        this.removePhotoCallback(galleryPhoto.id).then(() => {
            galleryPhoto.loading = false;
        })
    }

    download(path: string) {
        saveAs(path);
    }
}


export type GalleryPhoto = { path: string, fullPath: string, id: number, loading?: boolean };
