import {AfterContentInit, Directive, OnDestroy} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {ActivatedRoute, Router} from '@angular/router';
import {SubSink} from 'subsink';

@Directive({
    selector: 'p-tabView[appTabChangeDetector]'
})
export class TabChangeDetectorDirective implements AfterContentInit, OnDestroy {

    subsink = new SubSink();

    constructor(
        private tabView: TabView,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngAfterContentInit(): void {
        const activeTabIndex = parseInt(this.activatedRoute.snapshot.queryParamMap.get('activeTab') ?? '0', 10) ?? 0;
        if (activeTabIndex && activeTabIndex !== 0 && this.tabView.tabs[activeTabIndex]) {
            this.tabView.tabs[0].selected = false;
            this.tabView.tabs[activeTabIndex].selected = true;
        }
        this.tabView.onChange.subscribe(($event) => {
            this.tabChanged($event);
        });
    }

    tabChanged($event: any) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                activeTab: $event.index !== 0 ? $event.index : undefined
            },
            queryParamsHandling: "merge",
            replaceUrl: true
        }).then();
    }

    ngOnDestroy(): void {
        this.subsink.unsubscribe();
    }


}
