import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, Validators} from '@angular/forms';
import {Editor} from 'primeng/editor';

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss']
})
export class TextComponent extends ElementBaseComponent<SettingsControl> implements OnInit, AfterViewInit {

    @ViewChild('editor') editor: Editor

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            text: new FormControl('', {validators: Validators.required, nonNullable: true})
        });
    }


    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        if (this.stepElementForm.value.id) {
            return;
        }
        // const quill: Quill = this.editor.getQuill();
        // quill.format('align', 'justify')
    }


}

type SettingsControl = {
    text: FormControl<string>
}
