import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';

import {on} from '@ngrx/store';
import {
    fetchPermissionGroupsMetaDataSuccessAction,
    fetchPermissionGroupsSuccessAction,
    fetchPermissionGroupSuccessAction,
    updatePermissionGroupSuccessAction
} from './actions';

export const permissionGroupReducer = createImmerReducer(
    defaultDataState,
    on(fetchPermissionGroupsMetaDataSuccessAction, (state: IDataState, action) => {
        state.helperData.availablePermissionGroups = action.result;
        state.helperData.allPermissions = action.allPermissions;
        return state;
    }),
    on(fetchPermissionGroupsSuccessAction, (state: IDataState, action) => {
        if (action.result && action.updateStore) {
            state.permissionGroup.permissionGroups = action.result
        }
        return state;
    }),
    on(fetchPermissionGroupSuccessAction, (state: IDataState, action) => {
        state.permissionGroup.activePermissionGroup = action.permissionGroup;
        return state;
    }),
    on(updatePermissionGroupSuccessAction, (state: IDataState, action) => {
        if (action.result.permissionGroup) {
            state.permissionGroup.activePermissionGroup = action.result.permissionGroup;
        }
        return state;
    })
);
