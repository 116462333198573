import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetReviewsQueryVariables = Types.Exact<{
  filter: Types.ReviewFilter;
}>;


export type GetReviewsQuery = (
  { __typename?: 'Query' }
  & { getReviews: (
    { __typename?: 'ReviewsResult' }
    & { result: Array<(
      { __typename?: 'Review' }
      & ReviewFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetReviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetReviewQuery = (
  { __typename?: 'Query' }
  & { getReviews: (
    { __typename?: 'ReviewsResult' }
    & { result: Array<(
      { __typename?: 'Review' }
      & FullReviewFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type ReviewFragment = (
  { __typename?: 'Review' }
  & Pick<Types.Review, 'id' | 'public' | 'isResolved' | 'text' | 'rating' | 'reviewerName' | 'ipAddress' | 'phoneNumber' | 'createdAt' | 'source'>
  & { player?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'id' | 'email' | 'fullname'>
  )>, game?: Types.Maybe<(
    { __typename?: 'Game' }
    & Pick<Types.Game, 'id' | 'name'>
    & { gameGroup: (
      { __typename?: 'GameGroup' }
      & Pick<Types.GameGroup, 'id'>
      & { city?: Types.Maybe<(
        { __typename?: 'City' }
        & Pick<Types.City, 'id' | 'name'>
      )> }
    ) }
  )>, progress?: Types.Maybe<(
    { __typename?: 'PlayerProgress' }
    & Pick<Types.PlayerProgress, 'id'>
  )> }
);

export type FullReviewFragment = (
  { __typename?: 'Review' }
  & ReviewFragment
);

export const ReviewFragmentDoc = gql`
    fragment review on Review {
  id
  public
  isResolved
  text
  rating
  reviewerName
  ipAddress
  phoneNumber
  createdAt
  player {
    id
    email
    fullname
  }
  game {
    id
    name
    gameGroup {
      id
      city {
        id
        name
      }
    }
  }
  progress {
    id
  }
  source
}
    `;
export const FullReviewFragmentDoc = gql`
    fragment fullReview on Review {
  ...review
}
    ${ReviewFragmentDoc}`;
export const GetReviewsDocument = gql`
    query getReviews($filter: ReviewFilter!) {
  getReviews(filter: $filter) {
    result {
      ...review
    }
    meta {
      ...metaFragment
    }
  }
}
    ${ReviewFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewsGQL extends Apollo.Query<GetReviewsQuery, GetReviewsQueryVariables> {
    document = GetReviewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReviewDocument = gql`
    query getReview($id: String!) {
  getReviews(filter: {id: $id}) {
    result {
      ...fullReview
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullReviewFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewGQL extends Apollo.Query<GetReviewQuery, GetReviewQueryVariables> {
    document = GetReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }