<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="pt-4 col-12">
        <div class="text-left">
            <label class="pb-2">
                {{'elements.video.src'|translate|firstUpper}}
                <app-tooltip image="element-video-src.png"></app-tooltip>
            </label>
            <app-file-upload [readonly]="readonly" #videoC [file]="video" [uploadHandler]="onFileUpload" [accept]="'.mp4'" type="video"
                             [multiple]="false"></app-file-upload>
        </div>
    </div>
    <div class="col-4">
        <label for="autoplay-{{uniqueId}}" class="pb-2">{{'elements.video.autoplay'|translate|firstUpper}}</label>
        <p-selectButton id="autoplay-{{uniqueId}}" [options]="boolOptions"
                        formControlName="autoplay" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>

</div>
