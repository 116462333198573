import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchSaleSourceFailAction,
    fetchSaleSourcesAction,
    fetchSaleSourceSuccessAction,
    updateSaleSourceAction, updateSaleSourceFailAction, updateSaleSourceSuccessAction
} from './actions';
import {map, switchMap} from 'rxjs/operators';
import {GetSaleSourcesGQL} from '../../graphql/queries/getSaleSources/getSaleSources.graphql-gen';
import {SaleSourceResult, UpdateSaleSourceResult} from '../../graphql/types.graphql-gen';
import {UpdateSaleSourceGQL} from '../../graphql/mutations/updateSaleSource/updateSaleSource.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class SaleSourceEffect implements OnDestroy {


    subSink = new SubSink();

    fetchSaleSources$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchSaleSourcesAction
        ),
        switchMap((a) => {
            return this.getSaleSourcesGQL.fetch();
        }),
        map((res) => {
            if (res.errors) {
                return fetchSaleSourceFailAction({header: '', message: res.errors[0].message});
            }
            return fetchSaleSourceSuccessAction({result: res.data.getSaleSources as SaleSourceResult, availableIdentifiers: res.data.getSaleSources.availableIdentifiers});
        })
    ));

    updateSaleSource$ = createEffect(() => this.actions$.pipe(
        ofType(
            updateSaleSourceAction
        ),
        switchMap((a) => {
            return fixMutation(this.updateSaleSourceGQL.mutate({input: a.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updateSaleSourceFailAction({header: '', message: res.errors[0].message});
            }
            return updateSaleSourceSuccessAction({result: res.data.updateSaleSource as UpdateSaleSourceResult});
        })
    ));

    constructor(
        private actions$: Actions,
        private getSaleSourcesGQL: GetSaleSourcesGQL,
        private updateSaleSourceGQL: UpdateSaleSourceGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
