<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-6">
        <label for="ratio-{{uniqueId}}" class="pb-2">
            {{'elements.slidingPuzzle.ratio'|translate|firstUpper}}
            <app-tooltip value="{{'elements.tooltip.slidingPuzzle.ratio'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-dropdown id="ratio-{{uniqueId}}" formControlName="ratio"  [options]="ratios" (onChange)="dropdownChanged($event)"></p-dropdown>
    </div>
    <div class="col-6">
        <label for="usePhotoFromCamera-{{uniqueId}}" class="pb-2">
            {{'elements.slidingPuzzle.usePhotoFromCamera'|translate|firstUpper}}
            <app-tooltip value="{{'elements.tooltip.slidingPuzzle.usePhotoFromCamera'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton id="usePhotoFromCamera-{{uniqueId}}" [options]="boolOptions" class="on-off-select-button"
                        formControlName="usePhotoFromCamera" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="col-12" *ngIf="stepElementForm.controls.settings.controls.usePhotoFromCamera.value">
        <label for="takePhotoButtonId-{{uniqueId}}" class="pb-2">
            {{'elements.slidingPuzzle.takePhotoButtonId'|translate|firstUpper}}
            <app-tooltip value="{{'elements.tooltip.slidingPuzzle.takePhotoButtonId'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-dropdown placeholder="Select Photo Button Id" id="takePhotoButtonId-{{uniqueId}}" [showClear]="true"  [options]="availableTakePhotoButtonIds" formControlName="takePhotoButtonId"></p-dropdown>
    </div>
    <div class="pt-4 col-12">
        <div style="width: 220px">
            <label for="file-{{uniqueId}}" class="pb-2 {{!stepElementForm.controls.settings.controls.imageSrc.valid ? 'text-danger' : ''}}" >
                {{'elements.slidingPuzzle.image'|translate|firstUpper}}*
                <app-tooltip image="element-slidingPuzzle-image.png" value="{{'elements.tooltip.slidingPuzzle.image'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-file-upload id="file-{{uniqueId}}" [file]="image" [uploadHandler]="onFileUpload()" [multiple]="false"></app-file-upload>
        </div>
    </div>
</div>

