import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {ShareButtonSettings} from '../element-types';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {getGameSelector} from '../../../../store/games/selectors';
import {take} from 'rxjs/operators';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    availableTakePhotoButtonIds: (string)[] = [];

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            takePhotoButtonId: new FormControl<string|null>(null, CustomValidatorsService.customValidator((c) => {
                if (c.value === '') {
                    c.setValue(null);
                }
                return null;
            }))
        });
    }

    async ngOnInit() {
        const game = (await lastValueFrom(this.store.select(getGameSelector).pipe(take(1))))!;
        super.ngOnInit();
        let parent: any = this.stepElementForm.parent?.parent?.parent;
        if(!parent || !(parent instanceof MyFormGroupV2)) {
            parent = this.stepElementForm.root;
        }
        this.availableTakePhotoButtonIds = this.getAvailableTakePhotoButtonIds(game);
        const fa = this.stepElementForm.parent as MyFormArray;
        if(fa) {
            this.subSink.sink = fa.arrayOrderChanged$.subscribe(() => {
                this.availableTakePhotoButtonIds = this.getAvailableTakePhotoButtonIds(game);
                if (!this.availableTakePhotoButtonIds.includes(this.stepElementForm.controls.settings.controls.takePhotoButtonId.value ?? '')) {
                    this.stepElementForm.controls.settings.controls.takePhotoButtonId.setValue(null);
                }
            });
        }
    }

    // p dropdown has ng 100 error, when destroy this element, it mark form as touched....
    takePhotoButtonChanged($event: any) {
        this.stepElementForm.controls.settings.controls.takePhotoButtonId.setValue($event.value);
    }
}

type SettingsControl = {
    takePhotoButtonId: FormControl<string | null>
}
