import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-step-summary',
    templateUrl: './step-summary.component.html',
    styleUrls: ['./step-summary.component.scss']
})
export class StepSummaryComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            text: new FormControl('', {nonNullable: true})
        });
    }


}

type SettingsControl = {
    text: FormControl<string>
}
