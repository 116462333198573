import {createAction, props} from '@ngrx/store';
import {PushNotificationFilter, PushNotificationInput} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {
    FullPushNotificationFragment,
    UpdatePushNotificationMutation
} from '../../graphql/mutations/updatePushNotification/updatePushNotification.graphql-gen';
import {GetPushNotificationQuery} from '../../graphql/queries/getPushNotifications/getPushNotifications.graphql-gen';
import {
    PushNotificationDatagridTypes
} from '../../modules/backend/push-notification/components/push-notifications-datagrid/push-notifications-datagrid.component';


export const updatePushNotificationAction = createAction('[PushNotification] UPDATE_PUSH_NOTIFICATION', props<{input: PushNotificationInput}>());
export const updatePushNotificationSuccessAction = createAction('[PushNotification] UPDATE_PUSH_NOTIFICATION_SUCCESS', props<{res: UpdatePushNotificationMutation['updatePushNotification']}>());
export const updatePushNotificationFailAction = createAction('[PushNotification] UPDATE_PUSH_NOTIFICATION_FAIL', props<DefaultFailMessage>());


export const fetchPushNotificationsAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATIONS', props<{ input: PushNotificationFilter, activeType: PushNotificationDatagridTypes}>());
export const fetchPushNotificationsSuccessAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATIONS_SUCCESS', props<{activeType: PushNotificationDatagridTypes, res: GetPushNotificationQuery['getPushNotifications']}>());
export const fetchPushNotificationsFailAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATIONS_FAIL', props<DefaultFailMessage>());


export const fetchPushNotificationAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATION', props<{id: number}>());
export const fetchPushNotificationSuccessAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATION_SUCCESS', props<{pushNotification: FullPushNotificationFragment|null}>());
export const fetchPushNotificationFailAction = createAction('[PushNotification] LOAD_PUSH_NOTIFICATION_FAIL', props<DefaultFailMessage>());
