import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseFilterComponent} from '../../../../../shared/components/base/base-filter.component';
import {OrderByEnum, Player, PushNotificationFilter} from '../../../../../graphql/types.graphql-gen';
import {Table} from 'primeng/table';
import {
    PushNotificationFragment
} from '../../../../../graphql/mutations/updatePushNotification/updatePushNotification.graphql-gen';
import * as _ from 'lodash';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../../store/state';
import {AppActions} from '../../../../../store/app-actions.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem, MessageService} from 'primeng/api';
import {AclService} from '../../../../../services/acl.service';
import {ProgressService} from '../../../../../services/progress.service';
import {
    fetchPushNotificationsAction,
    fetchPushNotificationsFailAction,
    fetchPushNotificationsSuccessAction,
    updatePushNotificationAction,
    updatePushNotificationFailAction,
    updatePushNotificationSuccessAction
} from '../../../../../store/pushNotification/actions';
import {combineLatest, lastValueFrom} from 'rxjs';
import {getPushNotificationsSelector} from '../../../../../store/pushNotification/selectors';
import {firstUpper} from '../../../../../shared/helpers';
import {cancelConfirmDialogAction, showConfirmDialogAction} from '../../../../../store/general/actions';
import {
    updateAttributeAction,
    updateAttributeFailAction,
    updateAttributeSuccessAction
} from '../../../../../store/attributes/actions';

@Component({
  selector: 'app-push-notifications-datagrid',
  templateUrl: './push-notifications-datagrid.component.html',
  styleUrls: ['./push-notifications-datagrid.component.scss']
})
export class PushNotificationsDatagridComponent extends BaseFilterComponent<PushNotificationFilter | 'reset'> implements OnInit {

    @Input()
    activeType: PushNotificationDatagridTypes = PushNotificationDatagridTypes.Concept;
    @ViewChild('pushNotificationsTable') pushNotificationsTable?: Table;

    now = new Date();



    loaded = false;
    pushNotifications: PushNotificationFragment[];
    totalRecords: number = 0;
    loading = {
        general: false,
        dataGrid: false,
    };
    filterTimeout: any;
    hasChanges = false;

    constructor(
        private store: Store<IAppState>,
        private appActions: AppActions,
        private translateService: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        private messageService: MessageService,
        private aclService: AclService,
        private progressService: ProgressService
    ) {
        super();
        this.filter = {};
    }

    ngOnInit(): void {
        this.subSink.sink = combineLatest([
            this.store.select(getPushNotificationsSelector(this.activeType))
        ]).subscribe(([pushNotifications]) => {
            if(!pushNotifications) {
                return;
            }
            this.pushNotifications = _.cloneDeep(pushNotifications.result);
            this.totalRecords = pushNotifications.meta?.totalItems!;
            this.loaded = true;
        });
    }

    getPushNotificationActions = _.memoize((pushNotification: PushNotificationFragment): MenuItem[] => {
        const actions = [];
        actions.push({
            label: firstUpper(this.translateService.instant('general.edit')),
            icon: 'pi pi-cog',
            command: () => {
                this.router.navigate([pushNotification.id], {relativeTo: this.route}).then();
            }
        });
        if(!pushNotification.active || (!pushNotification.dateFinished && pushNotification.sendAt)) {
            actions.push({
                label: firstUpper(this.translateService.instant('general.remove')),
                icon: 'pi pi-times',
                command: () => {
                    this.removePushNotification(pushNotification).then();
                }
            });
        }
        return actions;
    });

    async removePushNotification(pushNotification: PushNotificationFragment) {
        const res =  await lastValueFrom(this.appActions.dispatch(showConfirmDialogAction(
                {
                    confirmButton: this.translateService.instant('general.confirmButton'),
                    cancelButton: this.translateService.instant('general.cancelButton'),
                    header: this.translateService.instant('general.attention'),
                    message: this.translateService.instant('pushNotification.message.remove')
                }),
            [cancelConfirmDialogAction])
        )
        if (!res.confirmed) {
            return;
        }
        const resAction = await lastValueFrom(this.appActions.dispatch(updatePushNotificationAction({input: {removePushNotification: pushNotification.id}}),
            [updatePushNotificationSuccessAction, updatePushNotificationFailAction]));
        if(resAction.type === updatePushNotificationFailAction.type) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: resAction.message});
        } else {
            this.messageService.add({severity: 'success', summary: this.translateService.instant('general.success'),  detail: this.translateService.instant('pushNotification.pushNotificationRemoved')});
        }
    }

    filterChanged(filter: PushNotificationFilter | null, setToFirstPage: boolean | undefined, extra: any): any {
        if (this.filter === 'reset') {
            filter = null;
            this.filter = {};
        }
        if (!filter) {
            filter = {};
        }
        if (!filter.filter && this.filter.filter) {
            filter.filter = _.cloneDeep(this.filter.filter);
        }
        if (!filter.filter) {
            filter.filter = {limit: 10};
        }
        // filter.filter.orderBy = [{value: OrderByEnum.Desc, property: 'createdAt'}];
        if (setToFirstPage && this.pushNotificationsTable) {
            this.pushNotificationsTable.first = 0;
            filter.filter.offset = 0;
        }
        this.filter = filter;
        this.loading.dataGrid = true;
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
        }
        this.filterTimeout = setTimeout(() => {
            if (typeof this.filter !== 'object') {
                return;
            }

            switch (this.activeType){
                case PushNotificationDatagridTypes.Finished:
                    this.filter.dateFinished = '%NOT_NULL%'
                    break;
                case PushNotificationDatagridTypes.Concept:
                    this.filter.dateFinished = '%NULL%'
                    this.filter.active = false;
                    break;
                case PushNotificationDatagridTypes.Upcoming:
                    this.filter.active = true;
                    this.filter.dateFinished = '%NULL%'
                    break;
            }
            this.extraData = extra;
            this.hasChanges = false;
            this.appActions.dispatch(fetchPushNotificationsAction({
                input: this.filter,
                activeType: this.activeType
            }), [fetchPushNotificationsSuccessAction, fetchPushNotificationsFailAction])
                .subscribe((a) => {
                    if (a.type === fetchPushNotificationsFailAction.type) {
                        this.messageService.add({severity: 'error', summary: 'Error', detail: a.message});
                    }
                    this.loading.dataGrid = false;
                });
        }, 100);
    }

    filterReset(filter: any): any {
        this.filter = filter ?? {};
        this.extraData = {};
        setTimeout(() => {
            if (this.pushNotificationsTable) {
                this.tableReset(this.pushNotificationsTable);
            }
        });
    }

    tableReset(table: Table) {
        table.reset();
        if([PushNotificationDatagridTypes.Upcoming].includes(this.activeType)) {
            table._multiSortMeta = [{field: 'sendAt', order: 1}];
        } else if([PushNotificationDatagridTypes.Finished].includes(this.activeType)) {
            table._multiSortMeta = [{field: 'dateFinished', order: -1}];
        }
        else {
            table._multiSortMeta = [{field: 'createdAt', order: -1}];
        }
        table._sortOrder = 1;
        table._sortField = 'id';
        table._rows = 10;
        table.sortSingle();
        table.saveState();
    }

}


export enum PushNotificationDatagridTypes {
    Finished = 'finished',
    Upcoming = 'upcoming',
    Concept = 'concept'
}
