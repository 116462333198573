import * as Types from '../../types.graphql-gen';

import { FullPlayerFragment } from '../getPlayers/getPlayers.graphql-gen';
import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { FullPlayerFragmentDoc } from '../getPlayers/getPlayers.graphql-gen';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPlayerQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetPlayerQuery = (
  { __typename?: 'Query' }
  & { getPlayers: (
    { __typename?: 'PlayersResult' }
    & { result: Array<(
      { __typename?: 'Player' }
      & FullPlayerFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export const GetPlayerDocument = gql`
    query getPlayer($id: String!) {
  getPlayers(filter: {id: $id}) {
    result {
      ...fullPlayer
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullPlayerFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayerGQL extends Apollo.Query<GetPlayerQuery, GetPlayerQueryVariables> {
    document = GetPlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }