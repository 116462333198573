import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {StartGameButtonSettings} from '../element-types';
import { MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-start-game-button',
    templateUrl: './start-game-button.component.html',
    styleUrls: ['./start-game-button.component.scss']
})
export class StartGameButtonComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            label: new FormControl('', {nonNullable: true}),
            showConfirmPopup: new FormControl(true, {nonNullable: true})
        });
    }


    ngOnInit() {
        super.ngOnInit();
    }
}

type SettingsControl = {
    label: FormControl<string>,
    showConfirmPopup: FormControl<boolean>
}
