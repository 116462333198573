<div class="p-fluid" [formGroup]="form">
    <div class="mb-2">
        <app-error-list [isTouched]="form.touched" [errors]="form.errors"></app-error-list>
    </div>
    <div>
        <label for="value" class="pb-2">
            {{'elements.selectOption.value'|translate|firstUpper}}<app-tooltip image="element-selectOption-value.png"></app-tooltip>
        </label>
        <input id="value"
               formControlName="value" type="text" pInputText
               class="{{form.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
    <div class="mt-4">
        <label for="isCorrect" class="pb-2">
            {{'elements.selectOption.isCorrect'|translate|firstUpper}}<app-tooltip image="element-selectOption-isCorrect.gif"></app-tooltip>
        </label>
        <p-selectButton id="isCorrect" [options]="boolOptions"
                        class="on-off-select-button"
                        [formControl]="form.controls.isCorrect" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="mt-4">
        <label for="fontSize" class="pb-2">
            {{'elements.selectOption.fontSize'|translate|firstUpper}}
            <app-tooltip value="{{'elements.selectOption.fontSize.tooltip'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-inputNumber id="fontSize"
                       formControlName="fontSize"
                       mode="decimal"
                       [showClear]="true"
                       [min]="1" [showButtons]="true"
                       [max]="200"
                       class="{{form.controls.fontSize.touched && form.controls.fontSize.invalid ? 'ng-invalid ng-dirty' : ''}}">
        </p-inputNumber>
    </div>
    <div class="mt-4">
        <div>
            <label for="src" class="pb-2">
                {{'elements.selectOption.src'|translate|firstUpper}}
            </label>
            <app-file-upload id="src" [file]="image" [uploadHandler]="onFileUpload()"
                             [multiple]="false"></app-file-upload>
        </div>
    </div>
</div>
<app-dialog-footer>
    <div class="pt-3">
        <button
            icon="pi pi-plus"
            class="p-button-success p-button-sm"
            pButton
            pRipple
            type="button"
            label="{{'general.save'|translate|firstUpper}}"
            (click)="save()">
        </button>
    </div>
</app-dialog-footer>
