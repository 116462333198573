import {
    Game,
    GameVoucher,
    GameVouchersResult,
    PermissionGroup,
    Player,
    PlayerProgress,
    PlayersResult,
    SaleSource
} from '../graphql/types.graphql-gen';
import {GetPurchasesQuery, PurchaseFragment} from '../graphql/queries/getPurchases/getPurchases.graphql-gen';
import {GetSettingsQuery} from '../graphql/queries/getSettings/getSettings.graphql-gen';
import {GetGameGroupsQuery} from '../graphql/queries/getGameGroups/getGameGroups.graphql-gen';
import {Action, ActionReducer} from '@ngrx/store';
import {GetInitialDataQuery} from '../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {GetGameGroupsDataQuery} from '../graphql/queries/getGameGroupsData/getGameGroupsData.graphql-gen';
import {
    GetAvailableEditorsMetaDataQuery,
    GetAvailablePlayersMetaDataQuery,
    GetPermissionGroupsMetaDataQuery,
    GetTranslationsMetadataQuery
} from '../graphql/queries/metaData/metaData.graphql-gen';
import {GetPermissionGroupsQuery} from '../graphql/queries/getPermissionGroups/getPermissionGroups.graphql-gen';
import {GetPlayerProgressesQuery} from '../graphql/queries/getPlayerProgresses/getPlayerProgresses.graphql-gen';
import {FullLicenseFragment, GetLicensesQuery} from '../graphql/queries/getLicenses/getLicenses.graphql-gen';
import {PageDataFragment} from '../graphql/queries/getPagesData/getPagesData.graphql-gen';
import {ProductPartnerFragment} from '../graphql/queries/getProductPartners/getProductPartners.graphql-gen';
import {GetTranslationsQuery} from '../graphql/queries/getTranslations/getTranslations.graphql-gen';
import {FullAttributeFragment, GetAttributesQuery} from '../graphql/queries/getAttributes/getAttributes.graphql-gen';
import {FullReviewFragment, GetReviewsQuery} from '../graphql/queries/getReviews/getReviews.graphql-gen';
import {
    FullPushNotificationFragment
} from '../graphql/mutations/updatePushNotification/updatePushNotification.graphql-gen';
import {GetPushNotificationsQuery} from '../graphql/queries/getPushNotifications/getPushNotifications.graphql-gen';
import {
    PushNotificationDatagridTypes
} from '../modules/backend/push-notification/components/push-notifications-datagrid/push-notifications-datagrid.component';
import {GetCitiesQuery} from '../graphql/queries/getCities/getCities.graphql-gen';
import {FullCityFragment} from '../graphql/mutations/updateCity/updateCity.graphql-gen';
import {GameFragment} from '../graphql/mutations/updateGame/updateGame.graphql-gen';
import {GameGroupFragment} from '../graphql/mutations/updateGameGroup/updateGameGroup.graphql-gen';
import {GetPartnersQuery} from '../graphql/queries/getPartners/getPartners.graphql-gen';

export interface IUserState {
    user: Player | null;
    preferences: { elements: { [elementName: string]: any } } | null
}

export interface IAppState {
    user: IUserState;
    data: IDataState;
}

export interface IDataState {
    settings: GetSettingsQuery | null;
    // gameGroups: GetGameGroupsQuery | null;
    game: GameFragment | null;
    saleSources: SaleSource[] | null;
    productPartners: ProductPartnerFragment[] | null;
    gameGroup: {
        gameGroups: GetGameGroupsQuery['getGameGroups'] | null,
        activeGameGroup: GameGroupFragment |null;
    },

    gameVoucher: {
        gameVouchers: GameVouchersResult | null;
        activeGameVoucher: GameVoucher | null;
    };
    player: {
        players: PlayersResult | null;
        activePlayer: Player | null;
    };
    partner: {
        partners: GetPartnersQuery['getPartners'] | null,
    },
    purchase: {
        purchases: GetPurchasesQuery['getPurchases'] | null;
        activePurchase: PurchaseFragment | null;
    }
    permissionGroup: {
        permissionGroups: GetPermissionGroupsQuery['getPermissionGroups'] | null;
        activePermissionGroup: PermissionGroup | null;
    };
    license: {
        licenses: GetLicensesQuery['getLicenses'] | null;
        activeLicense: FullLicenseFragment | null;
    }
    progresses: {
        progresses: GetPlayerProgressesQuery['getManagerPlayerProgresses'] | null;
        activeProgress: PlayerProgress | null;
    }
    page: {
        activePage: PageDataFragment | null;
    },
    attribute: {
        attributes: GetAttributesQuery['getAttributes'] | null;
        activeAttribute: FullAttributeFragment | null;
    },
    city: {
        cities: GetCitiesQuery['getManagerCities'] | null;
        activeCity: FullCityFragment | null;
    },
    pushNotification: {
        pushNotifications: { [key in PushNotificationDatagridTypes]: GetPushNotificationsQuery['getPushNotifications'] | null };
        activePushNotification: FullPushNotificationFragment | null;
    }
    translation: {
        translations: GetTranslationsQuery['getManagerTranslations'] | null
    },
    review: {
        reviews: GetReviewsQuery['getReviews'] | null,
        activeReview: FullReviewFragment | null
    },
    helperData: {
        navCollapsed: boolean;
        cities: GetInitialDataQuery['getManagerCities']['result'] | null,
        languages: GetInitialDataQuery['getLanguages']['result'] | null,
        translations: {
            langsMeta: GetTranslationsMetadataQuery['getLanguages']['langsMetadata'] | null;
            translationsMeta: GetTranslationsMetadataQuery['getManagerTranslations']['translationsMetadata'] | null;
        },
        gameGroups: GetGameGroupsDataQuery['getGameGroups']['availableGroupOptionsMeta'] | null,
        availableSaleSourceIdentifiers: string[] | null,
        // partners: GetInitialDataQuery['getPartners']['result'] | null,
        availablePermissionGroups: GetPermissionGroupsMetaDataQuery['getPermissionGroups']['result'] | null,
        availableEditors: GetAvailableEditorsMetaDataQuery['getPlayers']['availableEditors'] | null,
        availablePlayers: GetAvailablePlayersMetaDataQuery['getPlayers']['result'] | null,
        projectData: GetInitialDataQuery['getProjectData'] | null
        allPermissions: string[][] | null;
        filters: {
            gameVouchers: null | object,
            purchases: null | object,
            gameGroups: null | object,
            reviews: null | object,
            players: null | object,
            pushPlayers: null | object,
            permissionGroups: null | object,
            progresses: null | object,
            licenses: null | object,
            translations: null | object,
        }
    };

}

export type LazyLoadFile = { fileName: string, loaded: boolean };


export const concatReducers = <T, V extends Action = Action>(...reducers: Array<ActionReducer<T>>) => (
    state: T | undefined,
    action: Action,
): T =>
    // @ts-ignore
    reducers.reduce((accumulatedState, reducer) => reducer(accumulatedState, action), state);


export const defaultDataState: IDataState = {
    settings: null,
    // gameGroups: null,
    game: null,
    saleSources: null,
    productPartners: null,
    gameGroup: {
        gameGroups: null,
        activeGameGroup: null
    },
    partner: {
        partners: null
    },
    gameVoucher: {
        gameVouchers: null,
        activeGameVoucher: null,
    },
    purchase: {
        purchases: null,
        activePurchase: null
    },
    city: {
        cities: null,
        activeCity: null
    },
    player: {
        activePlayer: null,
        players: null
    },
    permissionGroup: {
        activePermissionGroup: null,
        permissionGroups: null
    },
    license: {
        licenses: null,
        activeLicense: null
    },
    progresses: {
        progresses: null,
        activeProgress: null
    },
    page: {
        activePage: null
    },
    translation: {
        translations: null
    },
    attribute: {
        attributes: null,
        activeAttribute: null
    },
    pushNotification: {
        pushNotifications: {
            [PushNotificationDatagridTypes.Finished]: null,
            [PushNotificationDatagridTypes.Upcoming]: null,
            [PushNotificationDatagridTypes.Concept]: null
        },
        activePushNotification: null
    },
    review: {
        reviews: null,
        activeReview: null
    },
    helperData: {
        navCollapsed: false,
        cities: null,
        gameGroups: null,
        languages: null,
        translations: {
            langsMeta: null,
            translationsMeta: null
        },
        availableSaleSourceIdentifiers: null,
        availablePermissionGroups: null,
        availableEditors: null,
        availablePlayers: null,
        // partners: null,
        allPermissions: null,
        projectData: null,
        filters: {
            gameVouchers: null,
            players: null,
            gameGroups: null,
            permissionGroups: null,
            progresses: null,
            licenses: null,
            purchases: null,
            translations: null,
            reviews: null,
            pushPlayers: null
        }
    }
};
