import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {ContinueButtonSettings} from '../element-types';
import { MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-continue-button',
    templateUrl: './continue-button.component.html',
    styleUrls: ['./continue-button.component.scss']
})
export class ContinueButtonComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            label: new FormControl('', {nonNullable: true})
        });
    }


}

type SettingsControl = {
    label: FormControl<string>
}
