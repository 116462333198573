import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {FormsModule} from '@angular/forms';
import {HttpLoaderFactory, SharedModule} from './shared/shared.module';
import {GraphQLModule} from './graphql.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {RequestInterceptor} from './interceptors/request.interceptor';
import {ResponseInterceptor} from './interceptors/response.interceptor';
import {Action, ActionReducer, StoreModule} from '@ngrx/store';
import {userReducer} from './store/user/reducer';
import {concatReducers, IUserState} from './store/state';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {EffectsModule} from '@ngrx/effects';
import {GeneralEffect} from './store/general/general.effect';
import {UserEffect} from './store/user/user.effect';
import {dataReducer} from './store/data/reducer';
import {PurchasesEffect} from './store/purchases/purchases.effect';
import {SettingsEffect} from './store/settings/settings.effect';
import {MessageService} from 'primeng/api';
import {RippleModule} from 'primeng/ripple';
import {GamesEffect} from './store/games/games.effect';
import {DataEffect} from './store/data/data.effect';
import {SaleSourceEffect} from './store/saleSource/sale-source.effect';
import {saleSourceReducer} from './store/saleSource/reducer';
import {gamesReducer} from './store/games/reducer';
import {ProductPartnerEffect} from './store/productPartner/product-partner.effect';
import {productPartnerReducer} from './store/productPartner/reducer';
import {GameVoucherEffect} from './store/gameVoucher/game-voucher.effect';
import {gameVoucherReducer} from './store/gameVoucher/reducer';
import {helpersDataReducer} from './store/helperData/reducer';
import {playerReducer} from './store/player/reducer';
import {PlayerEffect} from './store/player/player-effect';
import {permissionGroupReducer} from './store/permissionGroup/reducer';
import {PermissionGroupEffect} from './store/permissionGroup/permission-group.effect';
import {progressReducer} from './store/progress/reducer';
import {ProgressEffect} from './store/progress/progress-effect';
import {licenseReducer} from './store/license/reducer';
import {LicenseEffect} from './store/license/license-effect';
import {NotFoundComponent} from './not-found/not-found.component';
import {pageReducer} from './store/page/reducer';
import {PageEffect} from './store/page/page-effect';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule
} from '@abacritt/angularx-social-login';
import {partnerReducer} from './store/partner/reducer';
import {PartnerEffect} from './store/partner/partner-effect';
import {purchaseReducer} from './store/purchases/reducer';
import {translationReducer} from './store/translation/reducer';
import {TranslationEffect} from './store/translation/translation.effect';
import {AttributeEffect} from './store/attributes/attribute.effect';
import {attributeReducer} from './store/attributes/reducer';
import {ReviewEffect} from './store/reviews/review.effect';
import {reviewReducer} from './store/reviews/reducer';
import {pushNotificationReducer} from './store/pushNotification/reducer';
import {PushNotificationEffect} from './store/pushNotification/push-notification.effect';
import {CityEffect} from './store/city/city.effect';
import {cityReducer} from './store/city/reducer';
import {DialogService} from 'primeng/dynamicdialog';

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        NotFoundComponent,
        ForgottenPasswordComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        SharedModule,
        GraphQLModule,
        RippleModule,
        SocialLoginModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'cs'
        }),
        StoreModule.forRoot({
            user: userReducer as ActionReducer<IUserState, Action>,
            // data: dataReducer as ActionReducer<IDataState, Action>
            data: concatReducers(
                dataReducer,
                gamesReducer,
                saleSourceReducer,
                productPartnerReducer,
                gameVoucherReducer,
                helpersDataReducer,
                playerReducer,
                permissionGroupReducer,
                progressReducer,
                purchaseReducer,
                cityReducer,
                licenseReducer,
                pageReducer,
                partnerReducer,
                translationReducer,
                attributeReducer,
                reviewReducer,
                pushNotificationReducer
            )
        }),
        EffectsModule.forRoot([
            GeneralEffect,
            UserEffect,
            PurchasesEffect,
            SettingsEffect,
            GamesEffect,
            DataEffect,
            SaleSourceEffect,
            ProductPartnerEffect,
            GameVoucherEffect,
            PlayerEffect,
            PermissionGroupEffect,
            ProgressEffect,
            LicenseEffect,
            PageEffect,
            PartnerEffect,
            TranslationEffect,
            CityEffect,
            AttributeEffect,
            ReviewEffect,
            PushNotificationEffect
        ]),
        StoreDevtoolsModule.instrument({logOnly: environment.production}),
    ],
    providers: [
        DialogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true
        },
        MessageService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.facebookAppId
                        )
                    },
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleClientId ?? ''
                        )
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
