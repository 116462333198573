import {Component, Input} from '@angular/core';
import {StepElement, StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ControlContainer, UntypedFormArray} from '@angular/forms';
import {StepElementForm} from '../../../forms/types';
import {MyFormArray} from '../../../forms/forms';
import {AppActions} from '../../../../store/app-actions.service';
import {cancelConfirmDialogAction, showConfirmDialogAction} from '../../../../store/general/actions';
import {GameHelper} from '../../../forms/game-helpers';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-element-list',
    templateUrl: './element-list.component.html',
    styleUrls: ['./element-list.component.scss']
})
export class ElementListComponent {

    @Input() fieldsetTitle: string = '';
    @Input() allowedElementTypes: { label: string, command: () => any }[] = [];
    @Input() readonly = false;

    elementsWithoutSettings: StepElementTypeEnum[] = GameHelper.elementsWithoutSettings;

    constructor(
        private controlContainer: ControlContainer,
        private appActions: AppActions
    ) {
    }

    get formArray(): MyFormArray<StepElementForm> {
        if (!(this.controlContainer.control instanceof UntypedFormArray)) {
            throw new Error('FormGroup must be formArray!!!!');
        }
        return this.controlContainer.control as MyFormArray<StepElementForm>;
    }

    toggle(icon: HTMLElement) {
        if (icon.firstElementChild?.classList.contains('pi-chevron-down')) {
            icon.firstElementChild?.classList.remove('pi-chevron-down');
            icon.firstElementChild?.classList.add('pi-chevron-up');
        } else {
            icon.firstElementChild?.classList.add('pi-chevron-down');
            icon.firstElementChild?.classList.remove('pi-chevron-up');
        }
    }

    drop(event: CdkDragDrop<{ name: string }, any>) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        moveItemInArray(this.formArray.controls, event.previousIndex, event.currentIndex);
        this.formArray.patchValue([]);
        this.formArray.markAsTouched();
        this.formArray.arrayOrderChanged$.next(null);
    }

    async removeElement(index: number) {
        const res = await lastValueFrom(this.appActions.dispatch(showConfirmDialogAction({
            header: 'general.attention',
            message: 'games.steps.removeElementAlert.message',
            cancelButton: 'general.cancelButton',
            confirmButton: 'general.confirmButton'
        }), [cancelConfirmDialogAction]));
        if (!res.confirmed) {
            return;
        }
        this.formArray.removeAt(index);
        this.formArray.arrayOrderChanged$.next(null);
        this.formArray.markAsTouched();
    }
}
