
<app-error-list *ngIf="loaded" [errors]="form.errors"></app-error-list>
<ng-container *ngIf="loaded" [ngSwitch]="this.form.value.type">
    <app-audio *ngSwitchCase="elementTypes.Audio" [readonly]="readonly" [stepElementForm]="form"></app-audio>
    <app-clue-end *ngSwitchCase="elementTypes.ClueEnd" [readonly]="readonly" [stepElementForm]="form"></app-clue-end>
    <app-clue-start *ngSwitchCase="elementTypes.ClueStart" [readonly]="readonly" [stepElementForm]="form"></app-clue-start>
    <app-complete-game *ngSwitchCase="elementTypes.CompleteGame" [readonly]="readonly" [stepElementForm]="form"></app-complete-game>
    <app-continue-button *ngSwitchCase="elementTypes.ContinueButton" [readonly]="readonly" [stepElementForm]="form"></app-continue-button>
    <app-continue-timer *ngSwitchCase="elementTypes.ContinueTimer" [readonly]="readonly" [stepElementForm]="form"></app-continue-timer>
    <app-image *ngSwitchCase="elementTypes.Image" [readonly]="readonly" [stepElementForm]="form"></app-image>
    <app-input *ngSwitchCase="elementTypes.InputField" [readonly]="readonly" [stepElementForm]="form"></app-input>
    <app-inventory-new-item *ngSwitchCase="elementTypes.InventoryNewItemAlert" [readonly]="readonly" [stepElementForm]="form"></app-inventory-new-item>
    <app-location *ngSwitchCase="elementTypes.Location" [readonly]="readonly" [stepElementForm]="form"></app-location>
    <app-pause-timer *ngSwitchCase="elementTypes.PauseTimer" [readonly]="readonly" [stepElementForm]="form"></app-pause-timer>
    <app-phone-number *ngSwitchCase="elementTypes.PhoneNumberInput" [readonly]="readonly" [stepElementForm]="form"></app-phone-number>
    <app-rate-window *ngSwitchCase="elementTypes.RateWindow" [readonly]="readonly" [stepElementForm]="form"></app-rate-window>
    <app-select-option *ngSwitchCase="elementTypes.SelectOption" [readonly]="readonly" [stepElementForm]="form"></app-select-option>
    <app-share-button *ngSwitchCase="elementTypes.ShareButton" [readonly]="readonly" [stepElementForm]="form"></app-share-button>
    <app-skip-step-button *ngSwitchCase="elementTypes.SkipStepButton" [readonly]="readonly" [stepElementForm]="form"></app-skip-step-button>
    <app-sliding-puzzle *ngSwitchCase="elementTypes.SlidingPuzzle" [readonly]="readonly" [stepElementForm]="form"></app-sliding-puzzle>
    <app-small-info-popup *ngSwitchCase="elementTypes.SmallInfoPopup" [readonly]="readonly" [stepElementForm]="form"></app-small-info-popup>
    <app-start-game-button *ngSwitchCase="elementTypes.StartGameButton" [readonly]="readonly" [stepElementForm]="form"></app-start-game-button>
    <app-step-summary *ngSwitchCase="elementTypes.StepSummary" [readonly]="readonly" [stepElementForm]="form"></app-step-summary>
    <app-take-photo-button *ngSwitchCase="elementTypes.TakePhotoButton" [readonly]="readonly" [stepElementForm]="form"></app-take-photo-button>
    <app-team-name *ngSwitchCase="elementTypes.TeamNameInput" [readonly]="readonly" [stepElementForm]="form"></app-team-name>
    <app-text *ngSwitchCase="elementTypes.Text" [readonly]="readonly" [stepElementForm]="form"></app-text>
    <app-timeout *ngSwitchCase="elementTypes.Timeout" [readonly]="readonly" [stepElementForm]="form"></app-timeout>
    <app-video *ngSwitchCase="elementTypes.Video" [readonly]="readonly" [stepElementForm]="form"></app-video>
    <app-qr-code *ngSwitchCase="elementTypes.QrCode" [readonly]="readonly" [stepElementForm]="form"></app-qr-code>
    <app-pair-sentence *ngSwitchCase="elementTypes.PairSentence" [readonly]="readonly" [stepElementForm]="form"></app-pair-sentence>
    <ng-template ngSwitchDefault>
        TODO - Missing Component
    </ng-template>
</ng-container>
