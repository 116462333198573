import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {SelectPositionComponent} from '../../custom/select-position/select-position.component';
import {take} from 'rxjs/operators';
import {StepElement} from '../../../../graphql/types.graphql-gen';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {parseInt} from 'lodash';
import {lastValueFrom} from 'rxjs';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent extends ElementBaseComponent<LocationSettingsControl> implements OnInit {

    static getSettingsFormGroup(game: GameFragment, element?: StepElement): MyFormGroupV2<LocationSettingsControl> {
        const g = new MyFormGroupV2<LocationSettingsControl>({
            longitude: new FormControl<any>(null, {validators: Validators.compose([Validators.required, CustomValidatorsService.requiredNumber()]), nonNullable: true}),
            latitude: new FormControl<any>(null, {validators: Validators.compose([Validators.required, CustomValidatorsService.requiredNumber()]), nonNullable: true}),
            radius: new FormControl(15, {nonNullable: true, validators: Validators.required}),
            showCompass: new FormControl('0', {nonNullable: true}),
            options: new MyFormGroupV2({
                showDirection: new FormControl(true, {nonNullable: true}),
                showDistance: new FormControl(true, {nonNullable: true}),
            })
        });
        // backward compatibility
        if (element && !element.settings.options && element.settings.showCompass === '0') {
            g.controls.options.controls.showDirection.setValue(false);
        }
        g.addValidators(Validators.compose([
            CustomValidatorsService.customValidator(() => {
                if (g.controls.options.controls.showDirection.value && g.controls.showCompass.value === '0') {
                    g.controls.showCompass.setValue('1');
                } else if (!g.controls.options.controls.showDirection.value && g.controls.showCompass.value === '1') {
                    g.controls.showCompass.setValue('0');
                }
                if (typeof g.controls.latitude.value === 'string') {
                    const latitude = parseFloat(g.controls.latitude.value);
                    g.controls.latitude.setValue(latitude ?? null);
                }
                if (typeof g.controls.longitude.value === 'string') {
                    const longitude = parseFloat(g.controls.longitude.value);
                    g.controls.longitude.setValue(longitude ?? null);
                }
                if(typeof g.controls.radius.value === 'string') {
                    const radius = parseInt(g.controls.radius.value);
                    g.controls.radius.setValue(radius ?? 15);
                }
                return null;
            })]) as ValidatorFn);

        return g;
    }


    ngOnInit() {
        super.ngOnInit();
    }

    async openGoogleMap() {
        const coordinates: google.maps.LatLngLiteral | null = this.stepElementForm.getRawValue().settings.longitude && this.stepElementForm.getRawValue().settings.latitude ? {
            lat: parseFloat(this.stepElementForm.controls.settings.controls.latitude.value.toString()),
            lng: parseFloat(this.stepElementForm.controls.settings.controls.longitude.value.toString())
        } : null;
        const ref = this.dialogService.open(SelectPositionComponent, {
            data: {coordinates, radius: this.stepElementForm.controls.settings.controls.radius.value},
            header: 'Vyberte pozici',
            width: '700px',
            contentStyle: {'max-height': '750px', 'overflow': 'auto'},
            baseZIndex: 10000,
            dismissableMask: true
        });
        const res: { coordinates: google.maps.LatLngLiteral, radius: number } | undefined = await lastValueFrom(ref.onClose.pipe(take(1)));
        if (res?.coordinates) {
            this.stepElementForm.controls.settings.controls.latitude.setValue(res.coordinates.lat);
            this.stepElementForm.controls.settings.controls.longitude.setValue(res.coordinates.lng);
            this.stepElementForm.controls.settings.controls.radius.setValue(res.radius);
        }
    }
}

export type LocationSettingsControl = {
    longitude: FormControl<number>,
    latitude: FormControl<number>,
    radius: FormControl<number>,
    showCompass: FormControl<'0' | '1'>,
    options: MyFormGroupV2<{
        showDirection: FormControl<boolean>,
        showDistance: FormControl<boolean>
    }>
}
