import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchPermissionGroupAction,
    fetchPermissionGroupFailAction,
    fetchPermissionGroupsAction,
    fetchPermissionGroupsFailAction,
    fetchPermissionGroupsMetaDataAction,
    fetchPermissionGroupsMetaDataFailAction,
    fetchPermissionGroupsMetaDataSuccessAction,
    fetchPermissionGroupsSuccessAction,
    fetchPermissionGroupSuccessAction,
    updatePermissionGroupAction, updatePermissionGroupFailAction, updatePermissionGroupSuccessAction
} from './actions';
import {concatMap, map} from 'rxjs/operators';
import {GetPermissionGroupsMetaDataGQL} from '../../graphql/queries/metaData/metaData.graphql-gen';
import {
    GetPermissionGroupGQL,
    GetPermissionGroupsGQL
} from '../../graphql/queries/getPermissionGroups/getPermissionGroups.graphql-gen';
import {PermissionGroup, UpdatePermissionGroupResult} from '../../graphql/types.graphql-gen';
import {fetchPlayerFailAction} from '../player/actions';
import {fixMutation} from '../../shared/helpers';
import {
    UpdatePermissionGroupGQL
} from '../../graphql/mutations/updatePermissionGroup/updatePermissionGroup.graphql-gen';

@Injectable()
export class PermissionGroupEffect implements OnDestroy {


    subSink = new SubSink();

    fetchPermissionGroupsMetaData$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPermissionGroupsMetaDataAction
        ),
        concatMap((action) => {
            return this.getPermissionGroupsMetaDataGQL.fetch();
        }),
        map((res) => {
            if (res.errors) {
                return fetchPermissionGroupsMetaDataFailAction({header: '', message: res.errors[0].message});
            }
            return fetchPermissionGroupsMetaDataSuccessAction({result: res.data.getPermissionGroups.result, allPermissions: res.data.getPermissionGroups.allPermissions as string[][]});
        })
    ));

    fetchPermissionGroups$ = createEffect(() => this.actions$.pipe(
        ofType(fetchPermissionGroupsAction),
        concatMap((action) => {
            return this.getPermissionsGroupGQL.fetch({filter: action.filter}).pipe(
                map((res) => {
                    return {res, originalAction: action};
                })
            )
        }),
        map((res) => {
            if (res.res.errors) {
                return fetchPermissionGroupsFailAction({header: '', message: res.res.errors[0].message});
            }
            return fetchPermissionGroupsSuccessAction({
                result: res.res.data.getPermissionGroups,
                updateStore: res.originalAction.updateStore
            });
        })
    ));

    fetchPermissionGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fetchPermissionGroupAction),
        concatMap((action) => {
            return this.getPermissionGroupGQL.fetch(({id: action.id.toString()}))
        }),
        map((res) => {
            if(res.errors) {
                return fetchPermissionGroupFailAction({header: '', message: res.errors[0].message})
            }
            if (!res.data.getPermissionGroups.result[0]) {
                return fetchPermissionGroupFailAction({header: '', message: 'permission_group_not_found'});
            }
            return fetchPermissionGroupSuccessAction({permissionGroup: res.data.getPermissionGroups.result[0] as PermissionGroup})
        })
    ));

    updatePermissionGroup$ = createEffect(() => this.actions$.pipe(
       ofType(updatePermissionGroupAction),
       concatMap((action) => {
           return fixMutation(this.updatePermissionGroupGQL.mutate({input: action.input}))
       }),
        map((res) => {
            if(res.errors) {
                return updatePermissionGroupFailAction({header: '', message: res.errors[0].message})
            }
            return updatePermissionGroupSuccessAction({result: res.data.updatePermissionGroup as UpdatePermissionGroupResult})
        })
    ));

    constructor(
        private actions$: Actions,
        private getPermissionGroupsMetaDataGQL: GetPermissionGroupsMetaDataGQL,
        private getPermissionsGroupGQL: GetPermissionGroupsGQL,
        private getPermissionGroupGQL: GetPermissionGroupGQL,
        private updatePermissionGroupGQL: UpdatePermissionGroupGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
