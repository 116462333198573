import {Injectable} from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn, FormArray, FormControl, FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {EntityTranslationInput} from '../../graphql/types.graphql-gen';

@Injectable({
    providedIn: 'root'
})
export class CustomValidatorsService {

    constructor(
        private translateService: TranslateService
    ) {
    }

    phoneNumber(): ValidatorFn {
        return (control: AbstractControl) => {
            const regex = /^[\+][0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}$/im;
            if (control.value) {
                if ((control.value as string).match(regex)?.[0] !== control.value) {
                    return {
                        message: this.translateService.instant('errors.phoneNumberInvalid', {phoneNumber: control.value})
                    };
                }
            }
            return null;
        };
    }

    static required = _.memoize((message: string) => {
        return (control: AbstractControl) => {
            const res = Validators.required(control);
            if(res) {
                res.message = message;
            }
            return res;
        }
    });

    static requiredNumber(): ValidatorFn {
        return (control: AbstractControl) => {
            if(typeof control.value !== 'number') {
                return {
                    message: 'errors.elementMustBeNumber'
                };
            }
            return null;
        }
    }

    static addErrors(errors: ValidationErrors | null, errorsToAdd: ValidationErrors): ValidationErrors {
        if(errors) {
            return _.merge(errors, errorsToAdd);
        }
        return errorsToAdd;
    }

    static removeErrors(errors: ValidationErrors | null, errorsToRemove: string[]): ValidationErrors|null {
        if(!errors) {
            return null;
        }
        errorsToRemove.forEach((key) => {
            if(errors[key]) {
                delete errors[key];
            }
        })
        if(_.isEmpty(errors)) {
            return null;
        }
        return errors;
    }

    static requiredIf(paths: string[], value: any = null): ValidatorFn {
        return (control: AbstractControl) => {
            const form = control.root as UntypedFormGroup;
            paths.forEach((path) => {
                let c: AbstractControl | null = null;
                if (path.startsWith('this')) {
                    c = (control.parent as UntypedFormGroup)?.get(path.replace('this.', ''));
                } else {
                    c = form.get(path);
                }
                if (!c) {
                    return;
                }
                if (!!control.value && (value === null || control.value === value)) {
                    c.addValidators(Validators.required);
                    c.setValue(c.value);
                } else {
                    c.removeValidators(Validators.required);
                    c.setValue(c.value);
                }
            });
            return null;
        };

    }
    static customValidator(callback: (control: FormControl<any>) => any): ValidatorFn;
    static customValidator<T extends any>(callback: (control: FormControl<T>) => any): ValidatorFn;
    static customValidator(callback: (control: FormArray<any>) => any): ValidatorFn;
    static customValidator(callback: (control: AbstractControl<any>) => any): ValidatorFn;
    static customValidator(callback: (control: FormGroup<any>) => any): ValidatorFn;
    static customValidator(callback: (control: any) => any): ValidatorFn {
        return (control: AbstractControl) => {
            return callback(control);
        };
    }

    static customAsyncValidator(callback: (control: AbstractControl) => Observable<any>): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return callback(control);
        };
    }


    static oneTranslationRequiredError = CustomValidatorsService.customValidator(() => {
        return {oneTranslationRequired: 'errors.oneTranslationRequired'};
    })
    static oneTranslationRequired = CustomValidatorsService.customValidator((control: FormArray<FormGroup<{ langCode: FormControl<string>, value: FormControl<string> }>>) => {
        if(!control.getRawValue().find((v) => v.value.length > 0)) {
            if(!control.controls[0].controls.value.hasValidator(CustomValidatorsService.oneTranslationRequiredError)) {
                control.controls.forEach((c) => {
                    c.controls.value.addValidators(CustomValidatorsService.oneTranslationRequiredError);
                    c.controls.value.patchValue(c.controls.value.value);
                });
            }
        } else {
            if(control.controls[0].controls.value.hasValidator(CustomValidatorsService.oneTranslationRequiredError)) {
                control.controls.forEach((c) => {
                    c.controls.value.removeValidators(CustomValidatorsService.oneTranslationRequiredError);
                    c.controls.value.patchValue(c.controls.value.value);
                });
            }
        }
        return null;
    })

    emails(): ValidatorFn {
        return (control: AbstractControl) => {
            const value: string[] = control.value;
            if (!Array.isArray(value)) {
                return {mustBeArray: true};
            }
            const emails = [];
            let trimmed = false;
            for (const email of value) {
                if (email.trim().length !== email.length) {
                    trimmed = true;
                    emails.push(email.trim());
                } else {
                    emails.push(email.trim());
                }
                const emailControl = new UntypedFormControl(email.trim());
                if (Validators.email(emailControl) !== null) {
                    return {
                        message: this.translateService.instant('errors.emailNotValid', {email})
                    };
                }
            }
            if (trimmed) {
                control.setValue(emails);
            }
            return null;
        };
    }

}

