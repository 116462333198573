import {createAction, props} from '@ngrx/store';
import {LoginCredentialsInput, LoginResult} from '../../graphql/types.graphql-gen';
import {IUserState} from '../state';

export const loadUserStateAction = createAction('[User] LOAD_USER_STATE', props<LoadUserStateActionProps>());
export const logoutAction = createAction('[User] LOGOUT');
export const loginAction = createAction('[User] LOGIN', props<LoginActionProps>());
export const loginSuccessAction = createAction('[User] LOGIN_SUCCESS', props<LoginSuccessActionProps>());
export const loginFailAction = createAction('[User] LOGIN_FAIL', props<LoginFailActionProps>());
export const updateUserPermissionsAction = createAction('[User] UPDATE_USER_PERMISSIONS', props<UpdateUserPermissionsActionProps>());


export const saveElementPreferencesAction = createAction('[User] SAVE_ELEMENT_SETTINGS', props<{ name: string, preferences: any }>());

export type LoadUserStateActionProps = { userState: IUserState };
export type LoginActionProps = { loginCredentials: LoginCredentialsInput };
export type LoginSuccessActionProps = { loginResult: LoginResult };
export type LoginFailActionProps = DefaultFailMessage;
export type UpdateUserPermissionsActionProps = { implicitPermissions: string[][], managedGameIds: number[] };


export type DefaultFailMessage = { message: string, header: string, category?: string };
