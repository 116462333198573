import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePushNotificationMutationVariables = Types.Exact<{
  input: Types.PushNotificationInput;
}>;


export type UpdatePushNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updatePushNotification: (
    { __typename?: 'UpdatePushNotificationResult' }
    & Pick<Types.UpdatePushNotificationResult, 'removePushNotificationId'>
    & { pushNotification?: Types.Maybe<(
      { __typename?: 'PushNotification' }
      & FullPushNotificationFragment
    )> }
  ) }
);

export type PushNotificationFragment = (
  { __typename?: 'PushNotification' }
  & Pick<Types.PushNotification, 'id' | 'name' | 'priority' | 'title' | 'message' | 'dateFinished' | 'active' | 'sendAt' | 'extraData' | 'createdAt' | 'updatedAt'>
  & { photoSource?: Types.Maybe<(
    { __typename?: 'MediaFile' }
    & MediaFileFragment
  )>, stats: (
    { __typename?: 'PushNotificationStats' }
    & Pick<Types.PushNotificationStats, 'error' | 'success' | 'total'>
  ) }
);

export type MediaFileFragment = (
  { __typename?: 'MediaFile' }
  & Pick<Types.MediaFile, 'id' | 'name' | 'isImage' | 'createdAt' | 'updatedAt'>
);

export type FullPushNotificationFragment = (
  { __typename?: 'PushNotification' }
  & PushNotificationFragment
);

export const MediaFileFragmentDoc = gql`
    fragment mediaFile on MediaFile {
  id
  name
  isImage
  createdAt
  updatedAt
}
    `;
export const PushNotificationFragmentDoc = gql`
    fragment pushNotification on PushNotification {
  id
  name
  priority
  title
  message
  photoSource {
    ...mediaFile
  }
  dateFinished
  active
  sendAt
  extraData
  createdAt
  updatedAt
  stats {
    error
    success
    total
  }
}
    ${MediaFileFragmentDoc}`;
export const FullPushNotificationFragmentDoc = gql`
    fragment fullPushNotification on PushNotification {
  ...pushNotification
}
    ${PushNotificationFragmentDoc}`;
export const UpdatePushNotificationDocument = gql`
    mutation updatePushNotification($input: PushNotificationInput!) {
  updatePushNotification(input: $input) {
    pushNotification {
      ...fullPushNotification
    }
    removePushNotificationId
  }
}
    ${FullPushNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePushNotificationGQL extends Apollo.Mutation<UpdatePushNotificationMutation, UpdatePushNotificationMutationVariables> {
    document = UpdatePushNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }