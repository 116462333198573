import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MyFormGroup} from '../../../forms/forms';
import {City, GameGroupStateEnum, Lang, Player} from '../../../../graphql/types.graphql-gen';
import {BaseComponent} from '../../base/base.component';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state';
import {combineLatest} from 'rxjs';
import {initialDataSelector} from '../../../../store/data/selector';
import {take} from 'rxjs/operators';
import {AppActions} from '../../../../store/app-actions.service';
import {
    updateGameGroupAction,
    updateGameGroupFailAction,
    updateGameGroupSuccessAction
} from '../../../../store/games/actions';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {ScrollToInvalidControlDirective} from '../../../directives/scroll-to-invalid-control.directive';
import {userSelector} from '../../../../store/user/selectors';
import {GameGroupFormSchemeType, SchemeService} from '../../../../services/scheme.service';
import {webalize} from '../../../webalize';

type FormValue = {
    name: string,
    slug: string,
    langId: number,
    cityId: number
}

@Component({
    selector: 'app-new-game-group',
    templateUrl: './new-game-group.component.html',
    styleUrls: ['./new-game-group.component.scss']
})
export class NewGameGroupComponent extends BaseComponent implements OnInit {

    @ViewChildren(ScrollToInvalidControlDirective) scrollDirective: QueryList<ScrollToInvalidControlDirective>;
    form: MyFormGroup<UntypedFormGroup | UntypedFormControl | AbstractControl>;
    loaded = false;
    languages: Lang[];
    cities: City[];
    loading = {
        form: false
    }
    player: Player;
    activeFormScheme: GameGroupFormSchemeType;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAppState>,
        private appActions: AppActions,
        private messageService: MessageService,
        private schemeService: SchemeService,
        public ref: DynamicDialogRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.activeFormScheme = this.schemeService.gameGroupFormScheme;
        this.subSink.sink = combineLatest([
            this.store.select(initialDataSelector).pipe(take(1)),
            this.store.select(userSelector).pipe(take(1))
        ]).subscribe(([initialData, player]) => {
            if (this.loaded) {
                return;
            }
            this.player = player;
            this.languages = initialData.languages as Lang[];
            this.cities = initialData.cities as City[];
            this.initForm();
            this.loaded = true;
        });
    }

    private initForm() {
        let defaultLang = this.languages.find((l) => l.urlCode === 'cs')?.id;

        if (!defaultLang) {
            defaultLang = this.languages[0].id
        }
        this.form = this.formBuilder.group({
            name: new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(3)])),
            slug: new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/)])),
            langId: new UntypedFormControl(defaultLang, Validators.required)
        });
        if (this.player.license) {
            this.form.addControl('licenseId', new UntypedFormControl(this.player.license.id, Validators.required));
        } else {
            this.form.addControl('cityId', new UntypedFormControl(this.cities[0]?.id, Validators.required));
        }
    }

    createGroup() {
        if (this.loading.form) {
            return;
        }
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            this.scrollDirective.first.scrollToFirstInvalidControl();
            return;
        }
        this.loading.form = true;
        this.appActions.dispatch(
            updateGameGroupAction({
                input: {
                    updateGameGroup: {
                        ...(this.form.value as FormValue),
                        state: GameGroupStateEnum.Hidden,
                    }
                }
            }),
            [updateGameGroupSuccessAction, updateGameGroupFailAction]
        ).subscribe((action) => {
            if (action.type === updateGameGroupFailAction.type) {
                if (action.category === 'slugNotUnique') {
                    this.form.controls.slug.setErrors({slugNotValid: true});
                } else {
                    this.messageService.add({severity: 'error', summary: 'Error', detail: action.message});
                }
            } else {
                this.ref.close({groupId: action.gameGroup?.id});
            }
            this.loading.form = false;
        })
    }

    nameChanged(value: string) {
        this.form.controls?.slug.setValue(webalize(value));
    }
}
