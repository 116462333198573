<div class="p-fluid grid">
    <div class="col-9">
        <label class="pb-2">{{'elements.location.searchAddress'|translate|firstUpper}}</label>
        <p-autoComplete [placeholder]="'elements.location.typeToSearch'|translate|firstUpper" #searchComponent (onFocus)="setSearch(searchComponent)"
                        [(ngModel)]="text" [suggestions]="results" (onSelect)="selectPlace($event)"
                        (completeMethod)="search($event)"></p-autoComplete>
    </div>
    <div class="col-3">
        <label class="pb-2">{{'elements.location.radius'|translate|firstUpper}}</label>
        <p-inputNumber
            [showButtons]="true"
            [(ngModel)]="radius"
            inputId="radius-{{inputId}}"
            locale="cs-CS"
            [min]="0"
            (onInput)="changeRadius($event)"
            [suffix]="' m.'"></p-inputNumber>
    </div>
    <div class="col-12 pb-0">
        <google-map mark [height]="'400px'" width="'100%'" [style]="{'margin-bottom': '1em'}" [options]="options" (mapClick)="handleMapClick($event)" (mapInitialized)="setMap($event)" ></google-map>
<!--        <p-gmap-->
<!--            #gmap-->
<!--            [style]="{'width':'100%','height':'400px', 'margin-bottom': '1em'}"-->
<!--            [options]="options"-->
<!--            (onMapClick)="handleMapClick($event)"-->
<!--            (onMapReady)="setMap($event)">-->
<!--        </p-gmap>-->
    </div>
    <div class="col-12 pt-0">
        <button
            pButton
            label="Uložit"
            class="p-button-sm p-button-success auto-width"
            icon="pi pi-save"
            (click)="save()"
        ></button>

    </div>
</div>
