import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from './store/state';
import {userStateSelector} from './store/user/selectors';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AutologinGuard  {

    constructor(
        private store: Store<IAppState>,
        private router: Router
    ) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve) => {
            this.store.select(userStateSelector).pipe(take(1)).subscribe((s) => {
                if(s.user) {
                    resolve(true);
                    this.router.navigate(['/', 'admin']).then()
                } else {
                    resolve(true);
                }
            });
        });
    }

}
