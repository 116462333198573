import {createAction, props} from '@ngrx/store';
import {ProductPartnerInput, ProductPartnersResult, UpdateProductPartnerResult} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {
    UpdateProductPartnerMutation
} from '../../graphql/mutations/updateProductPartner/updateProductPartner.graphql-gen';


export const fetchProductPartnersAction = createAction('[ProductPartner] FETCH_PRODUCT_PARTNER');
export const fetchProductPartnersSuccessAction = createAction('[ProductPartner] FETCH_PRODUCT_PARTNER_SUCCESS', props<FetchProductPartnersSuccessActionProps>());
export const fetchProductPartnersFailAction = createAction('[ProductPartner] FETCH_PRODUCT_PARTNER_FAIL', props<FetchProductPartnersFailActionProps>());

export const updateProductPartnerAction = createAction('[ProductPartner] UPDATE_PRODUCT_PARTNER', props<UpdateProductPartnerActionProps>());
export const updateProductPartnerSuccessAction = createAction('[ProductPartner] UPDATE_PRODUCT_PARTNER_SUCCESS', props<UpdateProductPartnerSuccessActionProps>());
export const updateProductPartnerFailAction = createAction('[ProductPartner] UPDATE_PRODUCT_PARTNER_FAIL', props<UpdateProductPartnerFailActionProps>());

export type FetchProductPartnersSuccessActionProps = { result: ProductPartnersResult };
export type FetchProductPartnersFailActionProps = DefaultFailMessage;

export type UpdateProductPartnerActionProps = { input: ProductPartnerInput };
export type UpdateProductPartnerSuccessActionProps = { result: UpdateProductPartnerMutation['updateProductPartner']};
export type UpdateProductPartnerFailActionProps = DefaultFailMessage;

