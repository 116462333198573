import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchPageDataAction,
    fetchPageDataFailAction,
    fetchPageDataSuccessAction,
    updatePageDataAction, updatePageDataFailAction, updatePageDataSuccessAction
} from './actions';
import {concatMap} from 'rxjs';
import {GetPagesDataGQL} from '../../graphql/queries/getPagesData/getPagesData.graphql-gen';
import {map} from 'rxjs/operators';
import {UpdatePageDataGQL} from '../../graphql/mutations/updatePageData/updatePageData.graphql-gen';
import {fixMutation} from '../../shared/helpers';

@Injectable()
export class PageEffect implements OnDestroy {


    subSink = new SubSink();

    fetchPageData$ = createEffect(() => this.actions$.pipe(
        ofType(fetchPageDataAction),
        concatMap((action) => {
            return this.getPagesDataGQL.fetch({input: action.input});
        }),
        map((res) => {
            if(res.errors) {
                return fetchPageDataFailAction({message: res.errors[0].message, header: ''});
            }
            return fetchPageDataSuccessAction({result: res.data.getPagesData.pagesData[0]});
        })
    ));

    updatePageData$ = createEffect(() => this.actions$.pipe(
        ofType(updatePageDataAction),
        concatMap((action) => {
            return fixMutation(this.updatePageDataGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updatePageDataFailAction({message: res.errors[0].message, header: ''});
            }
            return  updatePageDataSuccessAction({result: res.data.updatePageData});
        })
    ));


    constructor(
        private actions$: Actions,
        private getPagesDataGQL: GetPagesDataGQL,
        private updatePageDataGQL: UpdatePageDataGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
