import {
    AfterViewInit, ApplicationRef, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Game, StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {GameHelper} from '../../../forms/game-helpers';
import {LocationSettings} from '../../elements/element-types';
import * as _ from 'lodash';
import {CoordinatesHelper} from '../../../utils/coordinatesHelper';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer} from '@angular/forms';
import {StepElementForm, ZedaGameFormType} from '../../../forms/types';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {LocationSettingsControl} from '../../elements/location/location.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {BaseComponent} from '../../base/base.component';
import {MenuItem} from 'primeng/api';
import {firstUpper, sleep} from '../../../helpers';

@Component({
    selector: 'app-game-map',
    templateUrl: './game-map.component.html',
    styleUrls: ['./game-map.component.scss'],
})
export class GameMapComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {


    @Input() game?: Game;
    @Output() createStepEvent = new EventEmitter<{ type: StepElementTypeEnum, position: google.maps.LatLngLiteral }>();
    @Output() focusPointEvent = new EventEmitter<any>();
    @ViewChild('menuDiv') menuDiv: ElementRef;
    @ViewChild('radiusControl', {read: ElementRef}) radiusControl: ElementRef;
    form?: ZedaGameFormType
    options: google.maps.MapOptions;
    loaded = false;

    map: google.maps.Map;
    directionsRenderer: google.maps.DirectionsRenderer;
    route: RoutePoint[];
    bounds: google.maps.LatLngBoundsLiteral | null = null;
    radius = 15;

    overlays: any[];
    highlightTimeout: any;
    lastHighlightPoint: google.maps.Marker;
    mapHelp?: {
        active: boolean,
        point: google.maps.Marker,
        infoWindow: google.maps.InfoWindow
    };
    lastClickedPosition: google.maps.LatLngLiteral;
    items: MenuItem[];

    mapRightClickListener: google.maps.MapsEventListener;

    constructor(
        private translateService: TranslateService,
        private controlContainer: ControlContainer,
        private cdr: ApplicationRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.refreshMap();
        this.loaded = true;

    }

    ngAfterViewInit() {
        if (this.map) {
            this.initializeMapControlButtons();
        }
    }

    refreshMap(form?: ZedaGameFormType) {
        this.form = form ?? this.controlContainer.control as ZedaGameFormType ?? undefined;
        this.initRoute();
        this.initMapItems();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.mapRightClickListener) {
            google.maps.event.removeListener(this.mapRightClickListener);
        }
    }

    initMapItems() {
        const items = [{
            label: firstUpper(this.translateService.instant('games.zedaSteps.chooseStepElementType')),
            items: [
                {
                    label: 'SelectOption',
                    icon: 'pi pi-plus',
                    command: () => {
                        if (!this.lastClickedPosition) {
                            return;
                        }
                        this.createStepEvent.emit({
                            position: this.lastClickedPosition,
                            type: StepElementTypeEnum.SelectOption
                        })
                    }
                },
                {
                    label: 'InputField',
                    icon: 'pi pi-plus',
                    command: () => {
                        if (!this.lastClickedPosition) {
                            return;
                        }
                        this.createStepEvent.emit({
                            position: this.lastClickedPosition,
                            type: StepElementTypeEnum.InputField
                        })
                    }
                }
            ]
        }];
        if (this.route.length) {
            this.items = items;
        } else {
            this.items = [{
                label: firstUpper(this.translateService.instant('games.zedaSteps.chooseStepElementType')),
                items: [
                    {
                        label: 'Start',
                        icon: 'pi pi-plus',
                        command: () => {
                            if (!this.lastClickedPosition) {
                                return;
                            }
                            this.createStepEvent.emit({
                                position: this.lastClickedPosition,
                                type: StepElementTypeEnum.SelectOption
                            })
                            this.items = items;
                        }
                    }
                ]
            }];
        }
    }

    async handleMapClick($event: any, onlyHide = false) {
        if (this.mapHelp?.active) {
            this.mapHelp.point.setVisible(false);
            this.mapHelp.infoWindow.close();
            this.mapHelp.active = false;
        } else {
            if (onlyHide) {
                this.menuDiv.nativeElement.style.display = 'none';
            } else {
                this.lastClickedPosition = $event.latLng.toJSON();
                // otherwise windows open also right click menu
                await sleep(10)
                this.menuDiv.nativeElement.style.display = this.menuDiv.nativeElement.style.display === 'none' ? 'block' : 'none';
                this.menuDiv.nativeElement.style.left = $event.domEvent.clientX + 'px';
                this.menuDiv.nativeElement.style.top = $event.domEvent.clientY + 'px';
            }
        }
    }


    async initializeMap($event: any) {
        this.map = $event;
        this.map.setOptions({gestureHandling: 'greedy'})
        if (this.bounds) {
            this.map.fitBounds(this.bounds);
        }
        if (this.route.length === 0) {
            const point = new google.maps.Marker({
                position: this.map.getCenter(),
                label: '',
                title: '',
                draggable: true,
                map: this.map
                // animation: google.maps.Animation.BOUNCE
            });
            point.setAnimation(google.maps.Animation.BOUNCE);
            const infowindow = new google.maps.InfoWindow({
                content: '<div><b style="font-weight: bold">' + firstUpper(this.translateService.instant('games.zedaSteps.tooltip.addNewPoint')) + '</b></div>',
            });
            infowindow.open({
                anchor: point,
                map: this.map,
                shouldFocus: false
            });
            this.mapHelp = {
                point,
                infoWindow: infowindow,
                active: true
            }
        }
        this.mapRightClickListener = this.map.addListener('rightclick', (function (event: any) {
            // @ts-ignore
            this.handleMapClick(event);
        }).bind(this))
        this.refreshMap();
        // if(this.radiusControl) {
        //     this.initializeMapControlButtons();
        // }
    }

    pointPositionChanged($event: any) {
        if (!$event.overlay.title && !isNaN(parseInt($event.overlay.title, 10))) {
            return;
        }
        const index = parseInt($event.overlay.title, 10) - 1;
        const routePoint = this.route[index];

        routePoint.radius.setCenter(routePoint.point.getPosition() ?? null);
        routePoint.locationForm?.controls.settings.patchValue({
            longitude: routePoint.point.getPosition()?.toJSON().lng,
            latitude: routePoint.point.getPosition()?.toJSON().lat
        }, {emitEvent: false});
        routePoint.locationForm?.markAsTouched();
    }

    private initRoute() {
        let center: google.maps.LatLngLiteral | null = null;
        const locations = GameHelper.getAllGameStepElements('controls', this.game, this.form?.controls.steps.controls)
            .filter((gs) => ((gs instanceof MyFormGroupV2) ? gs.controls.type.value : gs.type) === StepElementTypeEnum.Location);
        this.route = [];
        this.route = locations.map((l, index) => {
            let settings: LocationSettings;
            let location: StepElementForm<LocationSettingsControl> | undefined;
            if (l instanceof MyFormGroupV2) {
                // @ts-ignore
                settings = (l as StepElementForm<LocationSettingsControl>).controls.settings.getRawValue();
                // @ts-ignore
                location = l as StepElementForm<LocationSettingsControl>;
            } else {
                settings = l.settings;
            }
            settings.latitude = parseFloat(settings.latitude.toString());
            settings.longitude = parseFloat(settings.longitude.toString());
            settings.radius = parseFloat(settings.radius.toString());
            if (!center) {
                center = {
                    lat: settings.latitude,
                    lng: settings.longitude
                }
            }
            if(this.map) {
                center = {
                    lat: this.map.getCenter()?.lat()!,
                    lng: this.map.getCenter()?.lng()!
                }
            }
            return {...CoordinatesHelper.getRoutePoint(settings, (index + 1).toString()), locationForm: location};
        });
        this.route.forEach((routePoint) => {
            this.setRoutePointValueListener(routePoint);
        });
        this.refreshRoute();
        this.options = {
            center: center ?? {lat: 49.55733658116199, lng: 18.53796354611451},
            zoom: 6,
            clickableIcons: false,
            disableDoubleClickZoom: true,
        };

    }

    private setRoutePointValueListener(routePoint: RoutePoint) {
        if (!routePoint.locationForm) {
            return;
        }
        routePoint.point.addListener('dblclick', (event: any) => {
            this.focusLocationForm(routePoint);
        });
        routePoint.radius.addListener('dblclick', (event: any) => {
            this.focusLocationForm(routePoint);
        });
        // @ts-ignore
        this.subSink.sink = routePoint.locationForm.controls.settings.valueChanges.subscribe((values: LocationSettings) => {
            if (routePoint.locationForm?.getRawValue().settings.latitude && routePoint.locationForm?.getRawValue().settings.longitude) {

                const position = {
                    lat: parseFloat(values.latitude.toString()),
                    lng: parseFloat(values.longitude.toString()),
                };
                routePoint.point.setOptions({
                    position: position
                });
                routePoint.radius.setOptions({
                    radius: values.radius > 0 ? values.radius : 15,
                    center: position
                })
            }
        })
    }

    private refreshRoute() {
        if(this.overlays) {
            this.overlays.forEach((m) => m.setMap(null))
        }
        this.overlays = _.flatten(this.route.map((point, index) => {
            if (index === 0) {
                // point.point.setLabel(firstUpper(this.translateService.instant('games.zedaSteps.start')));
                point.point.setOptions({
                    label: '',
                    icon: 'assets/images/start.png',
                    title: (index + 1).toString(),
                    zIndex: 1,
                    map: this.map
                })
            } else if (index === this.route.length - 1) {
                point.point.setOptions({
                    label: '',
                    icon: 'assets/images/finish.png',
                    title: (index + 1).toString(),
                    zIndex: 1,
                    map: this.map
                })
            } else {
                point.point.setOptions({
                    icon: null,
                    label: (index + 1).toString(),
                    title: (index + 1).toString(),
                    zIndex: 0,
                    map: this.map
                });
            }
            point.radius.set('index', index);
            point.point.set('index', index);
            return [point.point, point.radius]
        }));

        this.recalculateBounds();
    }

    private recalculateBounds() {
        const allCoordinates: google.maps.LatLngLiteral[] = [];
        this.route.map((point) => {
            const coord = point.point.getPosition()?.toJSON();
            if (coord) {
                allCoordinates.push(coord)
            }
        })
        this.bounds = CoordinatesHelper.calculateBounds(allCoordinates);
    }

    public movePoint(previousIndex: number, currentIndex: number) {
        moveItemInArray(this.route, previousIndex, currentIndex);
        this.refreshRoute();
    }

    public changePointRadius(index: number, radius: number) {
        this.route[index]?.radius.setRadius(radius);
    }


    public addNewPoint(stepElementForm?: StepElementForm<LocationSettingsControl>, newPosition?: google.maps.LatLngLiteral) {
        const center = this.map.getCenter();
        if (!center) {
            return
        }
        if (!newPosition) {
            newPosition = center.toJSON();
        }
        const radius = this.radius >= 1 ? this.radius : 10;
        if (stepElementForm) {
            if (stepElementForm.getRawValue().settings.radius !== radius) {
                stepElementForm.controls.settings.controls.radius.setValue(radius, {emitEvent: false});
            }
        }
        const newPoint: RoutePoint = CoordinatesHelper.getRoutePoint({
                radius: radius,
                latitude: newPosition.lat,
                longitude: newPosition.lng,
            },
            (this.route.length + 1).toString(),
            true);

        newPoint.locationForm = stepElementForm;
        if (stepElementForm) {
            this.setRoutePointValueListener(newPoint);
            stepElementForm.controls.settings.controls.longitude.setValue(newPoint.point.getPosition()?.toJSON().lng!, {emitEvent: false});
            stepElementForm.controls.settings.controls.latitude.setValue(newPoint.point.getPosition()?.toJSON().lat!, {emitEvent: false});

        }
        this.route.push(newPoint);
        this.refreshRoute();
        this.menuDiv.nativeElement.style.display = 'none';
    }

    public highlightPoint(index: number) {
        const point = this.route[index]?.point;
        const position = point?.getPosition()?.toJSON();
        const bounds = this.map?.getBounds()?.toJSON();
        if (!point || !position || !bounds) {
            return;
        }
        // point is not visible
        if (position.lng <= bounds.west || bounds.east <= position.lng || position.lat <= bounds.south || bounds.north <= position.lat) {
            this.map.setCenter(position);
        }
        const oldZIndex = point.getZIndex();
        point.setOptions({animation: google.maps.Animation.BOUNCE, zIndex: 2});
        if (this.lastHighlightPoint && point !== this.lastHighlightPoint) {
            this.lastHighlightPoint.setAnimation(null);
        }
        this.lastHighlightPoint = point;
        clearTimeout(this.highlightTimeout);
        this.highlightTimeout = setTimeout(() => {
            point.setOptions({animation: null, zIndex: oldZIndex});
        }, 2000);
    }


    focusLocationForm(routePoint: RoutePoint) {
        const parent = routePoint?.locationForm?.parent?.parent?.parent;
        if (parent instanceof MyFormGroupV2) {
            parent.meta.highlight = true;
            this.focusPointEvent.next(null);
            setTimeout(() => {
                parent.meta.highlight = false;
            }, 1500);
            setTimeout(() => {
                const wobble = document.querySelector('.wobble') as HTMLElement;
                if (wobble) {
                    wobble.focus();
                    wobble.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            });
        }
    }

    initializeMapControlButtons() {
        const controlDiv = document.createElement("div");
        const controlBottomDiv = document.createElement("div");
        // Set CSS for the control border.
        const controlUI = document.createElement("div");

        controlUI.style.backgroundColor = "#fff";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginTop = "8px";
        controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = firstUpper(this.translateService.instant('games.zedaSteps.tooltip.fitBounds'));
        controlDiv.appendChild(controlUI);

        controlBottomDiv.appendChild(this.radiusControl.nativeElement);
        // controlDiv.appendChild(this.test.nativeElement);

        // Set CSS for the control interior.
        const controlText = document.createElement("div");

        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Roboto,Arial,sans-serif";
        controlText.style.fontSize = "16px";
        controlText.style.lineHeight = "38px";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = firstUpper(this.translateService.instant('games.zedaSteps.fitBounds'));
        controlUI.appendChild(controlText);

        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener("click", () => {
            this.recalculateBounds();
            if (this.bounds) {
                this.map.fitBounds(this.bounds);
            }
        });
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(controlDiv);
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(controlBottomDiv);
    }
}


type RoutePoint = {
    point: google.maps.Marker,
    radius: google.maps.Circle,
    locationForm?: StepElementForm<LocationSettingsControl>
};
