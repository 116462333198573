<ngx-monaco-editor (onInit)="afterEditorInit(editor)"  #editor style="height: calc(100% - 70px)" [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
<app-dialog-footer>
    <div class="pt-4">
        <button
            pButton
            [disabled]="code === initCode"
            label="{{'general.save'|translate}}"
            class="p-button-sm p-button-success me-2"
            icon="pi pi-save"
            (click)="saveCode()"
        ></button>
    </div>
</app-dialog-footer>
