import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-team-name',
    templateUrl: './team-name.component.html',
    styleUrls: ['./team-name.component.scss']
})
export class TeamNameComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            title: new FormControl('', {nonNullable: true}),
            text: new FormControl('', {nonNullable: true}),
            placeholder: new FormControl('', {nonNullable: true}),
        });
    }



}

type SettingsControl = {
    placeholder: FormControl<string>,
    title: FormControl<string>,
    text: FormControl<string>
}
