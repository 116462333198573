<!--<div class="app" *ngIf="showNavigation">-->
<!--  <app-navigation></app-navigation>-->
<!--  <app-header></app-header>-->
<!--  <main class="app__content">-->
<!--    <router-outlet></router-outlet>-->
<!--  </main>-->
<!--  &lt;!&ndash; HERE I WANT TO RENDER MODAL CONTENT &ndash;&gt;-->
<!--</div>-->
<!--<router-outlet *ngIf="showNavigation !== undefined && !showNavigation"></router-outlet>-->
<swal #confirmAlert
      title="Are you sure?"
      text="Are you sure you want to delete this game group?"
      confirmButtonText="Yes, do it!"
      footer=""
      cancelButtonText="Cancel"
      [showCancelButton]="true"
      icon="warning"
      [customClass]="{confirmButton: 'btn btn-danger', cancelButton: 'ms-4 btn btn-success'}"
      [buttonsStyling]="false"
      [reverseButtons]="false"
      [focusCancel]="true"
>
</swal>
<swal #confirmAlertSelectOption
      title="Are you sure?"
      text="Are you sure you want to delete this game group?"
      footer=""
      confirmButtonText="Yes, do it!"
      cancelButtonText="Cancel"
      [showCancelButton]="true"
      icon="warning"
      input="select"
      [inputValidator]="defaultSelectValidator"
      [inputOptions]="[123,23]"
      [inputValue]="123"
      inputPlaceholder="{{'general.selectOption'|translate|firstUpper}}"
      [customClass]="{confirmButton: 'btn btn-danger', cancelButton: 'ms-4 btn btn-success'}"
      [buttonsStyling]="false"
      [reverseButtons]="false"
      [focusCancel]="true"
>

</swal>
<swal #alert
      title="Are you sure?"
      text="Are you sure you want to delete this game group?"
      confirmButtonText="Ok"
      [showCancelButton]="false"
      icon="error"
      [customClass]="{confirmButton: 'btn btn-success'}"
      [buttonsStyling]="false"
>
</swal>
<swal #progressAlert
      [showConfirmButton]="false"
      [allowOutsideClick]="false"
      [progressSteps]=""
>
    <ng-container *swalPortal="swalTargets.title">
        {{progress ? (progress.title|translate) : ''}}
        <div *ngIf="progress && progress.showSpinner" class="mt-4">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="6" animationDuration="1.5s"></p-progressSpinner>
        </div>
    </ng-container>
    <ng-container *swalPortal="swalTargets.content">
        <div *ngIf="progress && progress.showProgressBar">
            <p>Nahrán {{progress.uploaded}} z {{progress.filesAmount}} souborů</p>
            <p-progressBar *ngIf="progress" [value]="parseFloat(((100 / progress.filesAmount * progress.uploaded)|number: '1.0-0') ?? '0')"></p-progressBar>
        </div>
    </ng-container>
</swal>
<p-toast></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>
<router-outlet (activate)="routerOutletActive($event)"></router-outlet>

<div class="p-fluid row align-content-center full-height m-0 p-0" *ngIf="initialLoading">
    <div class="col-12 text-center m-0 p-0">
        <p-progressSpinner [style]="{width: '150px', height: '150px'}" strokeWidth="4" animationDuration="2.0s"></p-progressSpinner>
    </div>
</div>
