<form [formGroup]="form">
    <div *ngIf="permissionTemplates.length && !disabled">
        <p style="font-size: 1.3rem" class="font-bold mb-6">
            {{'permission.permissionThemes'|translate|firstUpper}}:
            <ng-container *ngFor="let template of permissionTemplates">
                <p-chip class="mr-2 cursor-pointer" [label]="template.name|translate|firstUpper" (click)="setPermissions(template)"></p-chip>
            </ng-container>
        </p>
    </div>
<!--    <h1>{{'general.permissions'|translate|firstUpper}}</h1>-->
    <div *ngFor="let permissionModule of permissions|keyvalue" class="mb-6">
        <h4><app-permission-tooltip [permissionTranslationCode]="'permission.module.' + permissionModule.key"></app-permission-tooltip></h4>
        <p-divider></p-divider>
        <div class="grid">
            <ng-container *ngFor="let action of permissionModule.value.actions; let i=index">
                <div class="col-{{i === 0 ? 12 : 6}}">
                    <p-checkbox
                        class="{{!!readOnlyPermissions[permissionModule.key + '.' + action] || disabled ? 'readonly' : ''}}"
                        (onChange)="permissionChanged(permissionModule.key, action)"
                        [readonly]="!!readOnlyPermissions[permissionModule.key + '.' + action] || disabled"
                        [formControl]="form.controls['permissions']"
                        [label]="'permission.action.' + action | translate|firstUpper"
                        [value]="[permissionModule.key, action]">
                    </p-checkbox>
                    <app-permission-tooltip [showDescription]="false" [permissionTranslationCode]="'permission.module.' + permissionModule.key + '.action.' + action"></app-permission-tooltip>
                    <ng-container *ngIf="!!readOnlyPermissions[permissionModule.key + '.' + action]">
                        &nbsp;
                        <span
                            style="margin-top: 3px"
                            *ngFor="let groupName of readOnlyPermissions[permissionModule.key + '.' + action]"
                            class="custom-badge-alt custom-badge-gray mr-2">
                            {{groupName}}
                        </span>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <ng-container *ngFor="let actionGroup of permissionModule.value.actionGroups|keyvalue">
            <div class="ml-4 mt-4">
                <h5><app-permission-tooltip [permissionTranslationCode]="'permission.actionGroup.' + actionGroup.key"></app-permission-tooltip></h5>
                <p-divider></p-divider>
                <div class="grid">
                    <ng-container *ngFor="let action of actionGroup.value.actions; let i=index">
                        <div class="col-{{i === 0 ? 12 : 6}}">
                            <p-checkbox
                                class="{{!!readOnlyPermissions[permissionModule.key + '.' + actionGroup.key + '-' + action] || disabled ? 'readonly' : ''}}"
                                (onChange)="permissionChanged(permissionModule.key, actionGroup.key + '-' + action)"
                                [readonly]="!!readOnlyPermissions[permissionModule.key + '.' + actionGroup.key + '-' + action] || disabled"
                                [formControl]="form.controls['permissions']"
                                [label]="'permission.action.' + actionGroup.key + '-' + action | translate|firstUpper"
                                [value]="[permissionModule.key, actionGroup.key + '-' + action]"></p-checkbox>
                            <app-permission-tooltip [showDescription]="false" [permissionTranslationCode]="'permission.action.' + actionGroup.key + '-' + action"></app-permission-tooltip>
                            <ng-container *ngIf="!!readOnlyPermissions[permissionModule.key + '.' + actionGroup.key + '-' + action]">
                                &nbsp;
                                <span
                                    style="margin-top: 3px"
                                    *ngFor="let groupName of readOnlyPermissions[permissionModule.key + '.' + actionGroup.key + '-' + action]"
                                    class="custom-badge-alt custom-badge-gray mr-2">
                            {{groupName}}
                        </span>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <!--    <div *ngFor="let p of allPermissions" >-->
    <!--        <p-checkbox [formControl]="form.controls['permissions']" [label]="p[1] + '.' + p[2]" [value]="[p[1],p[2]]"></p-checkbox>-->
    <!--    </div>-->
</form>
