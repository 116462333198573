import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {readFileUrl} from '../../../helpers';
import {
    addFileToBufferAction,
    addFileToBufferSuccessAction,
    removeFileFromBufferAction
} from '../../../../store/general/actions';
import {SafeUrl} from '@angular/platform-browser';
import {DefaultFailMessage} from '../../../../store/user/actions';
import {getFullPath} from '../../../forms/helpers';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent extends ElementBaseComponent<SettingsControl> implements OnInit {



    image: { loading: boolean, url: string | SafeUrl | null, fullUrl?: string } = {loading: false, url: null};
    file: File|null;
    lastBufferedFilename: string|null = null;

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            src: new FormControl('', {validators: Validators.required, nonNullable: true}),
            keepImageQuality: new FormControl(false, {nonNullable: true})
        });
    }


    async ngOnInit() {
        super.ngOnInit();
        this.image = {
            url: this.stepElementForm.getRawValue().settings.src ? await readFileUrl(this.stepElementForm.getRawValue().settings.src, this.appActions) : null,
            fullUrl: this.stepElementForm.getRawValue().settings.src ? getFullPath(this.stepElementForm.getRawValue().settings.src) : undefined,
            loading: false
        };
    }

    onFileUpload: (file: File | null) => Promise<true | DefaultFailMessage> = (file: File | null) => {
        return new Promise(async (resolve) => {
            let filename = null;
            if (file) {
                filename = (await lastValueFrom(this.appActions.dispatch(addFileToBufferAction({file}), [addFileToBufferSuccessAction]))).filename;
                this.stepElementForm.controls.settings.controls.src.setValue(filename);
                this.stepElementForm.controls.settings.controls.src.markAsTouched();
            } else {
                this.stepElementForm.controls.settings.controls.src.setValue('');
                this.stepElementForm.controls.settings.controls.src.markAsTouched();
            }
            if(this.lastBufferedFilename) {
                await lastValueFrom(this.appActions.dispatch(removeFileFromBufferAction({filename: this.lastBufferedFilename})));
            }
            this.file = file;
            this.lastBufferedFilename = filename;
            this.image.url = filename ? await readFileUrl(filename, this.appActions) : null;
            this.image.fullUrl = undefined;
            resolve(true);
        });
    };
}

type SettingsControl = {
    src: FormControl<string>,
    keepImageQuality: FormControl<boolean>
}
