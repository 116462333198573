export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** This scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: any;
  /**
   * The `String` scalar type represents textual data, represented as UTF-8
   * character sequences. The String type is most often used by GraphQL to
   * represent free-form human-readable text.
   */
  DateTimeBetween: any;
  /** This scalar type represents e-mail formatted string. */
  Email: string;
  /**
   * The `Int` scalar type represents non-fractional signed whole numeric
   * values. Int can represent values between -(2^64) and 2^64 - 1.
   */
  Integer: number;
  /**
   * The `JSON` scalar type represents JSON values as specified by
   *         [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).
   */
  Json: any;
  /** %NULL% OR %NOT_NULL% OR DateTimeBetween */
  NullableDateTimeBetween: any;
}

export interface AddGroupPhotosInput {
  id: Scalars['Int'];
  photos: Array<FileInputHelper>;
}

export interface AddInventoryItemInput {
  photos: Array<FileInputHelper>;
}

export enum AgreementsEnum {
  Gdpr = 'gdpr',
  Newsletter = 'newsletter',
  TermsAndConditions = 'termsAndConditions'
}

export enum AndOrTypeEnum {
  And = 'and',
  Or = 'or'
}

export interface ApiToken {
  __typename?: 'ApiToken';
  /** Default Value is "now" */
  dateCreated: Scalars['DateTime'];
  id: Scalars['Int'];
  token: Scalars['String'];
  type: Scalars['String'];
}

export interface ApiTokenInput {
  removeApiToken?: InputMaybe<Scalars['Int']>;
  updateApiToken?: InputMaybe<UpdateApiTokenInput>;
}

export interface AppNotificationInputType {
  /** sends an email with QR codes to current active user with provided ID */
  sendGameQrCodes?: InputMaybe<Scalars['Int']>;
}

export interface AppNotificationResult {
  __typename?: 'AppNotificationResult';
  sendGameQrCodesSuccess: Maybe<Scalars['Boolean']>;
}

export interface AppSettings {
  __typename?: 'AppSettings';
  generalButtonLink: Scalars['String'];
}

export interface AppSettingsInput {
  generalButtonLink: Scalars['String'];
}

export interface ApplyCodeInput {
  /** QR code from player who created new game or VoucherCode or License activation key */
  code: Scalars['String'];
  extraData?: InputMaybe<ExtraDataInputHelper>;
}

export interface ApplyCodeResult {
  __typename?: 'ApplyCodeResult';
  gameJoined: Maybe<PlayerProgress>;
  licenseActivated: Maybe<LicenseActivatedData>;
  voucherCodeActivated: Maybe<EnterVoucherCodeResult>;
}

export interface Attachment {
  __typename?: 'Attachment';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  title: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface Attribute {
  __typename?: 'Attribute';
  /** Default Value is true */
  active: Scalars['Boolean'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  gameGroups: Array<GameGroup>;
  games: Array<Game>;
  id: Scalars['Int'];
  /** Default Value is "0" */
  level: Scalars['Int'];
  name: Scalars['String'];
  ordering: Scalars['Int'];
  parentId: Maybe<Scalars['Int']>;
  photoSource: Maybe<MediaFile>;
  slug: Maybe<Scalars['String']>;
  type: AttributeTypeEnum;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface AttributeFilter {
  /** Default Value is true */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  /** Default Value is "0" */
  level?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  /** Default Value is "category" */
  type?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface AttributeInput {
  changeOrder?: InputMaybe<ChangeOrder>;
  removeAttribute?: InputMaybe<Scalars['Int']>;
  updateAttribute?: InputMaybe<UpdateAttributeInput>;
}

export interface AttributeOption {
  __typename?: 'AttributeOption';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  level: Scalars['Int'];
  name: Scalars['String'];
  ordering: Scalars['Int'];
  parentId: Maybe<Scalars['Int']>;
  type: AttributeTypeEnum;
}

/** required if created */
export interface AttributeScalarInput {
  /** Default Value is true */
  active: Scalars['Boolean'];
  /** Default Value is "0" */
  level: Scalars['Int'];
  name: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  type: AttributeTypeEnum;
}

export enum AttributeTypeEnum {
  Category = 'category',
  EducationLevel = 'educationLevel',
  EducationSubject = 'educationSubject',
  GameType = 'gameType',
  TargetGroup = 'targetGroup'
}

export interface AttributesResult {
  __typename?: 'AttributesResult';
  meta: Maybe<PageInfo>;
  result: Array<Attribute>;
}

export interface AvailablePartner {
  __typename?: 'AvailablePartner';
  activeFrom: Maybe<Scalars['DateTime']>;
  activeTo: Maybe<Scalars['DateTime']>;
  /** Default Value is "normal" */
  commissionType: Scalars['String'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  /** Default Value is false */
  deleted: Scalars['Boolean'];
  discount: Scalars['Float'];
  discountDirectSale: Maybe<Scalars['Float']>;
  discountDirectSaleType: Scalars['String'];
  discountType: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface BenefitPlusSettings {
  __typename?: 'BenefitPlusSettings';
  active: Scalars['Boolean'];
  eshopId: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  testMode: Scalars['Boolean'];
}

export interface BenefitPlusSettingsInput {
  active: Scalars['Boolean'];
  eshopId: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  testMode: Scalars['Boolean'];
}

export interface BenefitySettings {
  __typename?: 'BenefitySettings';
  active: Scalars['Boolean'];
  benefitGroup: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  merchantNumber: Scalars['String'];
  username: Scalars['String'];
}

export interface BenefitySettingsInput {
  active: Scalars['Boolean'];
  benefitGroup: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  merchantNumber: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
}

export interface BillProductPartnerInput {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  productPartnerId: Scalars['Int'];
}

export interface BillProductPartnerResult {
  __typename?: 'BillProductPartnerResult';
  downloadLink: Maybe<Scalars['String']>;
  invalidVouchers: Maybe<Array<GameVoucher>>;
}

export interface BoughtGames {
  __typename?: 'BoughtGames';
  /** Default Value is true */
  active: Scalars['Boolean'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  fromSlevomat: Scalars['Boolean'];
  game: Maybe<Game>;
  id: Scalars['Int'];
  player: Maybe<Player>;
  playerProgress: Maybe<PlayerProgress>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  voucherCode: Maybe<Scalars['String']>;
}

export interface CalculateCommissionDataInput {
  gameId: Scalars['Int'];
  partnerId?: InputMaybe<Scalars['Int']>;
  productPartnerId: Scalars['Int'];
  saleSourceId?: InputMaybe<Scalars['Int']>;
  voucherId?: InputMaybe<Scalars['Int']>;
}

export interface ChangeOrder {
  orders: Array<ChangeOrderEntity>;
}

export interface ChangeOrderEntity {
  entityId: Scalars['Int'];
  order: Scalars['Int'];
}

export interface ChangeOrderResult {
  __typename?: 'ChangeOrderResult';
  entityId: Scalars['Int'];
  sortId: Scalars['Int'];
}

export interface CitiesResult {
  __typename?: 'CitiesResult';
  appSettings: AppSettings;
  cities: Maybe<Array<Maybe<City>>>;
  isActual: Maybe<Scalars['Boolean']>;
  license: Maybe<License>;
  /** Current user SynchKey */
  synchKey: Maybe<Scalars['String']>;
}

export interface City {
  __typename?: 'City';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  gameGroups: Array<GameGroup>;
  /** Default Value is false */
  hidden: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  nameCz: Maybe<Scalars['String']>;
  nameEn: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  photoSource: Maybe<Scalars['String']>;
  translation: CityTranslation;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface CityFilter {
  id?: InputMaybe<Scalars['String']>;
}

export interface CityInput {
  changeGroupOrder?: InputMaybe<ChangeOrder>;
  changeOrder?: InputMaybe<ChangeOrder>;
  removeCity?: InputMaybe<RemoveCityInput>;
  updateCity?: InputMaybe<UpdateCityInput>;
}

/** required if created */
export interface CityScalarInput {
  /** Default Value is false */
  hidden: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
}

export interface CityTranslation {
  __typename?: 'CityTranslation';
  name: Array<EntityTranslation>;
}

export interface CityTranslationInput {
  name?: InputMaybe<Array<EntityTranslationInput>>;
}

export interface CloneGameStepInput {
  gameId: Scalars['Int'];
  parentId?: InputMaybe<Scalars['Int']>;
  stepId: Scalars['Int'];
}

export interface CodSettings {
  __typename?: 'CodSettings';
  active: Scalars['Boolean'];
  price: Scalars['Float'];
}

export interface CodSettingsInput {
  active: Scalars['Boolean'];
  price: Scalars['Float'];
}

export interface DownloadGameResult {
  __typename?: 'DownloadGameResult';
  generatedGameSnapshot: Scalars['Json'];
}

export interface DownloadPlayerProgressInputType {
  id: Scalars['Int'];
  renderOnly: Scalars['Boolean'];
}

export interface DownloadQrCodesInput {
  colored: Scalars['Boolean'];
  useCache: Scalars['Boolean'];
}

export interface EmailSettings {
  __typename?: 'EmailSettings';
  active: Scalars['Boolean'];
  host: Scalars['String'];
  port: Scalars['String'];
  secure: Scalars['String'];
  username: Scalars['String'];
}

export interface EmailSettingsInput {
  active: Scalars['Boolean'];
  host: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  port: Scalars['String'];
  secure: Scalars['String'];
  username: Scalars['String'];
}

export interface EnterVoucherCodeResult {
  __typename?: 'EnterVoucherCodeResult';
  /** Present if type VOUCHER or SLEVOMAT_VOUCHER */
  boughtGame: Maybe<BoughtGames>;
  /** Present if type VOUCHER, USED or SLEVOMAT_VOUCHER */
  game: Maybe<Game>;
  /** Present if type PARTNER */
  partner: Maybe<Partner>;
  type: Maybe<VoucherTypeResultEnum>;
}

export interface EntityAttachment {
  __typename?: 'EntityAttachment';
  attachment: Attachment;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  game: Maybe<Game>;
  id: Scalars['Int'];
  sendType: EntityAttachmentSendTypeEnum;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export enum EntityAttachmentSendTypeEnum {
  GamePurchase = 'gamePurchase'
}

/** Name of file you upload via multipart */
export interface EntityTranslation {
  __typename?: 'EntityTranslation';
  langCode: Scalars['String'];
  value: Scalars['String'];
}

export interface EntityTranslationInput {
  langCode: Scalars['String'];
  value: Scalars['String'];
}

export interface ExternalVoucher {
  __typename?: 'ExternalVoucher';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  game: Maybe<Game>;
  id: Scalars['Int'];
  type: ExternalVoucherTypeEnum;
  voucherId: Scalars['Int'];
}

/** required if created */
export interface ExternalVoucherScalarInput {
  type: ExternalVoucherTypeEnum;
  voucherId: Scalars['Int'];
}

export enum ExternalVoucherTypeEnum {
  Slevomat = 'slevomat'
}

export interface ExtraDataInputHelper {
  appVersion: Scalars['String'];
  deviceData: Scalars['String'];
  osType: Scalars['String'];
}

/** Name of file you upload via multipart */
export interface FileInputHelper {
  name: Scalars['String'];
}

export interface Filter {
  getAllRows?: InputMaybe<Scalars['Boolean']>;
  /** Default is AND */
  likePropertiesMode?: InputMaybe<AndOrTypeEnum>;
  limit?: InputMaybe<Scalars['Integer']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  range?: InputMaybe<Array<InputMaybe<Range>>>;
}

export interface FrontendPageData {
  __typename?: 'FrontendPageData';
  pageGameGroups: Array<PageGameGroup>;
  pageLangs: Array<PageLang>;
}

export interface Game {
  __typename?: 'Game';
  activeDownloadFrom: Maybe<Scalars['DateTime']>;
  activeFrom: Maybe<Scalars['DateTime']>;
  appPurchaseId: Maybe<Scalars['String']>;
  attributes: Array<Attribute>;
  authorId: Maybe<Scalars['Int']>;
  averageRating: Maybe<Scalars['Float']>;
  averageRatingThreeMonths: Maybe<Scalars['Float']>;
  averageRatingYear: Maybe<Scalars['Float']>;
  canPurchaseTo: Maybe<Scalars['DateTime']>;
  congratsContent: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  difficulty: Maybe<Scalars['Int']>;
  difficultyNote: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  entityAttachments: Array<EntityAttachment>;
  externalVouchers: Array<ExternalVoucher>;
  extraSettings: Maybe<Scalars['String']>;
  gameGroup: GameGroup;
  gameSaleSources: Array<GameSaleSource>;
  gameSteps: Array<GameStep>;
  gpsEntry: Maybe<GpsEntry>;
  hasAlternativeSteps: Scalars['Boolean'];
  hash: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inventoryItems: Array<InventoryItem>;
  /** Default Value is false */
  isForFree: Scalars['Boolean'];
  labelActualize: Maybe<Scalars['String']>;
  labelBought: Maybe<Scalars['String']>;
  labelBuy: Maybe<Scalars['String']>;
  labelCancel: Maybe<Scalars['String']>;
  labelClosehelper: Maybe<Scalars['String']>;
  labelComplete: Maybe<Scalars['String']>;
  labelContinue: Maybe<Scalars['String']>;
  labelDifficulty: Maybe<Scalars['String']>;
  labelDownload: Maybe<Scalars['String']>;
  labelHelper: Maybe<Scalars['String']>;
  labelHelperquestion: Maybe<Scalars['String']>;
  labelNext: Maybe<Scalars['String']>;
  labelNoclues: Maybe<Scalars['String']>;
  labelNotes: Maybe<Scalars['String']>;
  labelOk: Maybe<Scalars['String']>;
  labelPlay: Maybe<Scalars['String']>;
  labelPrevious: Maybe<Scalars['String']>;
  labelRightansweris: Maybe<Scalars['String']>;
  labelShareonfacebook: Maybe<Scalars['String']>;
  labelSize: Maybe<Scalars['String']>;
  labelSkip: Maybe<Scalars['String']>;
  labelSkipphoto: Maybe<Scalars['String']>;
  labelSkipquestion: Maybe<Scalars['String']>;
  labelStartgame: Maybe<Scalars['String']>;
  labelStartgamequestion: Maybe<Scalars['String']>;
  labelSubmit: Maybe<Scalars['String']>;
  labelTakephoto: Maybe<Scalars['String']>;
  labelTelephonesupport: Maybe<Scalars['String']>;
  labelTelephonesupportquestion: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  maxPoints: Maybe<Scalars['Integer']>;
  maxTime: Maybe<Scalars['Int']>;
  minTime: Maybe<Scalars['Int']>;
  /** Default Value is true */
  mixStats: Scalars['Boolean'];
  /** Default Value is false */
  multiplayerGame: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  /** Default Value is "" */
  note: Scalars['String'];
  order: Maybe<Scalars['Int']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoSource: Maybe<Scalars['String']>;
  playerGames: Maybe<Array<PlayerGame>>;
  players: Maybe<Scalars['Json']>;
  predefinedNotes: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  productPartner: Maybe<ProductPartner>;
  /**
   * Default Value is true
   * @deprecated use State instead
   */
  public: Scalars['Boolean'];
  purchaseNote: Maybe<Scalars['String']>;
  randomCode: Maybe<Scalars['Json']>;
  restrictions: Maybe<Scalars['String']>;
  resultType: Maybe<Scalars['String']>;
  /** Default Value is false */
  sendQrCodes: Scalars['Boolean'];
  size: Maybe<Scalars['Float']>;
  startingPosition: Maybe<Scalars['String']>;
  state: GameStateEnum;
  stats: Maybe<Array<Maybe<Scalars['Json']>>>;
  synchKey: Maybe<Scalars['String']>;
  teamInfo: Maybe<Scalars['String']>;
  teamNamePopupActive: Maybe<Scalars['Boolean']>;
  teamNamePopupText: Maybe<Scalars['String']>;
  textEmail: Maybe<Scalars['String']>;
  textRating: Maybe<Scalars['String']>;
  type: GameTypeEnum;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  variantAlgorithm: Maybe<VariantAlgorithmEnum>;
}

export enum GameErrorTypeEnum {
  PlayerKicked = 'PLAYER_KICKED'
}

export interface GameFilter {
  activeDownloadFrom?: InputMaybe<Scalars['DateTime']>;
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  appPurchaseId?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['Int']>;
  canPurchaseTo?: InputMaybe<Scalars['DateTime']>;
  congratsContent?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  difficulty?: InputMaybe<Scalars['Int']>;
  difficultyNote?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  extraSettings?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  gameGroup?: InputMaybe<GameGroupFilter>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  isForFree?: InputMaybe<Scalars['Boolean']>;
  labelActualize?: InputMaybe<Scalars['String']>;
  labelBought?: InputMaybe<Scalars['String']>;
  labelBuy?: InputMaybe<Scalars['String']>;
  labelCancel?: InputMaybe<Scalars['String']>;
  labelClosehelper?: InputMaybe<Scalars['String']>;
  labelComplete?: InputMaybe<Scalars['String']>;
  labelContinue?: InputMaybe<Scalars['String']>;
  labelDifficulty?: InputMaybe<Scalars['String']>;
  labelDownload?: InputMaybe<Scalars['String']>;
  labelHelper?: InputMaybe<Scalars['String']>;
  labelHelperquestion?: InputMaybe<Scalars['String']>;
  labelNext?: InputMaybe<Scalars['String']>;
  labelNoclues?: InputMaybe<Scalars['String']>;
  labelNotes?: InputMaybe<Scalars['String']>;
  labelOk?: InputMaybe<Scalars['String']>;
  labelPlay?: InputMaybe<Scalars['String']>;
  labelPrevious?: InputMaybe<Scalars['String']>;
  labelRightansweris?: InputMaybe<Scalars['String']>;
  labelShareonfacebook?: InputMaybe<Scalars['String']>;
  labelSize?: InputMaybe<Scalars['String']>;
  labelSkip?: InputMaybe<Scalars['String']>;
  labelSkipphoto?: InputMaybe<Scalars['String']>;
  labelSkipquestion?: InputMaybe<Scalars['String']>;
  labelStartgame?: InputMaybe<Scalars['String']>;
  labelStartgamequestion?: InputMaybe<Scalars['String']>;
  labelSubmit?: InputMaybe<Scalars['String']>;
  labelTakephoto?: InputMaybe<Scalars['String']>;
  labelTelephonesupport?: InputMaybe<Scalars['String']>;
  labelTelephonesupportquestion?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  maxTime?: InputMaybe<Scalars['Int']>;
  minTime?: InputMaybe<Scalars['Int']>;
  /** Default Value is true */
  mixStats?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is false */
  multiplayerGame?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Default Value is "" */
  note?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoSource?: InputMaybe<Scalars['String']>;
  playerGames?: InputMaybe<PlayerToGameFilter>;
  players?: InputMaybe<Scalars['String']>;
  predefinedNotes?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  /** Default Value is true */
  public?: InputMaybe<Scalars['Boolean']>;
  purchaseNote?: InputMaybe<Scalars['String']>;
  randomCode?: InputMaybe<Scalars['String']>;
  restrictions?: InputMaybe<Scalars['String']>;
  resultType?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  sendQrCodes?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Float']>;
  startingPosition?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  synchKey?: InputMaybe<Scalars['String']>;
  teamInfo?: InputMaybe<Scalars['String']>;
  teamNamePopupActive?: InputMaybe<Scalars['Boolean']>;
  teamNamePopupText?: InputMaybe<Scalars['String']>;
  textEmail?: InputMaybe<Scalars['String']>;
  textRating?: InputMaybe<Scalars['String']>;
  /** Default Value is "full" */
  type?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variantAlgorithm?: InputMaybe<Scalars['String']>;
}

export interface GameGroup {
  __typename?: 'GameGroup';
  attributes: Array<Attribute>;
  city: Maybe<City>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  descriptionWeb: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['String']>;
  gamePhotos: Maybe<Array<Maybe<GamePhoto>>>;
  games: Array<Game>;
  hidden: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  innerMostMapId: Maybe<Scalars['Int']>;
  isForTeambuilding: Maybe<Scalars['Boolean']>;
  lang: Maybe<Lang>;
  language: Maybe<Scalars['String']>;
  license: Maybe<License>;
  name: Scalars['String'];
  notificationMails: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
  pages: Array<PageGameGroup>;
  photoSource: Maybe<Scalars['String']>;
  photoSourceAlt: Maybe<Scalars['String']>;
  rating: Maybe<Scalars['Float']>;
  restrictions: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  startingPosition: Maybe<Scalars['String']>;
  state: GameGroupStateEnum;
  updatedAt: Maybe<Scalars['DateTime']>;
  videoSource: Maybe<Scalars['String']>;
  voucherImage: Maybe<VoucherImage>;
}


export interface GameGroupGamesArgs {
  filter: InputMaybe<GameFilter>;
}

export interface GameGroupFilter {
  city?: InputMaybe<CityFilter>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionWeb?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  games?: InputMaybe<GameFilter>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isForTeambuilding?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguagesFilter>;
  language?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<LicenseFilter>;
  maps?: InputMaybe<MapFilter>;
  name?: InputMaybe<Scalars['String']>;
  notificationMails?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photoSource?: InputMaybe<Scalars['String']>;
  photoSourceAlt?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  restrictions?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startingPosition?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoSource?: InputMaybe<Scalars['String']>;
}

export interface GameGroupInput {
  addGroupPhoto?: InputMaybe<AddGroupPhotosInput>;
  changeGameOrder?: InputMaybe<ChangeOrder>;
  changeOrder?: InputMaybe<ChangeOrder>;
  removeGroup?: InputMaybe<Scalars['Int']>;
  removeGroupPhoto?: InputMaybe<RemoveGroupPhotoInput>;
  /** group ID */
  removeGroupVoucherImagePhoto?: InputMaybe<Scalars['Int']>;
  updateGameGroup?: InputMaybe<UpdateGameGroupInput>;
  updateGroupPhotos?: InputMaybe<UpdateGroupPhotosInput>;
  updateGroupVoucherImagePhoto?: InputMaybe<UpdateGroupVoucherImageInput>;
}

export interface GameGroupOption {
  __typename?: 'GameGroupOption';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export enum GameGroupStateEnum {
  Hidden = 'hidden',
  InProgress = 'in_progress',
  Public = 'public',
  PublicApp = 'public_app',
  PublicWeb = 'public_web'
}

export interface GameGroupsResult {
  __typename?: 'GameGroupsResult';
  availableGroupOptionsMeta: Array<GameGroup>;
  meta: Maybe<PageInfo>;
  result: Array<GameGroup>;
}

export interface GameInput {
  addInventoryItem?: InputMaybe<AddInventoryItemInput>;
  addPlayersToGame?: InputMaybe<Array<Scalars['Int']>>;
  checkAppId?: InputMaybe<Scalars['String']>;
  downloadGame?: InputMaybe<Scalars['Int']>;
  downloadGamePdf?: InputMaybe<Scalars['Int']>;
  downloadQrCodes?: InputMaybe<DownloadQrCodesInput>;
  /** set to 0 when creating new one */
  gameId: Scalars['Int'];
  importGame?: InputMaybe<ImportGameInput>;
  removeExternalVoucher?: InputMaybe<Scalars['Int']>;
  removeGame?: InputMaybe<Scalars['Int']>;
  removeInventoryItem?: InputMaybe<RemoveInventoryItemInput>;
  removePlayersFromGame?: InputMaybe<Array<Scalars['Int']>>;
  updateExternalVoucher?: InputMaybe<UpdateExternalVoucherInput>;
  updateGame?: InputMaybe<UpdateGameInput>;
  updateGamePhotos?: InputMaybe<UpdateGamePhotos>;
}

export interface GamePhoto {
  __typename?: 'GamePhoto';
  filename: Maybe<Scalars['String']>;
  id: Scalars['Int'];
}

export enum GameResultType {
  Points = 'points',
  Time = 'time'
}

export interface GameSaleSource {
  __typename?: 'GameSaleSource';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  game: Game;
  id: Scalars['Int'];
  price: Scalars['Float'];
  saleSource: SaleSource;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface GameSaleSourceInput {
  id?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  saleSourceId: Scalars['Int'];
}

export enum GameStateEnum {
  Hidden = 'hidden',
  Public = 'public',
  PublicApp = 'public_app',
  PublicWeb = 'public_web'
}

export interface GameStep {
  __typename?: 'GameStep';
  afterStepLoadedElements: Array<StepElement>;
  answer: Maybe<Scalars['String']>;
  answerLabel: Maybe<Scalars['String']>;
  beforeStepLeftElements: Array<StepElement>;
  contentElements: Array<StepElement>;
  contentType: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  footerElements: Array<StepElement>;
  hasTimeRestrictions: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  inactiveFrom: Maybe<Scalars['DateTime']>;
  inactiveTo: Maybe<Scalars['DateTime']>;
  info: Maybe<Scalars['String']>;
  infoTitle: Maybe<Scalars['String']>;
  internalNote: Maybe<Scalars['String']>;
  isEnding: Maybe<Scalars['Boolean']>;
  isParentVariant: Scalars['Boolean'];
  isPhoto: Maybe<Scalars['Boolean']>;
  isRating: Maybe<Scalars['Boolean']>;
  isSharing: Maybe<Scalars['Boolean']>;
  isStarting: Maybe<Scalars['Boolean']>;
  isVariant: Scalars['Boolean'];
  mapPhotoSource: Maybe<Scalars['String']>;
  mapText: Maybe<Scalars['String']>;
  maxTime: Maybe<Scalars['Int']>;
  minTime: Maybe<Scalars['Int']>;
  nophoto: Maybe<Scalars['Int']>;
  parent: Maybe<GameStep>;
  photoSource: Maybe<Scalars['String']>;
  puzzlePhotoFromCamera: Maybe<Scalars['Boolean']>;
  puzzleRatio: Maybe<Scalars['String']>;
  question: Maybe<Scalars['String']>;
  /** Default Value is false */
  selectedForTest: Scalars['Boolean'];
  showSummary: Maybe<Scalars['Boolean']>;
  skipInfo: Maybe<Scalars['String']>;
  skipPhotoSource: Maybe<Scalars['String']>;
  sortId: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
  summaryInstructions: Maybe<Scalars['String']>;
  textInventoryItems: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  videoSource: Maybe<Scalars['String']>;
}

export interface GameStepClue {
  __typename?: 'GameStepClue';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  filename: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  penalizationPoints: Scalars['Int'];
  penalizationTime: Scalars['Int'];
  title: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface GameStepClueInput {
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<FileInputHelper>;
  id?: InputMaybe<Scalars['Int']>;
  penalizationPoints: Scalars['Int'];
  penalizationTime: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
}

export interface GameStepInput {
  changeOrder?: InputMaybe<ChangeOrder>;
  cloneGameStep?: InputMaybe<CloneGameStepInput>;
  gameId?: InputMaybe<Scalars['Int']>;
  /** Step Id to remove */
  removeGameStep?: InputMaybe<Scalars['Int']>;
  setTestSteps?: InputMaybe<SetTestStepsInput>;
  stepId?: InputMaybe<Scalars['Int']>;
  switchStepActiveState?: InputMaybe<SwitchStepActiveStateInput>;
  updateGameStep?: InputMaybe<UpdateGameStepInput>;
  updateGameSteps?: InputMaybe<Array<UpdateGameStepInput>>;
}

export enum GameStepStateEnum {
  Active = 'active',
  Inactive = 'inactive',
  InactiveRange = 'inactive_range'
}

export interface GameStepsChangeOrder {
  __typename?: 'GameStepsChangeOrder';
  sortId: Scalars['Int'];
  stepId: Scalars['Int'];
}

export enum GameTypeEnum {
  Full = 'full',
  Zeda = 'zeda'
}

export interface GameVoucher {
  __typename?: 'GameVoucher';
  billingData: Maybe<GameVoucherBillingData>;
  bought: Maybe<Scalars['Boolean']>;
  boughtGames: Array<BoughtGames>;
  canceledAt: Maybe<Scalars['DateTime']>;
  code: Maybe<Scalars['String']>;
  commissionProductPartner: Maybe<Scalars['Float']>;
  commissionSaleSource: Maybe<Scalars['Float']>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  game: Game;
  gatewayCharge: Maybe<Scalars['Float']>;
  gatewayChargePercent: Maybe<Scalars['Float']>;
  gender: GenderEnum;
  id: Scalars['Int'];
  maxUsedCount: Maybe<Scalars['Int']>;
  /** Default Value is false */
  paidToProductPartner: Scalars['Boolean'];
  partner: Maybe<Partner>;
  photoSource: Maybe<Scalars['String']>;
  price: Maybe<Scalars['String']>;
  productPartner: Maybe<ProductPartner>;
  purchase: Maybe<Purchase>;
  saleSource: Maybe<SaleSource>;
  /** Default Value is "" */
  saleSourceCode: Scalars['String'];
  target: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  used: Maybe<Player>;
  user: Maybe<Player>;
  validTo: Maybe<Scalars['DateTime']>;
  voucherPrice: Maybe<Scalars['Float']>;
}

export interface GameVoucherBillingData {
  __typename?: 'GameVoucherBillingData';
  commissionProductPartner: Maybe<Scalars['Float']>;
  commissionSaleSource: Maybe<Scalars['Float']>;
  gatewayCharge: Maybe<Scalars['Float']>;
  gatewayChargePercent: Maybe<Scalars['Float']>;
  /** Default Value is false */
  paidToProductPartner: Scalars['Boolean'];
  partnerCommissionType: Maybe<PartnerCommissionTypeEnum>;
  partnerDiscount: Maybe<Scalars['Float']>;
  partnerDiscountDirectSale: Maybe<Scalars['Float']>;
  partnerDiscountDirectSaleType: Maybe<PartnerDiscountTypeEnum>;
  partnerDiscountType: Maybe<PartnerDiscountTypeEnum>;
  voucherPrice: Maybe<Scalars['Float']>;
  voucherRealPrice: Maybe<Scalars['Float']>;
}

export interface GameVoucherFilter {
  bought?: InputMaybe<Scalars['Boolean']>;
  canceledAt?: InputMaybe<Scalars['NullableDateTimeBetween']>;
  code?: InputMaybe<Scalars['String']>;
  commissionProductPartner?: InputMaybe<Scalars['Float']>;
  commissionSaleSource?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTimeBetween']>;
  description?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  game?: InputMaybe<GameFilter>;
  gatewayCharge?: InputMaybe<Scalars['Float']>;
  gatewayChargePercent?: InputMaybe<Scalars['Float']>;
  /** Default Value is "unknown" */
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  maxUsedCount?: InputMaybe<Scalars['Int']>;
  /** Default Value is false */
  paidToProductPartner?: InputMaybe<Scalars['Boolean']>;
  photoSource?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productPartner?: InputMaybe<ProductPartnerFilter>;
  saleSource?: InputMaybe<SaleSourceFilter>;
  /** Default Value is "" */
  saleSourceCode?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTimeBetween']>;
  voucherPrice?: InputMaybe<Scalars['Float']>;
}

export interface GameVoucherInput {
  calculateCommissionData?: InputMaybe<CalculateCommissionDataInput>;
  cancelGameVoucher?: InputMaybe<Scalars['Int']>;
  /** False if you want B&W */
  downloadQrCodesColored?: InputMaybe<Scalars['Boolean']>;
  downloadVoucherQrCode?: InputMaybe<Scalars['Int']>;
  gameVoucherId?: InputMaybe<Scalars['Int']>;
  regenerateVoucher?: InputMaybe<Scalars['Int']>;
  removeGameVoucher?: InputMaybe<Scalars['Int']>;
  updateGameVoucher?: InputMaybe<UpdateGameVoucherInput>;
}

export interface GameVouchersResult {
  __typename?: 'GameVouchersResult';
  meta: Maybe<PageInfo>;
  result: Array<GameVoucher>;
}

export interface GatewaySettings {
  __typename?: 'GatewaySettings';
  benefitPlus: BenefitPlusSettings;
  benefity: BenefitySettings;
  cod: CodSettings;
  gopay: GopaySettings;
  sodexo: SodexoSettings;
  sphere: SphereSettings;
  up: UpSettings;
}

export interface GatewaySettingsInput {
  benefitPlusSettings?: InputMaybe<BenefitPlusSettingsInput>;
  benefitySettings?: InputMaybe<BenefitySettingsInput>;
  codSettings?: InputMaybe<CodSettingsInput>;
  gopaySettings?: InputMaybe<GopaySettingsInput>;
  sodexoSettings?: InputMaybe<SodexoSettingsInput>;
  sphereSettings?: InputMaybe<SphereSettingsInput>;
  upSettings?: InputMaybe<UpSettingsInput>;
}

export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}

export interface GeneralSettings {
  __typename?: 'GeneralSettings';
  androidVersion: Scalars['String'];
  androidVersionUrl: Scalars['String'];
  codPurchaseEmail: Scalars['String'];
  gaId: Scalars['String'];
  iOSVersion: Scalars['String'];
  iOSVersionUrl: Scalars['String'];
}

export interface GeneralSettingsInput {
  androidVersion: Scalars['String'];
  androidVersionUrl: Scalars['String'];
  codPurchaseEmail: Scalars['String'];
  gaId: Scalars['String'];
  iOSVersion: Scalars['String'];
  iOSVersionUrl: Scalars['String'];
}

export interface GopaySettings {
  __typename?: 'GopaySettings';
  active: Scalars['Boolean'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  goId: Scalars['String'];
  testMode: Scalars['Boolean'];
}

export interface GopaySettingsInput {
  active: Scalars['Boolean'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  goId: Scalars['String'];
  testMode: Scalars['Boolean'];
}

export interface GpsEntry {
  __typename?: 'GpsEntry';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  data: Maybe<Scalars['Json']>;
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  radius: Maybe<Scalars['Int']>;
}

/** required if created */
export interface GpsEntryScalarInput {
  data?: InputMaybe<Scalars['String']>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  radius?: InputMaybe<Scalars['Int']>;
}

export interface ImageTextBoxData {
  __typename?: 'ImageTextBoxData';
  active: Maybe<Scalars['Boolean']>;
  /** HexaDecimal Value */
  color: Scalars['String'];
  font: Maybe<Scalars['String']>;
  fontSize: Scalars['Int'];
  testText: Maybe<Scalars['String']>;
  x1: Scalars['Int'];
  x2: Scalars['Int'];
  y1: Scalars['Int'];
  y2: Scalars['Int'];
}

export interface ImageTextBoxDataInput {
  active?: InputMaybe<Scalars['Boolean']>;
  /** HexaDecimal Value */
  color: Scalars['String'];
  font?: InputMaybe<Scalars['String']>;
  fontSize: Scalars['Int'];
  testText?: InputMaybe<Scalars['String']>;
  x1: Scalars['Int'];
  x2: Scalars['Int'];
  y1: Scalars['Int'];
  y2: Scalars['Int'];
}

export interface InventoryItem {
  __typename?: 'InventoryItem';
  id: Scalars['Int'];
  source: Scalars['String'];
}

export interface Lang {
  __typename?: 'Lang';
  /** Default Value is false */
  active: Scalars['Boolean'];
  allTranslations: Maybe<Scalars['Json']>;
  code: Scalars['String'];
  flagPath: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Default Value is false */
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  urlCode: Scalars['String'];
}

export interface LangInput {
  updateLang?: InputMaybe<UpdateLangInput>;
}

export interface LangScalarInputType {
  /** Default Value is false */
  active: Scalars['Boolean'];
  code: Scalars['String'];
  flagPath?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  urlCode: Scalars['String'];
}

export interface LangSelection {
  __typename?: 'LangSelection';
  code: Scalars['String'];
  name: Scalars['String'];
}

export interface LangsMetadata {
  __typename?: 'LangsMetadata';
  flags: Array<Scalars['String']>;
  langSelections: Array<Maybe<LangSelection>>;
}

export interface LanguagesFilter {
  /** Default Value is false */
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  flagPath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  urlCode?: InputMaybe<Scalars['String']>;
}

export interface LanguagesResult {
  __typename?: 'LanguagesResult';
  langsMetadata: LangsMetadata;
  meta: Maybe<PageInfo>;
  result: Array<Lang>;
}

export interface License {
  __typename?: 'License';
  /** Default Value is "" */
  city: Scalars['String'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  gameGroups: Maybe<Array<Maybe<GameGroup>>>;
  id: Scalars['Int'];
  licenseContacts: Array<LicenseContact>;
  licenseKeys: Array<LicenseKey>;
  name: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  photoSource: Maybe<Scalars['String']>;
  type: LicenseTypeEnum;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface LicenseContact {
  __typename?: 'LicenseContact';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  email: Scalars['Email'];
  id: Scalars['Int'];
  license: License;
  name: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  phone: Scalars['String'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface LicenseContactScalarInputType {
  email: Scalars['String'];
  name: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  phone: Scalars['String'];
}

export interface LicenseFilter {
  /** Default Value is "" */
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeBetween']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Default Value is "" */
  note?: InputMaybe<Scalars['String']>;
  photoSource?: InputMaybe<Scalars['String']>;
  /** Default Value is "simplifiedEditor" */
  type?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface LicenseInput {
  updateLicense?: InputMaybe<UpdateLicenseInput>;
}

export interface LicenseKey {
  __typename?: 'LicenseKey';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  key: Scalars['String'];
  license: License;
  /** Default Value is "2" */
  maxActiveUsers: Scalars['Int'];
  name: Scalars['String'];
  permissionGroup: PermissionGroup;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  validFrom: Maybe<Scalars['DateTime']>;
  validTo: Maybe<Scalars['DateTime']>;
}

export interface LicenseKeyInput {
  activateLicenseKeyToPlayer?: InputMaybe<LicenseKeyToPlayerInputType>;
  downloadLicenseKeyVoucher?: InputMaybe<Scalars['Int']>;
  removeLicenseKey?: InputMaybe<Scalars['Int']>;
  removeLicenseKeyFromPlayer?: InputMaybe<LicenseKeyToPlayerInputType>;
  updateLicenseKey?: InputMaybe<UpdateLicenseKeyInput>;
}

export interface LicenseKeyScalarInputType {
  /** Default Value is "2" */
  maxActiveUsers: Scalars['Int'];
  name: Scalars['String'];
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
}

export interface LicenseKeyToPlayerInputType {
  licenseKeyId: Scalars['Int'];
  playerId: Scalars['Int'];
}

export interface LicenseOption {
  __typename?: 'LicenseOption';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface LicenseScalarInputType {
  /** Default Value is "" */
  city: Scalars['String'];
  name: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  photoSource?: InputMaybe<Scalars['String']>;
  type: LicenseTypeEnum;
}

export enum LicenseTypeEnum {
  FullEditor = 'fullEditor',
  Other = 'other',
  SimplifiedEditor = 'simplifiedEditor',
  UserAccess = 'userAccess'
}

export interface LicensesResult {
  __typename?: 'LicensesResult';
  meta: Maybe<PageInfo>;
  result: Array<License>;
}

export enum LocationEnum {
  AfterStepLoadedElements = 'after_step_loaded_elements',
  BeforeStepLeftElements = 'before_step_left_elements',
  ContentElements = 'content_elements',
  FooterElements = 'footer_elements'
}

export interface LoginResult {
  __typename?: 'LoginResult';
  accessToken: Scalars['String'];
  player: Player;
  refreshToken: Scalars['String'];
}

export interface ManagerCitiesFilter {
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Filter>;
  /** Default Value is false */
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameCz?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photoSource?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface ManagerCitiesResult {
  __typename?: 'ManagerCitiesResult';
  meta: Maybe<PageInfo>;
  result: Array<City>;
}

export interface ManagerPlayerProgressFilter {
  canceledAt?: InputMaybe<Scalars['NullableDateTimeBetween']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeBetween']>;
  filter?: InputMaybe<Filter>;
  game?: InputMaybe<GameFilter>;
  id?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  multiplayerGame?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  resultType?: InputMaybe<Scalars['String']>;
  startedAt?: InputMaybe<Scalars['NullableDateTimeBetween']>;
  time?: InputMaybe<Scalars['Int']>;
  timePassed?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['NullableDateTimeBetween']>;
  user?: InputMaybe<PlayerFilter>;
}

export interface ManagerPlayerProgressesResult {
  __typename?: 'ManagerPlayerProgressesResult';
  meta: Maybe<PageInfo>;
  result: Array<PlayerProgress>;
}

export interface ManagerTranslation {
  __typename?: 'ManagerTranslation';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  translationValues: Array<ManagerTranslationValue>;
  /** Default Value is "text" */
  type: Scalars['String'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}


export interface ManagerTranslationTranslationValuesArgs {
  filter: InputMaybe<ManagerTranslationValueFilter>;
}

export interface ManagerTranslationValue {
  __typename?: 'ManagerTranslationValue';
  /** Default Value is "now" */
  dateCreated: Scalars['DateTime'];
  dateUpdated: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lang: Lang;
  value: Scalars['String'];
}

export interface ManagerTranslationValueFilter {
  /** Default Value is "now" */
  dateCreated?: InputMaybe<Scalars['DateTime']>;
  dateUpdated?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguagesFilter>;
  value?: InputMaybe<Scalars['String']>;
}

export interface ManagerTranslationsFilter {
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['String']>;
  translationValues?: InputMaybe<ManagerTranslationValueFilter>;
  /** Default Value is "text" */
  type?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface ManagerTranslationsMetadata {
  __typename?: 'ManagerTranslationsMetadata';
  translationProjects: Array<TranslationProject>;
}

export interface ManagerTranslationsResult {
  __typename?: 'ManagerTranslationsResult';
  meta: PageInfo;
  result: Array<ManagerTranslation>;
  translationsMetadata: ManagerTranslationsMetadata;
}

export interface MapFilter {
  id?: InputMaybe<Scalars['String']>;
}

export interface MapOption {
  __typename?: 'MapOption';
  googleId: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  parent: Maybe<Scalars['Int']>;
  type: MapTypeEnum;
}

export enum MapTypeEnum {
  Continent = 'continent',
  Country = 'country',
  District = 'district',
  Region = 'region'
}

export interface MediaFile {
  __typename?: 'MediaFile';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  hash: Scalars['String'];
  id: Scalars['Int'];
  isImage: Scalars['Boolean'];
  name: Scalars['String'];
  size: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  /** Use it either for join game, apply voucher or activate licenseKey */
  applyCode: ApplyCodeResult;
  /**
   * New instance of playerProgress will be created, after this, players can join multiplayer game. In Order
   *          to start the game, player must have active record in boughtGames Entity or has to be editor or admin
   */
  createGame: Maybe<PlayerProgress>;
  /** DEPRECATED in favor of applyCode (Method accept discount or purchased game Voucher) */
  enterVoucherCode: EnterVoucherCodeResult;
  /** TAC and GDPR are automatically extended, newsletter has to be present */
  extendAgreements: Maybe<Scalars['DateTime']>;
  /** DEPRECATED in favor of applyCode (Method joins player to multiplayer game) */
  joinGame: Maybe<PlayerProgress>;
  /** Owner of game can kick player. */
  kickPlayer: Maybe<PlayerProgress>;
  login: LoginResult;
  /** Call when player joined the game and is ready to play. */
  playerReady: Maybe<PlayerProgress>;
  /** Method refresh access or access and refresh tokens. Old one is always removed. */
  refreshToken: Maybe<RefreshTokenResult>;
  /** Register new Player */
  register: Maybe<LoginResult>;
  setNickname: Maybe<PlayerProgressPlayer>;
  /** This mutation should not be used via APP */
  syncAppTranslations: Maybe<Scalars['Json']>;
  updateAttribute: UpdateAttributeResult;
  updateCity: UpdateCityResult;
  updateGame: UpdateGameResult;
  updateGameGroup: UpdateGameGroupResult;
  /** updateGameStep */
  updateGameStep: UpdateGameStepResult;
  updateGameVoucher: UpdateGameVoucherResult;
  updateLang: UpdateLangResult;
  updateLicense: UpdateLicenseResult;
  updateManagerPlayerProgress: UpdatePlayerProgressResult;
  /** Method throws error, if another player already updated game */
  updateMultiplayerProgress: Maybe<PlayerProgress>;
  updatePageData: PageDataResult;
  updatePartner: UpdatePartnerResult;
  updatePermissionGroup: UpdatePermissionGroupResult;
  updatePlayer: UpdatePlayerResult;
  updatePlayerProgressPlayer: Maybe<PlayerProgressPlayer>;
  updateProductPartner: UpdateProductPartnerResult;
  /** Method update singlePlayer game progress */
  updateProgress: Maybe<Scalars['Boolean']>;
  updatePurchase: UpdatePurchaseResult;
  updatePushNotification: UpdatePushNotificationResult;
  updateReview: UpdateReviewResult;
  updateSaleSource: UpdateSaleSourceResult;
  updateSettings: Settings;
  updateTranslation: UpdateTranslationResult;
  uploadFiles: Scalars['Boolean'];
}


export interface MutationApplyCodeArgs {
  input: ApplyCodeInput;
}


export interface MutationCreateGameArgs {
  extraData: InputMaybe<ExtraDataInputHelper>;
  gameId: Scalars['Integer'];
}


export interface MutationEnterVoucherCodeArgs {
  code: Scalars['String'];
}


export interface MutationExtendAgreementsArgs {
  agreements: InputMaybe<Array<AgreementsEnum>>;
}


export interface MutationJoinGameArgs {
  extraData: InputMaybe<ExtraDataInputHelper>;
  inviteHash: Scalars['String'];
}


export interface MutationKickPlayerArgs {
  playerId: Scalars['Integer'];
  playerProgressId: Scalars['Integer'];
}


export interface MutationLoginArgs {
  loginCredentials: LoginCredentialsInput;
}


export interface MutationPlayerReadyArgs {
  playerProgressId: Scalars['Integer'];
  ready: Scalars['Boolean'];
}


export interface MutationRefreshTokenArgs {
  onlyAccessToken?: Scalars['Boolean'];
}


export interface MutationRegisterArgs {
  registerCredentials: RegisterCredentialsInput;
}


export interface MutationSetNicknameArgs {
  nickname: Scalars['String'];
  playerProgressId: Scalars['Integer'];
}


export interface MutationSyncAppTranslationsArgs {
  descriptions: Scalars['Json'];
  syncKey: Scalars['String'];
  translations: Scalars['Json'];
}


export interface MutationUpdateAttributeArgs {
  input: AttributeInput;
}


export interface MutationUpdateCityArgs {
  input: CityInput;
}


export interface MutationUpdateGameArgs {
  input: GameInput;
}


export interface MutationUpdateGameGroupArgs {
  input: GameGroupInput;
}


export interface MutationUpdateGameStepArgs {
  input: GameStepInput;
}


export interface MutationUpdateGameVoucherArgs {
  input: GameVoucherInput;
}


export interface MutationUpdateLangArgs {
  input: LangInput;
}


export interface MutationUpdateLicenseArgs {
  licenseInput: InputMaybe<LicenseInput>;
  licenseKeyInput: InputMaybe<LicenseKeyInput>;
}


export interface MutationUpdateManagerPlayerProgressArgs {
  input: InputMaybe<PlayerProgressInputType>;
}


export interface MutationUpdateMultiplayerProgressArgs {
  gameSnapshot?: InputMaybe<Scalars['Json']>;
  progress: InputMaybe<Scalars['Json']>;
  progressId: Scalars['Integer'];
  syncKey: Scalars['String'];
}


export interface MutationUpdatePageDataArgs {
  input: PageDataInput;
}


export interface MutationUpdatePartnerArgs {
  input: PartnerInput;
}


export interface MutationUpdatePermissionGroupArgs {
  input: PermissionGroupInput;
}


export interface MutationUpdatePlayerArgs {
  input: PlayerInput;
}


export interface MutationUpdatePlayerProgressPlayerArgs {
  playerProgressPlayer: PlayerProgressPlayerInput;
}


export interface MutationUpdateProductPartnerArgs {
  input: ProductPartnerInput;
}


export interface MutationUpdateProgressArgs {
  backwardSync: InputMaybe<Scalars['Boolean']>;
  progress: InputMaybe<Scalars['Json']>;
  progressId: Scalars['Integer'];
}


export interface MutationUpdatePurchaseArgs {
  input: PurchaseInput;
}


export interface MutationUpdatePushNotificationArgs {
  input: PushNotificationInput;
}


export interface MutationUpdateReviewArgs {
  input: ReviewInput;
}


export interface MutationUpdateSaleSourceArgs {
  input: SaleSourceInput;
}


export interface MutationUpdateSettingsArgs {
  settings: SettingsInput;
}


export interface MutationUpdateTranslationArgs {
  input: TranslationInput;
}


export interface MutationUploadFilesArgs {
  input: UploadFilesInput;
}

export interface OrderBy {
  property: Scalars['String'];
  value: OrderByEnum;
}

export enum OrderByEnum {
  /** Ascendant */
  Asc = 'ASC',
  /** Descendant */
  Desc = 'DESC'
}

export interface PageDataFilter {
  page?: InputMaybe<Scalars['String']>;
}

export interface PageDataInput {
  defaultLang: Scalars['Int'];
  groups?: InputMaybe<Array<Scalars['Int']>>;
  langs?: InputMaybe<Array<Scalars['Int']>>;
  page: Scalars['String'];
}

export interface PageDataResult {
  __typename?: 'PageDataResult';
  pageData: FrontendPageData;
}

export interface PageGameGroup {
  __typename?: 'PageGameGroup';
  gameGroup: GameGroup;
  page: Scalars['String'];
}

export interface PageInfo {
  __typename?: 'PageInfo';
  hasNextPage: Maybe<Scalars['Boolean']>;
  totalItems: Maybe<Scalars['Integer']>;
}

export interface PageLang {
  __typename?: 'PageLang';
  /** Default Value is false */
  isDefault: Scalars['Boolean'];
  lang: Lang;
  page: Scalars['String'];
}

export interface PagesDataResult {
  __typename?: 'PagesDataResult';
  pagesData: Array<FrontendPageData>;
}

export interface Partner {
  __typename?: 'Partner';
  activeFrom: Maybe<Scalars['DateTime']>;
  activeTo: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  commissionType: PartnerCommissionTypeEnum;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  /** Default Value is false */
  deleted: Scalars['Boolean'];
  discount: Scalars['Float'];
  discountDirectSale: Maybe<Scalars['Float']>;
  discountDirectSaleType: PartnerDiscountTypeEnum;
  discountType: PartnerDiscountTypeEnum;
  gameIds: Maybe<Array<Maybe<Scalars['Integer']>>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  productPartners: Array<ProductPartner>;
  token: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export enum PartnerCommissionTypeEnum {
  Normal = 'normal',
  RealPrice = 'realPrice'
}

export enum PartnerDiscountTypeEnum {
  Percent = 'percent',
  Value = 'value'
}

export interface PartnerFilter {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  code?: InputMaybe<Scalars['String']>;
  /** Default Value is "normal" */
  commissionType?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Default Value is false */
  deleted?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<Scalars['Float']>;
  discountDirectSale?: InputMaybe<Scalars['Float']>;
  discountDirectSaleType?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface PartnerInput {
  removePartner?: InputMaybe<Scalars['Int']>;
  updatePartner?: InputMaybe<UpdatePartnerInput>;
}

export interface PartnerResult {
  __typename?: 'PartnerResult';
  meta: Maybe<PageInfo>;
  result: Array<Partner>;
}

export interface PartnerScalarInputType {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  code: Scalars['String'];
  commissionType: PartnerCommissionTypeEnum;
  discount: Scalars['Float'];
  discountDirectSale: Scalars['Float'];
  discountDirectSaleType: PartnerDiscountTypeEnum;
  discountType: PartnerDiscountTypeEnum;
  name: Scalars['String'];
}

export enum PaymentCardTypeEnum {
  Ebenefity = 'EBENEFITY',
  Edenred = 'EDENRED',
  GopayApplePay = 'GOPAY_APPLE_PAY',
  GopayGooglePay = 'GOPAY_GOOGLE_PAY',
  Sphere = 'SPHERE'
}

export enum PaymentMethodEnum {
  Benefity = 'BENEFITY',
  BenefitPlus = 'BENEFIT_PLUS',
  Cod = 'COD',
  Gopay = 'GOPAY',
  Ios = 'IOS',
  Sodexo = 'SODEXO',
  Sphere = 'SPHERE',
  Up = 'UP'
}

export enum PaymentStateEnum {
  Canceled = 'CANCELED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  NotFound = 'NOT_FOUND',
  Paid = 'PAID',
  PaymentMethodChosen = 'PAYMENT_METHOD_CHOSEN',
  Refunded = 'REFUNDED',
  Timeouted = 'TIMEOUTED'
}

export interface PermissionGroup {
  __typename?: 'PermissionGroup';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  licenseKey: Maybe<LicenseKey>;
  name: Scalars['String'];
  permissions: Array<Array<Scalars['String']>>;
  players: Array<Player>;
  type: PermissionGroupTypeEnum;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface PermissionGroupFilter {
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Default Value is "role" */
  type?: InputMaybe<Scalars['String']>;
}

export interface PermissionGroupInput {
  updatePermissionGroup?: InputMaybe<UpdatePermissionGroupInput>;
}

export interface PermissionGroupScalarInputType {
  name: Scalars['String'];
  type: PermissionGroupTypeEnum;
}

export enum PermissionGroupTypeEnum {
  License = 'license',
  Role = 'role'
}

export interface PermissionGroupsResult {
  __typename?: 'PermissionGroupsResult';
  allPermissions: Array<Array<Scalars['String']>>;
  meta: Maybe<PageInfo>;
  result: Array<PermissionGroup>;
}

export interface Player {
  __typename?: 'Player';
  accountDeletionActive: Scalars['Boolean'];
  activeBoughtGames: Maybe<Array<Maybe<BoughtGames>>>;
  activePlayerProgresses: Maybe<Array<Maybe<PlayerProgress>>>;
  agreementExtensionToken: Maybe<Scalars['String']>;
  /** Default Value is true */
  agreementGdpr: Scalars['Boolean'];
  /** Default Value is false */
  agreementNewsletter: Scalars['Boolean'];
  /** Default Value is true */
  agreementTermsAndConditions: Scalars['Boolean'];
  agreementValidTo: Scalars['DateTime'];
  appleId: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  editorPartner: Maybe<Partner>;
  editorPartnerId: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['Email']>;
  fbUserId: Maybe<Scalars['String']>;
  /** Default Value is false */
  fromFacebook: Scalars['Boolean'];
  fullname: Maybe<Scalars['String']>;
  googleId: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  implicitPermissions: Array<Array<Scalars['String']>>;
  /** Default Value is false */
  isEditor: Scalars['Boolean'];
  lang: Maybe<Lang>;
  language: Maybe<Scalars['String']>;
  license: Maybe<License>;
  managedGameIds: Array<Scalars['Int']>;
  oneTimePassword: Maybe<Scalars['String']>;
  partner: Maybe<Partner>;
  partnerId: Maybe<Scalars['Int']>;
  permissionGroups: Array<PermissionGroup>;
  permissions: Array<Array<Scalars['String']>>;
  playerGames: Maybe<Array<PlayerGame>>;
  preferences: Maybe<Scalars['Json']>;
  pushToken: Maybe<Scalars['String']>;
  /** Default Value is true */
  pushTokenActive: Scalars['Boolean'];
  /** Default Value is false */
  receiveNewsletter: Scalars['Boolean'];
  /** Default Value is "player" */
  role: Scalars['String'];
  synchKey: Maybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  userToken: Maybe<Scalars['String']>;
}

export interface PlayerAppScalarInputData {
  fullname?: InputMaybe<Scalars['String']>;
  pushToken?: InputMaybe<Scalars['String']>;
}

export interface PlayerFilter {
  /** Default Value is false */
  agreementNewsletter?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is true */
  agreementTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  fullname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  permissionGroups?: InputMaybe<PermissionGroupFilter>;
  playerGameFilter?: InputMaybe<PlayerGameFilter>;
  pushToken?: InputMaybe<Scalars['String']>;
  /** Default Value is true */
  pushTokenActive?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is false */
  receiveNewsletter?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is "player" */
  role?: InputMaybe<Scalars['String']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface PlayerGame {
  __typename?: 'PlayerGame';
  game: Game;
  id: Scalars['Int'];
  player: Player;
}

export interface PlayerGameFilter {
  date?: InputMaybe<Scalars['DateTimeBetween']>;
  gameIds?: InputMaybe<Array<Scalars['Int']>>;
  mapIds?: InputMaybe<Array<Scalars['Int']>>;
  relation: PlayerGameRelationEnum;
}

/** If not set it will be OR relation (purchased or played) */
export enum PlayerGameRelationEnum {
  Played = 'played',
  Purchased = 'purchased'
}

export interface PlayerInput {
  generateOneTimePassword?: InputMaybe<Scalars['Int']>;
  updateAppPlayer?: InputMaybe<UpdateAppPlayerInput>;
  updatePlayer?: InputMaybe<UpdatePlayerInput>;
}

export interface PlayerPhoto {
  __typename?: 'PlayerPhoto';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  filename: Maybe<Scalars['String']>;
  id: Scalars['Int'];
}

export interface PlayerProgress {
  __typename?: 'PlayerProgress';
  actualIndex: Maybe<Scalars['Int']>;
  completed: Maybe<Scalars['Boolean']>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  emailsSent: Maybe<Scalars['String']>;
  game: Maybe<Game>;
  /** Will be set after startGame mutation is called. Contains rolled gameData (with {PLAYER_1}, {UNIQUE_PLAYER} etc. replaced) */
  gameSnapshot: Maybe<Scalars['Json']>;
  gameVariant: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Default Value is "" */
  inviteHash: Scalars['String'];
  /** Will be true after gameSnapshot is set and can be taken as multiplayer game started */
  isLobbyFinished: Maybe<Scalars['Boolean']>;
  /** Default Value is false */
  multiplayerGame: Scalars['Boolean'];
  playerPhotos: Array<PlayerPhoto>;
  playerProgressPlayers: Maybe<Array<Maybe<PlayerProgressPlayer>>>;
  points: Maybe<Scalars['Int']>;
  progress: Maybe<Scalars['Json']>;
  resultType: Maybe<Scalars['String']>;
  startedAt: Maybe<Scalars['DateTime']>;
  /** Default Value is "" */
  syncKey: Scalars['String'];
  time: Maybe<Scalars['Int']>;
  timePassed: Maybe<Scalars['Boolean']>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  user: Maybe<Player>;
  /** Default Value is false */
  variantGenerateIgnore: Scalars['Boolean'];
}

export interface PlayerProgressChangedResult {
  __typename?: 'PlayerProgressChangedResult';
  errorType: Maybe<GameErrorTypeEnum>;
  /** Return only if playerProgress is Changed from last Check */
  playerProgress: Maybe<PlayerProgress>;
  progressChanged: Scalars['Boolean'];
}

export interface PlayerProgressInputType {
  downloadPlayerProgress?: InputMaybe<DownloadPlayerProgressInputType>;
  removePlayerProgress?: InputMaybe<Scalars['Int']>;
}

export interface PlayerProgressPlayer {
  __typename?: 'PlayerProgressPlayer';
  /** Default Value is false */
  congratsOpened: Scalars['Boolean'];
  /** Default Value is "now" */
  dateCreated: Scalars['DateTime'];
  dateUpdated: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  nickname: Scalars['String'];
  /** Default Value is "" */
  notes: Scalars['String'];
  player: Player;
  playerProgress: PlayerProgress;
  /** Default Value is false */
  ready: Scalars['Boolean'];
}

export interface PlayerProgressPlayerInput {
  /** Default Value is false */
  congratsOpened?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** Default Value is "" */
  notes?: InputMaybe<Scalars['String']>;
}

export interface PlayerScalarInputData {
  fullname?: InputMaybe<Scalars['String']>;
}

export interface PlayerToGameFilter {
  player?: InputMaybe<PlayerFilter>;
}

export interface PlayersResult {
  __typename?: 'PlayersResult';
  availableEditors: Array<Player>;
  availablePlayers: Array<Player>;
  meta: Maybe<PageInfo>;
  result: Array<Player>;
}

export interface ProductPartner {
  __typename?: 'ProductPartner';
  allowedPayments: Array<PaymentMethodEnum>;
  apiTokens: Array<ApiToken>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  defaultCommission: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  notificationEmails: Scalars['String'];
  order: Scalars['Int'];
  partners: Array<Partner>;
  productPartnerSaleSources: Array<ProductPartnerSaleSource>;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface ProductPartnerFilter {
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  defaultCommission?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface ProductPartnerInput {
  billProductPartner?: InputMaybe<BillProductPartnerInput>;
  productPartnerId?: InputMaybe<Scalars['Int']>;
  removeProductPartner?: InputMaybe<Scalars['Int']>;
  updateApiToken?: InputMaybe<ApiTokenInput>;
  updateProductPartner?: InputMaybe<UpdateProductPartnerInput>;
}

export interface ProductPartnerSaleSource {
  __typename?: 'ProductPartnerSaleSource';
  commission: Scalars['Float'];
  /** Default Value is "20" */
  commissionExternalGame: Scalars['Float'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  productPartner: ProductPartner;
  saleSource: SaleSource;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface ProductPartnerSaleSourceInput {
  commission: Scalars['Float'];
  /** Default Value is "20" */
  commissionExternalGame: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  productPartnerId: Scalars['Int'];
  saleSourceId: Scalars['Int'];
}

export interface ProductPartnersResult {
  __typename?: 'ProductPartnersResult';
  meta: Maybe<PageInfo>;
  result: Array<ProductPartner>;
}

export interface ProjectDataResult {
  __typename?: 'ProjectDataResult';
  availableAttachments: Array<Attachment>;
  availableAttributes: Array<AttributeOption>;
  availableGameGroups: Array<GameGroupOption>;
  availableLicenses: Array<LicenseOption>;
  availableMaps: Array<MapOption>;
  availablePages: Array<Scalars['String']>;
  availablePartners: Array<AvailablePartner>;
}

export interface Purchase {
  __typename?: 'Purchase';
  cardType: Maybe<PaymentCardTypeEnum>;
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  currency: Maybe<Scalars['String']>;
  deliveryAddress: Maybe<PurchaseAddress>;
  email: Maybe<Scalars['Email']>;
  game: Maybe<Game>;
  gameVoucher: Maybe<GameVoucher>;
  hash: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  orderId: Maybe<Scalars['String']>;
  orderInfo: Scalars['String'];
  orderNumber: Maybe<Scalars['String']>;
  partner: Maybe<Partner>;
  paymentData: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethodEnum;
  price: Maybe<Scalars['Float']>;
  source: Maybe<Scalars['String']>;
  sourceInfo: Maybe<Scalars['String']>;
  state: Maybe<PaymentStateEnum>;
  /** Default Value is "0" */
  status: Scalars['Int'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  user: Maybe<Player>;
}

export interface PurchaseAddress {
  __typename?: 'PurchaseAddress';
  addressType: PurchaseAddressTypeEnum;
  /** Default Value is false */
  agreementsNewsletter: Scalars['Boolean'];
  city: Scalars['String'];
  country: Scalars['String'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  houseNumber: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
}

export interface PurchaseAddressScalarInputType {
  addressType: PurchaseAddressTypeEnum;
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  houseNumber: Scalars['String'];
  lastName: Scalars['String'];
  /** Default Value is "" */
  note: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
}

export enum PurchaseAddressTypeEnum {
  Billing = 'billing',
  Delivery = 'delivery'
}

export interface PurchaseExportInput {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}

export interface PurchaseFilter {
  cardType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeBetween']>;
  currency?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  game?: InputMaybe<GameFilter>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderInfo?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerFilter>;
  paymentData?: InputMaybe<Scalars['String']>;
  /** Default Value is "GOPAY" */
  paymentMethod?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  sourceInfo?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  /** Default Value is "0" */
  status?: InputMaybe<Scalars['Int']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface PurchaseInput {
  purchasesExport?: InputMaybe<PurchaseExportInput>;
  removePurchaseWithVoucher?: InputMaybe<Scalars['Int']>;
  resendNotificationMail?: InputMaybe<Scalars['Int']>;
  updatePurchase?: InputMaybe<UpdatePurchaseInput>;
}

export interface PurchaseScalarInputType {
  email?: InputMaybe<Scalars['Email']>;
}

export interface PurchasesResult {
  __typename?: 'PurchasesResult';
  meta: Maybe<PageInfo>;
  result: Array<Purchase>;
}

export interface PushNotification {
  __typename?: 'PushNotification';
  /** Default Value is false */
  active: Scalars['Boolean'];
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  dateFinished: Maybe<Scalars['DateTime']>;
  extraData: Maybe<Scalars['Json']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  name: Scalars['String'];
  photoSource: Maybe<MediaFile>;
  priority: PushNotificationPriorityEnum;
  sendAt: Maybe<Scalars['DateTime']>;
  stats: PushNotificationStats;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
}

export interface PushNotificationFilter {
  /** Default Value is false */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFinished?: InputMaybe<Scalars['String']>;
  extraData?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<PushNotificationPriorityEnum>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface PushNotificationInput {
  removePushNotification?: InputMaybe<Scalars['Int']>;
  sendTestPushNotification?: InputMaybe<TestPushNotificationInput>;
  updatePushNotification?: InputMaybe<UpdatePushNotificationInput>;
}

export enum PushNotificationPriorityEnum {
  Normal = 'normal'
}

/** required if created */
export interface PushNotificationScalarInput {
  /** Default Value is false */
  active: Scalars['Boolean'];
  dateFinished?: InputMaybe<Scalars['DateTime']>;
  extraData?: InputMaybe<Scalars['Json']>;
  message: Scalars['String'];
  name: Scalars['String'];
  sendAt?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
}

export interface PushNotificationStats {
  __typename?: 'PushNotificationStats';
  error: Scalars['Int'];
  success: Scalars['Int'];
  total: Scalars['Int'];
}

export interface PushNotificationsResult {
  __typename?: 'PushNotificationsResult';
  meta: Maybe<PageInfo>;
  result: Array<PushNotification>;
}

export interface Query {
  __typename?: 'Query';
  appNotification: AppNotificationResult;
  /** Method returns game data. In order to successfully call this method, CreateGameMutation or JoinGameMutation has to be called first if it is multiplayer, otherwise you can download it. */
  downloadGame: Maybe<Game>;
  /** Method returns game data. In order to successfully call this method, CreateGameMutation or JoinGameMutation has to be called first if it is multiplayer, otherwise you can download it. */
  downloadGameV2: DownloadGameResult;
  getAttributes: AttributesResult;
  /** Return cities with Groups and Games (call downloadGame to access game with all necessary data, steps etc.) */
  getCities: Maybe<CitiesResult>;
  getGame: Game;
  getGameGroups: GameGroupsResult;
  getGameVouchers: GameVouchersResult;
  getLanguages: LanguagesResult;
  getLicenses: LicensesResult;
  getManagerCities: ManagerCitiesResult;
  getManagerPlayerProgresses: ManagerPlayerProgressesResult;
  getManagerTranslations: ManagerTranslationsResult;
  getPagesData: PagesDataResult;
  getPartners: PartnerResult;
  /** Call without filter to get all available groups */
  getPermissionGroups: PermissionGroupsResult;
  /** Method returns Current user data based on AccessToken */
  getPlayerInfo: Maybe<Player>;
  /** Return only if you have access to that game and game is multiplayer */
  getPlayerProgress: Maybe<PlayerProgress>;
  getPlayers: PlayersResult;
  getProductPartners: ProductPartnersResult;
  getProjectData: ProjectDataResult;
  getPurchases: PurchasesResult;
  getPushNotifications: PushNotificationsResult;
  getReviews: ReviewsResult;
  getSaleSources: SaleSourceResult;
  getSettings: Settings;
  /** DEPRECATED: use getTranslationsV2 instead (Currently returns CZ and EN translations) */
  getTranslations: Maybe<TranslationResult>;
  /** Returns languages and translations */
  getTranslationsV2: Array<Maybe<Lang>>;
  /** Returns true or false in order if PlayerProgress is changed from last check */
  isPlayerProgressChanged: PlayerProgressChangedResult;
  /** Checks if player exists or not */
  playerExists: Maybe<Scalars['Boolean']>;
}


export interface QueryAppNotificationArgs {
  input: AppNotificationInputType;
}


export interface QueryDownloadGameArgs {
  gameId: Scalars['Integer'];
}


export interface QueryDownloadGameV2Args {
  gameId: Scalars['Integer'];
}


export interface QueryGetAttributesArgs {
  filter: AttributeFilter;
}


export interface QueryGetCitiesArgs {
  synchKey: Scalars['String'];
}


export interface QueryGetGameArgs {
  gameId: Scalars['Int'];
}


export interface QueryGetGameGroupsArgs {
  filter: InputMaybe<GameGroupFilter>;
}


export interface QueryGetGameVouchersArgs {
  filter: GameVoucherFilter;
}


export interface QueryGetLanguagesArgs {
  filter: InputMaybe<LanguagesFilter>;
}


export interface QueryGetLicensesArgs {
  filter: LicenseFilter;
}


export interface QueryGetManagerCitiesArgs {
  filter: ManagerCitiesFilter;
}


export interface QueryGetManagerPlayerProgressesArgs {
  filter: InputMaybe<ManagerPlayerProgressFilter>;
}


export interface QueryGetManagerTranslationsArgs {
  filter: InputMaybe<ManagerTranslationsFilter>;
}


export interface QueryGetPagesDataArgs {
  input: PageDataFilter;
}


export interface QueryGetPartnersArgs {
  filter: PartnerFilter;
}


export interface QueryGetPermissionGroupsArgs {
  filter: InputMaybe<PermissionGroupFilter>;
}


export interface QueryGetPlayerProgressArgs {
  playerProgressId: Scalars['Integer'];
}


export interface QueryGetPlayersArgs {
  filter: InputMaybe<PlayerFilter>;
}


export interface QueryGetPurchasesArgs {
  filter: PurchaseFilter;
}


export interface QueryGetPushNotificationsArgs {
  filter: PushNotificationFilter;
}


export interface QueryGetReviewsArgs {
  filter: ReviewFilter;
}


export interface QueryIsPlayerProgressChangedArgs {
  playerProgressId: Scalars['Integer'];
  syncKey: Scalars['String'];
}


export interface QueryPlayerExistsArgs {
  appleId: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['Email']>;
  facebookId: InputMaybe<Scalars['String']>;
  googleId: InputMaybe<Scalars['String']>;
}

export interface Range {
  from?: InputMaybe<Scalars['String']>;
  property: Scalars['String'];
  to?: InputMaybe<Scalars['String']>;
}

export interface RefreshTokenResult {
  __typename?: 'RefreshTokenResult';
  accessToken: Scalars['String'];
  implicitPermissions: Array<Array<Scalars['String']>>;
  managedGameIds: Array<Scalars['Int']>;
  refreshToken: Maybe<Scalars['String']>;
}

export interface RemoveCityInput {
  cityToMigrateGames: Scalars['Int'];
  cityToRemove: Scalars['Int'];
}

export interface RemoveGroupPhotoInput {
  groupPhotoId: Scalars['Int'];
}

export interface RemoveInventoryItemInput {
  inventoryItemId: Scalars['Int'];
}

export interface Review {
  __typename?: 'Review';
  createdAt: Maybe<Scalars['DateTime']>;
  game: Maybe<Game>;
  id: Scalars['Int'];
  ipAddress: Maybe<Scalars['String']>;
  isResolved: Maybe<Scalars['Boolean']>;
  phoneNumber: Maybe<Scalars['String']>;
  player: Maybe<Player>;
  progress: Maybe<PlayerProgress>;
  public: Maybe<Scalars['Boolean']>;
  rating: Scalars['Float'];
  reviewerName: Maybe<Scalars['String']>;
  source: Maybe<ReviewSourceEnum>;
  text: Maybe<Scalars['String']>;
}

export interface ReviewFilter {
  createdAt?: InputMaybe<Scalars['DateTimeBetween']>;
  filter?: InputMaybe<Filter>;
  game?: InputMaybe<GameFilter>;
  id?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isResolved?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['Float']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Array<ReviewSourceEnum>>;
  text?: InputMaybe<Scalars['String']>;
}

export interface ReviewInput {
  removeReview?: InputMaybe<Scalars['Int']>;
  updateReview?: InputMaybe<UpdateReviewInput>;
}

export interface ReviewScalarInputType {
  isResolved?: InputMaybe<Scalars['Boolean']>;
  public?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
}

export enum ReviewSourceEnum {
  External = 'external',
  Other = 'other',
  Purchase = 'purchase',
  Voucher = 'voucher'
}

export interface ReviewsResult {
  __typename?: 'ReviewsResult';
  meta: Maybe<PageInfo>;
  result: Array<Review>;
}

export interface SaleSource {
  __typename?: 'SaleSource';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  identifier: Maybe<Scalars['String']>;
  identifiers: Array<SaleSourceIdentifier>;
  /** Default Value is false */
  isForFree: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface SaleSourceFilter {
  /** Default Value is "now" */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  /** Default Value is false */
  isForFree?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  /** Default Value is "now" */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
}

export interface SaleSourceIdentifier {
  __typename?: 'SaleSourceIdentifier';
  /** Default Value is "now" */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  /** Must be unique */
  identifier: Scalars['String'];
  saleSource: SaleSource;
  /** Default Value is "now" */
  updatedAt: Scalars['DateTime'];
}

export interface SaleSourceInput {
  changeOrder?: InputMaybe<ChangeOrder>;
  removeSaleSource?: InputMaybe<Scalars['Int']>;
  saleSourceId?: InputMaybe<Scalars['Int']>;
  updateSaleSource?: InputMaybe<UpdateSaleSourceInput>;
}

export interface SaleSourceResult {
  __typename?: 'SaleSourceResult';
  availableIdentifiers: Array<Scalars['String']>;
  meta: Maybe<PageInfo>;
  result: Array<SaleSource>;
}

export interface SetTestStepsInput {
  stepIds: Array<Scalars['Int']>;
}

export interface Settings {
  __typename?: 'Settings';
  appSettings: AppSettings;
  emailSettings: EmailSettings;
  gatewaySettings: GatewaySettings;
  generalSettings: GeneralSettings;
}

export interface SettingsInput {
  appSettings?: InputMaybe<AppSettingsInput>;
  emailSettings?: InputMaybe<EmailSettingsInput>;
  gatewaySettings?: InputMaybe<GatewaySettingsInput>;
  generalSettings?: InputMaybe<GeneralSettingsInput>;
}

export interface SodexoSettings {
  __typename?: 'SodexoSettings';
  active: Scalars['Boolean'];
  benefitId: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  testMode: Scalars['Boolean'];
}

export interface SodexoSettingsInput {
  active: Scalars['Boolean'];
  benefitId: Scalars['String'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  testMode: Scalars['Boolean'];
}

export interface SphereSettings {
  __typename?: 'SphereSettings';
  active: Scalars['Boolean'];
  commodityId: Scalars['String'];
  connectorUser: Scalars['String'];
  gameDiscountPercent: Scalars['Float'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  partnerLocationId: Scalars['String'];
  posId: Scalars['String'];
  userId: Scalars['String'];
}

export interface SphereSettingsInput {
  active: Scalars['Boolean'];
  commodityId: Scalars['String'];
  connectorPassword?: InputMaybe<Scalars['String']>;
  connectorUser: Scalars['String'];
  gameDiscountPercent: Scalars['Float'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  partnerLocationId: Scalars['String'];
  posId: Scalars['String'];
  userId: Scalars['String'];
}

export interface StepElement {
  __typename?: 'StepElement';
  active: Maybe<Scalars['Boolean']>;
  gameStepClues: Array<GameStepClue>;
  id: Scalars['Int'];
  location: Maybe<LocationEnum>;
  orderIndex: Scalars['Int'];
  settings: Maybe<Scalars['Json']>;
  type: StepElementTypeEnum;
}

export interface StepElementInput {
  active?: InputMaybe<Scalars['Boolean']>;
  gameStepClues?: InputMaybe<Array<GameStepClueInput>>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<LocationEnum>;
  orderIndex: Scalars['Int'];
  settings?: InputMaybe<Scalars['Json']>;
  type: StepElementTypeEnum;
}

export enum StepElementTypeEnum {
  Audio = 'audio',
  ClueEnd = 'clue_end',
  ClueStart = 'clue_start',
  CompleteGame = 'complete_game',
  ContinueButton = 'continue_button',
  ContinueTimer = 'continue_timer',
  Image = 'image',
  InputField = 'input_field',
  InventoryNewItemAlert = 'inventory_new_item_alert',
  Location = 'location',
  PairSentence = 'pair_sentence',
  PauseTimer = 'pause_timer',
  PhoneNumberInput = 'phone_number_input',
  QrCode = 'qr_code',
  RateInlineElement = 'rate_inline_element',
  RateWindow = 'rate_window',
  SelectOption = 'select_option',
  ShareButton = 'share_button',
  SharingButton = 'sharing_button',
  SkipStepButton = 'skip_step_button',
  SlidingPuzzle = 'sliding_puzzle',
  SmallInfoPopup = 'small_info_popup',
  StartGameButton = 'start_game_button',
  StepSummary = 'step_summary',
  TakePhotoButton = 'take_photo_button',
  TeamNameInput = 'team_name_input',
  Text = 'text',
  Timeout = 'timeout',
  Video = 'video'
}

export interface SwitchStepActiveStateInput {
  includeVariants: Scalars['Boolean'];
  stepId: Scalars['Int'];
}

export interface TestPushNotificationInput {
  message: Scalars['String'];
  photoSource?: InputMaybe<FileInputHelper>;
  photoSourceId?: InputMaybe<Scalars['Int']>;
  pushToken: Scalars['String'];
  title: Scalars['String'];
}

export interface TranslationInput {
  updateTranslation?: InputMaybe<UpdateTranslationInput>;
}

export interface TranslationProject {
  __typename?: 'TranslationProject';
  projects: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
}

export interface TranslationResult {
  __typename?: 'TranslationResult';
  cz: Maybe<Scalars['Json']>;
  en: Maybe<Scalars['Json']>;
}

export interface TranslationScalarInputType {
  description: Scalars['String'];
  /** Default Value is "text" */
  type: Scalars['String'];
}

export interface TranslationValueInputType {
  value: Scalars['String'];
}

export interface UpSettings {
  __typename?: 'UpSettings';
  active: Scalars['Boolean'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  merchantId: Scalars['String'];
  testMode: Scalars['Boolean'];
}

export interface UpSettingsInput {
  active: Scalars['Boolean'];
  gatewayCharge: Scalars['Float'];
  gatewayChargePercent: Scalars['Float'];
  merchantId: Scalars['String'];
  testMode: Scalars['Boolean'];
}

export interface UpdateApiTokenInput {
  productPartnerId: Scalars['Int'];
}

export interface UpdateAppPlayerInput {
  deleteAccount?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  revertAccountDeletion?: InputMaybe<Scalars['Boolean']>;
  scalarData?: InputMaybe<PlayerAppScalarInputData>;
  sendDeleteAccountVerification?: InputMaybe<Scalars['Boolean']>;
}

export interface UpdateAttributeInput {
  gameGroups?: InputMaybe<Array<Scalars['Int']>>;
  games?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  /** send null to remove current one */
  photoSource?: InputMaybe<FileInputHelper>;
  scalarData?: InputMaybe<AttributeScalarInput>;
}

export interface UpdateAttributeResult {
  __typename?: 'UpdateAttributeResult';
  attribute: Maybe<Attribute>;
  changeOrder: Maybe<Array<ChangeOrderResult>>;
  removeAttributeId: Maybe<Scalars['Int']>;
}

export interface UpdateCityInput {
  id?: InputMaybe<Scalars['Int']>;
  /** send null to remove current one */
  photoSource?: InputMaybe<FileInputHelper>;
  scalarData?: InputMaybe<CityScalarInput>;
  translation?: InputMaybe<CityTranslationInput>;
}

export interface UpdateCityResult {
  __typename?: 'UpdateCityResult';
  changeOrder: Maybe<Array<ChangeOrderResult>>;
  city: Maybe<City>;
  removeCityId: Maybe<Scalars['Int']>;
}

export interface UpdateEntityAttachments {
  attachmentIds: Array<Scalars['Int']>;
  sendType: EntityAttachmentSendTypeEnum;
}

export interface UpdateExternalVoucherInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<ExternalVoucherScalarInput>;
}

export interface UpdateGameGroupInput {
  attributes?: InputMaybe<Array<Scalars['Int']>>;
  cityId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionWeb?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['String']>;
  groupPages?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  langId: Scalars['Int'];
  licenseId?: InputMaybe<Scalars['Int']>;
  mapId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  notificationMails?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  restrictions?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  startingPosition?: InputMaybe<Scalars['String']>;
  state: GameGroupStateEnum;
}

export interface UpdateGameGroupResult {
  __typename?: 'UpdateGameGroupResult';
  gameGroup: Maybe<GameGroup>;
  groupDeleted: Maybe<Scalars['Int']>;
}

export interface UpdateGameInput {
  activeDownloadFrom?: InputMaybe<Scalars['DateTime']>;
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  appPurchaseId?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<UpdateEntityAttachments>>;
  attributes?: InputMaybe<Array<Scalars['Int']>>;
  canPurchaseTo?: InputMaybe<Scalars['DateTime']>;
  congratsContent?: InputMaybe<Scalars['String']>;
  difficulty?: InputMaybe<Scalars['Int']>;
  difficultyNote?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  extraSettings: Scalars['Json'];
  gameSaleSources?: InputMaybe<Array<GameSaleSourceInput>>;
  gpsEntry?: InputMaybe<UpdateGpsEntryInput>;
  groupId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  /** Default Value is false */
  isForFree: Scalars['Boolean'];
  labelActualize?: InputMaybe<Scalars['String']>;
  labelBought?: InputMaybe<Scalars['String']>;
  labelBuy?: InputMaybe<Scalars['String']>;
  labelCancel?: InputMaybe<Scalars['String']>;
  labelClosehelper?: InputMaybe<Scalars['String']>;
  labelComplete?: InputMaybe<Scalars['String']>;
  labelContinue?: InputMaybe<Scalars['String']>;
  labelDifficulty?: InputMaybe<Scalars['String']>;
  labelDownload?: InputMaybe<Scalars['String']>;
  labelHelper?: InputMaybe<Scalars['String']>;
  labelHelperquestion?: InputMaybe<Scalars['String']>;
  labelNext?: InputMaybe<Scalars['String']>;
  labelNoclues?: InputMaybe<Scalars['String']>;
  labelNotes?: InputMaybe<Scalars['String']>;
  labelOk?: InputMaybe<Scalars['String']>;
  labelPlay?: InputMaybe<Scalars['String']>;
  labelPrevious?: InputMaybe<Scalars['String']>;
  labelRightansweris?: InputMaybe<Scalars['String']>;
  labelShareonfacebook?: InputMaybe<Scalars['String']>;
  labelSize?: InputMaybe<Scalars['String']>;
  labelSkip?: InputMaybe<Scalars['String']>;
  labelSkipphoto?: InputMaybe<Scalars['String']>;
  labelSkipquestion?: InputMaybe<Scalars['String']>;
  labelStartgame?: InputMaybe<Scalars['String']>;
  labelStartgamequestion?: InputMaybe<Scalars['String']>;
  labelSubmit?: InputMaybe<Scalars['String']>;
  labelTakephoto?: InputMaybe<Scalars['String']>;
  labelTelephonesupport?: InputMaybe<Scalars['String']>;
  labelTelephonesupportquestion?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  maxTime?: InputMaybe<Scalars['Int']>;
  minTime?: InputMaybe<Scalars['Int']>;
  /** Default Value is true */
  mixStats: Scalars['Boolean'];
  /** Default Value is false */
  multiplayerGame: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  /** Default Value is "" */
  note: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoSource?: InputMaybe<Scalars['String']>;
  players?: InputMaybe<Scalars['Json']>;
  predefinedNotes?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  productPartnerId?: InputMaybe<Scalars['Int']>;
  purchaseNote?: InputMaybe<Scalars['String']>;
  randomCode?: InputMaybe<Scalars['Json']>;
  restrictions?: InputMaybe<Scalars['String']>;
  resultType?: InputMaybe<GameResultType>;
  sendQrCodes?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Float']>;
  startingPosition?: InputMaybe<Scalars['String']>;
  state: GameStateEnum;
  teamInfo?: InputMaybe<Scalars['String']>;
  teamNamePopupActive?: InputMaybe<Scalars['Boolean']>;
  teamNamePopupText?: InputMaybe<Scalars['String']>;
  textEmail?: InputMaybe<Scalars['String']>;
  textRating?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<GameTypeEnum>;
  variantAlgorithm?: InputMaybe<VariantAlgorithmEnum>;
}

export interface UpdateGamePhotos {
  photoSource?: InputMaybe<FileInputHelper>;
}

export interface UpdateGameResult {
  __typename?: 'UpdateGameResult';
  changeOrder: Maybe<Array<ChangeOrderResult>>;
  checkAppId: Maybe<Scalars['Boolean']>;
  game: Maybe<Game>;
  gameDeleted: Maybe<Scalars['Int']>;
  gameDownloadLink: Maybe<Scalars['String']>;
}

export interface UpdateGameStepInput {
  afterStepLoadedElements?: InputMaybe<Array<StepElementInput>>;
  beforeStepLeftElements?: InputMaybe<Array<StepElementInput>>;
  contentElements?: InputMaybe<Array<StepElementInput>>;
  footerElements?: InputMaybe<Array<StepElementInput>>;
  id?: InputMaybe<Scalars['Int']>;
  inactiveFrom?: InputMaybe<Scalars['DateTime']>;
  inactiveTo?: InputMaybe<Scalars['DateTime']>;
  info?: InputMaybe<Scalars['String']>;
  infoTitle?: InputMaybe<Scalars['String']>;
  internalNote?: InputMaybe<Scalars['String']>;
  mapPhotoSource?: InputMaybe<FileInputHelper>;
  mapText?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  selectedForTest?: InputMaybe<Scalars['Boolean']>;
  state: GameStepStateEnum;
  title?: InputMaybe<Scalars['String']>;
}

export interface UpdateGameStepResult {
  __typename?: 'UpdateGameStepResult';
  changeOrder: Maybe<Array<GameStepsChangeOrder>>;
  gameStep: Maybe<GameStep>;
  gameStepDeleted: Maybe<Scalars['Int']>;
  gameSteps: Maybe<Array<GameStep>>;
}

export interface UpdateGameVoucherBillingDataInput {
  commissionProductPartner: Scalars['Float'];
  commissionSaleSource: Scalars['Float'];
  gatewayCharge?: InputMaybe<Scalars['Float']>;
  gatewayChargePercent?: InputMaybe<Scalars['Float']>;
  /** Default Value is false */
  paidToProductPartner: Scalars['Boolean'];
  partnerCommissionType?: InputMaybe<PartnerCommissionTypeEnum>;
  partnerDiscount?: InputMaybe<Scalars['Float']>;
  partnerDiscountDirectSale?: InputMaybe<Scalars['Float']>;
  partnerDiscountDirectSaleType?: InputMaybe<PartnerDiscountTypeEnum>;
  partnerDiscountType?: InputMaybe<PartnerDiscountTypeEnum>;
  voucherPrice: Scalars['Float'];
  voucherRealPrice?: InputMaybe<Scalars['Float']>;
}

export interface UpdateGameVoucherInput {
  amountVouchersToGenerate?: InputMaybe<Scalars['Int']>;
  billingData: UpdateGameVoucherBillingDataInput;
  description: Scalars['String'];
  gameId?: InputMaybe<Scalars['Int']>;
  gender: GenderEnum;
  id?: InputMaybe<Scalars['Int']>;
  maxUsedCount?: InputMaybe<Scalars['Int']>;
  /** Discount ID */
  partnerId?: InputMaybe<Scalars['Int']>;
  productPartnerId: Scalars['Int'];
  /** Default Value is "" */
  saleSourceCode: Scalars['String'];
  saleSourceId: Scalars['Int'];
  validTo?: InputMaybe<Scalars['DateTime']>;
}

export interface UpdateGameVoucherResult {
  __typename?: 'UpdateGameVoucherResult';
  commissionData: Maybe<VoucherCommissionData>;
  downloadLink: Maybe<Scalars['String']>;
  gameVoucher: Maybe<GameVoucher>;
  gameVoucherDeleted: Maybe<Scalars['Int']>;
  /** in case of updateGameVoucher, other methods will return gameVoucher */
  gameVouchers: Maybe<Array<GameVoucher>>;
}

export interface UpdateGpsEntryInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<GpsEntryScalarInput>;
}

export interface UpdateGroupPhotosInput {
  id: Scalars['Int'];
  photoSource?: InputMaybe<FileInputHelper>;
  photoSourceAlt?: InputMaybe<FileInputHelper>;
  videoSource?: InputMaybe<FileInputHelper>;
}

export interface UpdateGroupVoucherImageInput {
  extraSettings?: InputMaybe<VoucherImageExtraSettingsInput>;
  groupId: Scalars['Int'];
  /** JPEG only */
  photo?: InputMaybe<FileInputHelper>;
  xAxis?: InputMaybe<Scalars['Int']>;
  yAxis?: InputMaybe<Scalars['Int']>;
}

export interface UpdateLangInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<LangScalarInputType>;
}

export interface UpdateLangResult {
  __typename?: 'UpdateLangResult';
  lang: Maybe<Lang>;
}

export interface UpdateLicenseContactInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData: LicenseContactScalarInputType;
}

export interface UpdateLicenseInput {
  id?: InputMaybe<Scalars['Int']>;
  licenseContacts?: InputMaybe<Array<UpdateLicenseContactInput>>;
  photoSource?: InputMaybe<FileInputHelper>;
  scalarData?: InputMaybe<LicenseScalarInputType>;
}

export interface UpdateLicenseKeyInput {
  id?: InputMaybe<Scalars['Int']>;
  licenseId: Scalars['Int'];
  scalarData?: InputMaybe<LicenseKeyScalarInputType>;
}

export interface UpdateLicenseResult {
  __typename?: 'UpdateLicenseResult';
  license: Maybe<License>;
  licenseKey: Maybe<LicenseKey>;
  licenseKeyVoucherDownloadLink: Maybe<Scalars['String']>;
  removedLicenseKeyId: Maybe<Scalars['Int']>;
}

export interface UpdatePartnerInput {
  allowedPayments?: InputMaybe<Array<PaymentMethodEnum>>;
  /** If empty array, partner code is considered to be allowed for all games */
  gameIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  /** Mandatory if id is not null, later it cannot be changed */
  productPartnerIds?: InputMaybe<Array<Scalars['Int']>>;
  scalarData?: InputMaybe<PartnerScalarInputType>;
}

export interface UpdatePartnerResult {
  __typename?: 'UpdatePartnerResult';
  partner: Maybe<Partner>;
  productPartnerDeleted: Maybe<Scalars['Int']>;
}

export interface UpdatePermissionGroupInput {
  id?: InputMaybe<Scalars['Int']>;
  permissions?: InputMaybe<Array<Array<Scalars['String']>>>;
  scalarData?: InputMaybe<PermissionGroupScalarInputType>;
}

export interface UpdatePermissionGroupResult {
  __typename?: 'UpdatePermissionGroupResult';
  permissionGroup: Maybe<PermissionGroup>;
}

export interface UpdatePlayerInput {
  addPermissionGroups?: InputMaybe<Array<Scalars['Int']>>;
  games?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  permissions?: InputMaybe<Array<Array<Scalars['String']>>>;
  removePermissionGroups?: InputMaybe<Array<Scalars['Int']>>;
  scalarData?: InputMaybe<PlayerScalarInputData>;
}

export interface UpdatePlayerProgressResult {
  __typename?: 'UpdatePlayerProgressResult';
  fileUrl: Maybe<Scalars['String']>;
  removedPlayerProgressId: Maybe<Scalars['Int']>;
  renderedProgress: Maybe<Scalars['String']>;
}

export interface UpdatePlayerResult {
  __typename?: 'UpdatePlayerResult';
  accountDeleted: Maybe<Scalars['Boolean']>;
  deleteAccountVerificationMailSent: Maybe<Scalars['Boolean']>;
  oneTimePassword: Maybe<Scalars['String']>;
  player: Maybe<Player>;
}

export interface UpdateProductPartnerInput {
  allowedPayments: Array<PaymentMethodEnum>;
  defaultCommission: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  notificationEmails: Scalars['String'];
  productPartnerSaleSources: Array<ProductPartnerSaleSourceInput>;
}

export interface UpdateProductPartnerResult {
  __typename?: 'UpdateProductPartnerResult';
  billProductPartnerResult: Maybe<BillProductPartnerResult>;
  productPartner: Maybe<ProductPartner>;
  productPartnerDeleted: Maybe<Scalars['Int']>;
}

export interface UpdatePurchaseAddressInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<PurchaseAddressScalarInputType>;
}

export interface UpdatePurchaseInput {
  billingAddress?: InputMaybe<UpdatePurchaseAddressInput>;
  id: Scalars['Int'];
  scalarData?: InputMaybe<PurchaseScalarInputType>;
}

export interface UpdatePurchaseResult {
  __typename?: 'UpdatePurchaseResult';
  purchase: Maybe<Purchase>;
  purchaseExportLink: Maybe<Scalars['String']>;
  purchaseRemoved: Maybe<Scalars['Int']>;
  resendNotificationMailSuccess: Maybe<Scalars['Boolean']>;
}

export interface UpdatePushNotificationInput {
  addExtraPlayers?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  /** send null to remove current one */
  photoSource?: InputMaybe<FileInputHelper>;
  scalarData?: InputMaybe<PushNotificationScalarInput>;
}

export interface UpdatePushNotificationResult {
  __typename?: 'UpdatePushNotificationResult';
  pushNotification: Maybe<PushNotification>;
  removePushNotificationId: Maybe<Scalars['Int']>;
}

export interface UpdateReviewInput {
  id: Scalars['Int'];
  scalarData?: InputMaybe<ReviewScalarInputType>;
}

export interface UpdateReviewResult {
  __typename?: 'UpdateReviewResult';
  review: Maybe<Review>;
  reviewRemoved: Maybe<Scalars['Int']>;
}

export interface UpdateSaleSourceInput {
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['String']>;
  identifiers: Array<Scalars['String']>;
  /** Default Value is false */
  isForFree: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface UpdateSaleSourceResult {
  __typename?: 'UpdateSaleSourceResult';
  changeOrder: Maybe<Array<ChangeOrderResult>>;
  saleSource: Maybe<SaleSource>;
  saleSourceDeleted: Maybe<Scalars['Int']>;
}

export interface UpdateTranslationInput {
  id?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<TranslationScalarInputType>;
  translationValues?: InputMaybe<Array<UpdateTranslationValueInput>>;
}

export interface UpdateTranslationResult {
  __typename?: 'UpdateTranslationResult';
  translation: Maybe<ManagerTranslation>;
}

export interface UpdateTranslationValueInput {
  id?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  scalarData?: InputMaybe<TranslationValueInputType>;
}

export interface UploadFilesInput {
  files: Array<FileInputHelper>;
}

export enum VariantAlgorithmEnum {
  Competition = 'competition',
  Normal = 'normal'
}

export interface VoucherCommissionData {
  __typename?: 'VoucherCommissionData';
  commissionProductPartnerPercent: Scalars['Float'];
  commissionSaleSourcePercent: Scalars['Float'];
  gatewayCharge: Maybe<Scalars['Float']>;
  gatewayChargePercent: Maybe<Scalars['Float']>;
  partnerCommissionType: Maybe<PartnerCommissionTypeEnum>;
  partnerDiscount: Maybe<Scalars['Float']>;
  partnerDiscountDirectSale: Maybe<Scalars['Float']>;
  partnerDiscountDirectSaleType: Maybe<PartnerDiscountTypeEnum>;
  partnerDiscountType: Maybe<PartnerDiscountTypeEnum>;
  voucherPrice: Scalars['Float'];
  voucherRealPrice: Maybe<Scalars['Float']>;
}

export interface VoucherImage {
  __typename?: 'VoucherImage';
  extraSettings: Maybe<VoucherImageExtraSettings>;
  id: Scalars['Int'];
  name: Scalars['String'];
  xAxis: Scalars['Int'];
  yAxis: Scalars['Int'];
}

export interface VoucherImageExtraSettings {
  __typename?: 'VoucherImageExtraSettings';
  codeBox: ImageTextBoxData;
  gameNameBox: Maybe<ImageTextBoxData>;
  groupNameBox: Maybe<ImageTextBoxData>;
  qrBox: Maybe<ImageTextBoxData>;
  validToBox: Maybe<ImageTextBoxData>;
}

export interface VoucherImageExtraSettingsInput {
  codeBox: ImageTextBoxDataInput;
  gameNameBox?: InputMaybe<ImageTextBoxDataInput>;
  groupNameBox?: InputMaybe<ImageTextBoxDataInput>;
  qrBox?: InputMaybe<ImageTextBoxDataInput>;
  validToBox?: InputMaybe<ImageTextBoxDataInput>;
}

/** creates new game from ZIP file and adds it to desired group */
export interface ImportGameInput {
  groupId: Scalars['Int'];
  zipFile: FileInputHelper;
}

export interface LicenseActivatedData {
  __typename?: 'licenseActivatedData';
  implicitPermissions: Array<Array<Scalars['String']>>;
  license: License;
}

export interface LoginCredentialsInput {
  appleAccessToken?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
  facebookAccessToken?: InputMaybe<Scalars['String']>;
  googleIdToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}

export interface RegisterCredentialsInput {
  appleAccessToken?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
  facebookAccessToken?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  googleIdToken?: InputMaybe<Scalars['String']>;
  /** Required if fbId not included */
  password?: InputMaybe<Scalars['String']>;
  receiveNewsletter: Scalars['Boolean'];
}

export enum VoucherTypeResultEnum {
  Partner = 'PARTNER',
  SlevomatVoucher = 'SLEVOMAT_VOUCHER',
  Used = 'USED',
  Voucher = 'VOUCHER'
}
