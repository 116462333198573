import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchLicensesSuccessAction, fetchLicenseSuccessAction, updateLicenseSuccessAction} from './actions';

export const licenseReducer = createImmerReducer(
    defaultDataState,
    on(fetchLicensesSuccessAction, (state: IDataState, action) => {
        if (action.result) {
            state.license.licenses = action.result
        }
        return state;
    }),
    on(fetchLicenseSuccessAction, (state: IDataState, action) => {
        state.license.activeLicense = action.license;
        return state;
    }),
    on(updateLicenseSuccessAction, (state: IDataState, action: ReturnType<typeof updateLicenseSuccessAction>) => {
        if (!state.license.activeLicense) {
            return state;
        }
        if (action.result.licenseKey) {
            const index = state.license.activeLicense.licenseKeys.findIndex((l) => l.id === action.result.licenseKey?.id);
            if (index !== -1) {
                state.license.activeLicense.licenseKeys[index] = action.result.licenseKey;
            } else {
                state.license.activeLicense.licenseKeys.push(action.result.licenseKey);
            }
        }
        if (action.result.license) {
            state.license.activeLicense = action.result.license;
        }
        if (action.result.removedLicenseKeyId) {
            state.license.activeLicense.licenseKeys = state.license.activeLicense.licenseKeys
                .filter((l) => l.id !== action.result.removedLicenseKeyId);
        }
        return state;
    })
);
