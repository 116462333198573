import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PairSentenceForm} from '../pair-sentence.component';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {SentenceFormComponent} from './sentence-form/sentence-form.component';

@Component({
    selector: 'app-update-sentence',
    templateUrl: './update-sentence.component.html',
    styleUrls: ['./update-sentence.component.scss']
})
export class UpdateSentenceComponent extends BaseComponent implements OnInit {
    @ViewChild(`a`) private a: SentenceFormComponent;
    @ViewChild(`b`) private b: SentenceFormComponent;
    form: PairSentenceForm;

    constructor(
        public dynamicDialogConfig: DynamicDialogConfig,
        private ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.dynamicDialogConfig.data.sentence;
    }


    save() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return;
        }
        this.ref.close({fileA: this.a.file, fileB: this.b.file});
    }
}
