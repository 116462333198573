import {createAction, props} from '@ngrx/store';
import {DefaultFailMessage} from '../user/actions';
import {GetInitialDataQuery} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';
import {GetGameGroupsDataQuery} from '../../graphql/queries/getGameGroupsData/getGameGroupsData.graphql-gen';

export const fetchInitialDataAction = createAction('[Data] FETCH_INITIAL_DATA');
export const fetchInitialDataFailAction = createAction('[Data] FETCH_INITIAL_DATA_FAIL', props<FetchInitialDataFailProps>());
export const fetchInitialDataSuccessAction = createAction('[Data] FETCH_INITIAL_DATA_SUCCESS', props<FetchInitialDataSuccessProps>());

export const fetchGameGroupsDataAction = createAction('[Data] FETCH_GAME_GROUPS_DATA');
export const fetchGameGroupsDataSuccessAction = createAction('[Data] FETCH_GAME_GROUPS_DATA_SUCCESS', props<FetchGameGroupsDataSuccessActionProps>());
export const fetchGameGroupsDataFailAction = createAction('[Data] FETCH_GAME_GROUPS_DATA_FAIL', props<FetchGameGroupsDataFailActionProps>());

export type FetchGameGroupsDataSuccessActionProps = { data: GetGameGroupsDataQuery['getGameGroups']['availableGroupOptionsMeta'] | null };
export type FetchGameGroupsDataFailActionProps = DefaultFailMessage;

export type FetchInitialDataSuccessProps = {initialData: GetInitialDataQuery};
export type FetchInitialDataFailProps = DefaultFailMessage;
