import * as Types from '../../types.graphql-gen';

import { GameGroupFragment } from '../../mutations/updateGameGroup/updateGameGroup.graphql-gen';
import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { gql } from 'apollo-angular';
import { GameGroupFragmentDoc } from '../../mutations/updateGameGroup/updateGameGroup.graphql-gen';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetGameGroupsQueryVariables = Types.Exact<{
  filter: Types.GameGroupFilter;
}>;


export type GetGameGroupsQuery = (
  { __typename?: 'Query' }
  & { getGameGroups: (
    { __typename?: 'GameGroupsResult' }
    & { result: Array<(
      { __typename?: 'GameGroup' }
      & GameGroupFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export const GetGameGroupsDocument = gql`
    query getGameGroups($filter: GameGroupFilter!) {
  getGameGroups(filter: $filter) {
    result {
      ...gameGroup
    }
    meta {
      ...metaFragment
    }
  }
}
    ${GameGroupFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGameGroupsGQL extends Apollo.Query<GetGameGroupsQuery, GetGameGroupsQueryVariables> {
    document = GetGameGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }