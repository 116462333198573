<!--<div class="row" *ngIf="errors">-->
<ng-container *ngIf="isTouched">
    <p-message
        *ngFor="let error of errors|keyvalue"
        severity="{{error.value?.severity ?? 'error'}}" text="{{(error.value?.message ?? error.value) |translate}}"
        styleClass="p-mr-2 inline-flex" class="mr-2">
    </p-message>
</ng-container>

<!--</div>-->
