<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="pt-4 col-12" *ngIf="allowHashedVersion && !stepElementForm.value.settings?.allAnswersCorrect">
        <label for="hashedVersion-{{uniqueId}}" class="pb-2">
            {{'elements.input.hashedVersion'|translate|firstUpper}}
            <app-tooltip
                value="{{'elements.tooltip.input.hashedVersion'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton (onChange)="hashedVersionChanged()" id="hashedVersion-{{uniqueId}}" [options]="boolOptions"
                        formControlName="hashedVersion" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="col-lg-6 col-12 pt-4" *ngIf="!stepElementForm.value.settings?.allAnswersCorrect">
        <label for="strictMode-{{uniqueId}}" class="pb-2">
            {{'elements.input.strictMode'|translate|firstUpper}}
            <app-tooltip
                value="{{'elements.input.strictMode.tooltip'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton id="strictMode-{{uniqueId}}"
                        [options]="boolOptions"
                        formControlName="strictMode" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
    <div class="col-lg-6 col-12 pt-4" *ngIf="!stepElementForm.value.settings?.strictMode">
        <label for="allAnswersCorrect-{{uniqueId}}" class="pb-2">
            {{'elements.input.allAnswersCorrect'|translate|firstUpper}}
            <app-tooltip
                value="{{'elements.input.allAnswersCorrect.tooltip'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-selectButton (onChange)="allAnswersCorrectChanged()" id="allAnswersCorrect-{{uniqueId}}"
                        [options]="boolOptions"
                        formControlName="allAnswersCorrect" optionLabel="label"
                        optionValue="value"></p-selectButton>
    </div>
<!--    <div class="col-12"-->
<!--         *ngIf="stepElementForm.value.settings.strictMode || stepElementForm.value.settings.allAnswersCorrect">-->
<!--        <p-message-->
<!--            severity="info" text="{{'info.featureNotReadyApp'|translate|firstUpper}}"-->
<!--            styleClass="p-mr-2 inline-flex" class="mr-2">-->
<!--        </p-message>-->
<!--    </div>-->

    <ng-container *ngIf="!stepElementForm.value.settings?.allAnswersCorrect">
        <div class="col-12 pt-4">
            <label for="placeholder-{{uniqueId}}" class="pb-2">
                {{'elements.input.placeholder'|translate|firstUpper}}
                <app-tooltip image="element-input-placeholder.png"
                             value="{{'elements.tooltip.input.placeholder'|translate|firstUpper}}"></app-tooltip>
            </label>
            <input id="placeholder-{{uniqueId}}"
                   formControlName="placeholder" type="text" pInputText
                   class="{{stepElementForm.get('settings.placeholder')?.touched && stepElementForm.get('settings.placeholder')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
        </div>
        <div class="pt-4 col-12">
            <label for="answers-{{uniqueId}}" class="pb-2">
                {{'elements.input.answers'|translate|firstUpper}}
                <app-tooltip
                    value="{{'elements.tooltip.input.answers'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-chips id="answers-{{uniqueId}}" formControlName="answers"
                     (onAdd)="answerAdded($event)"
                     class="{{stepElementForm.get('settings.answers')?.touched && stepElementForm.get('settings.answers')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
                <ng-template let-item pTemplate="item">
                    {{item?.value ?? item}}
                </ng-template>
            </p-chips>
        </div>
    </ng-container>
    <div class="col-12 pt-4">
        <label for="skip_info-{{uniqueId}}" class="pb-2">
            {{'elements.input.skipPhotoText'|translate|firstUpper}}
            <app-tooltip image="element-input-skipPhotoText.gif"
                         value="{{'elements.tooltip.input.skipPhotoText'|translate|firstUpper}}"></app-tooltip>
        </label>
        <app-text-editor id="skip_info-{{uniqueId}}"
                         [control]="stepElementForm.controls.settings.controls.skip_info"></app-text-editor>
        <!--        <p-editor appQuillEditor id="skip_info-{{uniqueId}}" formControlName="skip_info"-->
        <!--                  [style]="{'height':'250px'}">-->
        <!--            <ng-template pTemplate="header">-->
        <!--                <app-editor-header></app-editor-header>-->
        <!--            </ng-template>-->
        <!--        </p-editor>-->
    </div>
    <div class="pt-4 col-12">
        <label for="inputmode-{{uniqueId}}" class="pb-2">
            {{'elements.input.inputmode'|translate|firstUpper}}
            <app-tooltip image="element-input-inputmode.gif"
                         value="{{'elements.tooltip.input.inputmode'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-dropdown [options]="inputmodeOptions" formControlName="inputmode" id="inputmode-{{uniqueId}}"
                    class="{{stepElementForm.controls.settings.controls.inputmode.invalid ? 'ng-invalid ng-dirty' : ''}}"
                    optionValue="value" optionLabel="name">
        </p-dropdown>
    </div>
</div>

<app-clue-list [readonly]="readonly" [gameStepClues]="gameStepClues"
               [deprecated]="false"
               (addClueEvent)="addClue($event)"
               (removeClueEvent)="removeClue($event)" (updateClueEvent)="updateClue($event)">
</app-clue-list>

