import {AttributeTypeEnum} from './graphql/types.graphql-gen';


export type AttributeTypeSettings = {
    game: boolean;
    gameGroup: boolean;
    multi: boolean
}

export const getAttributeTypeSettings = (type: AttributeTypeEnum): AttributeTypeSettings|null => {
    switch (type) {
        case AttributeTypeEnum.Category:
            return {
                game: false,
                gameGroup: true,
                multi: false

            }
        case AttributeTypeEnum.TargetGroup:
            return {
                game: true,
                gameGroup: false,
                multi: true
            }
        case AttributeTypeEnum.EducationLevel:
        case AttributeTypeEnum.EducationSubject:
        case AttributeTypeEnum.GameType:
            return {
                game: false,
                gameGroup: true,
                multi: true
            }

        default:
            return null;
    }
}

