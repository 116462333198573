<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="pt-4 col-12">
        <div style="width: 220px">
            <label class="pb-2">
                {{'elements.image.image'|translate|firstUpper}}
                <app-tooltip image="element-image-image.png" value="{{'elements.tooltip.image.image'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-file-upload [readonly]="readonly" [file]="image" [uploadHandler]="onFileUpload"
                             [multiple]="false"></app-file-upload>
        </div>
        <div class="pt-4 col-6" *ngIf="stepElementForm.touched && stepElementForm.value.settings?.src">
            <label for="keepImageQuality" class="pb-2">
                {{'elements.image.keepImageQuality'|translate|firstUpper}}
                <app-tooltip value="{{'elements.tooltip.image.keepImageQuality'|translate|firstUpper}}"></app-tooltip>
            </label>
            <p-selectButton
                id="keepImageQuality"
                class=""
                [options]="boolOptions"
                [formControl]="stepElementForm.controls.settings.controls.keepImageQuality"
                optionLabel="label"
                optionValue="value">
            </p-selectButton>
        </div>
    </div>
</div>
