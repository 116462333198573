import {createImmerReducer} from 'ngrx-immer/store';
import {defaultDataState, IDataState} from '../state';
import {on} from '@ngrx/store';
import {fetchPartnersSuccessAction, updatePartnerSuccessAction} from './actions';

export const partnerReducer = createImmerReducer(
    defaultDataState,
    on(updatePartnerSuccessAction, (state: IDataState, action: ReturnType<typeof updatePartnerSuccessAction>) => {
        if (!state.partner.partners) {
            return state;
        }
        if (action.result.partner) {
            const index = state.partner.partners.result.findIndex((p) => p.id === action.result.partner?.id)
            if (index !== -1) {
                state.partner.partners.result[index] = action.result.partner;
            } else {
                state.partner.partners.result.push(action.result.partner);
            }
        }
        if(action.result.productPartnerDeleted) {
            state.partner.partners.result = state.partner.partners.result.filter((p) => p.id !== action.result.productPartnerDeleted);
        }
        return state;
    }),
    on(fetchPartnersSuccessAction, (state: IDataState, action: ReturnType<typeof fetchPartnersSuccessAction>) => {
        if(action.result){
            state.partner.partners = action.result;
        }
        return state;
    })
);
