import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {GalleryComponent} from './gallery/gallery.component';
import {NewGameComponent} from './new-game/new-game.component';
import {NewGameGroupComponent} from './new-game-group/new-game-group.component';
import {UpdateClueComponent} from './update-clue/update-clue.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrimeModule} from '../../prime.module';
import {ScrollToInvalidControlDirective} from '../../directives/scroll-to-invalid-control.directive';
import {PipesModule} from '../../pipes/pipes.module';
import {ClueListComponent} from './clue-list/clue-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {InventoryItemSelectorComponent} from './inventory-item-selector/inventory-item-selector.component';
import {SelectPositionComponent} from './select-position/select-position.component';
// import {GMapModule} from 'primeng/gmap';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ErrorListComponent} from './error-list/error-list.component';
import {NewGameStepComponent} from './new-game-step/new-game-step.component';
import {NewSaleSourceComponent} from './new-sale-source/new-sale-source.component';
import {NewProductPartnerComponent} from './new-product-partner/new-product-partner.component';
import {GameOptionsComponent} from './forms/game-options/game-options.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {EditorHeaderComponent} from './editor-header/editor-header.component';
import {PermissionsComponent} from './forms/permissions/permissions.component';
import {DialogFooterComponent} from './dialog-footer/dialog-footer.component';
import {GameMapComponent} from './game-map/game-map.component';
import {DirectivesModule} from '../../directives/directives.module';
import {ImportGameComponent} from './import-game/import-game.component';
import {NgxEditorModule} from 'ngx-editor';
import {TextEditorComponent} from './text-editor/text-editor.component';
import {ngxEditorTranslations} from '../../../initial.guard';
import {VoucherImageComponent} from './voucher-image/voucher-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ColorPickerModule} from 'primeng/colorpicker';
import {PermissionTooltipComponent} from './permission-tooltip/permission-tooltip.component';
import {LangOptionsComponent} from './forms/lang-options/lang-options.component';
import {UpdatePartnerComponent} from './update-partner/update-partner.component';
import {ControlErrorComponent} from './control-error/control-error.component';
import {SelectFlagComponent} from './select-flag/select-flag.component';
import {AttributeOptionsComponent} from './forms/attribute-options/attribute-options.component';
import {MapSelectionComponent} from './forms/map-selection/map-selection.component';
import {FormErrorMessageComponent} from './forms/form-error-message/form-error-message.component';
import {EntityTranslationFormComponent} from './forms/entity-translation-form/entity-translation-form.component';
import {TutorialComponent} from './tutorial/tutorial.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { SourceEditorComponent } from './source-editor/source-editor.component';
import {MonacoEditorModule} from 'ngx-monaco-editor-v2';


@NgModule({
    declarations: [
        FileUploadComponent,
        GalleryComponent,
        NewGameComponent,
        NewGameGroupComponent,
        UpdateClueComponent,
        ScrollToInvalidControlDirective,
        ClueListComponent,
        InventoryItemSelectorComponent,
        SelectPositionComponent,
        ErrorListComponent,
        NewGameStepComponent,
        NewSaleSourceComponent,
        NewProductPartnerComponent,
        GameOptionsComponent,
        TooltipComponent,
        EditorHeaderComponent,
        PermissionsComponent,
        DialogFooterComponent,
        GameMapComponent,
        ImportGameComponent,
        TextEditorComponent,
        VoucherImageComponent,
        PermissionTooltipComponent,
        LangOptionsComponent,
        UpdatePartnerComponent,
        ControlErrorComponent,
        FormErrorMessageComponent,
        SelectFlagComponent,
        AttributeOptionsComponent,
        MapSelectionComponent,
        EntityTranslationFormComponent,
        TutorialComponent,
        SourceEditorComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PrimeModule,
        PipesModule,
        FormsModule,
        TranslateModule,
        GoogleMapsModule,
        // GMapModule,
        AutoCompleteModule,
        DirectivesModule,
        NgxEditorModule.forRoot(ngxEditorTranslations),
        ImageCropperModule,
        ColorPickerModule,
        MonacoEditorModule
    ],
    exports: [
        FileUploadComponent,
        GalleryComponent,
        NewGameComponent,
        NewGameGroupComponent,
        UpdateClueComponent,
        ScrollToInvalidControlDirective,
        ClueListComponent,
        InventoryItemSelectorComponent,
        SelectPositionComponent,
        ErrorListComponent,
        GameOptionsComponent,
        TooltipComponent,
        EditorHeaderComponent,
        PermissionsComponent,
        DialogFooterComponent,
        GameMapComponent,
        TextEditorComponent,
        LangOptionsComponent,
        UpdatePartnerComponent,
        ControlErrorComponent,
        AttributeOptionsComponent,
        MapSelectionComponent,
        FormErrorMessageComponent,
        EntityTranslationFormComponent,
        TutorialComponent
    ]
})
export class CustomModule {
}
