import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {lastValueFrom, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MyStorageService} from './services/my-storage.service';
import {environment} from '../environments/environment';
import {AppActions} from './store/app-actions.service';
import {loadUserStateAction} from './store/user/actions';
import {PrimeNGConfig} from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class InitialGuard  {


    loaded = false;

    constructor(
        private translateService: TranslateService,
        private storageService: MyStorageService,
        private appActions: AppActions,
        private config: PrimeNGConfig
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this.loaded) {
            return true;
        }
        return new Promise(async (resolve) => {
            this.loaded = true;
            // 1 Load translations
            this.translateService.setDefaultLang('cs');
            // await this.translateService.get('auth.forgottenPassword').toPromise();
            // const test = await this.translateService.getTranslation('cs').toPromise();
            // console.log(JSON.stringify(flatten(test)));
            this.config.setTranslation(this.translateService.instant('primeng'))
            // Check sync key
            const syncKey = await this.storageService.getSyncKey();
            if (syncKey !== environment.syncKey) {
                await this.storageService.clear();
                await this.storageService.saveSyncKey(environment.syncKey);
            }
            // Load State
            const s = await this.storageService.loadState();
            if (s) {
                await lastValueFrom(this.appActions.dispatch(loadUserStateAction({userState: s.user})));
            }
            this.translateNgxEditor();
            resolve(true);
        });
    }

    translateNgxEditor() {
        ngxEditorTranslations.locals = {
            // menu
            bold: this.translateService.instant('ngx-translations.bold'),
            italic: this.translateService.instant('ngx-translations.italic'),
            code: this.translateService.instant('ngx-translations.code'),
            blockquote: this.translateService.instant('ngx-translations.blockquote'),
            underline: this.translateService.instant('ngx-translations.underline'),
            strike: this.translateService.instant('ngx-translations.strike'),
            bullet_list: this.translateService.instant('ngx-translations.bullet_list'),
            ordered_list: this.translateService.instant('ngx-translations.ordered_list'),
            heading: this.translateService.instant('ngx-translations.heading'),
            h1: this.translateService.instant('ngx-translations.h1'),
            h2: this.translateService.instant('ngx-translations.h2'),
            h3: this.translateService.instant('ngx-translations.h3'),
            h4: this.translateService.instant('ngx-translations.h4'),
            h5: this.translateService.instant('ngx-translations.h5'),
            h6: this.translateService.instant('ngx-translations.h6'),
            align_left: this.translateService.instant('ngx-translations.align_left'),
            align_center: this.translateService.instant('ngx-translations.align_center'),
            align_right: this.translateService.instant('ngx-translations.align_right'),
            align_justify: this.translateService.instant('ngx-translations.align_justify'),
            text_color: this.translateService.instant('ngx-translations.text_color'),
            background_color: this.translateService.instant('ngx-translations.background_color'),

            // popups, forms, others...
            url: this.translateService.instant('ngx-translations.url'),
            text: this.translateService.instant('ngx-translations.text'),
            openInNewTab: this.translateService.instant('ngx-translations.openInNewTab'),
            insert: this.translateService.instant('ngx-translations.insert'),
            altText: this.translateService.instant('ngx-translations.altText'),
            title: this.translateService.instant('ngx-translations.title'),
            remove: this.translateService.instant('ngx-translations.remove'),
        };
    }

}



//@ts-ignore
function traverseAndFlatten(currentNode, target, flattenedKey) {
    for (var key in currentNode) {
        if (currentNode.hasOwnProperty(key)) {
            var newKey;
            if (flattenedKey === undefined) {
                newKey = key;
            } else {
                newKey = flattenedKey + '.' + key;
            }

            var value = currentNode[key];
            if (typeof value === "object") {
                traverseAndFlatten(value, target, newKey);
            } else {
                target[newKey] = value;
            }
        }
    }
}

// @ts-ignore
function flatten(obj) {
    var flattenedObject = {};
    // @ts-ignore
    traverseAndFlatten(obj, flattenedObject);
    return flattenedObject;
}



export const ngxEditorTranslations = {
    locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
    }
};
