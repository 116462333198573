import {Injectable} from '@angular/core';
import {ACLRights, AclService} from './acl.service';
import {GameGroupStateEnum, GameStateEnum, GameTypeEnum} from '../graphql/types.graphql-gen';
import {environment} from '../../environments/environment';
import {FormControl} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class SchemeService {

    constructor(
        private aclService: AclService
    ) {
    }


    get activeGameFormScheme(): GameFormScheme {
        const license = this.aclService.isAllowed(ACLRights.games.gameFormScheme.license);
        const full = this.aclService.isAllowed(ACLRights.games.gameFormScheme.full);
        const simplifiedGlitr = this.aclService.isAllowed(ACLRights.games.gameFormScheme.simplifiedGlitr);
        if (!license && !full && !simplifiedGlitr) {
            return GameFormScheme.full;
        }
        if (full) {
            return GameFormScheme.full;
        }
        if(license) {
            return GameFormScheme.license;
        }
        if(simplifiedGlitr) {
            return GameFormScheme.simplifiedGlitr;
        }
        return GameFormScheme.full;
    }

    get gameFormScheme(): GameFormSchemeType {
        const scheme: GameFormSchemeType = {
            showPrice: true,
            showType: true,
            showLabels: true,
            showAttachments: true,
            showMultiplayer: true,
            showExtraSettings: true,
            showPublic: true,
            showProductPartner: true,
            showIsForFree: true,
            showSendQrCodes: true,
            showMixExtraStats: true,
            showExternalVouchers: environment.options.allowedExternalVouchers,
            defaultGameType: GameTypeEnum.Full,
            showAppPurchaseId: true,
            showDifficultyNote: true,
            showTeamInfo: true,
            showMinTime: true,
            showMaxTime: true,
            showPredefinedNotes: true,
            showTextRating: true,
            showPhotos: true,
            showActiveDownloadFrom: true,
            showActiveFrom: true,
            showCanPurchaseTo: true,
            validators: {
                nameSameAsDifficultyDescription: false,
                productPartnerRequired: true,
                appPurchaseIdRequired: true
            },
            allowedStates: environment.options.allowedGameStates
        };
        const activeScheme = this.activeGameFormScheme;
        if (activeScheme === GameFormScheme.license || activeScheme === GameFormScheme.simplifiedGlitr) {
            scheme.showPrice = false;
            scheme.showType = false;
            scheme.showLabels = false;
            scheme.showExtraSettings = false;
            scheme.showPublic = false;
            scheme.showAttachments = false;
            scheme.showExternalVouchers = false,
            scheme.showProductPartner = false;
            scheme.showSendQrCodes = false;
            scheme.showIsForFree = false;
            scheme.showMixExtraStats = false;
            scheme.showAppPurchaseId = false;
            scheme.showDifficultyNote = false;
            scheme.showMinTime = false;
            scheme.showTeamInfo = false;
            scheme.showMaxTime = false;
            scheme.showPredefinedNotes = false;
            scheme.showTextRating = false;
            scheme.showActiveFrom = false;
            scheme.showActiveDownloadFrom = false;
            scheme.showCanPurchaseTo = false;
            scheme.defaultGameType = GameTypeEnum.Zeda;
            scheme.validators.nameSameAsDifficultyDescription = true;
            scheme.validators.productPartnerRequired = false;
            scheme.validators.appPurchaseIdRequired = false;
        }
        if(activeScheme === GameFormScheme.license) {
            scheme.showMultiplayer = false;
            scheme.showPhotos = true;
        }
        if(this.allowedGameTypes.length > 1) {
            scheme.showType = true;
        }
        if(this.aclService.isAllowed(ACLRights.games.createGameType.full)) {
            scheme.defaultGameType = GameTypeEnum.Full;
        } else if(this.aclService.isAllowed(ACLRights.games.createGameType.zeda)) {
            scheme.defaultGameType = GameTypeEnum.Zeda;
        }

        return scheme;
    }

    get activeGameGroupFormScheme(): GameGroupFormScheme {
        const license = this.aclService.isAllowed(ACLRights.games.gameGroupFormScheme.license);
        const full = this.aclService.isAllowed(ACLRights.games.gameGroupFormScheme.full);
        const simplifiedGlitr = this.aclService.isAllowed(ACLRights.games.gameGroupFormScheme.simplifiedGlitr);
        if (!license && !full && !simplifiedGlitr) {
            return GameGroupFormScheme.full;
        }
        if (full) {
            return GameGroupFormScheme.full;
        }
        if(license) {
            return GameGroupFormScheme.license;
        }
        if(simplifiedGlitr) {
            return GameGroupFormScheme.simplifiedGlitr;
        }
        return GameGroupFormScheme.full;
    }

    get gameGroupFormScheme(): GameGroupFormSchemeType {
        const scheme: GameGroupFormSchemeType = {
            showCity: true,
            showSlug: true,
            showNotificationMails: true,
            showRating: true,
            showDescriptionWeb: true,
            showPhotoSourceAlt: true,
            showVoucherImage: true,
            allowedStates: environment.options.allowedGameGroupStates
        };
        const activeScheme = this.activeGameGroupFormScheme;
        if (activeScheme === GameGroupFormScheme.license || activeScheme === GameGroupFormScheme.simplifiedGlitr) {
            if(activeScheme === GameGroupFormScheme.license) {
                scheme.showCity = false;
            }
            scheme.showSlug = false;
            scheme.showNotificationMails = false;
            scheme.showRating = false;
            scheme.showDescriptionWeb = false;
            scheme.showPhotoSourceAlt = false;
            scheme.showVoucherImage = false;
        }
        scheme.showCity = !this.aclService.user?.license;
        return scheme;
    }


    get allowedGameTypes() {
        const allowedTypes: GameTypeEnum[] = [];
        const full = this.aclService.isAllowed(ACLRights.games.createGameType.full)
        const zeda = this.aclService.isAllowed(ACLRights.games.createGameType.zeda)
        if(full) {
            allowedTypes.push(GameTypeEnum.Full);
        }
        if(zeda) {
            allowedTypes.push(GameTypeEnum.Zeda);
        }
        return allowedTypes;
    }

}


export type GameGroupFormSchemeType = {
    showCity: boolean,
    showSlug: boolean,
    showNotificationMails: boolean,
    showRating: boolean,
    showDescriptionWeb: boolean,
    showPhotoSourceAlt: boolean,
    showVoucherImage: boolean,
    allowedStates: GameGroupStateEnum[]
}

export type GameFormSchemeType = {
    defaultGameType: GameTypeEnum,
    allowedStates: GameStateEnum[]
    validators: {
        nameSameAsDifficultyDescription: boolean,
        productPartnerRequired: boolean,
        appPurchaseIdRequired: boolean,
    },
    showType: boolean,
    showPrice: boolean,
    showMultiplayer: boolean,
    showLabels: boolean,
    showExternalVouchers: boolean,
    showAttachments:boolean,
    showExtraSettings: boolean,
    showPublic: boolean,
    showProductPartner: boolean,
    showIsForFree: boolean,
    showSendQrCodes: boolean,
    showMixExtraStats: boolean,
    showAppPurchaseId: boolean,
    showTeamInfo: boolean,
    showDifficultyNote: boolean,
    showMinTime: boolean,
    showMaxTime: boolean,
    showPredefinedNotes: boolean,
    showTextRating: boolean,
    showPhotos: boolean,
    showActiveFrom: boolean,
    showActiveDownloadFrom: boolean,
    showCanPurchaseTo: boolean,
}

enum GameFormScheme {
    full = 'full',
    license = 'license',
    simplifiedGlitr = 'simplifiedGlitr',
}

enum GameGroupFormScheme {
    full = 'full',
    license = 'license',
    simplifiedGlitr = 'simplifiedGlitr',
}
