import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {InitialGuard} from './initial.guard';
import {LoggedSectionGuard} from './logged-section.guard';
import {AutologinGuard} from './autologin.guard';
import {NotFoundComponent} from './not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [InitialGuard],
        children: [
            {path: '', redirectTo: 'admin', pathMatch: 'full'},
            {
                path: 'admin',
                canActivate: [LoggedSectionGuard],
                loadChildren: () => import('./modules/backend/backend.module').then(m => m.BackendModule),
            },
            {
                path: '',
                canActivate: [AutologinGuard],
                children: [
                    {path: 'login', component: AuthComponent},
                    {path: 'forgotten-password', component: ForgottenPasswordComponent}
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'error'
            },
            {
                path: 'error',
                component: NotFoundComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
