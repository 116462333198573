import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MyFormArray, MyFormGroupV2} from '../../../forms/forms';
import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ElementBaseWithCluesComponent} from '../element-base-with-clues.component';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {GameStepClueInput, StepElement, StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {StepElementForm, StepFormType} from '../../../forms/types';
import * as _ from 'lodash';
import {blockingElementTypes} from '../../../data';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';

@Component({
    selector: 'app-clue-start',
    templateUrl: './clue-start.component.html',
    styleUrls: ['./clue-start.component.scss']
})
export class ClueStartComponent extends ElementBaseWithCluesComponent<SettingsControl> implements OnInit, OnChanges{
    ngOnInit() {
        super.ngOnInit();
        const parent = this.stepElementForm.parent as MyFormArray<StepElementForm>;
        this.subSink.sink = parent.arrayOrderChanged$.subscribe(() => {
            this.stepElementForm.updateValueAndValidity();
        });
    }



    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            skip_info: new FormControl('', {nonNullable: true})
        });
    }

    /**
     * Check if clueStart element has at least one clue
     * @param game
     * @param stepElement
     */
    static getValidators(game: GameFragment, stepElement?: StepElement): ValidatorFn | null {
        return CustomValidatorsService.customValidator((control) => {
            const errors: ValidationErrors = {};
            // @ts-ignore
            const form = control as StepElementForm;
            const root = control.root as StepFormType;
            const parent = control?.parent as MyFormArray<StepElementForm>;
            if (!root?.controls?.basicSettings || !parent) {
                return null;
            }
            if (form.controls.gameStepClues.value.length === 0) {
                errors['missingClues'] = 'games.validatorErrors.elements.clueStart.clueRequired';
            }
            const currentIndex = parent.controls.findIndex((c) => c.value.tempId === control.value.tempId);
            const controlsToSearch = parent.controls.slice(currentIndex + 1);
            const firstBlockingElementIndex = controlsToSearch.findIndex((c) => blockingElementTypes.includes(c.getRawValue().type));
            const clueStartIndex = controlsToSearch.findIndex((c) => c.value.type === StepElementTypeEnum.ClueStart);
            const clueEndIndex = controlsToSearch.findIndex((c) => c.value.type === StepElementTypeEnum.ClueEnd);
            if(clueEndIndex === -1) {
                errors['clueEndMustBeAfterClueStart'] = 'games.validatorErrors.elements.clueStart.clueEndMustBeAfterClueStart';
            } else if(clueStartIndex !== -1 && clueStartIndex < clueEndIndex) {
                errors['multipleClueStartElements'] = 'games.validatorErrors.elements.clueStart.multipleClueStartElements';
            } else if(firstBlockingElementIndex === -1 || (firstBlockingElementIndex !== -1 && firstBlockingElementIndex > clueEndIndex)) {
                errors['blockingElementAfterClueStartMissing'] = 'games.validatorErrors.elements.clueStart.blockingElementAfterClueStartMissing';
            }
            if (!_.isEmpty(errors)) {
                return errors;
            }

            return null;
        });
    }
}


type SettingsControl = {
    skip_info: FormControl<string>
}
