import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {MyFormGroup} from '../../../forms/forms';
import {
    AbstractControl,
    FormControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state';
import {AppActions} from '../../../../store/app-actions.service';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {GameGroupStateEnum, GameStateEnum, GameTypeEnum} from '../../../../graphql/types.graphql-gen';
import {updateGameAction, updateGameFailAction, updateGameSuccessAction} from '../../../../store/games/actions';
import {TranslateService} from '@ngx-translate/core';
import {getGameGroupOptionsSelector} from '../../../../store/games/selectors';
import {GameDifficultyEnum} from '../../../../store/types';
import {getDifficultyOptions, getGameTypeOptions, getYesNoOptions} from '../../../forms/helpers';
import {AclService} from '../../../../services/acl.service';
import {GameFormSchemeType, SchemeService} from '../../../../services/scheme.service';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';

@Component({
    selector: 'app-new-game',
    templateUrl: './new-game.component.html',
    styleUrls: ['./new-game.component.scss']
})
export class NewGameComponent extends BaseComponent implements OnInit {

    form: MyFormGroup<UntypedFormGroup | UntypedFormControl | AbstractControl>;
    loaded = false;
    gameGroups: { id: number, name: string }[];
    loading = {
        form: false
    };
    difficultyOptions = getDifficultyOptions(this.translateService);
    boolOptions = getYesNoOptions(this.translateService);
    gameTypeOptions = getGameTypeOptions(this.translateService);
    groupOptions: { id: number, name: string }[];
    activeFormScheme: GameFormSchemeType;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAppState>,
        private appActions: AppActions,
        private messageService: MessageService,
        private translateService: TranslateService,
        public ref: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private aclService: AclService,
        private schemeService: SchemeService
    ) {
        super();
    }

    ngOnInit(): void {
        this.activeFormScheme = this.schemeService.gameFormScheme;
        this.subSink.sink = combineLatest([
            this.store.select(getGameGroupOptionsSelector).pipe(take(1))
        ]).subscribe((res) => {
            if (this.loaded) {
                return;
            }
            this.loaded = true;
            this.groupOptions = res[0];
            this.initForm();
        });
    }

    initForm() {
        this.form = this.formBuilder.group({
            name: new UntypedFormControl('', Validators.required),
            type: new UntypedFormControl('', Validators.required),
            state: new UntypedFormControl(GameGroupStateEnum.Hidden, Validators.required),
            note: new FormControl<string>(''),
            // public: new FormControl(false, Validators.required),
            difficulty: new UntypedFormControl(GameDifficultyEnum.Medium, Validators.required),
            groupId: new UntypedFormControl(this.dynamicDialogConfig.data.groupId ?? this.groupOptions[0]?.id, Validators.required),
            price: new UntypedFormControl(0.0, Validators.compose([Validators.required, Validators.min(0)])),
            multiplayerGame: new UntypedFormControl(false, Validators.required),
            mixStats: new UntypedFormControl(false, Validators.required),
            isForFree: new UntypedFormControl(false, Validators.required)
        });
        // labelDifficulty will be same as gameName
        if(this.activeFormScheme.validators.nameSameAsDifficultyDescription) {
            this.form.addControl('labelDifficulty', new UntypedFormControl(''));
            this.form.controls.name.addValidators(CustomValidatorsService.customValidator((control) => {
                this.form.controls.labelDifficulty.setValue(control.value ?? '');
                return null;
            }));
        }
        this.form.controls.type.setValue(this.activeFormScheme.defaultGameType);
    }

    createGame() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            console.log(this.form);
            return;
        }
        this.loading.form = true;
        this.appActions.dispatch(updateGameAction({
            groupId: this.form.value.groupId,
            input: {
                updateGame: {
                    ...this.form.value,
                    players: {
                        maxPlayers: 10,
                        minPlayers: 2,
                        playersEnabled: false,
                        placeholder: '',
                        title: ''
                    },
                    extraSettings: {
                        partner: {
                            discountCode: '',
                            redirectCanceled: '',
                            redirectSuccess: '',
                            source: ''
                        }
                    }
                }, gameId: 0
            }
        }), [
            updateGameSuccessAction,
            updateGameFailAction
        ]).subscribe((action) => {
            if (action.type === updateGameFailAction.type) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: action.message});
            } else {
                this.ref.close({groupId: this.form.value.groupId, gameId: action.game?.id});
            }
            this.loading.form = false;
        });
    }

}


type FormValue = {
    name: string,
    state: GameStateEnum,
    public: boolean,
    difficulty: number,
    groupId: number,
    price: number,
    multiplayerGame: boolean,
    mixStats: boolean,
    isForFree: boolean
}
