import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-select-flag',
    templateUrl: './select-flag.component.html',
    styleUrls: ['./select-flag.component.scss']
})
export class SelectFlagComponent implements OnInit {

    flags: string[];
    filteredFlags: string[];
    search: string = '';
    constructor(
        private dynamicDialogConfig: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef
    ) {
    }

    ngOnInit(): void {
        this.flags = this.dynamicDialogConfig.data.flags;
        this.filteredFlags = this.dynamicDialogConfig.data.flags;
    }

    selectFlag(flag: string) {
        this.dialogRef.close(flag)
    }

    filter() {
        this.filteredFlags = this.flags.filter((f) => f.includes(this.search));
    }
}
