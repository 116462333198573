import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    static getSettingsFormGroup(): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            seconds: new FormControl(5, {validators: Validators.compose([Validators.required, Validators.min(1)]), nonNullable: true}),
            showSpinner: new FormControl(false, {nonNullable: true}),
            showTimer: new FormControl(false, {nonNullable: true})
        });
    }



}

type SettingsControl = {
    seconds: FormControl<number>,
    showSpinner: FormControl<boolean>,
    showTimer: FormControl<boolean>
}
