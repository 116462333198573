<div class="full-page-wrapper justify-content-center">
    <div class="col-10 col-lg-4 mx-auto auth-form">
        <h1 class="text-center">
            {{projectName}} - Manager
<!--            {{'auth.administration'|translate|firstUpper}}-->
        </h1>
        <form class="user-detail__general" #loginForm="ngForm">
            <div class="user-detail__info">
                <div class="form-group">
                    <label for="email" class="mb-2">E-mail</label>
                    <input
                        ngModel
                        required
                        email
                        id="email"
                        type="email"
                        name="email"
                        class="form-control"
                    />
                </div>
                <div class="form-group mt-3">
                    <label for="password" class="mb-2">{{'auth.form.password'| translate | firstUpper}}</label>
                    <input
                        ngModel
                        required
                        minlength="4"
                        id="password"
                        type="password"
                        name="password"
                        class="form-control"/>
                </div>
                <div class="form-group mt-4" style="position: relative; width: fit-content">
                    <button
                        (click)="onSubmit(loginForm)"
                        [disabled]="!loginForm.valid || isLoading"
                        class="btn btn-primary btn-block" style="min-width: 120px">
                        <span *ngIf="!isLoading">Přihlásit se</span>
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span>
                    </button>
                    <button pButton icon="pi pi-facebook" class="p-button-sm p-button-info ml-2" (click)="facebookLogin()"></button>
                    <asl-google-signin-button style="display: inline-block; width: 32px; height: 32px; overflow: hidden"  *ngIf="googleAllowed" width="32" type='icon' size='medium'></asl-google-signin-button>
                </div>
            </div>
        </form>
        <p class="mt-2"><a
            routerLink="/forgotten-password">{{'auth.forgottenPassword'|translate|firstUpper}}</a></p>
    </div>
    <swal #loginFailedAlert

          title="{{'auth.loginFailed'|translate}}"
          text=""
          icon="error"
    >
    </swal>

</div>
