import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetInitialDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInitialDataQuery = (
  { __typename?: 'Query' }
  & { getManagerCities: (
    { __typename?: 'ManagerCitiesResult' }
    & { result: Array<(
      { __typename?: 'City' }
      & MetaCityFragment
    )> }
  ), getLanguages: (
    { __typename?: 'LanguagesResult' }
    & { result: Array<(
      { __typename?: 'Lang' }
      & LangFragment
    )> }
  ), getPlayerInfo?: Types.Maybe<(
    { __typename?: 'Player' }
    & Pick<Types.Player, 'managedGameIds' | 'implicitPermissions'>
  )>, getProjectData: (
    { __typename?: 'ProjectDataResult' }
    & Pick<Types.ProjectDataResult, 'availablePages'>
    & { availableAttributes: Array<(
      { __typename?: 'AttributeOption' }
      & AttributeOptionFragment
    )>, availableMaps: Array<(
      { __typename?: 'MapOption' }
      & MapOptionFragment
    )>, availableAttachments: Array<(
      { __typename?: 'Attachment' }
      & AttachmentOptionFragment
    )>, availableLicenses: Array<(
      { __typename?: 'LicenseOption' }
      & Pick<Types.LicenseOption, 'id' | 'name'>
    )>, availablePartners: Array<(
      { __typename?: 'AvailablePartner' }
      & AvailablePartnerFragment
    )>, availableGameGroups: Array<(
      { __typename?: 'GameGroupOption' }
      & AvailableGameGroupFragment
    )> }
  ) }
);

export type AvailableGameGroupFragment = (
  { __typename?: 'GameGroupOption' }
  & Pick<Types.GameGroupOption, 'id' | 'name'>
);

export type AvailablePartnerFragment = (
  { __typename?: 'AvailablePartner' }
  & Pick<Types.AvailablePartner, 'id' | 'name' | 'discount' | 'discountType' | 'discountDirectSale' | 'discountDirectSaleType' | 'commissionType' | 'activeFrom' | 'activeTo' | 'deleted' | 'createdAt' | 'updatedAt'>
);

export type MetaCityFragment = (
  { __typename?: 'City' }
  & Pick<Types.City, 'id' | 'name' | 'hidden'>
);

export type AttachmentOptionFragment = (
  { __typename?: 'Attachment' }
  & Pick<Types.Attachment, 'id' | 'title' | 'name'>
);

export type AttributeOptionFragment = (
  { __typename?: 'AttributeOption' }
  & Pick<Types.AttributeOption, 'id' | 'active' | 'name' | 'type' | 'level' | 'ordering' | 'parentId'>
);

export type MapOptionFragment = (
  { __typename?: 'MapOption' }
  & Pick<Types.MapOption, 'id' | 'type' | 'name' | 'googleId' | 'parent'>
);

export type LangFragment = (
  { __typename?: 'Lang' }
  & Pick<Types.Lang, 'id' | 'code' | 'urlCode' | 'flagPath' | 'active' | 'name' | 'isDefault'>
);

export type PartnerFragment = (
  { __typename?: 'Partner' }
  & Pick<Types.Partner, 'id' | 'name' | 'discount' | 'discountType' | 'discountDirectSale' | 'discountDirectSaleType' | 'code' | 'commissionType' | 'gameIds' | 'activeFrom' | 'activeTo' | 'deleted' | 'createdAt' | 'updatedAt'>
  & { productPartners: Array<(
    { __typename?: 'ProductPartner' }
    & Pick<Types.ProductPartner, 'id'>
  )> }
);

export const AvailableGameGroupFragmentDoc = gql`
    fragment availableGameGroup on GameGroupOption {
  id
  name
}
    `;
export const AvailablePartnerFragmentDoc = gql`
    fragment availablePartner on AvailablePartner {
  id
  name
  discount
  discountType
  discountDirectSale
  discountDirectSaleType
  commissionType
  activeFrom
  activeTo
  deleted
  createdAt
  updatedAt
}
    `;
export const MetaCityFragmentDoc = gql`
    fragment metaCity on City {
  id
  name
  hidden
}
    `;
export const AttachmentOptionFragmentDoc = gql`
    fragment attachmentOption on Attachment {
  id
  title
  name
}
    `;
export const AttributeOptionFragmentDoc = gql`
    fragment attributeOption on AttributeOption {
  id
  active
  name
  type
  level
  ordering
  parentId
}
    `;
export const MapOptionFragmentDoc = gql`
    fragment mapOption on MapOption {
  id
  type
  name
  googleId
  parent
}
    `;
export const LangFragmentDoc = gql`
    fragment lang on Lang {
  id
  code
  urlCode
  flagPath
  active
  name
  isDefault
}
    `;
export const PartnerFragmentDoc = gql`
    fragment partner on Partner {
  id
  name
  discount
  discountType
  discountDirectSale
  discountDirectSaleType
  code
  commissionType
  gameIds
  productPartners {
    id
  }
  activeFrom
  activeTo
  deleted
  createdAt
  updatedAt
}
    `;
export const GetInitialDataDocument = gql`
    query getInitialData {
  getManagerCities(filter: {filter: {limit: 1000}}) {
    result {
      ...metaCity
    }
  }
  getLanguages(filter: {filter: {limit: 1000}}) {
    result {
      ...lang
    }
  }
  getPlayerInfo {
    managedGameIds
    implicitPermissions
  }
  getProjectData {
    availablePages
    availableAttributes {
      ...attributeOption
    }
    availableMaps {
      ...mapOption
    }
    availableAttachments {
      ...attachmentOption
    }
    availableLicenses {
      id
      name
    }
    availablePartners {
      ...availablePartner
    }
    availableGameGroups {
      ...availableGameGroup
    }
  }
}
    ${MetaCityFragmentDoc}
${LangFragmentDoc}
${AttributeOptionFragmentDoc}
${MapOptionFragmentDoc}
${AttachmentOptionFragmentDoc}
${AvailablePartnerFragmentDoc}
${AvailableGameGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInitialDataGQL extends Apollo.Query<GetInitialDataQuery, GetInitialDataQueryVariables> {
    document = GetInitialDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }