import * as Types from '../../types.graphql-gen';

import { PartnerFragment } from '../getInitialData/getinitialData.graphql-gen';
import { gql } from 'apollo-angular';
import { PartnerFragmentDoc } from '../getInitialData/getinitialData.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPartnersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPartnersQuery = (
  { __typename?: 'Query' }
  & { getPartners: (
    { __typename?: 'PartnerResult' }
    & { result: Array<(
      { __typename?: 'Partner' }
      & PartnerFragment
    )> }
  ) }
);

export const GetPartnersDocument = gql`
    query getPartners {
  getPartners(filter: {filter: {limit: 1000}}) {
    result {
      ...partner
    }
  }
}
    ${PartnerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPartnersGQL extends Apollo.Query<GetPartnersQuery, GetPartnersQueryVariables> {
    document = GetPartnersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }