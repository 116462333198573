import {createAction, props} from '@ngrx/store';
import {
    GameVoucher,
    GameVoucherFilter,
    GameVoucherInput,
    GameVouchersResult,
    UpdateGameVoucherResult
} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';

// ACTIONS
export const fetchGameVouchersAction = createAction('[GameVoucher] FETCH_GAME_VOUCHERS', props<FetchGameVouchersActionProps>());
export const fetchGameVouchersSuccessAction = createAction('[GameVoucher] FETCH_GAME_VOUCHERS_SUCCESS', props<FetchGameVouchersSuccessActionProps>());
export const fetchGameVouchersFailAction = createAction('[GameVoucher] FETCH_GAME_VOUCHERS_FAIL', props<FetchGameVouchersFailActionProps>());

export const fetchGameVoucherAction = createAction('[GameVoucher] FETCH_GAME_VOUCHER', props<FetchGameVoucherActionProps>());
export const fetchGameVoucherSuccessAction = createAction('[GameVoucher] FETCH_GAME_VOUCHER_SUCCESS', props<FetchGameVoucherSuccessActionProps>());
export const fetchGameVoucherFailAction = createAction('[GameVoucher] FETCH_GAME_VOUCHER_FAIL', props<FetchGameVoucherFailActionProps>());

export const updateGameVoucherAction = createAction('[GameVoucher] UPDATE_GAME_VOUCHER', props<UpdateGameVoucherActionProps>());
export const updateGameVoucherSuccessAction = createAction('[GameVoucher] UPDATE_GAME_VOUCHER_SUCCESS', props<UpdateGameVoucherSuccessActionProps>());
export const updateGameVoucherFailAction = createAction('[GameVoucher] UPDATE_GAME_VOUCHER_FAIL', props<UpdateGameVoucherFailActionProps>());


// Action Types
export type UpdateGameVoucherSuccessActionType = ReturnType<typeof updateGameVoucherSuccessAction>;
// PROPS
export type UpdateGameVoucherActionProps = {input: GameVoucherInput};
export type UpdateGameVoucherSuccessActionProps = {result: UpdateGameVoucherResult};
export type UpdateGameVoucherFailActionProps = DefaultFailMessage;


export type FetchGameVoucherActionProps = { id: number };
export type FetchGameVoucherSuccessActionProps = { gameVoucher: GameVoucher | null };
export type FetchGameVoucherFailActionProps = DefaultFailMessage;

export type FetchGameVouchersActionProps = { filter: GameVoucherFilter };
export type FetchGameVouchersSuccessActionProps = { result: GameVouchersResult };
export type FetchGameVouchersFailActionProps = DefaultFailMessage;
