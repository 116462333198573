import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    fetchPurchaseAction,
    fetchPurchaseFailAction,
    fetchPurchasesAction,
    fetchPurchasesFailAction,
    fetchPurchasesSuccessAction,
    fetchPurchaseSuccessAction,
    updatePurchaseAction,
    updatePurchaseFailAction,
    updatePurchaseSuccessAction
} from './actions';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {GetPurchasesGQL} from '../../graphql/queries/getPurchases/getPurchases.graphql-gen';
import {GetPurchaseGQL} from '../../graphql/queries/getPurchase/getPurchase.graphql-gen';
import {fetchPlayerFailAction} from '../player/actions';
import {fixMutation} from '../../shared/helpers';
import {UpdatePurchaseGQL} from '../../graphql/mutations/updatePurchase/updatePurchase.graphql-gen';

@Injectable()
export class PurchasesEffect implements OnDestroy {


    subSink = new SubSink();

    fetchPurchases$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPurchasesAction
        ),
        concatMap((a) => {
            return this.getPurchasesGQL.fetch({filter: a.filter});
        }),
        map((res) => {
            if (res.errors) {
                return fetchPurchasesFailAction({header: '', message: res.errors[0].message});
            }
            return fetchPurchasesSuccessAction({purchases: res.data});
        })
    ));

    updatePurchase$ = createEffect(() => this.actions$.pipe(
        ofType(
            updatePurchaseAction
        ),
        concatMap((action) => {
               return fixMutation(this.updatePurchaseGQL.mutate({input: action.input}));
        }),
        map((res) => {
            if(res.errors) {
                return updatePurchaseFailAction({message: res.errors[0].message, header: ''})
            }
            return updatePurchaseSuccessAction({result: res.data.updatePurchase});
        })
    ));

    fetchPurchase$ = createEffect(() => this.actions$.pipe(
        ofType(
            fetchPurchaseAction
        ),
        concatMap((a) => {
            return this.getPurchaseGQL.fetch({id: a.id});
        }),
        map((res) => {
            if (res.errors) {
                return fetchPurchaseFailAction({header: '', message: res.errors[0].message});
            }
            if (!res.data.getPurchases.result[0]) {
                return fetchPlayerFailAction({header: '', message: 'purchase_not_found'});
            }
            return fetchPurchaseSuccessAction({purchase: res.data.getPurchases.result[0]})
        })
    ));

    constructor(
        private actions$: Actions,
        private getPurchasesGQL: GetPurchasesGQL,
        private getPurchaseGQL: GetPurchaseGQL,
        private updatePurchaseGQL: UpdatePurchaseGQL
    ) {
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
