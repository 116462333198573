import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {PermissionGroup, PermissionGroupTypeEnum, Player} from '../../../../../graphql/types.graphql-gen';
import {AbstractControl, FormControl, UntypedFormControl} from '@angular/forms';
import {MyFormGroupV2} from '../../../../forms/forms';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {firstUpper} from '../../../../helpers';
import {environment} from '../../../../../../environments/environment';

type Permission = { actions: string[], actionGroups: { [actionGroup: string]: { actions: string[] } } };
type GroupNames = string[];

@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent extends BaseComponent implements OnInit {


    form: MyFormGroupV2<{
        permissions: FormControl<any[]>
    }>;
    permissions: { [module: string]: Permission } = {};
    readOnlyPermissions: { [permission: string]: GroupNames } = {};
    @Input() allPermissions: string[][];
    @Input() player: Player | null = null;
    @Input() permissionGroup: PermissionGroup | null;
    @Input() control: AbstractControl | null;
    @Input() disabled = false;
    @Output() onChange = new EventEmitter<string[][]>();

    permissionTemplates = environment.permissionTemplates;


    constructor(
        private translateService: TranslateService
        // private controlContainer: ControlContainer
    ) {
        super();
    }

    ngOnInit(): void {
        // console.log(this.controlContainer.control);
        this.initForm();
    }

    initForm() {
        this.initPermissions();
        this.form = new MyFormGroupV2({
            permissions: new UntypedFormControl([])
        });
        if (this.player) {
            this.form.controls.permissions.setValue(this.player.implicitPermissions.map((p) => ([p[1], p[2]])))
        } else if (this.permissionGroup) {
            this.form.controls.permissions.setValue(this.permissionGroup.permissions.map((p) => ([p[1], p[2]])))
        }
    }

    private initPermissions() {
        this.permissions = {};
        this.readOnlyPermissions = {};
        this.allPermissions.forEach(([role, module, action]) => {
            if (!this.permissions[module]) {
                this.permissions[module] = {actions: [], actionGroups: {}};
            }
            if (action.includes('-')) {
                const [actionGroup, subAction] = action.split('-');
                if (!this.permissions[module].actionGroups[actionGroup]) {
                    this.permissions[module].actionGroups[actionGroup] = {actions: []};
                }
                this.permissions[module].actionGroups[actionGroup].actions.push(subAction);
                this.permissions[module].actionGroups[actionGroup].actions.sort(sortActions);
            } else {
                this.permissions[module].actions.push(action);
                this.permissions[module].actions.sort(sortActions);
            }
        });
        if (this.player?.implicitPermissions) {
            this.player.implicitPermissions
                .filter(([role]) => role !== 'player-' + this.player?.id)
                .forEach(([role, module, action]) => {
                    if (!this.readOnlyPermissions[module + '.' + action]) {
                        this.readOnlyPermissions[module + '.' + action] = [];
                    }
                    this.readOnlyPermissions[module + '.' + action].push(role);
                });
        }
        if (!this.permissionGroup || this.permissionGroup?.type !== PermissionGroupTypeEnum.License) {
            if (!this.readOnlyPermissions[this.aclRights.games.gameGroupFormScheme.license]) {
                this.readOnlyPermissions[this.aclRights.games.gameGroupFormScheme.license] = [firstUpper(this.translateService.instant('permission.messages.licenseOnlyPermission'))];
            }
            if (!this.readOnlyPermissions[this.aclRights.games.gameFormScheme.license]) {
                this.readOnlyPermissions[this.aclRights.games.gameFormScheme.license] = [firstUpper(this.translateService.instant('permission.messages.licenseOnlyPermission'))];
            }
        }
    }

    permissionChanged(module: string, action: string) {
        console.log('test');
        const formValue: string[][] = this.form.getRawValue().permissions;
        const added = !!formValue.find(([m, a]) => module === m && action === a);
        let affectedActions: string[][] = [];
        if (action === 'all') {
            affectedActions.push(...this.permissions[module].actions.map((v) => [module, v]));
            if (this.permissions[module].actionGroups) {
                for (const [actionGroup, value] of Object.entries(this.permissions[module].actionGroups)) {
                    affectedActions.push(...value.actions.map((v) => [module, actionGroup + '-' + v]));
                }
            }
        } else if (action.includes('-')) {
            const [group, a] = action.split('-');
            if (a === 'all') {
                if (this.permissions[module].actionGroups[group]?.actions) {
                    affectedActions.push(...this.permissions[module].actionGroups[group]?.actions.map((v) => [module, group + '-' + v]));
                }
            }
        }
        if (!action.includes('Assigned')) {
            const assigned = this.allPermissions.find(([role, m, a]) => m === module && a === action + 'Assigned');
            if (assigned) {
                affectedActions.push([assigned[1], assigned[2]]);
            }
        } else if (!added) {
            const assigned = this.allPermissions.find(([role, m, a]) => m === module && a === action.replace('Assigned', ''));
            if (assigned) {
                affectedActions.push([assigned[1], assigned[2]]);
            }
        }
        affectedActions = affectedActions.filter(([m, a]) => !this.readOnlyPermissions[m + '.' + a]);
        let newValue = formValue.map((v) => v.join('|'));

        // Added
        if (added) {
            newValue.push(...affectedActions.map((v) => v.join('|')));
            newValue = _.uniq(newValue);
        }
        // removed
        else {
            if (action.includes('-')) {
                const [group, a] = action.split('-');
                if (a !== 'all') {
                    affectedActions.push([module, group + '-all']);
                    affectedActions.push([module, 'all']);
                }
            } else if (action !== 'all') {
                affectedActions.push([module, 'all']);
            }
            newValue = _.difference(newValue, affectedActions.map((v) => v.join('|')));
        }
        this.form.setValue({permissions: newValue.map((v) => v.split('|'))});
        this.onChange.emit(this.form.value.permissions);
        if (this.control) {
            this.control.setValue(this.form.value.permissions);
            this.control.markAsTouched();
        }
    }

    setPermissions(template: { permissions: [string, string][]; name: string }) {
        const permissionsToRemove: [string, string][] = [];
        const permissionsToAdd: [string, string][] = [];
        (this.form.value.permissions ?? []).forEach((permission: [string, string]) => {
            if(!this.readOnlyPermissions[permission[0] + '.' + permission[1]]) {
                permissionsToRemove.push(permission);
            }
        })
        template.permissions.forEach((permission) => {
            if(!this.readOnlyPermissions[permission[0] + '.' + permission[1]]) {
                permissionsToAdd.push(permission);
            }
        });
        let newValue: [string, string][] = this.form.controls.permissions.value;

        newValue = _.difference(newValue, permissionsToRemove);
        newValue = [...newValue, ...permissionsToAdd];

        this.form.controls.permissions.setValue(newValue);
        this.onChange.emit(this.form.value.permissions);
        if (this.control) {
            this.control.setValue(this.form.value.permissions);
            this.control.markAsTouched();
        }
    }
}


const sortActions = (a: string, b: string) => {
    if (a === 'all' && b !== 'all') {
        return -1;
    }
    if (a !== 'all' && b === 'all') {
        return 1;
    }
    // if(a.includes('Assigned') && !b.includes('Assigned')) {
    //     return 1;
    // }
    // if(!a.includes('Assigned') && b.includes('Assigned')) {
    //     return -1;
    // }
    if (a > b) {
        return 1;
    }
    if (b > a) {
        return -1;
    }
    return 0;
}
