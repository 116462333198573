<div class="p-fluid grid" [formGroup]="stepElementForm.controls.settings">
    <div class="col-12">
        <label for="label" class="pb-2">
            {{'elements.continueButton.label'|translate|firstUpper}}
            <app-tooltip image="element-continueButton-label.png"
                         value="{{'elements.tooltip.continueButton.label'|translate|firstUpper}}"></app-tooltip>
        </label>
        <input id="label"
               formControlName="label" type="text" pInputText
               class="{{stepElementForm.get('settings.label')?.touched && stepElementForm.get('settings.label')?.invalid ? 'ng-invalid ng-dirty' : ''}}">
    </div>
</div>
