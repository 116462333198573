import * as Types from '../../types.graphql-gen';

import { FullLicenseFragment, LicenseKeyFragment } from '../../queries/getLicenses/getLicenses.graphql-gen';
import { gql } from 'apollo-angular';
import { FullLicenseFragmentDoc, LicenseKeyFragmentDoc } from '../../queries/getLicenses/getLicenses.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateLicenseMutationVariables = Types.Exact<{
  licenseInput?: Types.InputMaybe<Types.LicenseInput>;
  licenseKeyInput?: Types.InputMaybe<Types.LicenseKeyInput>;
}>;


export type UpdateLicenseMutation = (
  { __typename?: 'Mutation' }
  & { updateLicense: (
    { __typename?: 'UpdateLicenseResult' }
    & Pick<Types.UpdateLicenseResult, 'removedLicenseKeyId' | 'licenseKeyVoucherDownloadLink'>
    & { license?: Types.Maybe<(
      { __typename?: 'License' }
      & FullLicenseFragment
    )>, licenseKey?: Types.Maybe<(
      { __typename?: 'LicenseKey' }
      & LicenseKeyFragment
    )> }
  ) }
);

export const UpdateLicenseDocument = gql`
    mutation updateLicense($licenseInput: LicenseInput, $licenseKeyInput: LicenseKeyInput) {
  updateLicense(licenseInput: $licenseInput, licenseKeyInput: $licenseKeyInput) {
    license {
      ...fullLicense
    }
    licenseKey {
      ...licenseKey
    }
    removedLicenseKeyId
    licenseKeyVoucherDownloadLink
  }
}
    ${FullLicenseFragmentDoc}
${LicenseKeyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLicenseGQL extends Apollo.Mutation<UpdateLicenseMutation, UpdateLicenseMutationVariables> {
    document = UpdateLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }