import {Component, OnInit} from '@angular/core';
import {ElementBaseComponent} from '../element-base.component';
import {MyFormGroupV2} from '../../../forms/forms';
import {FormControl, ValidationErrors} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {getGameSelector} from '../../../../store/games/selectors';
import {take} from 'rxjs/operators';
import {Game, StepElementTypeEnum} from '../../../../graphql/types.graphql-gen';
import {CustomValidatorsService} from '../../../forms/custom-validators.service';
import {StepElementForm, StepFormType} from '../../../forms/types';
import {GameHelper} from '../../../forms/game-helpers';
import * as _ from 'lodash';
import {GameFragment} from '../../../../graphql/mutations/updateGame/updateGame.graphql-gen';

@Component({
    selector: 'app-inventory-new-item',
    templateUrl: './inventory-new-item.component.html',
    styleUrls: ['./inventory-new-item.component.scss']
})
export class InventoryNewItemComponent extends ElementBaseComponent<SettingsControl> implements OnInit {

    game: Game;
    selectedItems: string[];

    static getSettingsFormGroup(game: GameFragment): MyFormGroupV2<SettingsControl> {
        return new MyFormGroupV2({
            assignedItems: new FormControl({}, {validators: CustomValidatorsService.customValidator((control) => {
                    const errors: ValidationErrors = {};
                    const root = control.root as StepFormType;
                    const parent = control?.parent?.parent as StepElementForm;
                    if (!root?.controls?.basicSettings || !parent) {
                        return null;
                    }
                    if (Object.keys(control.value).length === 0) {
                        errors['assignedItemRequired'] = 'games.validatorErrors.elements.inventoryNewItem.assignedItemRequired';
                        return errors;
                    }
                    const allGameElements = GameHelper.getAllGameStepElements('values', game, root);
                    const allAssignedItemsInOtherStepElements = [].concat.apply([],
                        // @ts-ignore
                        allGameElements.filter((se) => ( GameHelper.getStepElementInputId(se) !== GameHelper.getStepElementInputId(parent.value)) && se.type === StepElementTypeEnum.InventoryNewItemAlert)
                            .map((se) => Object.keys(se.settings.assignedItems)));
                    if (_.intersection(Object.keys(control.value), allAssignedItemsInOtherStepElements).length) {
                        errors['assignedItemInAnotherElement'] = 'games.validatorErrors.elements.inventoryNewItem.assignedItemInAnotherElement';
                    }
                    if (!_.isEmpty(errors)) {
                        return errors;
                    }
                    return null;
                }), nonNullable: true}),
            text: new FormControl('', {nonNullable: true}),
            title: new FormControl('', {nonNullable: true})
        });
    }


    ngOnInit() {
        super.ngOnInit();
        this.loaded = false;
        this.subSink.sink = combineLatest([
            this.store.select(getGameSelector),
        ]).pipe(take(1)).subscribe((res) => {
            this.game = res[0] as Game;
            this.selectedItems = Object.keys(this.stepElementForm.controls.settings.controls.assignedItems.value).map((v) => v.toString());
            this.loaded = true;
        });
    }

    selectedItemsChanged(ids: number[]) {
        this.stepElementForm.markAsTouched();
        const obj: {
            [key: string]: { selected: 'on' };
        } = {};
        ids.forEach((id) => {
            obj[id] = {selected: 'on'};
        });
        this.stepElementForm.controls.settings.controls.assignedItems.setValue(obj);
        (this.stepElementForm.root as StepFormType).controls.stepElements.controls.contentElements.controls
            .filter((c) => c.controls.type.value === StepElementTypeEnum.InventoryNewItemAlert).forEach((control) => {
            // @ts-ignore
            control.controls.settings.controls.assignedItems.updateValueAndValidity();
        });
    }
}

type SettingsControl = {
    title: FormControl<string>,
    text: FormControl<string>,
    assignedItems: FormControl<{
        [key: string]: { selected: 'on' };
    }>
}
