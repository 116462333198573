import {createSelector} from '@ngrx/store';
import {IAppState, IDataState} from '../state';
import {GetInitialDataQuery, LangFragment} from '../../graphql/queries/getInitialData/getinitialData.graphql-gen';

export const dataStateSelector = createSelector(
    (state: IAppState) => state.data,
    (data): IDataState => data
);

export const initialDataSelector = createSelector(
    dataStateSelector,
    (data) =>  ({cities: data.helperData.cities, languages: data.helperData.languages}) as InitialDataType
);


export const availableLanguagesSelector = createSelector(
    initialDataSelector,
    (data) => [...(data?.languages ?? [])].sort(function (a,b) {
        if(a.isDefault) {
            return -1;
        }
        return a.id - b.id;
    })
);

export type InitialDataType = {
    cities: GetInitialDataQuery['getManagerCities']['result'],
    languages: GetInitialDataQuery['getLanguages']['result']
}
