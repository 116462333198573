import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'noSanitize'
})
export class NoSanitizePipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) {

    }
    transform(html: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }

}

@Pipe({
    name: "decodeHtmlString",
    pure: true
})
export class DecodeHtmlString implements PipeTransform {
    transform(value: string) {
        const tempElement = document.createElement("div")
        tempElement.innerHTML = value
        return tempElement.innerText
    }
}
