import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { PushNotificationFragment, FullPushNotificationFragment } from '../../mutations/updatePushNotification/updatePushNotification.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { PushNotificationFragmentDoc, FullPushNotificationFragmentDoc } from '../../mutations/updatePushNotification/updatePushNotification.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPushNotificationsQueryVariables = Types.Exact<{
  filter: Types.PushNotificationFilter;
}>;


export type GetPushNotificationsQuery = (
  { __typename?: 'Query' }
  & { getPushNotifications: (
    { __typename?: 'PushNotificationsResult' }
    & { meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )>, result: Array<(
      { __typename?: 'PushNotification' }
      & PushNotificationFragment
    )> }
  ) }
);

export type GetPushNotificationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetPushNotificationQuery = (
  { __typename?: 'Query' }
  & { getPushNotifications: (
    { __typename?: 'PushNotificationsResult' }
    & { result: Array<(
      { __typename?: 'PushNotification' }
      & FullPushNotificationFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export const GetPushNotificationsDocument = gql`
    query getPushNotifications($filter: PushNotificationFilter!) {
  getPushNotifications(filter: $filter) {
    meta {
      ...metaFragment
    }
    result {
      ...pushNotification
    }
  }
}
    ${MetaFragmentFragmentDoc}
${PushNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPushNotificationsGQL extends Apollo.Query<GetPushNotificationsQuery, GetPushNotificationsQueryVariables> {
    document = GetPushNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPushNotificationDocument = gql`
    query getPushNotification($id: String!) {
  getPushNotifications(filter: {id: $id}) {
    result {
      ...fullPushNotification
    }
    meta {
      ...metaFragment
    }
  }
}
    ${FullPushNotificationFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPushNotificationGQL extends Apollo.Query<GetPushNotificationQuery, GetPushNotificationQueryVariables> {
    document = GetPushNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }