import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {InventoryItem} from '../../../../graphql/types.graphql-gen';
import {Checkbox} from 'primeng/checkbox';

@Component({
    selector: 'app-inventory-item-selector',
    templateUrl: './inventory-item-selector.component.html',
    styleUrls: ['./inventory-item-selector.component.scss']
})
export class InventoryItemSelectorComponent implements OnInit {

    @Input() inventoryItems: InventoryItem[] = [];
    @Input() selectedItemIds: string[] = [];
    @Output() selectedItemChangedEvent = new EventEmitter<number[]>();


    constructor() {
    }

    ngOnInit(): void {
    }

    click(checkbox?: Checkbox) {
        if(checkbox) {
            checkbox.inputViewChild!.nativeElement.click();
            return;
        }
        setTimeout(() => {
            const selectedItemIds = this.selectedItemIds;
            this.selectedItemIds = selectedItemIds;
            this.selectedItemChangedEvent.emit(selectedItemIds.map((id) => parseInt(id, 10)));
        });
    }
}
