import * as Types from '../../types.graphql-gen';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateSaleSourceMutationVariables = Types.Exact<{
  input: Types.SaleSourceInput;
}>;


export type UpdateSaleSourceMutation = (
  { __typename?: 'Mutation' }
  & { updateSaleSource: (
    { __typename?: 'UpdateSaleSourceResult' }
    & UpdateSaleSourceFragmentFragment
  ) }
);

export type UpdateSaleSourceFragmentFragment = (
  { __typename?: 'UpdateSaleSourceResult' }
  & Pick<Types.UpdateSaleSourceResult, 'saleSourceDeleted'>
  & { saleSource?: Types.Maybe<(
    { __typename?: 'SaleSource' }
    & SaleSourceFragmentFragment
  )>, changeOrder?: Types.Maybe<Array<(
    { __typename?: 'ChangeOrderResult' }
    & Pick<Types.ChangeOrderResult, 'entityId' | 'sortId'>
  )>> }
);

export type SaleSourceFragmentFragment = (
  { __typename?: 'SaleSource' }
  & Pick<Types.SaleSource, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'order' | 'isForFree'>
  & { identifiers: Array<(
    { __typename?: 'SaleSourceIdentifier' }
    & SaleSourceIdentifierFragmentFragment
  )> }
);

export type SaleSourceIdentifierFragmentFragment = (
  { __typename?: 'SaleSourceIdentifier' }
  & Pick<Types.SaleSourceIdentifier, 'id' | 'identifier' | 'createdAt' | 'updatedAt'>
);

export const SaleSourceIdentifierFragmentFragmentDoc = gql`
    fragment saleSourceIdentifierFragment on SaleSourceIdentifier {
  id
  identifier
  createdAt
  updatedAt
}
    `;
export const SaleSourceFragmentFragmentDoc = gql`
    fragment saleSourceFragment on SaleSource {
  id
  name
  createdAt
  updatedAt
  order
  isForFree
  identifiers {
    ...saleSourceIdentifierFragment
  }
}
    ${SaleSourceIdentifierFragmentFragmentDoc}`;
export const UpdateSaleSourceFragmentFragmentDoc = gql`
    fragment updateSaleSourceFragment on UpdateSaleSourceResult {
  saleSource {
    ...saleSourceFragment
  }
  saleSourceDeleted
  changeOrder {
    entityId
    sortId
  }
}
    ${SaleSourceFragmentFragmentDoc}`;
export const UpdateSaleSourceDocument = gql`
    mutation updateSaleSource($input: SaleSourceInput!) {
  updateSaleSource(input: $input) {
    ...updateSaleSourceFragment
  }
}
    ${UpdateSaleSourceFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSaleSourceGQL extends Apollo.Mutation<UpdateSaleSourceMutation, UpdateSaleSourceMutationVariables> {
    document = UpdateSaleSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }