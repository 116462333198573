<div class="p-fluid" [formGroup]="form">
    <div>
        <label for="text" class="pb-2">
            {{'elements.pairSentence.title'|translate|firstUpper}}
            <app-tooltip value="{{'elements.pairSentence.title.tooltip'|translate|firstUpper}}"></app-tooltip>
        </label>
        <input
            id="text"
            class="{{form.controls.text.touched && form.controls.text.invalid ? 'ng-invalid ng-dirty' : ''}}"
            formControlName="text"
            type="text"
            pInputText>
    </div>
    <div class="mt-4">
        <label for="fontSize" class="pb-2">
            {{'elements.pairSentence.fontSize'|translate|firstUpper}}
            <app-tooltip value="{{'elements.pairSentence.fontSize.tooltip'|translate|firstUpper}}"></app-tooltip>
        </label>
        <p-inputNumber
            id="fontSize"
            [showButtons]="true"
            locale="cs-CS"
            [min]="0"
            class="{{form.controls.fontSize.touched && form.controls.fontSize.invalid ? 'ng-invalid ng-dirty' : ''}}"
            formControlName="fontSize"
        ></p-inputNumber>
        <p style="font-size: {{form.controls.fontSize.value}}px; margin-top: 4px">{{'general.fontSize'|translate|firstUpper}}</p>
    </div>
    <div class="mt-4">
        <div style="width: 220px">
            <label for="src" class="pb-2">
                {{'elements.pairSentence.src'|translate|firstUpper}}
                <app-tooltip value="{{'elements.pairSentence.src.tooltip'|translate|firstUpper}}"></app-tooltip>
            </label>
            <app-file-upload id="src" [file]="image" [uploadHandler]="onFileUpload()"
                             [multiple]="false"></app-file-upload>
        </div>
    </div>
</div>
