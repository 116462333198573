import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StepElementForm, ZedaGameFormType} from '../../forms/types';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {MyFormGroupV2} from '../../forms/forms';
import {getYesNoOptions, getYesNoOptionsAlt} from '../../forms/helpers';
import {TranslateService} from '@ngx-translate/core';
import {AppActions} from '../../../store/app-actions.service';
import {DialogService} from 'primeng/dynamicdialog';
import {SubSink} from 'subsink';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../store/state';
import {StepElement} from '../../../graphql/types.graphql-gen';
import {GameHelper} from '../../forms/game-helpers';
import {preferencesSelector} from '../../../store/user/selectors';
import {take} from 'rxjs/operators';
import {GameFragment} from '../../../graphql/mutations/updateGame/updateGame.graphql-gen';

@Component({
    template: ''
})

export abstract class ElementBaseComponent<SettingsControls extends {
    [key: string]: AbstractControl;
}, ElementPreferences extends any = any> implements OnInit, OnDestroy {

    // @Input() stepElement: MyStepElement<T>;

    @Input() stepElementForm: StepElementForm<SettingsControls>;
    @Input() readonly = false;
    boolOptions = getYesNoOptions(this.translateService);
    boolOptionsAlt = getYesNoOptionsAlt(this.translateService);
    uniqueId: string;
    subSink: SubSink;
    loaded = false;

    preferences: ElementPreferences;

    constructor(
        protected translateService: TranslateService,
        protected appActions: AppActions,
        protected dialogService: DialogService,
        protected store: Store<IAppState>
    ) {
        this.subSink = new SubSink();
    }

    ngOnInit() {
        this.uniqueId = this.stepElementForm.controls.tempId.value;
        this.loadPreferences();
        this.loaded = true;
    }

    loadPreferences() {
        this.store.select(preferencesSelector).pipe(take(1)).subscribe((preferences) => {
            if (preferences?.elements[this.constructor.name]) {
                this.preferences = preferences.elements[this.constructor.name];
            }
        });
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    static getSettingsFormGroup(game?: GameFragment, stepElement?: StepElement): MyFormGroupV2<any> {
        throw new Error('CREATE THIS METHOD');
    };

    static getValidators(game?: GameFragment, stepElement?: StepElement): ValidatorFn | null {
        return null;
    }

    getAvailableTakePhotoButtonIds(game: GameFragment) {
        if (!(this.stepElementForm.root instanceof MyFormGroupV2)) {
            return [];
        }
        if (this.stepElementForm.root?.controls?.steps) {
            const root = this.stepElementForm.root as ZedaGameFormType;
            return GameHelper.getAvailableTakePhotoButtonIds(this.stepElementForm, root.controls.steps.controls);
        }
        return GameHelper.getAvailableTakePhotoButtonIds(this.stepElementForm, this.stepElementForm.root, game);
    }
}
