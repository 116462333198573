import {Directive, OnInit} from '@angular/core';
import {Editor} from 'primeng/editor';
// import Quill from 'quill';
import {take} from 'rxjs/operators';
import {shiftEnterHandler} from '../quill-editor';
import * as QUILL from 'quill';
import Delta from 'quill-delta';

@Directive({
    selector: '[appQuillEditor]'
})
export class QuillEditorDirective implements OnInit {

    constructor(
        private editor: Editor
    ) {
    }

    ngOnInit(): void {
        this.editor.onInit.pipe(take(1)).subscribe(() => {
            const quill = (this.editor.getQuill() as QUILL.Quill);
            // @ts-ignore
            quill.keyboard.bindings[13] = [{ key: 13, shiftKey: true, handler: shiftEnterHandler}, ...quill.keyboard.bindings['13']];
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node: any, delta: QUILL.DeltaStatic) {
                const d = new Delta()
                d.insert(node.innerText);
                //@ts-ignore
                return d as QUILL.DeltaStatic;
            });
        })
    }


}
