import {createAction, props} from '@ngrx/store';
import {ManagerPlayerProgressFilter, PlayerProgress, PlayerProgressInputType} from '../../graphql/types.graphql-gen';
import {DefaultFailMessage} from '../user/actions';
import {GetPlayerProgressesQuery} from '../../graphql/queries/getPlayerProgresses/getPlayerProgresses.graphql-gen';
import {
    UpdatePlayerProgressMutation
} from '../../graphql/mutations/updatePlayerProgress/updatePlayerProgress.graphql-gen';

export const fetchProgressesAction = createAction('[Progress] FETCH_PROGRESSES', props<FetchProgressesActionProps>());
export const fetchProgressesSuccessAction = createAction('[Progress] FETCH_PROGRESSES_SUCCESS', props<FetchProgressesSuccessActionProps>());
export const fetchProgressesFailAction = createAction('[Progress] FETCH_PROGRESSES_FAIL', props<FetchProgressesFailActionProps>());

export const fetchProgressAction = createAction('[Progress] FETCH_PROGRESS', props<FetchProgressActionProps>());
export const fetchProgressSuccessAction = createAction('[Progress] FETCH_PROGRESS_SUCCESS', props<FetchProgressSuccessActionProps>());
export const fetchProgressFailAction = createAction('[Progress] FETCH_PROGRESS_FAIL', props<FetchProgressFailActionProps>());

export const updateProgressAction = createAction('[Progress] UPDATE_PROGRESS', props<UpdateProgressActionProps>());
export const updateProgressSuccessAction = createAction('[Progress] UPDATE_PROGRESS_SUCCESS', props<UpdateProgressSuccessActionProps>());
export const updateProgressFailAction = createAction('[Progress] UPDATE_PROGRESS_FAIL', props<UpdateProgressFailActionProps>());


export type UpdateProgressActionProps = { input: PlayerProgressInputType }
export type UpdateProgressSuccessActionProps = { result: UpdatePlayerProgressMutation['updateManagerPlayerProgress'] }
export type UpdateProgressFailActionProps = DefaultFailMessage;

export type FetchProgressesActionProps = { filter: ManagerPlayerProgressFilter };
export type FetchProgressesSuccessActionProps = { result: GetPlayerProgressesQuery['getManagerPlayerProgresses'] };
export type FetchProgressesFailActionProps = DefaultFailMessage;

export type FetchProgressActionProps = { id: number };
export type FetchProgressSuccessActionProps = { progress: PlayerProgress | null };
export type FetchProgressFailActionProps = DefaultFailMessage;
