import * as Types from '../../types.graphql-gen';

import { MetaFragmentFragment, PurchaseFragment } from '../getPurchases/getPurchases.graphql-gen';
import { LangFragmentFragment } from '../../mutations/login/login.graphql-gen';
import { gql } from 'apollo-angular';
import { MetaFragmentFragmentDoc, PurchaseFragmentDoc } from '../getPurchases/getPurchases.graphql-gen';
import { LangFragmentFragmentDoc } from '../../mutations/login/login.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPlayersQueryVariables = Types.Exact<{
  filter: Types.PlayerFilter;
}>;


export type GetPlayersQuery = (
  { __typename?: 'Query' }
  & { getPlayers: (
    { __typename?: 'PlayersResult' }
    & { result: Array<(
      { __typename?: 'Player' }
      & PlayerFragment
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type GetPlayerIdsQueryVariables = Types.Exact<{
  filter: Types.PlayerFilter;
}>;


export type GetPlayerIdsQuery = (
  { __typename?: 'Query' }
  & { getPlayers: (
    { __typename?: 'PlayersResult' }
    & { result: Array<(
      { __typename?: 'Player' }
      & Pick<Types.Player, 'id'>
    )>, meta?: Types.Maybe<(
      { __typename?: 'PageInfo' }
      & MetaFragmentFragment
    )> }
  ) }
);

export type FullPlayerFragment = (
  { __typename?: 'Player' }
  & Pick<Types.Player, 'permissions' | 'implicitPermissions'>
  & PlayerFragment
);

export type PlayerFragment = (
  { __typename?: 'Player' }
  & Pick<Types.Player, 'id' | 'fullname' | 'email' | 'userToken' | 'synchKey' | 'receiveNewsletter' | 'fromFacebook' | 'fbUserId' | 'isEditor' | 'partnerId' | 'editorPartnerId' | 'role' | 'language' | 'createdAt' | 'updatedAt' | 'agreementGdpr' | 'agreementTermsAndConditions' | 'agreementNewsletter' | 'agreementValidTo' | 'agreementExtensionToken'>
  & { playerGames?: Types.Maybe<Array<(
    { __typename?: 'PlayerGame' }
    & { game: (
      { __typename?: 'Game' }
      & Pick<Types.Game, 'id'>
    ) }
  )>>, permissionGroups: Array<(
    { __typename?: 'PermissionGroup' }
    & Pick<Types.PermissionGroup, 'id' | 'type' | 'name'>
  )> }
  & LangFragmentFragment
);

export const PlayerFragmentDoc = gql`
    fragment player on Player {
  id
  fullname
  email
  userToken
  synchKey
  receiveNewsletter
  fromFacebook
  fbUserId
  isEditor
  partnerId
  editorPartnerId
  role
  language
  createdAt
  updatedAt
  agreementGdpr
  agreementTermsAndConditions
  agreementNewsletter
  agreementValidTo
  agreementExtensionToken
  ...langFragment
  playerGames {
    game {
      id
    }
  }
  permissionGroups {
    id
    type
    name
  }
}
    ${LangFragmentFragmentDoc}`;
export const FullPlayerFragmentDoc = gql`
    fragment fullPlayer on Player {
  ...player
  permissions
  implicitPermissions
}
    ${PlayerFragmentDoc}`;
export const GetPlayersDocument = gql`
    query getPlayers($filter: PlayerFilter!) {
  getPlayers(filter: $filter) {
    result {
      ...player
    }
    meta {
      ...metaFragment
    }
  }
}
    ${PlayerFragmentDoc}
${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayersGQL extends Apollo.Query<GetPlayersQuery, GetPlayersQueryVariables> {
    document = GetPlayersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPlayerIdsDocument = gql`
    query getPlayerIds($filter: PlayerFilter!) {
  getPlayers(filter: $filter) {
    result {
      id
    }
    meta {
      ...metaFragment
    }
  }
}
    ${MetaFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPlayerIdsGQL extends Apollo.Query<GetPlayerIdsQuery, GetPlayerIdsQueryVariables> {
    document = GetPlayerIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }